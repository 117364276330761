<script setup lang="ts">
import { updateAgency } from '@/api/mutations/updateAgency';
import { updateAgencyDocument } from '@/api/mutations/updateAgencyDocument';
import { addAgencyDocument } from '@/api/mutations/addAgencyDocument';
import { getAgency } from '@/api/queries/agency';
import Button from '@/components/atoms/Button.vue';
import Notification from '@/components/atoms/Notification.vue';
import GoBack from '@/components/molecules/GoBack.vue';
import AgenciesUpdateForm from '@/components/organisms/CreateUpdateForm/AgenciesUpdateForm.vue';
import strings from '@/data/Agencies.json';
import genericString from '@/data/GenericStrings.json';
import { NOTIFICATION_DURATION } from '@/data/constants';
import type { MutationUpdateAgencyArgs, UpdateAgencyInput } from '@/gql/Gql.types';
import { DocumentType } from '@/gql/Gql.types';
import { computed, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Document {
  documentId?: string;
  file?: object;
  type?: DocumentType;
}

const { agencyUpdatedErrorToast, agencyUpdatedSuccessToast, agencySubmitLabel } = strings;

const { genericError, updatedSuccessDoc, updatedErrorDoc, addSuccessDoc, addErrorDoc } =
  genericString;

const {
  params: { id },
} = useRoute();

const router = useRouter();
const submitted = ref(false);
const manageAgency = ref(false);
const notificationPopup = ref(false);
const notesSaved = ref(false);
const biographySaved = ref(false);

const {
  data: dataAgency,
  isError: isErrorFetchingAgency,
  isSuccess: isSuccessAgencyLoaded,
  remove,
  refetch,
} = getAgency({ agencyId: id as string });

const headline = computed(() => {
  if (!dataAgency.value) return '';

  const { agency } = dataAgency.value;
  return agency.companyName;
});

const datas = computed(
  () => !(!dataAgency.value || !isSuccessAgencyLoaded) && dataAgency.value.agency
);

const formData = ref<UpdateAgencyInput>();
const data = reactive<MutationUpdateAgencyArgs>({
  newAgencyData: {} as UpdateAgencyInput,
  agencyId: id as string,
});

watch(formData, () => (data.newAgencyData = { ...formData.value }));

const enabled = computed(() => Object.keys(data.newAgencyData).length > 0 && !!id);
const { isSuccess, isError } = updateAgency(data, { enabled });
const updateTalentSubmit = (data: UpdateAgencyInput) => (
  (formData.value = data), (submitted.value = false)
);

const document = ref<Document>();

const updateDoc = reactive({
  documentId: '',
  agencyId: id as string,
  file: {},
});

const addDoc = reactive({
  agencyId: id as string,
  file: {},
  type: '' as DocumentType,
});

const enabledUpdateDoc = computed(() => Object.keys(updateDoc.documentId).length > 0 && !!id);
const {
  isSuccess: successUpdateDoc,
  isError: errorUpdateDoc,
  remove: removeUpdateDoc,
} = updateAgencyDocument(
  { data: updateDoc },
  {
    enabled: enabledUpdateDoc,
  }
);

const enabledAddDoc = computed(() => Object.keys(addDoc.type).length > 0 && !!id);
const {
  isSuccess: successAddDoc,
  isError: errorAddDoc,
  remove: removeAddDoc,
} = addAgencyDocument(
  { data: addDoc },
  {
    enabled: enabledAddDoc,
  }
);

const updateAgencyDocumentSumbit = (doc: object) => (document.value = { ...doc });

watch(document, () => {
  const { documentId = '', file, type } = document.value || {};
  if (!file) return;

  if (type && type.length > 0) {
    addDoc.file = file;
    addDoc.type = type;
  } else {
    updateDoc.documentId = documentId;
    updateDoc.file = file;
  }
});

watch(isErrorFetchingAgency, () => isErrorFetchingAgency && router.push('/agencies'));

const correctMessage = computed(() => {
  switch (true) {
    case errorUpdateDoc.value:
      return updatedErrorDoc;

    case successUpdateDoc.value:
      return updatedSuccessDoc;

    case successAddDoc.value:
      return addSuccessDoc;

    case errorAddDoc.value:
      return addErrorDoc;

    case isSuccess.value:
      return agencyUpdatedSuccessToast;

    case isError.value:
      return agencyUpdatedErrorToast;

    default:
      return genericError;
  }
});

watch(
  [isSuccess, successUpdateDoc, successAddDoc, manageAgency, notesSaved, biographySaved],
  async () => {
    if (
      !isSuccess.value &&
      !successUpdateDoc.value &&
      !successAddDoc.value &&
      !manageAgency.value &&
      !notesSaved.value &&
      !biographySaved.value
    )
      return;
    await refetch();
    if (successUpdateDoc.value) {
      await removeUpdateDoc();
    }
    if (successAddDoc.value) {
      await removeAddDoc();
    }
  }
);

const onSubmit = () => {
  submitted.value = true;

  setTimeout(() => {
    submitted.value = false;
  }, 300);
};

watch([isError, isSuccess, successUpdateDoc, errorUpdateDoc, successAddDoc, errorAddDoc], () => {
  if (
    isError.value ||
    isSuccess.value ||
    errorUpdateDoc.value ||
    errorAddDoc.value ||
    successUpdateDoc.value ||
    successAddDoc.value
  ) {
    notificationPopup.value = true;

    setTimeout(() => {
      updateDoc.documentId = '';
      updateDoc.file = '';

      addDoc.type = '' as DocumentType;
      addDoc.file = {};
      data.newAgencyData = {};
    }, NOTIFICATION_DURATION);
  } else {
    notificationPopup.value = false;
  }
});
</script>

<template>
  <div class="UpdateAgency">
    <GoBack :headline="headline" previous-route="/agencies" />
    <div class="UpdateAgency__submit">
      <Button :label="agencySubmitLabel" @clicked="onSubmit" />
    </div>
    <div class="UpdateAgency__form container">
      <div class="col-12">
        <AgenciesUpdateForm
          v-if="datas"
          v-bind="{ ...datas }"
          :submitted="submitted"
          @agency-data="updateTalentSubmit"
          @update-document="updateAgencyDocumentSumbit"
          @manage-talent="(action: boolean) => manageAgency = action"
          @saved-notes="(notes: boolean) => notesSaved = notes"
          @saved-biography="(biography: boolean) => biographySaved = biography"
        />
      </div>
    </div>
    <Notification
      v-if="notificationPopup"
      :message="correctMessage"
      :status="isError || errorUpdateDoc || errorAddDoc ? 'error' : 'success'"
      :duration="NOTIFICATION_DURATION"
    />
  </div>
</template>

<style scoped lang="scss">
.UpdateAgency {
  position: relative;

  &__form {
    margin-top: 4rem;
  }

  &__submit {
    position: absolute;
    right: 4rem;
    top: 4rem;
  }
}
</style>
