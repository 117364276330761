import { gql } from '@/api/graphql-client';
import { usersFragment } from '@/api/fragments/users';

export const loginMutation = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      accessToken
      refreshToken
      user {
        ...node
      }
    }
  }
  ${usersFragment}
`;
