import { gql } from '@/api/graphql-client';
import { residenceFragment } from '@/api/fragments/residence';

export const billingFragment = gql`
  fragment billing on BillingType {
    private {
      birthCity
      birthProvince
      nationality
      phone
      registeredResidence {
        ...residence
      }
      taxIdCode
    }
    termsAcceptance
    vat {
      businessName
      registeredResidence {
        ...residence
      }
      vat
    }
  }
  ${residenceFragment}
`;
