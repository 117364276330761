<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';
import Button from '@/components/atoms/Button.vue';
import Upload from '@/components/atoms/Upload.vue';
import { type DocumentOutputType, DocumentType } from '@/gql/Gql.types';

type Buttons = {
  [key: string]: string;
};

interface IMultiButtonProps {
  title: string;
  buttonCV?: Buttons;
  CVrules?: any;
  buttonPortfolio?: Buttons;
  PortfolioRules?: any;
  viewCV?: string;
  viewPortfolio?: string;
  updateDoc?: string;
  documents?: DocumentOutputType[];
}

const props = defineProps<IMultiButtonProps>();

const {
  title,
  buttonCV,
  CVrules,
  PortfolioRules,
  buttonPortfolio,
  viewCV,
  viewPortfolio,
  updateDoc,
} = toRefs(props);

const documents = ref(props.documents);
const successDoc = ref(false);

watch(
  () => props.documents,
  (newDoc) => {
    if (newDoc) {
      documents.value = [...newDoc];
      successDoc.value = true;
    }
  }
);

const typeDocuments = computed(() => documents?.value?.map((docs) => docs.type));

const showAddButtons = computed(() => typeDocuments?.value?.length === 0);

const documentId = ref('');
const file = ref();
const type = ref<DocumentType>();

const emit = defineEmits(['updateDoc', 'openModalCV']);

watch([documentId, type], () => {
  if (documentId.value.length > 0 || (type.value && type.value?.length > 0)) {
    successDoc.value = false;
    emit('updateDoc', { documentId: documentId.value, file: file.value, type: type.value });
    documentId.value = '';
    file.value = '';
    type.value = undefined;
  }
});
</script>

<template>
  <div class="MultiButtonDocs">
    <h3 class="heading-h5">{{ title }}</h3>
    <div class="MultiButtonDocs__buttonWrapper">
      <div v-if="!showAddButtons" v-for="(doc, index) in documents" :key="index">
        <Button
          :label="doc.type === 'CV' ? viewCV : viewPortfolio"
          @clicked="$emit('openModalCV', [true, doc.url])"
        />
        <Upload
          variant="link-tertiary"
          :label="updateDoc"
          tag="a"
          @upload="(upload) => ((file = upload), (documentId = doc.documentId))"
          :success="successDoc"
        />
      </div>
      <div class="MultiButtonDocs__buttonWrapper--addFile">
        <Upload
          :label="buttonCV?.label"
          :name="buttonCV?.name"
          :rules="documents?.length === 0 ? CVrules : 'pdf'"
          variant="secondary"
          icon="plus"
          tag="a"
          @upload="(upload) => ((file = upload), (type = DocumentType.Cv))"
          :success="successDoc"
        />
        <Upload
          :label="buttonPortfolio?.label"
          :name="buttonPortfolio?.name"
          :rules="PortfolioRules"
          variant="secondary"
          icon="plus"
          tag="a"
          @upload="(upload) => ((file = upload), (type = DocumentType.Portfolio))"
          :success="successDoc"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MultiButtonDocs {
  &__buttonWrapper {
    @include flexing(row, start, start);
    gap: 2.4rem;
    flex-wrap: wrap;
    margin: 2.4rem 0;

    &--viewFile {
      @include flexing(column, start, start);
    }

    &--addFile {
      @include flexing(row, start, start);
      gap: 2.4rem;
      :deep &.Button {
        .label-icon {
          margin-left: 1.397rem;
          order: 1;
        }
      }
    }
  }
}
</style>
