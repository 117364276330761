<script setup lang="ts">
import { getCoupons } from '@/api/queries/coupons';
import Button from '@/components/atoms/Button.vue';
import Select from '@/components/atoms/Select.vue';
import Tab from '@/components/atoms/Tab.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import Table from '@/components/organisms/Table/Table.vue';
import { usePagination } from '@/composables/usePagination';
import { ITab, useTabOptions } from '@/composables/useTabOptions';
import { useTable } from '@/composables/useTable';
import CouponString from '@/data/Coupon.json';
import strings from '@/data/GenericStrings.json';
import type { GetCouponsQueryVariables } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { ITableColumns } from '@/models/tableModel';
import moment from 'moment';
import 'moment/dist/locale/it';
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const { selectVisible, tabSelection, editAction } = CouponString;

const { columns, selectItems } = useTable();

const route = useRoute();

const {
  items: tabItems,
  setActiveTab,
  activeTab,
} = useTabOptions({
  tab: ITab.Coupon,
  pathName: route.matched[0].name as string,
});

const tabActived = computed(() => (activeTab.value as string).includes(tabItems[0].name));

const isActive = ref(true);
const visibleResult = ref(+selectVisible.options[0].value);

const selectVisibleResult = (result: Option) => (visibleResult.value = +result.value);

const page = ref<number>(1);

const {
  data: dataCount,
  status: statusCount,
  isLoading: isLoadingCount,
  refetch: refetchCount,
} = getCoupons();

const params = reactive<GetCouponsQueryVariables>({
  first: visibleResult.value,
  filters: { isActive: isActive.value },
});
const { data, status, isLoading, refetch } = getCoupons(params);

const countCoupon = computed(() => {
  if (
    statusCount.value !== 'success' ||
    isLoadingCount.value ||
    !dataCount.value ||
    !dataCount.value?.coupons ||
    status.value !== 'success' ||
    isLoading.value ||
    !data.value ||
    !data.value.coupons
  )
    return {
      active: 0,
      deleted: 0,
    };

  // total coupon minus coupon active
  if (isActive.value) {
    return {
      active: data.value?.coupons?.totalCount,
      deleted: dataCount.value?.coupons?.totalCount - data.value?.coupons?.totalCount,
    };
  } else {
    return {
      active: dataCount.value?.coupons?.totalCount - data.value?.coupons?.totalCount,
      deleted: data.value?.coupons?.totalCount,
    };
  }
});

const totalPages = computed(() => {
  const count = isActive.value ? countCoupon.value.active : countCoupon.value.deleted;
  return Math.ceil(count / visibleResult.value);
});

const statusFilterTabs = computed(() =>
  tabSelection.map(({ label, name }, index: number) => ({
    name,
    label: `${label} ${index === 0 ? countCoupon.value.active : countCoupon.value.deleted}`,
  }))
);

watch(activeTab, () => {
  isActive.value = tabActived.value;
});

const paginationCouponValue = computed(() =>
  isActive.value
    ? countCoupon.value.active
    : countCoupon.value.deleted - visibleResult.value * (totalPages.value - 1)
);

const experienceCoupon = computed(() => (category?: string, location?: string) => {
  if (!category || !location) return '-';
  return category + '<br>' + location;
});

const items = computed(() => {
  if (status.value !== 'success' || isLoading.value || !data.value || !data.value?.coupons)
    return [];

  return (data.value?.coupons?.edges || [])?.map((element) => {
    const { node: coupon } = element;

    return {
      id: coupon.id,
      createdAt: coupon.createdAt,
      lastEdit: coupon.updatedAt,
      codeName: coupon.name,
      code: coupon.code,
      percentage: `${coupon.discount}%`,
      redemptions: coupon.maxRedemptions,
      email: coupon.associatedEmail || '-',
      domain: coupon.associatedDomain || '-',
      expirationDate: coupon.expirationDate
        ? moment(coupon.expirationDate).locale('it').format('DD/MM/YYYY')
        : '-',
      experience: experienceCoupon.value(
        coupon.experience?.category?.[0].title,
        coupon.experience?.location
      ),
      actions: {
        labelTo: editAction,
        tag: 'router-link',
        to: '/coupon/edit/' + coupon.id,
      },
    };
  });
});

watch([isActive, visibleResult], async () => {
  page.value = 1;
  delete params.after;
  delete params.before;
  delete params.last;
  params.first = visibleResult.value;
  params.filters = {
    isActive: isActive.value,
  };
  await refetchCount();
  await refetch();
});

const couponsPagination = computed(() => {
  if (!data.value) return undefined;
  const {
    endCursor: nextPage,
    hasNextPage,
    hasPreviousPage,
    startCursor: prevPage,
  } = data.value.coupons.pageInfo;
  return {
    label: strings.coupon,
    currentPage: page.value ?? 1,
    nextPage,
    hasNextPage,
    prevPage,
    hasPreviousPage,
    totalItem: isActive.value ? countCoupon.value.active : countCoupon.value.deleted,
    totalPages: totalPages.value,
  };
});
const changePage = async (type: 'first' | 'prev' | 'next' | 'last', cursor: string | undefined) => {
  const { currentPage, paginationParams } = usePagination(
    type,
    cursor,
    page.value,
    visibleResult.value,
    totalPages.value,
    paginationCouponValue.value,
    params
  );
  page.value = currentPage;
  Object.assign(params, paginationParams);
};
</script>

<template>
  <main class="Coupon container-fluid">
    <div class="container col-12">
      <div class="Coupon__heading col-12 mb-2">
        <h1 class="Coupon__heading-title heading-h1">{{ strings.coupon }}</h1>
        <Button
          v-if="items.length > 0 && !isLoading"
          :label="CouponString.createCoupon"
          tag="router-link"
          to="/coupon/add"
          icon="plus"
        />
      </div>
      <div class="col-12">
        <Tab
          class="Coupon__tab mb-1"
          :items="statusFilterTabs"
          :initial-active="(activeTab as string)"
          @clicked="setActiveTab"
        />
        <div v-if="items.length < 1 && !isLoading && tabActived" class="Coupon__empty">
          <p class="Coupon__empty-paragraph heading-h5 col-12">
            {{ CouponString.emptyCoupons }}
          </p>
          <Button
            :label="CouponString.createCoupon"
            tag="router-link"
            to="/coupon/add"
            icon="plus"
          />
        </div>
        <div class="Coupon__select mb-2" v-if="items.length > 0">
          <Select
            :label="selectVisible.label"
            :options="selectVisible.options"
            :initial-value="selectVisible.options[0]"
            variant="table"
            @selected="selectVisibleResult"
          />
        </div>
        <Table
          :checkable="false"
          :items="items || []"
          :columns="columns[ITableColumns.Coupon]"
          :is-loading="isLoading"
          @select-items="selectItems"
        />
        <Pagination
          v-if="couponsPagination && items.length > 0"
          v-bind="{ ...couponsPagination }"
          @change-page="changePage"
        />
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.Coupon {
  &__heading {
    @include flexing(row, space-between, center);
    &-title {
      margin: 0;
    }
  }

  &__empty {
    &-paragraph {
      margin: 0 0 2.4rem;
    }
  }

  &__select {
    :deep .Select {
      justify-content: end;
    }
  }
}
</style>
