<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

const { title } = toRefs(props);
</script>

<template>
  <div class="FormGroup">
    <h2 class="FormGroup-title heading-h4">{{ title }}</h2>
    <slot name="formContent"></slot>
  </div>
</template>

<style scoped lang="scss">
.FormGroup {
  margin: 0 0 1.6rem 0;
  border: 0.1rem solid $black-20;
  border-radius: 0.4rem;
  padding: 2.4rem;
  &-title {
    margin: 0 0 1.6rem 0;
  }

  :deep {
    .Accordion,
    .AccordionForm {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
