import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { TalentsCounterQuery, TalentsCounterQueryVariables } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const talentsCounterQuery = gql`
  query talentsCounter {
    talentsCounter {
      _count
      status
    }
  }
`;

export const getTalentsCounter = (
  options?: UseQueryOptions<TalentsCounterQuery, void, TalentsCounterQuery, any[]>
): UseQueryReturnType<TalentsCounterQuery, TalentsCounterQueryVariables> =>
  useGraphql({
    query: talentsCounterQuery,
    options,
  });
