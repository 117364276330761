import { gql } from '@/api/graphql-client';
import { couponFragment } from '@/api/fragments/coupon';
import type { MutationUpdateCouponArgs, Coupon } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateCouponMutation = gql`
  mutation UpdateCoupon($couponId: String!, $data: UpdateCouponInput!) {
    updateCoupon(couponId: $couponId, data: $data) {
      ...coupon
    }
  }
  ${couponFragment}
`;

export const updateCoupon = (
  props: MutationUpdateCouponArgs,
  options?: UseQueryOptions<Coupon, MutationUpdateCouponArgs, Coupon, any[]>
): UseQueryReturnType<Coupon, MutationUpdateCouponArgs> =>
  useGraphql({
    query: updateCouponMutation,
    variables: props,
    options,
  });
