import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { MutationCreateFreeOrderArgs, Order } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

export const addParticipantMutation = gql`
  mutation CreateFreeOrder($data: CreateFreeOrderInput!) {
    createFreeOrder(data: $data) {
      coupon
      experienceId
      guests {
        birthDate
        company
        createdAt
        email
        firstName
        gender
        id
        isOwner
        lastName
        role
        roomType
        updatedAt
      }
    }
  }
`;

export const addParticipant = (
  props?: MutationCreateFreeOrderArgs,
  options?: UseQueryOptions<Order, MutationCreateFreeOrderArgs, Order, any[]>
): UseQueryReturnType<Order, MutationCreateFreeOrderArgs> =>
  useGraphql({
    query: addParticipantMutation,
    variables: props,
    options,
  });
