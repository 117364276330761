<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import Button from '@/components/atoms/Button.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import string from '@/data/MailManagement.json';
import { Field } from 'vee-validate';

const { MailForm } = string;

interface IBoxTextArea {
  label?: string;
  placeholder?: string;
  name: string;
  initialValue?: string[];
  rules?: any;
}

const props = defineProps<IBoxTextArea>();

const { label, placeholder, name, initialValue } = toRefs(props);

const totParagraphs = ref<string[]>([...(initialValue?.value || '')] ?? ['']);

const paragraph = ref(totParagraphs.value[0] ?? '');

const indexTotParagraphs = ref(0);

const writeParagraph = (s: string) => {
  paragraph.value = s;
};

const saveParagraph = () => {
  totParagraphs.value[indexTotParagraphs.value] = paragraph.value;
};

const addParagraph = () => {
  saveParagraph();
  paragraph.value = '';

  totParagraphs.value.push(paragraph.value ?? '');

  indexTotParagraphs.value = totParagraphs.value.length - 1;
};

const changeParagraph = (index: number) => {
  saveParagraph();
  indexTotParagraphs.value = index;
  paragraph.value = totParagraphs.value[index];
};

const deleteParagraph = () => {
  totParagraphs.value.splice(indexTotParagraphs.value, 1);
  indexTotParagraphs.value = totParagraphs.value.length - 1;
  paragraph.value = totParagraphs.value[totParagraphs.value.length - 1];
};

watch(paragraph, (newValue, _) => {
  if (paragraph.value === newValue) {
    setTimeout(() => {
      saveParagraph();
    }, 700);
  }
});
</script>

<template>
  <Field :name="name" v-model="totParagraphs" v-slot="{ field, errors }" :rules="rules">
    <div class="BoxTextArea">
      <o-inputitems v-model="totParagraphs" v-bind="field" />
      <div class="BoxTextArea__buttons">
        <div>
          <Button
            v-for="(_, index) in totParagraphs"
            :key="index"
            :label="(index + 1).toString()"
            @clicked="() => changeParagraph(index)"
            :variant="indexTotParagraphs === index ? 'secondary' : 'primary'"
          />
          <Button variant="secondary" type="icon" icon="plus" @clicked="addParagraph" />
        </div>
        <Button
          v-if="totParagraphs.length > 1"
          class="BoxTextArea__buttons-deleteParagraph"
          :label="MailForm.deleteParagraph"
          variant="link-tertiary-status"
          @clicked="deleteParagraph"
        />
      </div>
      <TextAreaForm
        :label="label"
        :placeholder="placeholder"
        :name="name"
        :initial-value="paragraph"
        :standalone="true"
        @keystart="writeParagraph"
      />
    </div>
  </Field>
</template>

<style scoped lang="scss">
.BoxTextArea {
  :deep {
    .o-inputit {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 0;
    }
    .Button {
      @extend .text-body-1;
      height: 4rem;
      padding: 0.8rem;
      border-radius: 0;
    }
  }

  &__buttons {
    @include flexing(row, space-between, center);
    flex-wrap: wrap;
  }
}
</style>
