<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import InputText from '@/components/atoms/InputText.vue';
import LinkModuleString from '@/data/LinkModule.json';
import Select from '@/components/atoms/Select.vue';
import type { Option } from '@/models/selectModel';
import type { PortfolioLinkInput, PortfolioLinkType } from '@/gql/Gql.types';
import { ref, toRefs, watch } from 'vue';
import { PortfolioType } from '@/gql/Gql.types';
import { capitalizeFirstLetterOfEveryWord } from '@/utils/stringUtils';

const { title, inputText, deleteLink } = LinkModuleString;

const props = defineProps<{ portfolioLinks: PortfolioLinkInput[] | PortfolioLinkType[] }>();
const { portfolioLinks } = toRefs(props);

const emits = defineEmits(['portfolios']);

const selectedPortfolioLinks = ref(
  portfolioLinks.value.map(({ link, url }, index) => ({
    id: index + 1,
    link: link ? link : ('BEHANCE' as PortfolioType),
    url: url ? url : '',
  }))
);

const select = Object.entries(PortfolioType).reduce(
  (
    acc: {
      options: {
        label: string;
        value: PortfolioType;
      }[];
      icons: {
        label: string;
        value: string;
      }[];
    },
    [label, value]
  ) => {
    acc.options = [...acc.options, { label, value }];
    acc.icons = [...acc.icons, { label, value: label.toLowerCase() }];
    return acc;
  },
  { options: [], icons: [] }
);

const deleteLinkHandler = (linkId: number) => {
  const deleteLink = (linkId: number) =>
    (selectedPortfolioLinks.value = selectedPortfolioLinks.value
      .filter((el) => el.id !== linkId)
      .map((el, index) => ({
        ...el,
        id: index + 1,
      })));

  if (selectedPortfolioLinks.value.length <= 1) {
    deleteLink(linkId);
    emits('portfolios', selectedPortfolioLinks.value);
    return;
  }

  deleteLink(linkId);
};

const updateSelection = ({
  link,
  option,
  url,
}: {
  link: { id: number; link: PortfolioType; url: string };
  option?: Option;
  url?: string;
}) => {
  if (!link) return;

  selectedPortfolioLinks.value = selectedPortfolioLinks.value.map((el, index) => ({
    ...el,
    url: el.id === link.id && !!url ? url : el.url,
    link: el.id === link.id && !!option ? (option.value as PortfolioType) : el.link,
  }));
};

const addPortfolioLink = () => {
  selectedPortfolioLinks.value = [
    ...selectedPortfolioLinks.value,
    {
      id: selectedPortfolioLinks.value.length + 1,
      link: 'BEHANCE' as PortfolioType,
      url: '',
    },
  ];
};

watch(selectedPortfolioLinks, () => {
  emits('portfolios', selectedPortfolioLinks.value);
});
</script>
<template>
  <div class="LinkModule">
    <div class="LinkModule__title">{{ title }}</div>
    <div
      v-if="selectedPortfolioLinks.length"
      v-for="(link, i) in selectedPortfolioLinks"
      :key="i"
      class="LinkModule__links"
    >
      <div class="LinkModule__links-link">
        <Select
          variant="icons"
          :name="link.link ?? ''"
          :options="select.options"
          :icons="select.icons"
          :initial-value="{
            label: capitalizeFirstLetterOfEveryWord(link.link ?? ''),
            value: link.link ?? '',
          }"
          @selected="(option) => updateSelection({ option, link })"
          :standalone="true"
        />
        <div class="LinkModule__links-link--input">
          <InputText
            :placeholder="inputText"
            :name="link.id.toString()"
            :rules="{ url: 'https://.*' }"
            :initial-value="link.url"
            @keystart="(url) => updateSelection({ url, link })"
            :standalone="true"
          />
        </div>
      </div>
      <Button
        class="LinkModule__deleteLink"
        :label="deleteLink"
        variant="link-tertiary-status"
        @clicked="deleteLinkHandler(link.id)"
      />
    </div>
    <Button
      class="LinkModule__button"
      icon="plus"
      type="icon"
      variant="tertiary"
      :disabled="
        Object.keys(selectedPortfolioLinks).length > 0 &&
        selectedPortfolioLinks[selectedPortfolioLinks.length - 1].url === ''
      "
      @clicked="addPortfolioLink"
    />
  </div>
</template>
<style scoped lang="scss">
.LinkModule {
  &__title {
    @extend .text-label;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__links {
    margin-bottom: 2.4rem;

    &-link {
      @include flexing(row, start, start);
      gap: 1rem;

      :deep {
        .Select {
          width: 9.5rem;

          &__drop {
            width: 100%;
          }

          .error-message {
            display: none;
          }
        }
      }

      &--input {
        flex: 1;

        :deep {
          .InputText {
            height: 100%;
            margin-bottom: 0;

            .o-input {
              height: 100%;
            }
          }
        }
      }
    }
  }

  &__deleteLink {
    display: block;
    margin-left: auto;
  }

  &__button {
    :deep &.Button {
      width: 100%;

      .o-btn__label {
        @include flexing(row, center, center);
      }
    }
  }
}
</style>
