import type { TalentsFilterInput } from '@/gql/Gql.types';
import type { IFilter } from '@/models/selectFilterModel';

const mapExpertise = (result: { name: string; skills: string[] }, obj: IFilter[]) => {
  const copyResult = { name: result.name, skills: [] as string[] };

  obj.forEach((val) => {
    if (result.name === val.name) {
      copyResult.skills = [...copyResult.skills, val.skills];
      if (!val.skills.length) {
        copyResult.skills = [];
      }
    }
  });
  return copyResult;
};

export const useMappedFilters = (filters: any): TalentsFilterInput => {
  const obj: { [key: string]: any } = {};
  const expertiseResult = { name: '', skills: [] };

  for (const [key, value] of filters) {
    if (value.length > 0 && Array.isArray(value)) {
      const mappedValue = value
        .map((val: IFilter, _, array) => {
          if (val.hasExpertise && val.name) {
            if (expertiseResult.name === val.name) return;
            expertiseResult.name = val.name;
            return mapExpertise(expertiseResult, array);
          }
          if (key === 'country') {
            return val.skillsLabel;
          }
          if (val.isBoolean) {
            return val.skills === 'true' ? true : false;
          }
          if (!val.hasLevel) {
            return val.skills;
          } else {
            return {
              name: val.skills,
              levels: val.levels,
            };
          }
        })
        .filter((item) => item !== undefined);
      const isRadio = value.some((val) => val.isLikeRadio);

      // temporary fix only for country, change with swith in future if necessary
      isRadio || mappedValue.includes(true || false)
        ? (obj[key === 'countries' ? 'country' : key] = mappedValue[0])
        : (obj[key] = mappedValue);
    } else if (value.length > 0) {
      obj[key] = value;
    }
  }

  return obj;
};
