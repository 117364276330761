import type { QueryTalentsArgs } from '@/gql/Gql.types';
import { ref, computed } from 'vue';
const currentPage = ref(1);

export const usePagination = (
  type: 'first' | 'prev' | 'next' | 'last',
  cursor: string | undefined,
  page: number,
  visibleResult: number,
  totalPage: number,
  lastPage: number,
  params: Pick<QueryTalentsArgs, 'after' | 'before' | 'first' | 'last'>
) => {
  const paginationParams = computed(() => {
    switch (type) {
      case 'first':
        delete params.before;
        delete params.last;
        delete params.after;

        params.first = visibleResult;
        break;
      case 'prev':
        delete params.first;
        delete params.after;

        params.last = visibleResult;
        params.before = cursor;
        break;
      case 'next':
        delete params.last;
        delete params.before;

        params.first = visibleResult;
        params.after = cursor;
        break;
      case 'last':
        delete params.after;
        delete params.before;
        delete params.first;
        params.last = lastPage;
        break;
    }
    return params;
  });

  currentPage.value = page;

  switch (type) {
    case 'first':
      currentPage.value = 1;
      break;
    case 'prev':
      currentPage.value > 1 ? (currentPage.value -= 1) : currentPage.value;
      break;
    case 'next':
      currentPage.value < totalPage ? (currentPage.value += 1) : currentPage.value;
      break;
    case 'last':
      currentPage.value < totalPage ? (currentPage.value = totalPage) : currentPage.value;
      break;
  }

  return { currentPage: currentPage.value, paginationParams: paginationParams.value };
};
