import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetExpertiseQuery } from '@/gql/Gql.types';

const expertiseQuery = gql`
  query getExpertise {
    expertises {
      edges {
        cursor
        node {
          id
          name
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${pageInfo}
`;

export const getExpertise = (
  options?: UseQueryOptions<GetExpertiseQuery, void, GetExpertiseQuery, any[]>
): UseQueryReturnType<GetExpertiseQuery, void> =>
  useGraphql({
    query: expertiseQuery,
    options,
  });
