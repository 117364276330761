import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAgenciesStore = defineStore('agencies', () => {
  const params = ref({});
  const page = ref(1);
  return {
    params,
    page,
  };
});
