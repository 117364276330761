<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import { Field } from 'vee-validate';

interface ICheckboxProps {
  label: string;
  as?: 'checkbox' | 'chip';
  disabled?: boolean;
  initialValue?: boolean;
  name?: string;
  standalone?: boolean;
  rules?: any;
}

const props = withDefaults(defineProps<ICheckboxProps>(), {
  label: '',
  as: 'checkbox',
  standalone: false,
});

const { label, as, disabled, initialValue, name, rules } = toRefs(props);

const value = ref(initialValue.value ?? false);

watch(
  () => initialValue.value,
  (newValue) => {
    newValue !== undefined ? (value.value = newValue) : '';
  }
);
</script>

<template>
  <Field
    :name="name ?? ''"
    type="checkbox"
    :value="value"
    v-slot="{ field, errors }"
    :rules="rules"
    :standalone="standalone"
  >
    <o-checkbox
      :class="`Checkbox Checkbox--${as}`"
      :disabled="disabled"
      v-model="value"
      v-bind="field"
      :checked="initialValue ?? false"
      @change.stop="$emit('changed', $event)"
    >
      <span :class="`${as === 'checkbox' ? 'text-body-1' : 'text-body-2'}`">{{ label }}</span>
    </o-checkbox>
    <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
  </Field>
</template>

<style lang="scss" scoped>
.Checkbox {
  &--checkbox {
    &.o-chk--disabled {
      color: $black-20;
      pointer-events: none;
      :deep .o-chk {
        &__check,
        &__check--checked {
          border-color: $black-20;
          opacity: 1;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    :deep .o-chk {
      &__check {
        width: 2rem;
        height: 2rem;
        border: 0.1rem $primary-color solid;
        border-radius: 0.4rem;

        &:hover {
          background-color: $primary-color;
        }

        &--checked {
          background-color: $primary-color;
          background-image: url(@/assets/icons/check.svg);
          background-repeat: no-repeat;
          background-size: 1.8rem 1.11rem;
        }
      }

      &__label {
        margin-left: 0.8rem;
      }
    }
  }

  &--chip {
    padding: 1.1rem 2.4rem;
    border: 0.1rem $primary-color solid;
    border-radius: 7.2rem;

    &:hover {
      background-color: $primary-color;
      color: $secondary-color;
    }

    &.o-chk.o-chk--checked {
      background-color: $primary-color;
      color: $secondary-color;
    }

    &.o-chk.o-chk--disabled {
      pointer-events: none;
    }

    :deep .o-chk {
      &__check {
        display: none;
      }
    }
  }
}
</style>
