import { gql } from '@/api/graphql-client';
import { agencyFragment } from '@/api/fragments/agency';
import type { MutationUpdateAgencyBiographyArgs, Agency } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateAgencyBiographyMutation = gql`
  mutation UpdateAgencyBiography($agencyId: String!, $biography: String!) {
    updateAgencyBiography(agencyId: $agencyId, biography: $biography) {
      ...agency
    }
  }
  ${agencyFragment}
`;

export const updateAgencyBiography = (
  props: MutationUpdateAgencyBiographyArgs,
  options?: UseQueryOptions<Agency, MutationUpdateAgencyBiographyArgs, Agency, any[]>
): UseQueryReturnType<Agency, MutationUpdateAgencyBiographyArgs> =>
  useGraphql({
    query: updateAgencyBiographyMutation,
    variables: props,
    options,
  });
