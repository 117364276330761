import { gql } from 'graphql-request';
import type { MutationHardDeleteUserArgs, User } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const hardDeleteUserMutation = gql`
  mutation HardDeleteUser($userIds: [String!]!) {
    hardDeleteUser(userIds: $userIds)
  }
`;

export const hardDeleteUser = (
  props?: MutationHardDeleteUserArgs,
  options?: UseQueryOptions<User, MutationHardDeleteUserArgs, User, any[]>
): UseQueryReturnType<User, MutationHardDeleteUserArgs> =>
  useGraphql({
    query: hardDeleteUserMutation,
    variables: props,
    options,
  });
