import { gql } from 'graphql-request';
import type { RestoreAgenciesMutation, MutationRestoreAgenciesArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const restoreAgenciesMutation = gql`
  mutation RestoreAgencies($agencyIds: [String!]!) {
    restoreAgencies(agencyIds: $agencyIds)
  }
`;

export const restoreAgencies = (
  props?: MutationRestoreAgenciesArgs,
  options?: UseQueryOptions<
    RestoreAgenciesMutation,
    MutationRestoreAgenciesArgs,
    RestoreAgenciesMutation,
    any[]
  >
): UseQueryReturnType<RestoreAgenciesMutation, MutationRestoreAgenciesArgs> =>
  useGraphql({
    query: restoreAgenciesMutation,
    variables: props,
    options,
  });
