import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import { agencyFragment } from '@/api/fragments/agency';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { SearchAgenciesQuery, QuerySearchAgenciesArgs } from '@/gql/Gql.types';

const searchAgenciesQuery = gql`
  query searchAgencies(
    $after: String
    $before: String
    $filters: AgenciesFilterInput
    $keyword: String!
    $first: Int
    $last: Int
    $orderBy: AgenciesOrder
  ) {
    searchAgencies(
      after: $after
      before: $before
      filters: $filters
      keyword: $keyword
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...agency
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${agencyFragment}
  ${pageInfo}
`;

export const searchAgencies = (
  props?: QuerySearchAgenciesArgs,
  options?: UseQueryOptions<
    SearchAgenciesQuery,
    QuerySearchAgenciesArgs,
    SearchAgenciesQuery,
    any[]
  >
): UseQueryReturnType<SearchAgenciesQuery, QuerySearchAgenciesArgs> =>
  useGraphql({
    query: searchAgenciesQuery,
    variables: props,
    options,
  });
