<script setup lang="ts">
import Icon from '@/components/atoms/Icon.vue';
import type { IButtonProps } from '@/models/buttonModel';
import { reactive, ref, toRefs, watch } from 'vue';

const props = withDefaults(defineProps<IButtonProps>(), {
  active: false,
  disabled: false,
  icon: '',
  iconSize: 'small',
  label: '',
  tag: 'button',
  variant: 'primary',
  target: '',
  type: undefined,
});

const { active, disabled, icon, iconSize, label, tag, target, to, type, variant } = toRefs(props);

const disable = ref(disabled.value);

watch(disabled, (newDisabled) => {
  disable.value = newDisabled;
});

const classes = reactive([
  type.value === 'module' ? 'text-button' : 'text-body-2',
  label.value && icon.value ? 'label-icon' : '',
  ...[
    [
      'link-tertiary',
      'link-tertiary-dark',
      'link-tertiary-accent',
      'link-tertiary-status',
      'danger',
    ].includes(variant.value) && ['text-link'],
  ],
]);
</script>

<template>
  <o-button
    :aria-label="label ?? ''"
    :class="`Button ${type ?? ''} ${!icon && type !== 'navbar' ? '--no-icon' : ''} ${
      active ? 'active' : ''
    }`"
    :disabled="disable"
    :href="to"
    :tag="tag"
    :target="target"
    :to="to"
    :variant="variant"
    @click="!to && !target ? $emit('clicked', $event) : null"
  >
    <span :class="classes">{{ label }}</span>
    <Icon v-if="icon" :icon="icon" :icon-size="iconSize" />
  </o-button>
</template>

<style lang="scss" scoped>
.Button.o-btn {
  width: max-content;
  height: max-content;
  min-width: 3.2rem;
  min-height: 3.2rem;
  padding: 1.1rem 1.6rem;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;
  font-family: $primary-font-family;

  :deep(.o-btn__label) {
    @include row-center;
    justify-content: center;
  }

  &.--no-icon,
  &.icon {
    min-width: 4rem;
    min-height: 4rem;
  }

  &.navbar {
    padding: 0.7rem 1.6rem;
  }

  &.module {
    padding: 1.9rem 1.6rem;
  }

  &.indicator {
    width: 4rem;
    height: 4rem;
    color: $primary-color;
    background-color: $secondary-color;
    border: 0.1rem solid $black-10;
    border-radius: 50%;
    padding: 1.2rem;

    &:hover {
      border-color: $primary-color;
    }
  }

  .label-icon {
    margin-right: 1.397rem;
  }

  .text-body-2 {
    line-height: 1rem;
  }

  .text-button {
    line-height: 1rem;
  }

  &--accent {
    background-color: $accent;
    color: $secondary-color;
    width: auto;
    padding: 1.2rem;

    :deep .o-btn__label {
      @include row-center-center;
    }

    &:hover {
      background-color: rgba($accent, 0.8);
    }
  }

  &--danger {
    background-color: $red;
    color: $secondary-color;
    width: auto;
    padding: 1.2rem;

    :deep .o-btn__label {
      @include row-center-center;
    }
  }

  &--primary {
    background-color: $primary-color;

    &:hover {
      background-color: transparent;
      color: $primary-color;
      border-color: $primary-color;
    }

    &:active {
      background-color: $black-04;
      border-color: transparent;
      color: $primary-color;
    }

    &-dark {
      color: $primary-color;
      background-color: $secondary-color;

      &:hover {
        background-color: transparent;
        color: $secondary-color;
        border-color: $secondary-color;
      }

      &:active {
        border-color: transparent;
        background-color: $black-04;
      }
    }

    &.icon {
      border-color: $accent;
      background-color: $accent;
      color: $secondary-color;
    }
  }

  &--disabled.o-btn--primary {
    color: $black-20;
    background-color: $black-20;
  }

  &--disabled.o-btn--primary-dark {
    color: $white-20;
    background-color: $white-20;
  }

  &--secondary {
    border: 0.1rem solid $primary-color;
    background-color: $secondary-color;
    color: $primary-color;

    &:hover {
      background-color: $primary-color;
      color: $secondary-color;
      border-color: $secondary-color;
    }

    &:active {
      background-color: $black-20;
      color: $secondary-color;
    }

    &-dark {
      border-color: $secondary-color;
      background-color: transparent;
      color: $secondary-color;

      &:hover {
        background-color: $secondary-color;
        color: $primary-color;
        border-color: $secondary-color;
      }

      &:active {
        background-color: $white-20;
        color: $primary-color;
        border-color: transparent;
      }
    }
  }

  &--tertiary {
    background-color: transparent;
    border-color: transparent;
    color: $primary-color;

    &:hover {
      border-color: $primary-color;
    }

    &.active {
      border-color: transparent;
      background-color: $black-04;
    }

    &.o-btn--disabled {
      background-color: transparent;
      color: $black-20;
    }

    &-dark {
      background-color: transparent;
      border-color: transparent;
      color: $secondary-color;

      &:hover {
        border-color: $secondary-color;
        color: $secondary-color;
      }

      &.active {
        border-color: transparent;
        background-color: $white-10;
      }

      &.o-btn--disabled {
        background-color: transparent;
        color: $white-20;
        border-color: $white-20;
      }
    }
    &.icon {
      background-color: $black-20;
      color: $primary-color;

      &:hover {
        border-color: $black-20;
      }
      &:active {
        background-color: $black-10;
      }
    }
  }

  &\--link-tertiary {
    @include reset-button;

    color: $primary-color;

    span:first-child {
      padding-bottom: 0.3rem;
      border-bottom: 1px solid $primary-color;
    }

    &:hover {
      color: $accent;

      span:first-child {
        border-color: $accent;
      }
    }

    &:disabled {
      color: $black-20;
      background: transparent;
      padding: 0;

      span:first-child {
        border-color: $black-20;
      }
    }
  }

  &\--link-tertiary-dark {
    @include reset-button;

    color: $secondary-color;

    span:first-child {
      padding-bottom: 0.3rem;
      border-bottom: 1px solid $secondary-color;
    }

    &:hover {
      color: $accent;

      span:first-child {
        border-color: $accent;
      }
    }

    &:disabled {
      color: $black-20;
      background: transparent;
      padding: 0;

      span:first-child {
        border-color: $black-20;
      }
    }
  }

  &\--link-tertiary-status {
    @include reset-button;

    color: $status;

    span:first-child {
      padding-bottom: 0.3rem;
      border-bottom: 1px solid $status;
    }

    &:hover {
      color: $status;

      span:first-child {
        border-color: $status;
      }
    }

    &:disabled {
      color: $black-20;
      background: transparent;
      padding: 0;

      span:first-child {
        border-color: $black-20;
      }
    }
  }

  &--tab-button {
    @include reset-button-style;
    color: $black-50;
    margin-bottom: 0;
    padding: 3.1rem 0;
    border: none;
    border-bottom: 0.1rem solid transparent;

    &:hover {
      border-bottom: 0.1rem solid $primary-color;
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
      border-bottom: 0.1rem solid $primary-color;
    }

    &.o-btn--disabled {
      color: $black-20;
      border: none;
    }
  }
}
</style>
