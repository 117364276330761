import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetTechnologiesQuery, QueryTechnologiesArgs } from '@/gql/Gql.types';
import { technologyFragment } from '@/api/fragments/technology';

const technologiesQuery = gql`
  query getTechnologies($after: String, $before: String, $first: Int, $last: Int) {
    technologies(after: $after, before: $before, first: $first, last: $last) {
      edges {
        cursor
        node {
          ...technology
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${technologyFragment}
  ${pageInfo}
`;

export const getTechnologies = (
  props?: QueryTechnologiesArgs,
  options?: UseQueryOptions<
    GetTechnologiesQuery,
    QueryTechnologiesArgs,
    GetTechnologiesQuery,
    any[]
  >
): UseQueryReturnType<GetTechnologiesQuery, QueryTechnologiesArgs> =>
  useGraphql({
    query: technologiesQuery,
    variables: props,
    options,
  });
