import { gql } from '@/api/graphql-client';

export const pageInfo = gql`
  fragment pageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
