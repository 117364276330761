import { gql } from 'graphql-request';
import type { MigrateToTalentMutationMutation, MutationMigrateToTalentArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const migrateToTalentMutation = gql`
  mutation MigrateToTalentMutation($agencyId: String!) {
    migrateToTalent(agencyId: $agencyId)
  }
`;

export const migrateToTalent = (
  props?: MutationMigrateToTalentArgs,
  options?: UseQueryOptions<
    MigrateToTalentMutationMutation,
    MutationMigrateToTalentArgs,
    MigrateToTalentMutationMutation,
    any[]
  >
): UseQueryReturnType<MigrateToTalentMutationMutation, MutationMigrateToTalentArgs> =>
  useGraphql({
    query: migrateToTalentMutation,
    variables: props,
    options,
  });
