<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import { Field } from 'vee-validate';
import Icon from '@/components/atoms/Icon.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import type { Option, ISelectProps } from '@/models/selectModel';

const props = withDefaults(defineProps<ISelectProps>(), {
  initialValue: () => ({
    label: '',
    value: '',
  }),
  disabled: false,
  label: '',
  placeholder: '',
  size: '',
  variant: 'default',
  standalone: false,
  keepInitialValue: false,
});

const {
  placeholder,
  initialValue,
  label,
  options,
  icons,
  variant,
  count,
  initialChecked,
  name,
  rules,
  size,
  disabled,
  disabledCheckbox,
  keepInitialValue,
} = toRefs(props);

const currentOption = ref<Option>(initialValue.value);
const currentCheck = ref(initialChecked.value ?? false);

watch(options, (newOptions, oldOptions) => {
  if (JSON.stringify(newOptions) !== JSON.stringify(oldOptions) && !keepInitialValue.value) {
    currentOption.value = { ...currentOption.value, label: '', value: '' };
  }
});

watch(initialValue, (newInitialValue, oldInitialValue) => {
  if (newInitialValue.value !== oldInitialValue.value && !keepInitialValue.value) {
    currentOption.value = newInitialValue;
  }
});

watch(initialChecked, (newInitialChecked, oldInitialChecked) => {
  if (newInitialChecked !== oldInitialChecked) {
    currentCheck.value = newInitialChecked;
  }
});

const emit = defineEmits(['selected']);

watch(currentOption, () => {
  emit('selected', currentOption.value);
});

const updatedCurrentOption = (e: Event, option: Option) => {
  currentOption.value = (e.target as HTMLInputElement).checked
    ? option
    : { ...currentOption.value, label: `${option.label}`, value: `${option.value}` };
};
</script>

<template>
  <Field
    :name="name ?? placeholder"
    :value="initialValue.value ?? currentOption.value"
    v-model="currentOption"
    v-slot="{ field, errors }"
    :rules="rules"
    :standalone="standalone"
  >
    <div :class="`Select Select--${variant}`">
      <p
        :class="[
          `Select--${variant}-label ${variant === 'default' ? 'text-label' : 'text-body-2'}`,
        ]"
        v-if="label && variant !== 'filter'"
      >
        {{ variant === 'sort' ? label + ':' : label }}
      </p>
      <o-dropdown
        :disabled="disabled"
        :class="`Select__drop${size && '--' + size}`"
        aria-role="list"
        v-model="currentOption"
        v-bind="field"
      >
        <template #trigger="{ active }">
          <div
            :class="[
              `Select__drop${size && '--' + size}-trigger`,
              { active },
              `${variant === 'default' ? 'text-button' : 'text-body-2'}`,
              { counter: count },
            ]"
          >
            <span class="Select__drop-icon--large" v-if="icons">
              <Icon :icon="currentOption.value.toLocaleLowerCase()" />
            </span>
            <span
              class="Select__drop-text"
              v-else-if="currentOption.value === '' && variant !== 'filter'"
            >
              {{ placeholder }}
            </span>
            <span v-else class="Select__drop-text"
              >{{ variant === 'filter' ? placeholder : currentOption.label }}
              <span v-if="count && count >= 0">({{ count }})</span></span
            >
            <Icon
              :class="`Select__drop${size && '--' + size}-icon`"
              :icon="active ? 'chevron-up' : 'chevron-down'"
            />
          </div>
        </template>
        <o-dropdown-item
          v-if="variant !== 'filter'"
          v-for="(option, index) in options"
          :key="option.value + index + option.label"
          :class="[
            `${variant === 'default' ? 'text-button' : 'text-body-2'}`,
            { icons: icons && icons[index] },
          ]"
          aria-role="listitem"
          :value="option"
          :name="option.value"
        >
          <div v-if="icons">
            <Icon v-if="icons[index]" :icon="icons[index].value" icon-size="medium" />
          </div>
          <span>{{ option.label }}</span>
        </o-dropdown-item>
        <Checkbox
          v-else
          v-for="(option, index) in options"
          :key="index"
          :label="option.label"
          as="checkbox"
          :initial-value="currentCheck"
          :standalone="true"
          :disabled="disabledCheckbox ? disabledCheckbox[index] : false"
          @changed="updatedCurrentOption($event, option)"
        />
      </o-dropdown>
      <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
  </Field>
</template>

<style scoped lang="scss">
.Select {
  :deep .o-drop__menu {
    @include flexing(column, start, start);
    max-height: 18.4rem;
    width: max-content;
    overflow-y: auto;
    box-shadow: 0 1rem 4.4rem 0 $black-08;

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $black-20;
      border-radius: 4rem;
    }

    .o-drop__item {
      width: 100%;

      &:hover {
        background-color: $black-04;
      }

      &--active {
        background-color: $black-04;
        color: $primary-color;
        border-radius: 0.4rem;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1.6rem;
          height: 1.6rem;
          top: 2rem;
          right: 1.6rem;
          background-image: url(@/assets/icons/check.svg);
          background-repeat: no-repeat;
          background-size: 1.8rem 1.11rem;
          filter: invert(1);
        }
      }
    }
  }

  &--default {
    &-label {
      text-transform: uppercase;
      margin: 0 0 0.8rem 0;
    }

    .Select__drop {
      width: 100%;

      &-trigger {
        padding: 1.9rem 1.6rem;
        position: relative;
        cursor: pointer;
        border: 0.1rem solid $black-50;
        border-radius: 0.4rem;
        min-height: 4rem;

        &:hover,
        &.active {
          border-color: $primary-color;
          background-color: $secondary-color;
        }
      }

      &-icon {
        position: absolute;
        top: 1.9rem;
        right: 1.6rem;
      }

      &-text {
        padding-right: 2.8rem;
      }

      :deep .o-drop__menu {
        width: 100%;
        background-color: $secondary-color;
        border-radius: 0.4rem;
        margin-top: 0.8rem;

        &--active {
          padding: 0.8rem;
        }

        .o-drop__item {
          padding: 1.6rem 1.2rem;
        }
      }

      &--small {
        width: 100%;

        &-trigger {
          max-height: 4rem;
          padding: 1.15rem 1.6rem;
          position: relative;
          cursor: pointer;
          border: 0.1rem solid $black-50;
          border-radius: 0.4rem;
          min-height: 4rem;

          &:hover,
          &.active {
            border-color: $primary-color;
            background-color: $secondary-color;
          }
        }

        &-icon {
          position: absolute;
          top: 1.3rem;
          right: 1.6rem;
        }

        :deep .o-drop__menu {
          width: 100%;
          background-color: $secondary-color;
          border-radius: 0.4rem;

          margin-top: 0.8rem;

          &--active {
            padding: 0.8rem;
            width: max-content;
          }

          .o-drop__item {
            padding: 1.6rem 1.2rem;
            min-width: max-content;
            width: 100%;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  &--sort {
    @include flexing(row, start, center);

    &-label {
      margin: 0 0.8rem 0 0;
      color: $black-50;
    }

    .Select__drop {
      &-trigger {
        @include flexing(row, start, center);
        cursor: pointer;
      }

      &-icon {
        margin-left: 0.8rem;
      }

      :deep .o-drop__menu {
        margin-top: 1.2rem;
        padding: 1.6rem;
        border-radius: 0.4rem;
        min-width: max-content;

        .o-drop__item {
          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }

          padding-right: 2.8rem;

          &--active {
            background: transparent;

            &::after {
              top: 0.2rem;
              right: 0;
            }
          }
        }
      }
    }
  }

  &--filter {
    .Select__drop {
      &-trigger {
        @include flexing(row, start, center);
        cursor: pointer;
        padding: 0.8rem 1.6rem;
        border: 0.1rem solid $black-50;
        border-radius: 0.4rem;
        min-height: 4rem;

        &:hover,
        &.active {
          border-color: $primary-color;
        }

        &.counter {
          background-color: $primary-color;
          color: $secondary-color;
        }
      }

      &-icon {
        margin-left: 0.4rem;
      }

      :deep .o-drop__menu {
        padding: 1.6rem;
        margin-top: 0.8rem;
        border-radius: 0.4rem;

        .o-chk.Checkbox {
          align-items: flex-start;

          &:not(:last-of-type) {
            margin-bottom: 1.6rem;
          }
        }
      }
    }
  }

  &--icons {
    .Select__drop {
      &-trigger {
        @include flexing(row, space-between, center);
        cursor: pointer;
        padding: 1.3rem 1.7rem;
        border: 0.1rem solid $black-20;
        border-radius: 0.4rem;
        min-height: 4rem;
      }

      &-icon {
        &--large {
          @include flexing(row, center, center);

          :deep .icon__svg {
            width: 3rem;
            height: 3rem;
          }
        }
      }

      :deep .o-drop__menu {
        margin-top: 1.2rem;
        padding: 1.9rem;
        border: 0.1rem solid $black-20;
        border-radius: 0.4rem;
        min-width: max-content;
        max-height: none;

        .o-drop__item.icons {
          @include flexing(row, start, center);
          gap: 1rem;

          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }

          &:hover {
            background-color: transparent;
          }

          &--active {
            background-color: transparent;

            &::after {
              content: '';
              background: none;
            }
          }
        }

        .o-drop__item {
          text-align: right;

          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }

          &:hover {
            background-color: transparent;
          }

          &--active {
            background-color: transparent;

            &::after {
              content: '';
              background: none;
            }
          }
        }
      }
    }
  }

  &--table {
    @include flexing(row, start, center);
    gap: 0.8rem;

    :deep {
      .o-drop {
        height: 4rem;
        border: 0.1rem solid $black-50;
        border-radius: 0.4rem;
        cursor: pointer;

        &__trigger {
          @include flexing(row, start, center);
          gap: 0.4rem;
          padding: 0.8rem 1.6rem;
          min-height: 4rem;

          .Select__drop {
            &-trigger {
              @include flexing(row, start, center);
              gap: 0.4rem;
            }
          }
        }

        &__menu {
          margin-top: 1.2rem;
          padding: 1.6rem;
          border-radius: 0.4rem;
          min-width: max-content;

          .o-drop__item {
            &:not(:last-child) {
              margin-bottom: 0.8rem;
            }

            padding-right: 2.8rem;

            &--active {
              background-color: transparent;

              &::after {
                top: 0.2rem;
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
