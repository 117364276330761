import strings from '@/data/GenericStrings.json';
import TableActions from '@/components/organisms/Table/components/TableActions.vue';
import TableDocumentsViewer from '@/components/organisms/Table/components/TableDocumentsViewer.vue';
import TableNameView from '@/components/organisms/Table/components/TableNameView.vue';
import TableSeniority from '@/components/organisms/Table/components/TableSeniority.vue';
import TableSimpleText from '@/components/organisms/Table/components/TableSimpleText.vue';
import TableToInterview from '@/components/organisms/Table/components/TableToInterview.vue';
import type { IColumn, IColumns } from '@/models/tableModel';
import { ITableColumns } from '@/models/tableModel';
import { markRaw, reactive, ref } from 'vue';

export const useTable = () => {
  const talents = ref<IColumn[]>([
    {
      field: 'actions',
      label: '',
      component: markRaw(TableToInterview),
      width: '1.5rem',
    },
    {
      field: 'nameView',
      label: strings.name,
      component: markRaw(TableNameView),
    },
    {
      field: 'documents',
      label: strings.documents,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'seniority',
      label: strings.seniority,
      component: markRaw(TableSeniority),
    },
    {
      field: 'specialities',
      label: strings.specialities,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'tools',
      label: strings.tools,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'workplace',
      label: strings.workplace,
    },
    {
      field: 'freelance',
      label: strings.freelance,
    },
    {
      field: 'languages',
      label: strings.languages,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'notes',
      label: strings.notes,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'prediction',
      label: strings.statusRecommended,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'actions',
      label: strings.actions,
      component: markRaw(TableActions),
    },
  ]);

  const talentsDelete = ref<IColumn[]>([
    {
      field: 'actions',
      label: '',
      component: markRaw(TableToInterview),
      width: '1.5rem',
    },
    {
      field: 'nameView',
      label: strings.name,
      component: markRaw(TableNameView),
    },
    {
      field: 'documents',
      label: strings.documents,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'seniority',
      label: strings.seniority,
      component: markRaw(TableSeniority),
    },
    {
      field: 'specialities',
      label: strings.specialities,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'tools',
      label: strings.tools,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'workplace',
      label: strings.workplace,
    },
    {
      field: 'freelance',
      label: strings.freelance,
    },
    {
      field: 'languages',
      label: strings.languages,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'notes',
      label: strings.notes,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'prediction',
      label: strings.statusRecommended,
      component: markRaw(TableDocumentsViewer),
    },
  ]);

  const agencies = ref([
    {
      field: 'actions',
      label: '',
      component: markRaw(TableToInterview),
      width: '1.5rem',
    },
    {
      field: 'nameView',
      label: strings.name,
      component: markRaw(TableNameView),
    },
    {
      field: 'documents',
      label: strings.documents,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'seniority',
      label: strings.seniority,
    },
    {
      field: 'collaborators',
      label: strings.collaborators,
    },
    {
      field: 'specialities',
      label: strings.specialities,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'tools',
      label: strings.tools,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'industries',
      label: strings.industries,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'notes',
      label: strings.notes,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'actions',
      label: strings.actions,
      component: markRaw(TableActions),
    },
  ]);

  const agenciesDeleted = ref<IColumn[]>([
    {
      field: 'actions',
      label: '',
      component: markRaw(TableToInterview),
      width: '1.5rem',
    },
    {
      field: 'nameView',
      label: strings.name,
      component: markRaw(TableNameView),
    },
    {
      field: 'documents',
      label: strings.documents,
      component: markRaw(TableDocumentsViewer),
    },
    {
      field: 'seniority',
      label: strings.seniority,
    },
    {
      field: 'collaborators',
      label: strings.collaborators,
    },
    {
      field: 'specialities',
      label: strings.specialities,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'tools',
      label: strings.tools,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'industries',
      label: strings.industries,
      component: markRaw(TableSimpleText),
    },
    {
      field: 'notes',
      label: strings.notes,
      component: markRaw(TableDocumentsViewer),
    },
  ]);

  const usersManagement = ref([
    {
      field: 'name',
      label: strings.name,
    },
    {
      field: 'email',
      label: strings.email,
    },
    {
      field: 'roleValue',
      label: strings.role,
    },
    {
      field: 'actions',
      label: strings.actions,
      component: markRaw(TableActions),
    },
  ]);

  const usersManagementDelete = ref([
    {
      field: 'name',
      label: strings.name,
    },
    {
      field: 'email',
      label: strings.email,
    },
    {
      field: 'roleValue',
      label: strings.role,
    },
  ]);

  const experiences = ref([
    {
      field: 'category',
      label: strings.category,
    },
    {
      field: 'location',
      label: strings.location,
    },
    {
      field: 'fromTo',
      label: strings.fromTo,
    },
    {
      field: 'availables',
      label: strings.availables,
    },
    {
      field: 'price',
      label: strings.price,
    },
    {
      field: 'discount',
      label: strings.discount,
    },
    {
      field: 'insurance',
      label: strings.insurance,
    },
    {
      field: 'flexibleDeleting',
      label: strings.flexibleDeleting,
    },
    {
      field: 'bookings',
      label: strings.bookings,
    },
    {
      field: 'actions',
      label: strings.actions,
      component: markRaw(TableActions),
    },
  ]);

  const settings = ref([
    {
      field: 'name',
      label: strings.name,
    },
    {
      field: 'actions',
      label: strings.actions,
      component: markRaw(TableActions),
    },
  ]);

  const coupon = ref([
    {
      field: 'codeName',
      label: strings.codeName,
    },
    {
      field: 'code',
      label: strings.code,
    },
    {
      field: 'percentage',
      label: strings.percentage,
    },
    {
      field: 'redemptions',
      label: strings.redemptions,
    },
    {
      field: 'email',
      label: strings.email,
    },
    {
      field: 'domain',
      label: strings.domain,
    },
    {
      field: 'expirationDate',
      label: strings.expirationDate,
    },
    {
      field: 'experience',
      label: strings.experience,
    },
    {
      field: 'actions',
      label: strings.actions,
      component: markRaw(TableActions),
    },
  ]);

  const users = ref([]);

  const selectedItems = ref([]);
  const selectItems = (items: any) => (selectedItems.value = items.value);

  /**
   * append this function to the Table to make sure that selectedItems flush
   * @param {boolean} bool default to true in Table component
   * @return {void} if ternary
   */
  const resetSelection = (bool: boolean) => (!bool ? null : selectedItems.value.splice(0));

  return {
    selectedItems,
    selectItems,
    resetSelection,
    columns: reactive<IColumns>({
      [ITableColumns.Agencies]: agencies.value,
      [ITableColumns.AgenciesDeleted]: agenciesDeleted.value,
      [ITableColumns.Talents]: talents.value,
      [ITableColumns.TalentsDelete]: talentsDelete.value,
      [ITableColumns.Users]: users.value,
      [ITableColumns.UsersManagement]: usersManagement.value,
      [ITableColumns.UsersManagementDelete]: usersManagementDelete.value,
      [ITableColumns.Experience]: experiences.value,
      [ITableColumns.Settings]: settings.value,
      [ITableColumns.Coupon]: coupon.value,
    }),
  };
};
