<script setup lang="ts">
import { computed, toRefs } from 'vue';

interface AvatarProps {
  src?: string;
  fullName: string;
  size: 'small' | 'medium' | 'large';
}

const props = withDefaults(defineProps<AvatarProps>(), {
  src: '',
  fullName: '',
  size: 'small',
});

const { src, fullName, size } = toRefs(props);

const firstCharFullname = computed(() => {
  return (fullName: string) => {
    const [firstname, lastname] = fullName.split(' ');
    return `${firstname?.charAt(0)}${lastname?.charAt(0)}`;
  };
});
</script>

<template>
  <div :class="['Avatar', `--${size}`, { '--image': src.length > 0 }]">
    <img class="Avatar-image" :src="src" :alt="fullName" v-if="src.length > 0" />
    <div v-else class="Avatar-fullname">
      {{ firstCharFullname(fullName) }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.Avatar {
  @include flexing(row, center, center);
  overflow: hidden;
  background-color: $primary-color;
  border-radius: 50%;

  &.--image {
    border: 0.1rem solid $secondary-color;
  }

  &.--small {
    @extend .text-label;
    width: 2.4rem;
    height: 2.4rem;
  }

  &.--medium {
    @extend .heading-h5;
    width: 4rem;
    height: 4rem;
  }

  &.--large {
    @extend .heading-h3;
    width: 8rem;
    height: 8rem;
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-fullname {
    color: $secondary-color;
    text-transform: uppercase;
  }
}
</style>
