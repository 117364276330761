<script setup lang="ts">
import { exportCsvAgencies } from '@/api/mutations/exportCsvAgencies';
import { hardDeleteAgencies } from '@/api/mutations/hardDeleteAgencies';
import { inviteAgency } from '@/api/mutations/inviteAgency';
import { rejectAgency } from '@/api/mutations/rejectAgency';
import { remindInviteAgency } from '@/api/mutations/remindInviteAgency';
import { restoreAgencies } from '@/api/mutations/restoreAgencies';
import { softDeleteAgencies } from '@/api/mutations/softDeleteAgencies';
import { updateAgencyNotes } from '@/api/mutations/updateAgencyNotes';
import { getAgencies } from '@/api/queries/agencies';
import { getAgenciesCounter } from '@/api/queries/agenciesCounter';
import { getAgencyInterview } from '@/api/queries/agencyInterview';
import { searchAgencies } from '@/api/queries/searchAgencies';
import { getTalentCommonData } from '@/api/queries/talentCommonData';
import Button from '@/components/atoms/Button.vue';
import Modal from '@/components/atoms/Modal.vue';
import MultiSelect from '@/components/atoms/MultiSelect.vue';
import Notification from '@/components/atoms/Notification.vue';
import Select from '@/components/atoms/Select.vue';
import Nav from '@/components/molecules/Nav.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import Filters from '@/components/organisms/Filters/Filters.vue';
import Table from '@/components/organisms/Table/Table.vue';
import { useFilterDataMapper } from '@/composables/talents/useFilterDataMapper';
import { useMappedQueryFilter } from '@/composables/talents/useMappedQueryFilter';
import { useTabItemsMapper } from '@/composables/talents/useTabItemsMapper';
import { useTotalCount } from '@/composables/talents/useTotalCount';
import { IFiltersOptions, useFilters } from '@/composables/useFilters';
import { useMappedFilters } from '@/composables/useMappedFilters';
import { INavOptions, useNav } from '@/composables/useNav';
import { usePagination } from '@/composables/usePagination';
import { ITab, useTabOptions } from '@/composables/useTabOptions';
import { useTable } from '@/composables/useTable';
import { useTableItemsMapper } from '@/composables/useTableItemsMapper';
import agenciesString from '@/data/Agencies.json';
import GenericStrings from '@/data/GenericStrings.json';
import {
  MIN_LENGTH_SEARCH,
  NOTIFICATION_DURATION,
  TIME_DELAY_SEARCH,
  TIME_TO_RESTORE_VARIABLES,
} from '@/data/constants';
import type {
  Agency,
  MutationExportAgenciesArgs,
  MutationRejectAgencyArgs,
  MutationSoftDeleteAgenciesArgs,
  MutationUpdateAgencyNotesArgs,
  QueryAgenciesArgs,
  QuerySearchAgenciesArgs,
} from '@/gql/Gql.types';
import { TalentStatus } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import type { IColumn } from '@/models/tableModel';
import { ITableColumns } from '@/models/tableModel';
import { useUserStore } from '@/stores/user';
import moment from 'moment';
import 'moment/dist/locale/it';
import { storeToRefs } from 'pinia';
import { computed, onMounted, reactive, ref, watch, type ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useAgenciesStore } from '@/stores/agencies';

const agenciesStore = useAgenciesStore();

type actionModal = 'interview' | 'remindInterview' | 'reject' | 'addNote';
type NotificationStatus = 'success' | 'error' | '';

const {
  selectAction,
  buttonAction,
  selectVisibleAgencies,
  rejectParagraphModal,
  rejectAgencySentModal,
  rejectError,
  inviteInterview,
  remindInviteInterview,
  interviewSentModal,
  interviewParagraphModal,
  deleteAgenciesSuccess,
  deleteAgenciesError,
  restoreSuccessful,
  addAgency,
  agency: agencyString,
} = agenciesString;

const {
  confirmButton,
  understand,
  cancel,
  resultFound,
  agencies,
  futureTalent,
  cantera,
  moveIn: moveInString,
  addNote: addNoteString,
  notePlaceholder,
  saveNote,
  successAddNote: successAddNoteString,
  errorAddNote: errorAddNoteString,
  genericError,
  future,
  eligible,
  selectColumns,
  lastUpdate,
  user,
} = GenericStrings;

const route = useRoute();
const router = useRouter();

const {
  activeNav,
  setActiveNav,
  items: navItems,
} = useNav({ nav: INavOptions.Agencies, pathName: route.matched[0].name as string });
const isDeleted = computed(() => activeNav.value.value === 'DELETED');

const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const queryFilterMapped = computed(() => useMappedQueryFilter(route.query));

const filtersQuery = reactive(
  (function () {
    const json: string = localStorage.getItem('filter-agency') || '{"operator": "", "filters": []}';
    return !queryFilterMapped.value.operator ? JSON.parse(json) : queryFilterMapped.value;
  })()
);

const {
  items: tabItems,
  setActiveTab,
  activeTab,
} = useTabOptions({
  tab: ITab.Agencies,
  pathName: route.matched[0].name as string,
});

const { columns, selectItems, resetSelection, selectedItems } = useTable();

const { data: counterFilter, refetch: refetchTabCounter } = getAgenciesCounter();
const { data: filtersData } = getTalentCommonData();

const agenciesVisibleResultString: string = localStorage.getItem('agencies-visible-result') || '';
const searchTerms = ref('');
const page = ref(1);

const visibleResult = ref<number>(
  agenciesVisibleResultString !== ''
    ? JSON.parse(agenciesVisibleResultString)
    : +selectVisibleAgencies.options[0].value
);

const initialVisibleResult = reactive(
  (function () {
    return agenciesVisibleResultString !== ''
      ? {
          value: JSON.parse(agenciesVisibleResultString),
          label: JSON.parse(agenciesVisibleResultString),
        }
      : selectVisibleAgencies.options[0];
  })()
);

const agenciesIdsSelected = ref<MutationSoftDeleteAgenciesArgs>({ agencyIds: [''] });

const filterApplied = ref(false);
const submitted = ref(false);
const resultAgenciesFound = ref(false);

const action = ref('');
const sendingActionAgency = ref(false);
const agencyStatus = ref<TalentStatus>();
const actionOpenAgencyModal = ref(false);
const typeModal = ref<actionModal>();

const agencyId = reactive({ agencyId: '' });
const notificationPopup = ref(false);
const note = ref('');
const enableAddNote = ref(false);
const startDownload = ref(false);
const modalTimeout = ref();
const currentNotification = ref();

const agenciesColumns = computed(() =>
  !isDeleted.value ? columns[ITableColumns.Agencies] : columns[ITableColumns.AgenciesDeleted]
);

const realColumns = ref(agenciesColumns.value);

const columnsOptions = computed(() =>
  agenciesColumns.value
    .map((column: IColumn) => ({
      label: column.label,
      value: column.label,
    }))
    .filter((col: IColumn) => col.label !== '' && col.label !== 'Actions' && col.label !== 'Name')
);

const jsonColumns: string = localStorage.getItem('agencies-columns') || '';

const visibleColumns = ref(
  jsonColumns
    ? JSON.parse(jsonColumns)
    : agenciesColumns.value
        .map((column: IColumn) => ({
          label: column.label,
          value: column.label,
        }))
        .filter((col: IColumn) => col.label !== '')
);

const removeColumn = (opt: Option[]) => {
  visibleColumns.value = [...opt];
};

watch(
  [visibleColumns, agenciesColumns],
  () => {
    const col = visibleColumns.value.map((col: Option) => col.label);

    realColumns.value = agenciesColumns.value.filter((columns: IColumn) => {
      return col.includes(columns.label);
    });

    localStorage.setItem('agencies-columns', JSON.stringify(visibleColumns.value));
  },
  { immediate: true }
);

const params = reactive<QueryAgenciesArgs>({
  first: visibleResult.value,
  filters: { deleted: isDeleted.value },
});

const searchParams = reactive<QuerySearchAgenciesArgs>({
  first: visibleResult.value,
  keyword: '',
  filters: { deleted: isDeleted.value },
});

const enabled = (isSearch: boolean): ComputedRef<boolean> =>
  computed(() => isSearch === searchParams.keyword.length > MIN_LENGTH_SEARCH);

const { data, status, isLoading, refetch } = getAgencies(params, { enabled: enabled(false) });

const {
  data: searchData,
  status: statusSearch,
  isLoading: loadingSearch,
  refetch: refetchSearch,
} = searchAgencies(searchParams, { enabled: enabled(true) });

const agenciesIds = computed(() =>
  searchTerms.value.length <= MIN_LENGTH_SEARCH
    ? data.value?.agencies.edges?.map((agency) => agency.cursor)
    : searchData.value?.searchAgencies.edges?.map((agency) => agency.cursor)
);

/**
 Clear params of filter (Agencies & Search Agencies), clear the searchterms
 reset the Tab to first element.
 */
const clearParam = () => {
  page.value = 1;
  searchTerms.value = '';
  startDownload.value = false;

  delete params.after;
  delete params.before;
  delete params.last;
  params.filters = { deleted: isDeleted.value };

  delete searchParams.after;
  delete searchParams.before;
  delete searchParams.last;
  searchParams.filters = { deleted: isDeleted.value };

  clearTimeout(modalTimeout.value);
};

watch([status, statusSearch], () => {
  localStorage.setItem('filter-agency', JSON.stringify(queryFilterMapped.value));
});

const clearQuery = () => {
  router.replace({ query: {} });
  for (const [key] of Object.entries(route.query)) {
    delete route.query[key];
  }
  localStorage.removeItem('filter-agency');
};

/**
 When activeNav change, set the value of the filters about deleted to true
 leaving the old filters params.
 Set the value of submitted to false.
 */
watch(activeNav, () => {
  clearParam();
  activeTab.value = tabItems[0];
  submitted.value = false;
  action.value = '';
  agenciesIdsSelected.value.agencyIds.length = 0;

  params.filters = {
    ...(activeNav.value.value === 'DELETED' && { deleted: true }),
  };
  searchParams.filters = {
    ...(activeNav.value.value === 'DELETED' && { deleted: true }),
  };
});

// Count total of agencies visible based on filters
const totalCount = computed(() =>
  useTotalCount({
    data: data.value?.agencies,
    searchData: searchData.value?.searchAgencies,
    searchTerms: searchTerms.value,
  })
);

const totalPages = computed(() => Math.ceil(totalCount.value / visibleResult.value));

const agenciesCounter = computed(() => counterFilter.value?.agenciesCounter ?? []);

const paginationAgenciesValue = computed(
  () => totalCount.value - visibleResult.value * (totalPages.value - 1)
);

/**
 When change the visibleResult or write in input search, page set to 1, the pararms with first set to visibleResult
 and the fetch of data start after 400ms
 */
watch([visibleResult, searchTerms], (oldValue, _) => {
  const oldSearch = oldValue[1];
  page.value = 1;
  params.first = visibleResult.value;
  searchParams.first = visibleResult.value;
  localStorage.setItem('agencies-visible-result', JSON.stringify(visibleResult.value));

  startDownload.value = false;

  delete params.after;
  delete searchParams.after;

  delete params.last;
  delete searchParams.last;

  const timeout = setTimeout(() => {
    oldSearch === searchTerms.value && searchTerms.value.length > MIN_LENGTH_SEARCH
      ? (searchParams.keyword = searchTerms.value)
      : '';
  }, TIME_DELAY_SEARCH);

  return () => clearTimeout(timeout);
});

onMounted(() => {
  if (
    typeof activeTab.value === 'object'
      ? activeTab.value.name === 'TOTAL'
      : activeTab.value === 'TOTAL'
  ) {
    delete params.filters?.status;
    delete searchParams.filters?.status;
  } else {
    params.filters = { ...params.filters, status: activeTab.value as TalentStatus };
    searchParams.filters = { ...searchParams.filters, status: activeTab.value as TalentStatus };
  }
  if (window.history.state.back.startsWith('/agencies/edit')) {
    delete params.first;
    delete searchParams.first;
    delete params.last;
    delete searchParams.last;
    delete params.after;
    delete searchParams.after;
    delete params.before;
    delete searchParams.before;
    Object.assign(params, agenciesStore.params);
    page.value = agenciesStore.page;
  }
});

watch([params, searchParams, route, page], () => {
  if (!window.history.state.back.startsWith('/agencies/edit')) {
    agenciesStore.params = params;
    agenciesStore.page = page.value;
  }
});

watch(page, () => {
  agenciesStore.page = page.value;
});

// When change the activeTab, set filter with correct status, if status is equal TOTAL delete his status param
watch(activeTab, () => {
  if (
    typeof activeTab.value === 'object'
      ? activeTab.value.name === 'TOTAL'
      : activeTab.value === 'TOTAL'
  ) {
    delete params.filters?.status;
    delete searchParams.filters?.status;
  } else {
    params.filters = { ...params.filters, status: activeTab.value as TalentStatus };
    searchParams.filters = { ...searchParams.filters, status: activeTab.value as TalentStatus };
  }
});

// Set the correct number of item for each item's tab
const tabItemsMapped = computed(() =>
  useTabItemsMapper({
    counter: agenciesCounter.value,
    tabItems: tabItems,
  })
);

/**
 Set the Search terms based on writing in input
 @param {string} text - text write in input
 */
const handleSearch = (text: string) => {
  searchTerms.value = text;
};

const { items: filtersItems } = useFilters({ filters: IFiltersOptions.Agencies });

// Populate the filters with correct label and value
const AgenciesFilterData = computed(() =>
  useFilterDataMapper({
    filters: filtersData.value,
    filtersItems: filtersItems,
  })
);

const { getAgenciesQueryMapper } = useTableItemsMapper();

// Set the items for table
const items = computed(() =>
  getAgenciesQueryMapper({
    data:
      searchTerms.value.length > MIN_LENGTH_SEARCH
        ? searchData.value?.searchAgencies
        : data.value?.agencies,
    status: searchTerms.value.length > MIN_LENGTH_SEARCH ? statusSearch.value : status.value,
    isLoading: searchTerms.value.length > MIN_LENGTH_SEARCH ? loadingSearch.value : isLoading.value,
    actions: { moveIn, addNote },
    userRole: userRole.value,
  })
);

const paramsDownloadCSV = reactive<MutationExportAgenciesArgs>({
  filters: {},
});

watch(selectedItems, () => {
  agenciesIdsSelected.value.agencyIds = [...selectedItems.value];
});

// Set the filters or keyword of Talents and enable/start download the CSV
const enabledDownloadCSV = computed(() => startDownload.value);

watch(enabledDownloadCSV, () => {
  if (enabledDownloadCSV.value) {
    setTimeout(() => {
      startDownload.value = false;
    }, 300);
  }
});

watch(startDownload, () => {
  if (startDownload.value) {
    if (Object.keys(selectedItems.value).length !== 0) {
      paramsDownloadCSV.agencyIds = selectedItems.value.map((items: Agency) => items.id);
    } else {
      paramsDownloadCSV.agencyIds = agenciesIds.value ? [...agenciesIds.value] : [];
    }

    setTimeout(() => {
      startDownload.value = false;
    }, 300);
  }
});

const { data: downloadCSV, isLoading: exportLoading } = exportCsvAgencies(paramsDownloadCSV, {
  enabled: enabledDownloadCSV,
});

/**
 Set params on filters
 @param {Object[]} filters - the object with all parameters
 */
const activeFilters = (filters: any[]) => {
  const status = params.filters?.status;
  if (!window.history.state.back.startsWith('/agencies/edit')) {
    clearParam();
    if (params.after) params.first = visibleResult.value;
    if (params.before) params.first = visibleResult.value;
  }
  const mappedFilters = useMappedFilters(filters);
  startDownload.value = false;
  resultAgenciesFound.value = true;
  filterApplied.value = true;

  params.filters = {
    ...mappedFilters,
    status: status,
    deleted: isDeleted.value,
  };

  searchParams.first = visibleResult.value;
  searchParams.filters = {
    ...mappedFilters,
    status: status,
    deleted: isDeleted.value,
  };
};

// Clear params on filters
const resetFilters = () => {
  clearParam();
  activeTab.value = tabItems[0];

  filterApplied.value = false;
  resultAgenciesFound.value = false;

  filtersQuery.filters = [];
  filtersQuery.operator = '';

  clearQuery();
};

/**
 Set the action of the select (verify, increase level, delete)
 @param {Option} opt - option value selected
 @return {string}
 */
const setAction = (opt: Option) => (action.value = opt.value);

// If the action is not yet set, can't apply the action
const isApplyButtonDisabled = computed(
  () =>
    selectedItems.value.length <= 0 ||
    action.value === '' ||
    (userRole.value === 'JUNIOR' && activeNav.value.value === 'DELETED')
);

/**
 InviteAgency to interview, open modal, set ID of agency
 @param {string} id - Id of agency.
 @return {void}
 */
const actions = (id: string) => {
  agencyId.agencyId = id;
  actionOpenAgencyModal.value = true;
  if (modalTimeout.value) {
    clearTimeout(modalTimeout.value);
  }

  activeTab.value === 'INVITED'
    ? (typeModal.value = 'remindInterview')
    : (typeModal.value = 'interview');
};

/**
 Enable action of table
 @param {boolean} isInterview - If is interview (Interview) else Reject.
 @param {boolean} isRemind - if is remind (remind Interview)
 @return {boolean}
 */
const enabledActionAgency = (isInterview: boolean, isRemind: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      sendingActionAgency.value &&
      isInterview === (typeModal.value === 'interview') &&
      isRemind === (typeModal.value === 'remindInterview')
  );

const enableFetchAgency = computed(() => !!agencyId.agencyId);

// Get data (Company name, Notes) of Agency
const {
  data: agency,
  isSuccess: successDataAgency,
  remove: removeDataAgency,
} = getAgencyInterview(agencyId, {
  enabled: enableFetchAgency,
});

const addNote = (id: string) => {
  agencyId.agencyId = id;
  actionOpenAgencyModal.value = true;
  if (modalTimeout.value) {
    clearTimeout(modalTimeout.value);
  }
  typeModal.value = 'addNote';
};

const updateNote = () => {
  paramAddNoteAgency.notes = note.value.replace(/<[^>]+>/g, '');
  paramAddNoteAgency.agencyId = agencyId.agencyId;

  enableAddNote.value = true;
};

const paramAddNoteAgency = reactive<MutationUpdateAgencyNotesArgs>({
  agencyId: agencyId.agencyId,
  notes: note.value,
});

const enabledAddNote = computed(() => enableAddNote.value && paramAddNoteAgency.notes.length > 0);

// Update note Agency
const {
  isSuccess: successAddNote,
  isError: errorAddNote,
  remove: removeAddNote,
} = updateAgencyNotes(paramAddNoteAgency, { enabled: enabledAddNote });

// Invite Agency to interview
const {
  isSuccess: successSentInterview,
  isError: errorSentInterview,
  remove: removeSentInterval,
} = inviteAgency(agencyId, {
  enabled: enabledActionAgency(true, false),
});

// Remind Agency
const {
  isSuccess: successRemindSentInterview,
  isError: errorRemindSentInterview,
  remove: removeRemindSentInterval,
} = remindInviteAgency(agencyId, {
  enabled: enabledActionAgency(false, true),
});

// Reject the Agency
const moveIn = (status: TalentStatus, id: string) => {
  agencyStatus.value = status;
  agencyId.agencyId = id;
  actionOpenAgencyModal.value = true;
  if (modalTimeout.value) {
    clearTimeout(modalTimeout.value);
  }
  typeModal.value = 'reject';
};

const paramsRejectAgency = reactive<MutationRejectAgencyArgs>({
  status: agencyStatus.value as TalentStatus,
  agencyId: agencyId.agencyId,
});

watch(sendingActionAgency, () => {
  paramsRejectAgency.status = agencyStatus.value as TalentStatus;
  paramsRejectAgency.agencyId = agencyId.agencyId;
});

const {
  isSuccess: successRejectAgency,
  isError: errorRejectAgency,
  remove: removeRejectAgency,
} = rejectAgency(paramsRejectAgency, {
  enabled: enabledActionAgency(false, false),
});

/**
 Enable delete of agency
 @param {boolean} isHardDelete - If is true it's hardDelete else softDelete.
 @return {boolean}
 */
const enableDelete = (isHardDelete: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      !!agenciesIdsSelected.value.agencyIds &&
      submitted.value &&
      action.value === 'delete' &&
      isHardDelete === (activeNav.value.value === 'DELETED')
  );

const {
  isSuccess: successSoftDelete,
  isError: errorSoftDelete,
  remove: removeSoftDelete,
} = softDeleteAgencies(agenciesIdsSelected.value, {
  enabled: enableDelete(false),
});

const {
  isSuccess: successHardDelete,
  isError: errorHardDelete,
  remove: removeHardDelete,
} = hardDeleteAgencies(agenciesIdsSelected.value, {
  enabled: enableDelete(true),
});

const enableRestore = computed(
  () =>
    !!agenciesIdsSelected.value &&
    action.value === 'restore' &&
    submitted.value &&
    activeNav.value.value === 'DELETED'
);

const {
  isSuccess: successRestore,
  isError: errorRestore,
  remove: removeRestore,
} = restoreAgencies(agenciesIdsSelected.value, {
  enabled: enableRestore,
});

const removeAPIerror = () => {
  removeRejectAgency();
  removeSentInterval();
  removeSoftDelete();
  removeHardDelete();
  removeRestore();
  removeAddNote();
  removeDataAgency();
  removeRemindSentInterval();

  setTimeout(() => {
    enableAddNote.value = false;
    submitted.value = false;
    sendingActionAgency.value = false;
    agenciesIdsSelected.value.agencyIds.length = 0;
    agencyId.agencyId = '';
  }, TIME_TO_RESTORE_VARIABLES);
};

watch(
  [
    successSentInterview,
    successRemindSentInterview,
    successRejectAgency,
    successRestore,
    successSoftDelete,
    successHardDelete,
    successAddNote,
  ],
  () => {
    searchTerms.value.length > MIN_LENGTH_SEARCH ? refetchSearch() : refetch();
    refetchTabCounter();

    removeAPIerror();
  }
);

const submit = () => {
  const agencyToDelete = selectedItems.value.map((item) => (item as (typeof items.value)[0]).id);
  if (action.value === 'delete') {
    agenciesIdsSelected.value.agencyIds = agencyToDelete;
    submitted.value = true;
  }

  const agencyToRestore = selectedItems.value.map((item) => (item as (typeof items.value)[0]).id);
  if (action.value === 'restore' && isDeleted.value) {
    agenciesIdsSelected.value.agencyIds = agencyToRestore;
    submitted.value = true;
  }
};

const showCorrectModaltext = computed(() => {
  switch (typeModal.value) {
    case 'interview':
      return {
        head: inviteInterview,
        paragraph: interviewParagraphModal,
        sent: interviewSentModal,
      };

    case 'remindInterview':
      return {
        head: remindInviteInterview,
        paragraph: interviewParagraphModal,
        sent: interviewSentModal,
      };

    case 'reject':
      if (agencyStatus.value === 'CANTERA') {
        return {
          head: moveInString + ' ' + cantera,
          paragraph: rejectParagraphModal + ' ' + cantera,
          sent: rejectAgencySentModal,
        };
      } else if (agencyStatus.value === 'FUTURE') {
        return {
          head: moveInString + ' ' + futureTalent,
          paragraph: rejectParagraphModal + ' ' + futureTalent,
          sent: rejectAgencySentModal,
        };
      } else {
        return {
          head: moveInString + ' ' + eligible,
          paragraph: rejectParagraphModal + ' ' + eligible,
          sent: rejectAgencySentModal,
        };
      }

    case 'addNote':
      return { head: addNoteString, paragraph: notePlaceholder, sent: '' };

    default:
      return { head: '', paragraph: '', sent: '' };
  }
});

watch(
  [
    successSentInterview,
    errorSentInterview,
    successRemindSentInterview,
    errorRemindSentInterview,
    successRejectAgency,
    errorRejectAgency,
    errorSentInterview,
    successSoftDelete,
    errorSoftDelete,
    successHardDelete,
    errorHardDelete,
    successRestore,
    errorRestore,
    successAddNote,
    errorAddNote,
  ],
  () => {
    if (
      successSentInterview.value ||
      errorSentInterview.value ||
      successRemindSentInterview.value ||
      errorRemindSentInterview.value ||
      successRejectAgency.value ||
      errorRejectAgency.value ||
      errorSentInterview.value ||
      errorSoftDelete.value ||
      errorHardDelete.value ||
      successSoftDelete.value ||
      successRestore.value ||
      errorRestore.value ||
      successAddNote.value ||
      errorAddNote.value
    ) {
      notificationPopup.value = true;
      modalTimeout.value = setTimeout(() => {
        if (actionOpenAgencyModal.value) {
          actionOpenAgencyModal.value = false;
        }
      }, NOTIFICATION_DURATION);
    } else {
      setTimeout(() => {
        notificationPopup.value = false;
      }, NOTIFICATION_DURATION);
    }
  }
);

const messageNotification = computed(() => {
  switch (true) {
    case successSentInterview.value:
      return {
        status: 'success',
        message: interviewSentModal,
      };

    case errorSentInterview.value:
      return {
        status: 'error',
        message: rejectError,
      };

    case successRemindSentInterview.value:
      return {
        status: 'success',
        message: interviewSentModal,
      };

    case errorRemindSentInterview.value:
      return {
        status: 'error',
        message: rejectError,
      };

    case successRejectAgency.value:
      return {
        status: 'success',
        message: rejectAgencySentModal + ' ' + (agencyStatus.value === 'FUTURE' ? future : cantera),
      };

    case errorRejectAgency.value:
      return {
        status: 'error',
        message: rejectError,
      };

    case successSoftDelete.value:
      return {
        status: 'success',
        message: deleteAgenciesSuccess,
      };

    case errorSoftDelete.value:
      return {
        status: 'error',
        message: deleteAgenciesError,
      };

    case successHardDelete.value:
      return {
        status: 'success',
        message: deleteAgenciesSuccess,
      };

    case errorHardDelete.value:
      return {
        status: 'error',
        message: deleteAgenciesError,
      };

    case successRestore.value:
      return {
        status: 'success',
        message: restoreSuccessful,
      };

    case errorRestore.value:
      return {
        status: 'error',
        message: genericError,
      };

    case successAddNote.value:
      return {
        status: 'success',
        message: successAddNoteString,
      };

    case errorAddNote.value:
      return {
        status: 'error',
        message: errorAddNoteString,
      };

    default:
      return {
        status: 'error',
        message: genericError,
      };
  }
});

watch(
  notificationPopup,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      currentNotification.value = messageNotification.value;
    } else {
      notificationPopup.value = false;
    }
  },
  { deep: true }
);

const agenciesPagination = computed(() => {
  if (searchData.value && searchTerms.value.length > MIN_LENGTH_SEARCH) {
    const {
      endCursor: nextPage,
      hasNextPage,
      hasPreviousPage,
      startCursor: prevPage,
    } = searchData.value.searchAgencies.pageInfo;

    return {
      label: agencies,
      currentPage: page.value ?? 1,
      nextPage,
      hasNextPage,
      prevPage,
      hasPreviousPage,
      totalItem: totalCount.value,
      totalPages: totalPages.value,
    };
  } else if (data.value) {
    const {
      endCursor: nextPage,
      hasNextPage,
      hasPreviousPage,
      startCursor: prevPage,
    } = data.value.agencies.pageInfo;

    return {
      label: agencies,
      currentPage: page.value ?? 1,
      nextPage,
      hasNextPage,
      prevPage,
      hasPreviousPage,
      totalItem: totalCount.value,
      totalPages: totalPages.value,
    };
  }
});

const changePage = async (type: 'first' | 'prev' | 'next' | 'last', cursor: string | undefined) => {
  const { currentPage, paginationParams } = usePagination(
    type,
    cursor,
    page.value,
    visibleResult.value,
    totalPages.value,
    paginationAgenciesValue.value,
    searchData.value && searchTerms.value.length > MIN_LENGTH_SEARCH ? searchParams : params
  );
  page.value = currentPage;
  Object.assign(params, paginationParams);
};
</script>

<template>
  <div class="Agencies">
    <div class="Agencies__nav container-fluid">
      <Nav class="col-12" :items="navItems" :active-item="activeNav.value" @clicked="setActiveNav">
        <template #content>
          <Button
            variant="primary"
            tag="router-link"
            to="/agencies/add"
            :label="addAgency"
            icon="plus"
            :disabled="userRole !== ('ADMIN' || 'SUPERVISOR')"
          />
        </template>
      </Nav>
    </div>
    <div class="container">
      <Filters
        class="col-12"
        :tab-items="tabItemsMapped"
        :select-options="AgenciesFilterData"
        :active-tab="typeof activeTab === 'object' ? activeTab.name : activeTab"
        :hidden-tab="isDeleted"
        :download-c-s-v="downloadCSV"
        :loading-download="exportLoading && enabledDownloadCSV"
        :filters-query="filtersQuery"
        @tab-selected="setActiveTab"
        @keystart="handleSearch"
        @active="activeFilters"
        @reset="resetFilters"
        @start-download="(download: boolean) => startDownload = download"
      />
    </div>
    <div class="container">
      <div class="col-12">
        <div class="Agencies__select">
          <div class="Agencies__select-actions">
            <Select
              :placeholder="selectAction"
              size="small"
              :options="!isDeleted ? agenciesString.options : agenciesString.optionsDeleted"
              @selected="setAction"
            />
            <Button
              :label="buttonAction"
              variant="secondary"
              native-type="submit"
              :disabled="isApplyButtonDisabled"
              @click="submit"
            />
          </div>
          <div class="Agencies__select-actions">
            <Select
              :label="selectVisibleAgencies.label"
              :options="selectVisibleAgencies.options"
              :initial-value="initialVisibleResult"
              variant="table"
              @selected="(option: Option) => (visibleResult = +option.value, page = 1)"
            />
            <MultiSelect
              :options="columnsOptions"
              :initial-value="visibleColumns"
              :placeholder="selectColumns"
              size="small"
              @selected="removeColumn"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="col-12">
        <div class="Agencies__found" v-if="resultAgenciesFound || searchTerms.length">
          <span class="Agencies__found--number">{{ totalCount }}</span>
          <span class="Agencies__found--text">{{ resultFound }}</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="col-12">
        <Table
          :items="items"
          :columns="columns[ITableColumns.Agencies]"
          :action="actions"
          icon="edit"
          is-small-row
          :is-loading="searchTerms.length > MIN_LENGTH_SEARCH ? loadingSearch : isLoading"
          :highlight-text="searchTerms"
          @select-items="selectItems"
          @reset-selection="resetSelection"
        />
        <Pagination
          v-if="agenciesPagination && items.length > 0"
          v-bind="{ ...agenciesPagination }"
          @change-page="changePage"
        />
      </div>
    </div>
    <Modal
      :is-modal-active="actionOpenAgencyModal"
      @close-modal="actionOpenAgencyModal = false"
      :head="showCorrectModaltext.head"
    >
      <template #modal-main v-if="typeModal !== 'addNote'">
        <div class="popupInterview">
          <o-skeleton
            class="popupInterview-skeleton --title"
            :animated="true"
            v-if="!agency?.agency.companyName"
          />
          <h4 class="popupInterview-title heading-h4" v-else>
            {{ agency.agency.companyName }}
          </h4>
          <o-skeleton
            class="popupInterview-skeleton --subtitle"
            :animated="true"
            v-if="!agency?.agency.email"
          />
          <div class="popupInterview-subtitle text-body-1" v-else>
            {{ agency?.agency.email }}
          </div>
          <div class="popupInterview-main">
            <p
              v-if="!successSentInterview || !successRejectAgency || !successRemindSentInterview"
              class="popupInterview-main-paragraph heading-h4"
            >
              {{ showCorrectModaltext.paragraph }}
            </p>
            <p v-else class="popupInterview-main-paragraph heading-h4">
              {{ showCorrectModaltext.sent }}
            </p>
          </div>
        </div>
      </template>
      <template #modal-main>
        <div class="popupAddNote">
          <div class="popupAddNote__note-details mb-1" v-if="agency?.agency.notesDetails?.userId">
            <span class="popupAddNote__note-details--info">
              {{ lastUpdate }}:
              {{
                moment(agency?.agency.notesDetails.updatedAt)
                  .locale('it')
                  .format('YYYY-MM-DD, ddd H:mm')
              }}
            </span>
            <span class="popupAddNote__note-details--info">
              {{ user }}: {{ agency?.agency.notesDetails.firstName }}
              {{ agency?.agency.notesDetails.lastName }}
            </span>
            <span class="popupAddNote__note-details--info">
              {{ user }} ID: {{ agency?.agency.notesDetails.userId }}
            </span>
          </div>

          <o-skeleton v-if="!successDataAgency" class="popupAddNote-skeleton" :animated="true" />
          <TextAreaForm
            v-else
            :initial-value="agency?.agency.notes"
            :placeholder="showCorrectModaltext.paragraph.replace('{name}', agencyString)"
            @keystart="(p) => (note = p)"
          />
        </div>
      </template>

      <template #modal-footer v-if="typeModal === 'addNote'">
        <component
          :is="Button"
          variant="primary"
          :label="saveNote"
          @clicked="updateNote"
          :disabled="note.length < 1"
        ></component>
      </template>
      <template #modal-footer v-else>
        <component
          :is="Button"
          variant="secondary"
          :label="cancel"
          @clicked="actionOpenAgencyModal = false"
          v-if="!successSentInterview || !successRejectAgency || !successRemindSentInterview"
        ></component>
        <component
          :is="Button"
          variant="primary"
          :label="confirmButton"
          @clicked="sendingActionAgency = true"
          v-if="!successSentInterview || !successRejectAgency || !successRemindSentInterview"
        ></component>

        <component
          v-else
          :is="Button"
          variant="primary"
          :label="understand"
          @clicked="actionOpenAgencyModal = false"
        ></component>
      </template>
    </Modal>
    <Notification
      v-if="notificationPopup && currentNotification"
      :message="currentNotification.message"
      :status="(currentNotification.status as NotificationStatus)"
      :duration="NOTIFICATION_DURATION"
    />
  </div>
</template>

<style scoped lang="scss">
.Agencies {
  &__nav {
    margin-bottom: 4rem;
  }

  &__select {
    @include flexing(row, space-between, center);
    margin-bottom: 3.9rem;

    &-actions {
      @include flexing(row, start, center);
      gap: 0.8rem;
    }
  }

  &__found {
    @extend .heading-h4;
    @include flexing(row, start, center);
    gap: 0.4rem;
    margin-bottom: 2.4rem;

    &--text {
      color: $black-50;
    }
  }

  mark {
    background-color: $accent;
    color: $secondary-color;
    border-radius: 0.2rem;
  }
}

.popupInterview {
  text-align: center;
  width: 39rem;
  margin: auto;

  &-skeleton {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.8rem;

    &.--title {
      :deep(.o-sklt__item) {
        height: 3rem;
      }

      width: 40%;
    }

    &.--subtitle {
      :deep(.o-sklt__item) {
        height: 2.5rem;
      }

      width: 60%;
    }
  }

  &-title {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  &-subtitle {
    color: $black-50;
  }

  &-main {
    margin: 3.2rem 0;

    &-paragraph {
      margin: 0;
    }
  }
}

.popupAddNote {
  padding: 2.5rem;

  &__note-details {
    border: 0.1rem solid $black-20;
    border-radius: 0.4rem;
    @include flexing(column, center, center);
    gap: 0.8rem;
    @extend .text-body-2;
    padding: 2rem 0;
  }

  &-skeleton {
    :deep(.o-sklt__item) {
      min-height: 18.8rem;
    }

    width: 100%;
  }
}
</style>
