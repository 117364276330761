import json from '@/data/NavOptions.json';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export enum INavOptions {
  UsersManagement = 'UsersManagement',
  Talents = 'Talents',
  Agencies = 'Agencies',
  Analytics = 'Analytics',
  Settings = 'Settings',
  Mail = 'Mail',
  // Todo: add all the nav options
}

type IUseNav = {
  nav: INavOptions;
  pathName: string;
};

export const useNav = (props: IUseNav) => {
  const { nav, pathName } = props;
  const router = useRouter();

  const items = json[nav];
  const activeNav = ref(items[0]);

  const savedNav = localStorage.getItem(`activeNav-${pathName}`);
  const setActive = json[nav].find((item) => item.value === savedNav);
  if (setActive) {
    activeNav.value = { ...setActive };
    router.push({ query: { nav: activeNav.value.value } });
  } else {
    router.push({ query: { nav: items[0].value } });
  }

  const setActiveNav = (e: { text: string; value: string }) => {
    activeNav.value = e;
    router.push({ query: { nav: e.value } });
    localStorage.setItem(`activeNav-${pathName}`, e.value);
  };

  return {
    items,
    activeNav,
    setActiveNav,
  };
};
