import { gql } from '@/api/graphql-client';
import { technologyFragment } from '@/api/fragments/technology';
import type { MutationUpdateTechnologyArgs, Technology } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateTechnologyMutation = gql`
  mutation UpdateTechnology($technologyId: String!, $input: UpdateTechnologyInput!) {
    updateTechnology(technologyId: $technologyId, input: $input) {
      ...technology
    }
  }
  ${technologyFragment}
`;

export const updateTechnology = (
  props?: MutationUpdateTechnologyArgs,
  options?: UseQueryOptions<Technology, MutationUpdateTechnologyArgs, Technology, any[]>
): UseQueryReturnType<Technology, MutationUpdateTechnologyArgs> =>
  useGraphql({
    query: updateTechnologyMutation,
    variables: props,
    options,
  });
