import { gql } from 'graphql-request';
import type {
  RemoveTalentToolMutationMutation,
  MutationRemoveTalentToolArgs,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const removeTalentToolMutation = gql`
  mutation RemoveTalentToolMutation($technologyId: String!, $talentId: String!) {
    removeTalentTool(technologyId: $technologyId, talentId: $talentId)
  }
`;

export const removeTalentTool = (
  props?: MutationRemoveTalentToolArgs,
  options?: UseQueryOptions<
    RemoveTalentToolMutationMutation,
    MutationRemoveTalentToolArgs,
    RemoveTalentToolMutationMutation,
    any[]
  >
): UseQueryReturnType<RemoveTalentToolMutationMutation, MutationRemoveTalentToolArgs> =>
  useGraphql({
    query: removeTalentToolMutation,
    variables: props,
    options,
  });
