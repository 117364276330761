<script setup lang="ts">
import { DealTalentSelection } from '@/gql/Gql.types';
import Button from '@/components/atoms/Button.vue';
import Select from '@/components/atoms/Select.vue';
import { getDeal } from '@/api/queries/deal';
import { useRouteParams } from '@vueuse/router';
import { computed, defineEmits, ref, defineProps } from 'vue';
import { useRouteQuery } from '@vueuse/router';

const tab = useRouteQuery<string>('tab');

const emit = defineEmits([
  'openStatusModal',
  'setSelectedTalent',
  'setSelectedTalentStatus',
  'openNotesModal',
  'openEditModal',
  'deleteTalentSelection',
]);

const id = useRouteParams<string>('id');

const props = defineProps<{
  talent: DealTalentSelection;
  resetSelect: number;
}>();

const { data: deal } = getDeal({
  id: id.value,
});

const cvUrl = computed(() => props.talent?.talent.documents?.find((doc) => doc.type === 'CV')?.url);

const portfolioUrl = computed(
  () => props.talent?.talent.documents?.find((doc) => doc.type === 'PORTFOLIO')?.url
);

const selectOptions = ref([
  {
    value: 'UNCHECKED',
    label: 'Unchecked',
  },
  {
    value: 'REJECTED',
    label: 'Rejected',
  },
  {
    value: 'NOT_AVAILABLE',
    label: 'Not Avaliable',
  },
  {
    value: 'MAYBE',
    label: 'Maybe',
  },
  {
    value: 'SHORTLIST',
    label: 'Shortlist',
  },
]);
</script>

<template>
  <div class="talent">
    <div class="info">
      <span class="manuallyAdded" v-if="props.talent.manuallyAdded">Manually Added</span>
      <span class="name"
        >{{ props.talent.talent?.firstName }} {{ props.talent.talent?.lastName }}</span
      >
      <span class="email">{{ props.talent.talent?.email }}</span>
      <span class="mobile">{{ props.talent.talent?.mobile }}</span>
    </div>
    <div class="statusSelect">
      <Select
        :options="selectOptions"
        :initial-value="selectOptions.find((option) => option.value === props.talent.status)"
        size="small"
        @selected="
          (value) => {
            emit('openStatusModal', true);
            emit('setSelectedTalentStatus', value.value);
            emit('setSelectedTalent', props.talent.id);
          }
        "
        :key="props.resetSelect"
        :disabled="deal?.deal.isArchived || Boolean(deal?.deal.sentAt)"
      />
    </div>

    <div class="links">
      <Button
        icon="search-doc"
        label="CV"
        variant="link-tertiary"
        :to="cvUrl"
        tag="a"
        target="_blank"
      />
      <Button
        icon="search-doc"
        label="Portfolio"
        variant="link-tertiary"
        :to="portfolioUrl"
        tag="a"
        target="_blank"
      />
      <Button
        icon="search-doc"
        label="Notes"
        variant="link-tertiary"
        @click="
          () => {
            emit('openNotesModal', true);
            emit('setSelectedTalent', props.talent.id);
          }
        "
      />
    </div>
    <div class="ratecard">
      <div>
        <span class="title">Ratecard Range</span>
        <span class="values">€ {{ deal?.deal.ratecard }} - € {{ deal?.deal.ratecardMax }}</span>
      </div>
      <div>
        <span class="agreedRatecardTitle">Agreed Ratecard</span>
        <span class="agreedRatecard">€ {{ props.talent.agreedRatecard }}</span>
      </div>
    </div>
    <div class="actions">
      <div class="actions-left">
        <Button
          icon="edit"
          variant="accent"
          :disabled="deal?.deal.isArchived"
          @clicked="
            () => {
              emit('openEditModal', true);
              emit('setSelectedTalent', props.talent.id);
            }
          "
        />
        <Button
          v-if="tab === 'POOL'"
          icon="delete"
          variant="danger"
          :disabled="deal?.deal.isArchived || Boolean(deal?.deal.sentAt)"
          @clicked="
            () => {
              emit('deleteTalentSelection', [props.talent.id]);
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.talent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 3rem 0;
  border-bottom: 1px solid $black-10;
  gap: 3rem;
  .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 15%;
    .name {
      font-size: 1.6rem;
      font-weight: $primary-font-weight;
    }

    .email,
    .mobile {
      font-size: 1.3rem;
      color: $black-50;
    }

    .manuallyAdded {
      font-size: 1.3rem;
      color: rgba($blue, 0.75);
    }
  }

  .statusSelect {
    width: 18rem;
  }

  .links {
    display: flex;
    gap: 1rem;
  }

  .ratecard {
    display: flex;
    gap: 4rem;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .title {
        font-size: 1.3rem;
        color: $black-50;
      }
      .values {
        font-size: 1.3rem;
        font-weight: $primary-font-weight;
      }
      .agreedRatecardTitle {
        font-size: 1.3rem;
        color: $black-50;
      }
      .agreedRatecard {
        font-size: 1.3rem;
        font-weight: $primary-font-weight;
      }
    }
  }

  .actions-left {
    display: flex;
    gap: 1rem;
  }
}
</style>
