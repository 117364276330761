<script setup lang="ts">
import { ref, reactive } from 'vue';
import { useRoute } from 'vue-router';
import LoginForm from '@/components/molecules/LoginForm.vue';
import resetPasswordString from '@/data/ResetPassword.json';
import { useUserStore } from '@/stores/user';
import type { ResetPasswordInput } from '@/gql/Gql.types';
import Notification from '@/components/atoms/Notification.vue';

const { title, formTitle, inputs, login, resetMessage, backToLogin } = resetPasswordString;

const { resetPassword } = useUserStore();
const { params } = useRoute();

interface IResetPasswordHandle {
  password: string;
}

const inputResetPassword: ResetPasswordInput = reactive({
  newPassword: '',
  token: params.token.toString(),
});

const resettingPassword = ref(false);

const error = ref();

const resetPasswordHandle = async (newCredentials: IResetPasswordHandle) => {
  error.value = '';
  resettingPassword.value = true;
  inputResetPassword.newPassword = newCredentials.password;
  try {
    await resetPassword(inputResetPassword);
  } catch (e) {
    error.value = e;
  }
};
</script>

<template>
  <Notification v-if="error" :message="error" status="error" />
  <LoginForm
    :title="title"
    :form-title="formTitle"
    :inputs="inputs"
    :primary-btn="login"
    :message="resetMessage"
    :sending="resettingPassword"
    :back-to-login="backToLogin"
    @submitted="resetPasswordHandle"
  />
</template>
