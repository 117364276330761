import { gql } from '@/api/graphql-client';

export const refreshTokenMutation = gql`
  mutation refreshToken($token: JWT!) {
    refreshToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;
