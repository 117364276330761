import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UserAnalyticsQuery, UserAnalyticsQueryVariables } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const userAnalyticsQuery = gql`
  query userAnalytics {
    userAnalytics(filters: { entity: TALENT, timeRange: WEEK }) {
      firstname
      analytics {
        range
        totalCount
        screening {
          expertises {
            expertise
            count
          }
        }
        interview {
          expertises {
            expertise
            count
          }
        }
      }
    }
  }
`;

export const getUserAnalytics = (
  options?: UseQueryOptions<UserAnalyticsQuery, void, UserAnalyticsQuery, any[]>
): UseQueryReturnType<UserAnalyticsQuery, UserAnalyticsQueryVariables> =>
  useGraphql({
    query: userAnalyticsQuery,
    options,
  });
