<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import type { IButtonProps } from '@/models/buttonModel';
import { toRefs } from 'vue';

type ITableDocumentsViewerProps = {
  links: {
    tag?: string;
    target: string;
    src: string;
    label: string;
    action?: Function;
    highlight?: boolean;
  }[];
};

const props = withDefaults(defineProps<ITableDocumentsViewerProps>(), {
  links: () => [],
});

const { links } = toRefs(props);
</script>
<template>
  <div class="TableDocumentsViewer">
    <Button
      v-for="(item, index) in links"
      :key="item.label + index"
      :class="`TableDocumentsViewer__item ${item.highlight ? 'mark' : ''}`"
      variant="link-tertiary"
      :tag="item.tag as IButtonProps['tag'] ?? 'a'"
      :target="item.target"
      :label="item.label"
      :to="item.src"
      icon="search-doc"
      @clicked="item.action"
    />
  </div>
</template>
<style scoped lang="scss">
.TableDocumentsViewer {
  @include flexing(column);

  &__item {
    margin-bottom: 0.6rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.mark {
      padding: 0.2rem;
      border-radius: 0.2rem;
      background-color: $accent;
      color: $secondary-color;

      :deep .o-btn__label .text-link {
        border-color: $secondary-color;
      }

      &:hover {
        background-color: initial;
        color: initial;
        :deep .o-btn__label .text-link {
          border-color: initial;
        }
      }
    }
  }
}
</style>
