import { gql } from '@/api/graphql-client';

export const technologyFragment = gql`
  fragment technology on Technology {
    id
    name
    expertises {
      id
      name
    }
  }
`;
