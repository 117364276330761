<script setup lang="ts">
import { computed, toRefs } from 'vue';

type ILogo = {
  type: 'black' | 'white';
};

const props = withDefaults(defineProps<ILogo>(), {
  type: 'white',
});

const { type } = toRefs(props);

const getLogo = computed(() => {
  return (type: string) => {
    const url = new URL(`@/assets/logo.svg#`, import.meta.url).href;
    return url + type;
  };
});
</script>

<template>
  <span class="Logo">
    <svg class="Logo__svg">
      <use :xlink:href="getLogo(type)" />
    </svg>
  </span>
</template>

<style scoped lang="scss">
.Logo {
  &__svg {
    max-height: 3.2rem;
    max-width: 13.4rem;
  }
}
</style>
