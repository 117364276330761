import { gql } from '@/api/graphql-client';
import type { MutationCreateInferenceReviewArgs, InferenceReview } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const createInferenceReviewMutation = gql`
  mutation CreateInferenceReview($input: CreateInferenceReviewInput!) {
    createInferenceReview(input: $input) {
      note
      review
    }
  }
`;

export const createInferenceReview = (
  props?: MutationCreateInferenceReviewArgs,
  options?: UseQueryOptions<
    InferenceReview,
    MutationCreateInferenceReviewArgs,
    InferenceReview,
    any[]
  >
): UseQueryReturnType<InferenceReview, MutationCreateInferenceReviewArgs> =>
  useGraphql({
    query: createInferenceReviewMutation,
    variables: props,
    options,
  });
