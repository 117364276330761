<script setup lang="ts">
import { computed, toRefs } from 'vue';

type IIconProps = {
  icon: string;
  iconSize?: 'micro' | 'small' | 'medium' | 'large';
};

const props = withDefaults(defineProps<IIconProps>(), {
  iconSize: 'small',
});

const { iconSize, icon } = toRefs(props);

const iconName = computed(() => {
  const url = new URL(`@/assets/icons/icons.svg#`, import.meta.url).href;
  return url + icon.value;
});
</script>

<template>
  <span class="icon">
    <svg :class="`icon__svg --${iconSize}`">
      <use :xlink:href="iconName" />
    </svg>
  </span>
</template>

<style scoped lang="scss">
.icon {
  display: inline-flex;
  align-items: center;

  &__svg {
    height: 1.6rem;
    width: 1.6rem;
  }

  .--large {
    height: 6.4rem;
    width: max-content;
  }

  .--medium {
    height: 2.4rem;
    width: 2.4rem;
  }

  .--micro {
    height: 1rem;
    width: 1rem;
  }
}
</style>
