import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { TalentCommonDataQuery } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const talentCommonDataQuery = gql`
  query TalentCommonData {
    talentCommonData {
      collaborations
      countries
      freelance
      industries
      languages
      rates
      seniorities
      specialities {
        expertise
        skills
      }
      tools {
        expertise
        technologies
      }
      status
      workplaces
      prediction
    }
  }
`;

export const getTalentCommonData = (
  options?: UseQueryOptions<TalentCommonDataQuery, void, TalentCommonDataQuery, any[]>
): UseQueryReturnType<TalentCommonDataQuery, void> =>
  useGraphql({
    query: talentCommonDataQuery,
    options,
  });
