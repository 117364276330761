<script setup lang="ts">
import { toRefs, computed } from 'vue';

interface IHighlightProps {
  text?: string;
  highlightText: string | undefined;
}

const props = defineProps<IHighlightProps>();

const { text, highlightText } = toRefs(props);

const matchText = computed(() => {
  return (current?: string) => {
    if (!current) return '';
    if (highlightText.value === undefined || highlightText.value.length <= 0)
      return `<span>${current}</span>`;

    const escapedHighlight = highlightText.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedHighlight, 'ig');
    const found = current.match(regex);

    if (!found) return current;

    return current.replace(regex, '<span class="mark">' + found[0] + '</span>');
  };
});
</script>

<template>
  <div class="Highlight" v-html="matchText(text)"></div>
</template>

<style lang="scss">
.Highlight {
  font-size: inherit;
  font-family: inherit;
  display: inline-block;

  .mark {
    padding: 0.2rem;
    border-radius: 0.2rem;
    background-color: $accent;
    color: $secondary-color;
  }
}
</style>
