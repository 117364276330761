<script setup lang="ts">
import { hardDeleteCoupon } from '@/api/mutations/hardDeleteCoupon';
import { softDeleteCoupon } from '@/api/mutations/softDeleteCoupon';
import { getCoupon } from '@/api/queries/coupon';
import Button from '@/components/atoms/Button.vue';
import Modal from '@/components/atoms/Modal.vue';
import CouponForm from '@/components/molecules/CouponForm.vue';
import GoBack from '@/components/molecules/GoBack.vue';
import strings from '@/data/Coupon.json';
import GenericStrings from '@/data/GenericStrings.json';
import { type ComputedRef, computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NAVIGATION_BACK_DEFAULT_TIME, NOTIFICATION_DURATION } from '@/data/constants';
import Notification from '@/components/atoms/Notification.vue';

const {
  editCoupon,
  addCoupon,
  deleteCoupon,
  deleteCouponParagraph,
  deleteCouponSuccess,
  deleteCouponError,
} = strings;

const { confirmButton, cancel } = GenericStrings;

const router = useRouter();

const {
  params: { id },
} = useRoute();

const isEdit = computed(() => useRoute().fullPath.includes('edit'));

const startDelete = ref(false);
const openModalDeleteCoupon = ref(false);

const { data: coupon, isSuccess } = getCoupon({ couponId: id as string }, { enabled: !!id });

const couponData = computed(() => !(!coupon.value || !isSuccess) && coupon.value.coupon);

const enableDelete = (isHardDelete: boolean): ComputedRef<boolean> =>
  computed(
    () => !!couponData.value && startDelete.value && isHardDelete === !!couponData.value.deletedAt
  );

const { isSuccess: successSoftDelete, isError: errorSoftDelete } = softDeleteCoupon(
  { couponId: id as string },
  {
    enabled: enableDelete(false),
  }
);

const { isSuccess: successHardDelete, isError: errorHardDelete } = hardDeleteCoupon(
  { couponId: id as string },
  {
    enabled: enableDelete(true),
  }
);

watch([successHardDelete, successSoftDelete], async () => {
  if (successSoftDelete.value || successHardDelete.value) {
    openModalDeleteCoupon.value = false;
  }

  setTimeout(() => {
    router.push('/coupon');
  }, NAVIGATION_BACK_DEFAULT_TIME);
});
</script>

<template>
  <div class="EditAddCoupon">
    <GoBack
      class="EditAddCoupon-goBack"
      :headline="id ? editCoupon : addCoupon"
      previous-route="/coupon/"
    >
      <template #action-button v-if="isEdit && couponData">
        <Button :label="deleteCoupon" @clicked="() => (openModalDeleteCoupon = true)" />
      </template>
    </GoBack>
    <div class="EditAddCoupon-form container">
      <CouponForm
        v-if="(isEdit && couponData) || !isEdit"
        v-bind="{ ...couponData }"
        :id="(id as string)"
        class="col-6 start-4 start-lx-3"
      />
      <o-skeleton
        v-if="!couponData && isEdit"
        class="col-6 start-4 start-lx-3"
        width="100%"
        height="78rem"
        :animated="true"
      />
    </div>
  </div>
  <Modal
    :is-modal-active="openModalDeleteCoupon"
    @close-modal="openModalDeleteCoupon = false"
    :head="deleteCoupon"
  >
    <template #modal-main>
      <p class="modalCoupon heading-h4">
        {{ deleteCouponParagraph }}
      </p>
    </template>

    <template #modal-footer>
      <component
        :is="Button"
        variant="secondary"
        :label="cancel"
        @clicked="openModalDeleteCoupon = false"
      ></component>
      <component
        :is="Button"
        variant="primary"
        :label="confirmButton"
        @clicked="() => (startDelete = true)"
      ></component>
    </template>
  </Modal>
  <Notification
    v-if="successSoftDelete || errorSoftDelete || successHardDelete || errorHardDelete"
    :message="successSoftDelete || successHardDelete ? deleteCouponSuccess : deleteCouponError"
    :status="errorSoftDelete || errorHardDelete ? 'error' : 'success'"
    :duration="NOTIFICATION_DURATION"
  />
</template>

<style scoped lang="scss">
.EditAddCoupon {
  &-goBack {
    margin-bottom: 6.4rem;
  }

  &-form {
    margin-bottom: 4.3rem;
  }
}

.modalCoupon {
  margin: 0 0 3.2rem;
  text-align: center;
}
</style>
