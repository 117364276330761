<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';
import { Field } from 'vee-validate';
import Icon from '@/components/atoms/Icon.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import type { Option, IMultiSelectProps } from '@/models/multiSelectModel';

const props = withDefaults(defineProps<IMultiSelectProps>(), {
  initialValue: () => [
    {
      label: '',
      value: '',
    },
  ],
  disabled: false,
  label: '',
  placeholder: '',
  size: '',
  variant: 'filter',
});

const { placeholder, initialValue, label, options, name, rules, size, disabled } = toRefs(props);

const checkIntialValue = computed(() => initialValue.value[0].label.length > 0);

const currentOptions = ref<Option[]>(checkIntialValue.value ? initialValue.value : []);

const isChecked = ref(false);

const removeEmptyValue = (array: Option[]) =>
  array.filter((obj) => obj.label !== '' || obj.value !== '');

watch(initialValue, (newInitialValue, oldinitialValue) => {
  if (newInitialValue !== oldinitialValue) {
    currentOptions.value = newInitialValue;
    currentOptions.value = removeEmptyValue(currentOptions.value);
  }
});

const updatedCurrentOptions = (e: Event, option: Option) => {
  isChecked.value = (e.target as HTMLInputElement).checked;
  isChecked.value ? pushOption(option) : pullOption(option);
};

const pushOption = (option: Option) => {
  currentOptions.value.push(option);
};

const checkAlredySelected = computed(() => (option: Option): [boolean, number] => {
  const indexToPull = currentOptions.value!.findIndex((opt) => opt.value === option.value);

  return [indexToPull !== -1, indexToPull];
});

const pullOption = (option: Option) => {
  const [isSelected, indexToPull] = checkAlredySelected.value(option);
  if (isSelected) {
    currentOptions.value!.splice(indexToPull, 1);
  }
};

const emit = defineEmits(['selected']);

watch(
  () => currentOptions.value?.length,
  () => {
    emit('selected', currentOptions.value);
  }
);
</script>

<template>
  <Field
    :name="name ?? placeholder"
    :value="currentOptions"
    v-slot="{ field, errors }"
    :rules="rules"
  >
    <div class="MultiSelect">
      <p class="MultiSelect-label text-label" v-if="label">
        {{ label }}
      </p>
      <o-dropdown
        :disabled="disabled"
        :class="`MultiSelect__drop ${size && '--' + size}`"
        aria-role="list"
        multiple
      >
        <template #trigger="{ active }">
          <div
            :class="[
              `MultiSelect__drop-trigger ${size && '--' + size}`,
              { active },
              'text-button',
              { counter: currentOptions?.length ?? '' },
            ]"
          >
            <span class="MultiSelect__drop-text" v-if="!!currentOptions">
              {{ placeholder }}
              <span v-if="currentOptions?.length > 0">({{ currentOptions?.length }})</span>
            </span>
            <Icon
              :class="`MultiSelect__drop-icon`"
              :icon="active ? 'chevron-up' : 'chevron-down'"
            />
          </div>
        </template>
        <Checkbox
          v-for="(option, index) in options"
          :key="index + option.value"
          :label="option.label"
          as="checkbox"
          :initial-value="
            !!currentOptions.length && currentOptions.some((opt) => opt.value === option.value)
          "
          :standalone="true"
          @changed="updatedCurrentOptions($event, option)"
        />
      </o-dropdown>
      <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
  </Field>
</template>

<style scoped lang="scss">
.MultiSelect {
  &__drop-trigger {
    min-height: 4rem;
  }

  &--small {
    width: 100%;

    &-trigger {
      max-height: 4rem;
      padding: 1.15rem 1.6rem;
      position: relative;
      cursor: pointer;
      border: 0.1rem solid $black-50;
      border-radius: 0.4rem;

      &:hover,
      &.active {
        border-color: $primary-color;
        background-color: $secondary-color;
      }
    }

    &-icon {
      position: absolute;
      top: 1.3rem;
      right: 1.6rem;
    }

    :deep .o-drop__menu {
      width: 100%;
      background-color: $secondary-color;
      border-radius: 0.4rem;
      margin-top: 0.8rem;

      &--active {
        padding: 0.8rem;
        width: max-content;
      }

      .o-drop__item {
        padding: 1.6rem 1.2rem;
        min-width: max-content;
        width: 100%;

        &::after {
          display: none;
        }
      }
    }
  }

  :deep .o-drop__menu {
    @include flexing(column, start, start);
    max-height: 18.4rem;
    width: max-content;
    overflow-y: auto;
    box-shadow: 0 1rem 4.4rem 0 $black-08;

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $black-20;
      border-radius: 4rem;
    }

    .o-drop__item {
      width: 100%;

      &:hover {
        background-color: $black-04;
      }

      &--active {
        background-color: $black-04;
        color: $primary-color;
        border-radius: 0.4rem;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 1.6rem;
          height: 1.6rem;
          top: 2rem;
          right: 1.6rem;
          background-image: url(@/assets/icons/check.svg);
          background-repeat: no-repeat;
          background-size: 1.8rem 1.11rem;
          filter: invert(1);
        }
      }
    }
  }
  &-label {
    text-transform: uppercase;
    margin: 0 0 0.8rem 0;
  }
  .MultiSelect__drop {
    width: 100%;

    &.--small {
      width: auto;
    }

    &-trigger {
      @include flexing(row, space-between, center);
      cursor: pointer;
      padding: 1.9rem 1.6rem;
      border: 0.1rem solid $black-50;
      border-radius: 0.4rem;

      &.--small {
        padding: 0.8rem 1.6rem;
      }

      &:hover,
      &.active {
        border-color: $primary-color;
      }

      &.counter {
        background-color: $primary-color;
        color: $secondary-color;
      }
    }

    &-icon {
      margin-left: 0.4rem;
    }

    :deep .o-drop__menu {
      padding: 1.6rem;
      margin-top: 0.8rem;
      border-radius: 0.4rem;

      .o-chk.Checkbox {
        &:not(:last-of-type) {
          margin-bottom: 1.6rem;
        }
      }
    }
  }
}
</style>
