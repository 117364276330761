import { gql } from '@/api/graphql-client';
import type { MutationExportAgenciesArgs, ExportAgenciesMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const exportCSVAgenciesMutation = gql`
  mutation exportAgencies($filters: AgenciesFilterInput, $agencyIds: [String!], $keyword: String) {
    exportAgencies(filters: $filters, agencyIds: $agencyIds, keyword: $keyword)
  }
`;

export const exportCsvAgencies = (
  props?: MutationExportAgenciesArgs,
  options?: UseQueryOptions<
    ExportAgenciesMutation,
    MutationExportAgenciesArgs,
    ExportAgenciesMutation,
    any[]
  >
): UseQueryReturnType<ExportAgenciesMutation, MutationExportAgenciesArgs> =>
  useGraphql({
    query: exportCSVAgenciesMutation,
    variables: props,
    options,
  });
