<script setup lang="ts">
import Accordion from '@/components/atoms/Accordion.vue';
import Button from '@/components/atoms/Button.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Modal from '@/components/atoms/Modal.vue';
import Tab from '@/components/atoms/Tab.vue';
import BoxString from '@/data/BoxCategorySkillAccordion.json';
import GenericStrings from '@/data/GenericStrings.json';
import type { LanguageType, TechnologyType } from '@/gql/Gql.types';
import type { IBox, Technology } from '@/models/boxCategorySkillsModel';
import { Field } from 'vee-validate';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import BoxButtons from './components/BoxButton.vue';
import BoxLevel from './components/BoxLevel.vue';

const {
  chooseCategory,
  chooseSkills,
  deleteSkill: deleteSkillString,
  paragraphModalDeleteSkill,
} = BoxString;
const { confirmButton, cancel } = GenericStrings;

const props = withDefaults(defineProps<IBox>(), {
  standalone: false,
  talentName: '',
  talentMail: '',
  successDeleteSkill: false,
  enabledAddSkills: undefined,
});

const { expertises, rules, name, talentName, talentMail, successDeleteSkill, enabledAddSkills } =
  toRefs(props);

/**
 * shallow copy necessary because we cant edit the props coming from API
 */
const shallowExpertises = ref(expertises.value);

const specialitiesSelected = ref(enabledAddSkills.value);

watch(enabledAddSkills, (newSkills) => {
  if (newSkills) specialitiesSelected.value = newSkills;
});

const expertiseEnabled = computed(
  () => (expertise: string) =>
    !specialitiesSelected.value?.some(
      (specialities) => specialities.expertise === expertise && specialities.skills.length > 0
    )
);

const isLanguageExpertise = computed(() => shallowExpertises.value[0].expertise === 'Lingue');

const categoriesKeys = shallowExpertises.value.map(({ expertise }) => ({
  name: expertise,
  label: expertise,
}));

const selectedTechnologiesByExpertise = computed(
  () => (expertise: string) =>
    shallowExpertises.value
      .find((el) => el.expertise === expertise)
      ?.technologies.filter((el) => el.level !== '')
);

const selectedCategory = ref<string>(shallowExpertises.value[0].expertise);
const selectedTechnology = ref<string>('');
const technologySelected = ref('');
const startToAdd = ref(false);
const openModalDeleteSkill = ref(false);
const selectedTechnolyPressed = ref<TechnologyType | LanguageType>();

const showCategory = ({ ...args }) => {
  startToAdd.value = args.startToAdd;
  selectedTechnology.value = args.selectedTechnology;
  selectedCategory.value = args.selectedCategory;
};

const addSkill = ({ ...args }, expertise: string) => {
  startToAdd.value = args.startToAdd;
  selectedTechnology.value = '';
  selectedCategory.value = expertise;
};

/**
 * index of selected accordion
 */
const selectedAccordion = ref<string>('');

/**
 * computed switching selection of the accordion
 */
const selectAccordion = computed(() => (expertise: string) => {
  selectedAccordion.value = expertise;
  selectedCategory.value = expertise;
  selectedTechnology.value = '';
  startToAdd.value = false;
});

/**
 * expertise by accordion
 */
const selectedExpertise = computed(
  () =>
    shallowExpertises.value.find((el) => el.expertise === selectedAccordion.value) ||
    shallowExpertises.value[0]
);

/**
 * expertise selected by category, after clicking on the plus button
 */
const expertiseByCategory = computed(
  () => shallowExpertises.value.find((el) => el.expertise === selectedCategory.value)!
);

/**
 * computed responsible for checking the already selected technology
 */
const checkAlreadySelected = computed(
  () => (technology: string, expertise?: string) =>
    shallowExpertises.value
      .filter((el) => el.expertise === expertise || el.expertise === selectedCategory.value)
      .flatMap((el) => el.technologies.filter((el) => el.level !== ''))
      ?.some((item) => item.technology === technology)
);

const disableTab = computed(
  () => (expertise: string) => categoriesKeys.map((key) => key.name !== expertise)
);

/**
 * computed responsible for counting selected technologies by accordion
 */
const count = computed(
  () => (expertise: string) => selectedTechnologiesByExpertise.value(expertise)?.length || 0
);

/**
 *
 * @param {Event} technology
 * @return {void}
 * function that is responsible for changing technologies when there more than one
 * technology selected in the box
 */
const selectTechnology = (technology: Event) => {
  technologySelected.value = (technology.target as HTMLInputElement).getAttribute('name')!;

  startToAdd.value = false;

  return (shallowExpertises.value = shallowExpertises.value.map((el) =>
    el.expertise !== selectedCategory.value
      ? el
      : {
          expertise: el.expertise,
          technologies: el.technologies.map((el) =>
            el.technology === technologySelected.value
              ? {
                  ...el,
                  level: 'Base',
                }
              : el
          ),
        }
  ));
};

const selectedLanguages = computed(() => {
  return shallowExpertises.value[0].technologies.filter((item: Technology) => item.level !== '');
});

/**
 *
 * @param {String} levelSelected
 * @return {void}
 * function that is responsible for the radio button in technologies levels
 */
const selectLevel = (levelSelected: string) => {
  shallowExpertises.value = shallowExpertises.value.map(({ expertise, technologies }) => {
    if (expertise !== selectedAccordion.value) return { expertise, technologies };

    return {
      expertise,
      technologies: technologies.map(({ technology, level, technologyId, languageId }) => {
        if (technology !== selectedTechnology.value)
          if (!isLanguageExpertise.value) {
            return { technology, level, technologyId };
          } else {
            return { technology, level, languageId };
          }

        return {
          technology,
          level: levelSelected,
          ...(!isLanguageExpertise.value ? { technologyId } : { languageId }),
        };
      }),
    };
  });
};

const selectedLevel = computed(() => {
  if (!selectedExpertise.value || !selectedTechnology.value) return '';
  return selectedExpertise.value.technologies.find(
    (el) => el.technology === selectedTechnology.value
  )!.level;
});

const selectedTechnologies = computed(() => {
  return shallowExpertises.value
    .map((item) => {
      const filteredTechnologies = item.technologies.filter((tech) => tech.level !== '');

      return filteredTechnologies.length > 0
        ? { expertise: item.expertise, technologies: filteredTechnologies }
        : undefined;
    })
    .filter(Boolean);
});

onMounted(() => {
  if (shallowExpertises.value.length <= 1) {
    selectedAccordion.value = selectedExpertise.value.expertise;
  }
});

const existTechnology = computed(() => {
  if (!Array.isArray(selectedTechnologies.value)) {
    return false;
  }

  return selectedTechnologies.value.some((technology) =>
    technology?.technologies?.some((tech) => {
      const correctKeyId = Object.prototype.hasOwnProperty.call(tech, 'technologyId')
        ? tech.technologyId?.trim()
        : tech.languageId?.trim();

      return tech.technology === selectedTechnology.value && correctKeyId !== '';
    })
  );
});

const handleDelete = () => {
  shallowExpertises.value = shallowExpertises.value.map(({ expertise, technologies }) => {
    if (expertise !== selectedAccordion.value) return { expertise, technologies };

    return {
      expertise,
      technologies: technologies.map(({ technology, level, technologyId, languageId }) => {
        if (technology !== selectedTechnology.value)
          if (!isLanguageExpertise.value) {
            return { technology, level, technologyId };
          } else {
            return { technology, level, languageId };
          }

        return {
          technology,
          level: '',
          ...(!isLanguageExpertise.value ? { technologyId } : { languageId }),
        };
      }),
    };
  });

  selectedTechnology.value = '';
};

// eslint-disable-next-line valid-jsdoc
/**
 * function responsible for deleting the technology selected
 */
const deleteSkill = () => {
  openModalDeleteSkill.value = true;
  if (!existTechnology.value) {
    handleDelete();
    openModalDeleteSkill.value = false;
  }
};

watch(successDeleteSkill, () => {
  openModalDeleteSkill.value = false;

  if (successDeleteSkill.value) {
    handleDelete();
  }
});

const correctTechnologyPressed = computed(() => {
  if (selectedTechnolyPressed?.value) {
    const pressedValue = selectedTechnolyPressed.value;
    const name = (selectedTechnolyPressed.value as TechnologyType).technology;

    if (Object.prototype.hasOwnProperty.call(pressedValue, 'technologyId')) {
      const technologyPressed = pressedValue as TechnologyType;
      return { name, id: technologyPressed.technologyId };
    } else {
      const languagePressed = pressedValue as LanguageType;
      return { name, id: languagePressed.languageId };
    }
  }
  return { name: '', id: '' };
});

const emits = defineEmits(['technologies', 'technologyId']);

watch(shallowExpertises, () => emits('technologies', shallowExpertises.value));
</script>
<template>
  <Field
    v-if="shallowExpertises.length > 1"
    v-model="selectedTechnologies"
    v-slot="{ field, errors }"
    :name="name ?? ''"
    :rules="rules"
    :standalone="standalone"
  >
    <Accordion
      v-for="(item, index) in shallowExpertises"
      :key="index + item.expertise"
      :label="item.expertise"
      :has-count="true"
      :count="count(item.expertise)"
      :is-open="selectedAccordion === item.expertise"
      :index="index"
      @open="selectAccordion(item.expertise)"
    >
      <template #content>
        <div class="BoxCategorySkills">
          <o-inputitems v-model="selectedTechnologies" v-bind="field" />
          <BoxButtons
            :selected-expertise="selectedExpertise"
            :start-to-add="startToAdd"
            :disabled="expertiseEnabled(item.expertise)"
            @changed="showCategory"
            @add-skill="(args) => addSkill({ ...args }, item.expertise)"
            @technology-pressed="(tech) => (selectedTechnolyPressed = tech)"
          />

          <template v-if="startToAdd">
            <div class="BoxCategorySkills__category">
              <h3 class="text-body-1">{{ chooseCategory }}</h3>
              <Tab
                :items="categoriesKeys"
                :initial-active="selectedCategory"
                :is-disabled="disableTab(item.expertise)"
                @clicked="(category: string) => selectedCategory = category"
              />
            </div>

            <div class="BoxCategorySkills__skills">
              <h3 class="text-body-1">{{ chooseSkills }}</h3>
              <div class="BoxCategorySkills__skills--wrapper">
                <div
                  v-for="(technology, i) in expertiseByCategory.technologies"
                  :key="i"
                  class="BoxCategorySkills__skills--wrapper-chip"
                >
                  <Checkbox
                    as="chip"
                    :label="technology.technology"
                    :name="technology.technology"
                    :standalone="true"
                    :disabled="checkAlreadySelected(technology.technology, item.expertise)"
                    @changed="selectTechnology"
                  />
                </div>
              </div>
            </div>
          </template>

          <BoxLevel
            v-else
            :selected-expertise="selectedExpertise"
            :selected-technology="selectedTechnology"
            :selected-level="selectedLevel"
            :technology-pressed="selectedTechnolyPressed"
            @clicked="deleteSkill"
            @changed="selectLevel"
          />
        </div>
      </template>
    </Accordion>
    <p class="error-message text-body-2" v-if="errors[0]">
      {{ errors[0] }}
    </p>
  </Field>
  <Field
    v-else
    :name="name ?? ''"
    v-model="selectedLanguages"
    v-slot="{ field, errors }"
    :rules="rules"
  >
    <div class="BoxCategorySkills">
      <o-inputitems v-model="selectedLanguages" v-bind="field" />
      <BoxButtons
        :selected-expertise="selectedExpertise"
        :start-to-add="startToAdd"
        @changed="showCategory"
        @add-skill="(args) => addSkill({ ...args }, shallowExpertises[0].expertise)"
        @technology-pressed="(tech) => (selectedTechnolyPressed = tech)"
      />

      <div class="BoxCategorySkills__category" v-if="startToAdd">
        <h3 class="text-body-1">{{ chooseCategory }}</h3>
        <Tab
          :items="categoriesKeys"
          :initial-active="selectedCategory"
          @clicked="(category: string) => selectedCategory = category"
        />
      </div>

      <div class="BoxCategorySkills__skills" v-if="startToAdd">
        <h3 class="text-body-1">{{ chooseSkills }}</h3>
        <div class="BoxCategorySkills__skills--wrapper">
          <div
            class="BoxCategorySkills__skills--wrapper-chip"
            v-for="(technology, i) in expertiseByCategory.technologies"
            :key="i"
          >
            <Checkbox
              as="chip"
              :label="technology.technology"
              :name="technology.technology"
              :standalone="true"
              :disabled="checkAlreadySelected(technology.technology)"
              @changed="selectTechnology"
            />
          </div>
        </div>
      </div>

      <BoxLevel
        v-else
        :selected-expertise="selectedExpertise"
        :selected-technology="selectedTechnology"
        :selected-level="selectedLevel"
        :technology-pressed="selectedTechnolyPressed"
        @clicked="deleteSkill"
        @changed="selectLevel"
      />
    </div>
    <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
  </Field>

  <Modal
    :is-modal-active="openModalDeleteSkill && existTechnology === true"
    @close-modal="openModalDeleteSkill = false"
    :head="deleteSkillString"
  >
    <template #modal-main>
      <div class="popupSkillDelete">
        <div class="popupSkillDelete__title heading-h4">{{ talentName }}</div>
        <div class="popupSkillDelete__subtitle text-body-1">{{ talentMail }}</div>
        <p class="popupSkillDelete__main heading-h4">
          {{ paragraphModalDeleteSkill.replace('{skill}', correctTechnologyPressed.name) }}
        </p>
      </div>
    </template>

    <template #modal-footer>
      <component
        :is="Button"
        variant="secondary"
        :label="cancel"
        @clicked="openModalDeleteSkill = false"
      ></component>
      <component
        :is="Button"
        variant="primary"
        :label="confirmButton"
        @clicked="$emit('technologyId', [correctTechnologyPressed.id, selectedCategory])"
      ></component>
    </template>
  </Modal>
</template>
<style scoped lang="scss">
.BoxCategorySkills {
  :deep .o-inputit {
    display: none;
  }

  :deep {
    .Button {
      @extend .text-body-1;
      height: 4rem;
      padding: 0.8rem;
      border-radius: 0;
    }
  }

  &__skills {
    &--wrapper {
      @include flexing(row, start, start);
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}

.popupSkillDelete {
  text-align: center;
  margin: auto;
  max-width: 39rem;

  &__title {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  &__subtitle {
    color: $black-50;
    margin-bottom: 3.2rem;
  }

  &__main {
    margin: 3.2rem 0;
  }
}
</style>
