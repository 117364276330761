<script setup lang="ts">
import AddUserForm from '@/components/molecules/AddUserForm.vue';
import GoBack from '@/components/molecules/GoBack.vue';
import Notification from '@/components/atoms/Notification.vue';
import strings from '@/data/CreateUser.json';
import type { CreateUserInput, MutationCreateUserArgs } from '@/gql/Gql.types';
import { computed, reactive, ref, watch } from 'vue';
import { createUser } from '@/api/mutations/createUser';
import { NAVIGATION_BACK_DEFAULT_TIME, NOTIFICATION_DURATION } from '@/data/constants';
import { useRouter } from 'vue-router';

const { userCreatedErrorToast, userCreatedSuccessToast, newUser } = strings;

const router = useRouter();

const formData = ref<CreateUserInput>();
const data = reactive<MutationCreateUserArgs>({ data: formData.value as CreateUserInput });

watch(formData, () => {
  if (formData.value) {
    data.data = formData.value;
  }
});

const enabled = computed(() => !!data.data);

const { isError, isSuccess } = createUser(data, { enabled });

const addUserSubmit = (data: CreateUserInput) => (formData.value = data);

watch(isSuccess, () => {
  if (isSuccess.value) {
    setTimeout(() => {
      router.back();
    }, NAVIGATION_BACK_DEFAULT_TIME);
  }
});
</script>
<template>
  <div class="CreateUser">
    <GoBack :headline="newUser" previous-route="/users" />
    <div class="CreateUser__form container">
      <div class="CreateUser__form-template start-3 start-xl-4 col-8 col-xl-6">
        <AddUserForm @user-data="addUserSubmit" @reset-form="(cb) => isSuccess && cb()" />
      </div>
    </div>
  </div>
  <Notification
    v-if="isError || isSuccess"
    :message="isError ? userCreatedErrorToast : userCreatedSuccessToast"
    :status="isError ? 'error' : 'success'"
    :duration="NOTIFICATION_DURATION"
  />
</template>
<style scoped lang="scss">
.CreateUser {
  padding-bottom: 4rem;

  &__form {
    margin-top: 4rem;
  }
}
</style>
