<script setup lang="ts">
import { toRefs } from 'vue';
import { NOTIFICATION_DURATION } from '@/data/constants';

type NotificationStatus = 'success' | 'error' | '';

export interface INotificationProps {
  message: string | object;
  status?: NotificationStatus;
  duration?: number;
}

const props = withDefaults(defineProps<INotificationProps>(), {
  status: '',
  duration: NOTIFICATION_DURATION,
});

const { message } = toRefs(props);
</script>

<template>
  <o-notification
    :class="`Notification ${status && '--' + status}`"
    closable
    aria-close-label="Close notification"
    :duration="duration"
    :auto-close="true"
    animation="fade"
  >
    {{ message }}
  </o-notification>
</template>

<style scoped lang="scss">
.Notification {
  position: fixed;
  top: 8.6rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 2.4rem;
  @extend .heading-h5;
  z-index: 200;

  &.--error {
    background-color: $status;
  }

  &.--success {
    background-color: $accent;
  }

  :deep {
    &.o-notification {
      position: fixed;
    }
    .o-notification {
      &__close {
        visibility: hidden;

        &::after {
          content: '';
          visibility: visible;
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          top: 0;
          right: 0;
          background-image: url(@/assets/icons/close.svg);
          background-repeat: no-repeat;
          filter: invert(1);
        }
      }
    }
  }
}
</style>
