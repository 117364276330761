import { gql } from 'graphql-request';
import type { InviteAgencyMutationMutation, MutationInviteAgencyArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const inviteAgencyMutation = gql`
  mutation InviteAgencyMutation($agencyId: String!) {
    inviteAgency(agencyId: $agencyId)
  }
`;

export const inviteAgency = (
  props?: MutationInviteAgencyArgs,
  options?: UseQueryOptions<
    InviteAgencyMutationMutation,
    MutationInviteAgencyArgs,
    InviteAgencyMutationMutation,
    any[]
  >
): UseQueryReturnType<InviteAgencyMutationMutation, MutationInviteAgencyArgs> =>
  useGraphql({
    query: inviteAgencyMutation,
    variables: props,
    options,
  });
