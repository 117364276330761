import { gql } from 'graphql-request';
import type { MutationSoftDeleteUserArgs, User } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const softDeleteUserMutation = gql`
  mutation SoftDeleteUser($userIds: [String!]!) {
    softDeleteUser(userIds: $userIds)
  }
`;

export const softDeleteUser = (
  props?: MutationSoftDeleteUserArgs,
  options?: UseQueryOptions<User, MutationSoftDeleteUserArgs, User, any[]>
): UseQueryReturnType<User, MutationSoftDeleteUserArgs> =>
  useGraphql({
    query: softDeleteUserMutation,
    variables: props,
    options,
  });
