import type { Component } from 'vue';

export type IColumn = {
  field: string;
  label: string;
  width?: string;
  numeric?: boolean;
  visible?: boolean;
  position?: 'left' | 'centered' | 'right';
  component?: string | unknown | Component;
};

export type ITableProps<T> = {
  action?: (el: any) => void;
  onSelectItems(
    arg0: (event: 'selectItems' | 'resetSelection', ...args: any[]) => void,
    arg1: string,
    onSelectItems: any
  ): unknown;
  onResetSelection?(arg0: boolean): unknown;
  icon?: string;
  columns: IColumn[];
  isLoading?: boolean;
  isSmallRow?: boolean;
  items: T[];
  checkable?: boolean;
  highlightText?: string;
};

export enum ITableColumns {
  Agencies = 'AGENCIES',
  AgenciesDeleted = 'AGENCIES_DELETED',
  Talents = 'TALENTS',
  TalentsDelete = 'TALENTS_DELETED',
  Users = 'USERS',
  UsersManagement = 'USERS_MANAGEMENT',
  UsersManagementDelete = 'USERS_MANAGEMENT_DELETED',
  Experience = 'EXPERIENCE',
  Settings = 'SETTINGS',
  Coupon = 'COUPON',
}

export type IColumns = {
  [key in ITableColumns]: any;
};
