<script setup lang="ts">
import { ref, watch, toRefs } from 'vue';
import Radio from '@/components/atoms/Radio.vue';
import type { IRadioProps } from '@/models/radioModel';

interface IPickerGroupProps {
  items: IRadioProps[];
  variant?: 'dark';
  initialValue?: string;
  standalone?: boolean;
}

const props = defineProps<IPickerGroupProps>();
const { items, variant, initialValue } = toRefs(props);

const radio = ref(initialValue?.value || '');

const emit = defineEmits(['changed']);

watch(radio, () => {
  emit('changed', radio.value);
});
</script>

<template>
  <div :class="['PickerGroup', variant, items.length > 4 ? '--scrollX' : '']">
    <Radio
      v-for="(option, index) in items"
      as="picker"
      :key="index + option.label"
      :name="option.name"
      :label="option.label"
      :value="option.value"
      :initial-value="initialValue"
      @changed="(val) => (radio = val)"
      :rules="{ required: false }"
    />
  </div>
</template>

<style lang="scss" scoped>
.PickerGroup {
  @include flexing(row, start, normal);
  width: max-content;
  background-color: $black-04;
  border-radius: 2rem;

  &.--scrollX {
    overflow-x: scroll;
    max-width: max-content;
    width: auto;
    padding-bottom: 0.4rem;
  }

  &::-webkit-scrollbar {
    height: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-20;
    border-radius: 4rem;
    background-clip: padding-box;
  }

  &.dark {
    background-color: $white-10;
  }
}
</style>
