import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { ExperienceQueryQuery, QueryExperienceArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const experienceLocationQuery = gql`
  query ExperienceLocationQuery($experienceId: String!) {
    experience(experienceId: $experienceId) {
      bookings {
        id
      }
      location
      from
      to
    }
  }
`;

export const getLocationExperience = (
  props?: QueryExperienceArgs,
  options?: UseQueryOptions<ExperienceQueryQuery, QueryExperienceArgs, ExperienceQueryQuery, any[]>
): UseQueryReturnType<ExperienceQueryQuery, QueryExperienceArgs> =>
  useGraphql({
    query: experienceLocationQuery,
    variables: props,
    options,
  });
