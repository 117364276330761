import { gql } from '@/api/graphql-client';
import { type MutationDeleteDealTalentSelectionArgs } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { fetcher } from '@/api/use-graphql';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dealTalentSelectionQuery } from '@/api/queries/dealTalentSelection';
import { dealsQuery } from '../queries/deals';

export const deleteDealTalentSelection = gql`
  mutation deleteDealTalentSelection($ids: [String!]!) {
    deleteDealTalentSelection(ids: $ids)
  }
`;

export const useDeleteDealTalentSelection = () => {
  const queryClient = useQueryClient();
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);
  const fn = (props: MutationDeleteDealTalentSelectionArgs) =>
    fetcher<MutationDeleteDealTalentSelectionArgs, MutationDeleteDealTalentSelectionArgs>({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
      },
      query: deleteDealTalentSelection,
      variables: props,
    });

  const mutation = useMutation({
    mutationFn: fn,
    onSuccess: () => {
      queryClient.invalidateQueries([dealTalentSelectionQuery]);
      queryClient.invalidateQueries([dealsQuery]);
    },
  });

  return mutation;
};
