<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';
import Radio from '@/components/atoms/Radio.vue';
import Button from '@/components/atoms/Button.vue';
import BoxString from '@/data/BoxCategorySkillAccordion.json';
import type { LanguageType, TechnologyType } from '@/gql/Gql.types';

interface IBoxLevelProps {
  selectedLevel: string;
  selectedTechnology: string;
  technologyPressed?: TechnologyType | LanguageType;
}

const { deleteSkill, levels, labelLevels } = BoxString;

const props = defineProps<IBoxLevelProps>();
const { selectedTechnology, selectedLevel, technologyPressed } = toRefs(props);

const selected = ref('');

const correctTechnologyPressed = computed(() => {
  if (technologyPressed?.value) {
    if ('technology' in technologyPressed.value) {
      return (technologyPressed.value as TechnologyType).technology;
    } else {
      return (technologyPressed?.value as LanguageType).language;
    }
  }
  return '';
});

const emit = defineEmits(['changed', 'clicked']);

watch(selected, () => emit('changed', selected.value));
</script>
<template>
  <div class="BoxLevel">
    <div class="BoxLevel--wrapper">
      <div class="BoxLevel--wrapper-radio" v-if="selectedTechnology">
        <Radio
          v-for="(level, index) in levels"
          :key="index"
          :name="selectedTechnology"
          :label="labelLevels[index]"
          :value="level"
          :initial-value="selectedLevel"
          :rules="{ required: true }"
          @changed="(lvl: string) => selected = lvl"
          :standalone="true"
        />
      </div>
    </div>
    <Button
      v-if="correctTechnologyPressed === selectedTechnology && correctTechnologyPressed.length"
      :label="deleteSkill"
      variant="link-tertiary-status"
      @clicked="$emit('clicked', selectedTechnology)"
    />
  </div>
</template>

<style scoped lang="scss">
.BoxLevel {
  @include flexing(row, space-between, center);

  &--wrapper {
    @include flexing(row, start, center);

    &-radio {
      @include flexing(row, start, center);
      flex-wrap: wrap;
      gap: 1.6rem;

      :deep .error-message {
        margin: 0;

        &:not(:last-child) {
          display: none;
        }
      }
    }
  }
}
</style>
