import { gql } from '@/api/graphql-client';
import type { MutationCreateTechnologyArgs, Technology } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';
import { technologyFragment } from '@/api/fragments/technology';

export const createTechnologyMutation = gql`
  mutation CreateTechnology($input: CreateTechnologyInput!) {
    createTechnology(input: $input) {
      ...technology
    }
  }
  ${technologyFragment}
`;

export const createTechnology = (
  props?: MutationCreateTechnologyArgs,
  options?: UseQueryOptions<Technology, MutationCreateTechnologyArgs, Technology, any[]>
): UseQueryReturnType<Technology, MutationCreateTechnologyArgs> =>
  useGraphql({
    query: createTechnologyMutation,
    variables: props,
    options,
  });
