import { gql } from '@/api/graphql-client';
import { talentFragment } from '@/api/fragments/talent';
import type { MutationUpdateTalentBiographyArgs, Talent } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateTalentBiographyMutation = gql`
  mutation UpdateTalentBiography($talentId: String!, $biography: String!) {
    updateTalentBiography(talentId: $talentId, biography: $biography) {
      ...talent
    }
  }
  ${talentFragment}
`;

export const updateTalentBiography = (
  props: MutationUpdateTalentBiographyArgs,
  options?: UseQueryOptions<Talent, MutationUpdateTalentBiographyArgs, Talent, any[]>
): UseQueryReturnType<Talent, MutationUpdateTalentBiographyArgs> =>
  useGraphql({
    query: updateTalentBiographyMutation,
    variables: props,
    options,
  });
