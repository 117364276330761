import { gql } from '@/api/graphql-client';
import { agencyFragment } from '@/api/fragments/agency';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';
import type { QueryAgencyArgs, AgencyQueryQuery } from '@/gql/Gql.types';

export const agencyQuery = gql`
  query AgencyQuery($agencyId: String!) {
    agency(agencyId: $agencyId) {
      ...agency
    }
  }
  ${agencyFragment}
`;

export const getAgency = (
  props?: QueryAgencyArgs,
  options?: UseQueryOptions<AgencyQueryQuery, QueryAgencyArgs, AgencyQueryQuery, any[]>
): UseQueryReturnType<AgencyQueryQuery, QueryAgencyArgs> =>
  useGraphql({
    query: agencyQuery,
    variables: props,
    options,
  });
