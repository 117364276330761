<script setup lang="ts">
import LoginForm from '@/components/molecules/LoginForm.vue';
import LoginString from '@/data/LoginPage.json';
import Notification from '@/components/atoms/Notification.vue';
import type { LoginInput } from '@/gql/Gql.types';
import { ref } from 'vue';
import { useUserStore } from '@/stores/user';

const { welcome, formTitle, inputs, login, passwordForgot } = LoginString;

const userStore = useUserStore();
const { login: loginAction } = userStore;

const error = ref();

const loginSubmit = async (credentials: LoginInput) => {
  error.value = '';
  try {
    await loginAction(credentials);
  } catch (e) {
    error.value = e;
  }
};
</script>

<template>
  <Notification v-if="error" :message="error" status="error" />
  <LoginForm
    :title="welcome"
    :form-title="formTitle"
    :inputs="inputs"
    :primary-btn="login"
    :link-btn="passwordForgot"
    @submitted="loginSubmit"
  />
</template>
