import type { EmailTemplateCommonDataQuery } from '@/gql/Gql.types';

export const useMappedPickerEmail = (
  arr: EmailTemplateCommonDataQuery | undefined,
  category: string
) => {
  if (arr === undefined)
    return [
      {
        name: '',
        value: '',
        label: '',
      },
    ];

  const filteredArr = arr.emailTemplatesCommonData
    .filter((item) => item.category === category)
    .map((item) => item.typologies);

  return filteredArr.flatMap((items) => {
    return items!?.map((item) => {
      const label = item?.toLowerCase().replace(/_/g, ' ');
      return {
        name: item,
        value: item,
        label: label,
      };
    });
  });
};
