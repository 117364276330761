import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetEmailTemplatesQuery, QueryEmailTemplatesArgs } from '@/gql/Gql.types';
import { emailTemplateFragment } from '@/api/fragments/emailTemplate';

const emailTemplatesQuery = gql`
  query getEmailTemplates($filters: EmailTemplateFilterInput, $orderBy: EmailTemplateOrder) {
    emailTemplates(filters: $filters, orderBy: $orderBy) {
      templates {
        ...emailTemplate
      }
      typology
    }
  }
  ${emailTemplateFragment}
`;

export const getEmailTemplates = (
  props?: QueryEmailTemplatesArgs,
  options?: UseQueryOptions<
    GetEmailTemplatesQuery,
    QueryEmailTemplatesArgs,
    GetEmailTemplatesQuery,
    any[]
  >
): UseQueryReturnType<GetEmailTemplatesQuery, QueryEmailTemplatesArgs> =>
  useGraphql({
    query: emailTemplatesQuery,
    variables: props,
    options,
  });
