import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import { talentFragment } from './../fragments/talent';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { SearchTalentsQuery, QuerySearchTalentsArgs } from '@/gql/Gql.types';

const searchTalentsQuery = gql`
  query searchTalents(
    $after: String
    $before: String
    $filters: TalentsFilterInput
    $keyword: String!
    $first: Int
    $last: Int
    $orderBy: TalentOrder
  ) {
    searchTalents(
      after: $after
      before: $before
      filters: $filters
      keyword: $keyword
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...talent
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${talentFragment}
  ${pageInfo}
`;

export const searchTalents = (
  props?: QuerySearchTalentsArgs,
  options?: UseQueryOptions<SearchTalentsQuery, QuerySearchTalentsArgs, SearchTalentsQuery, any[]>
): UseQueryReturnType<SearchTalentsQuery, QuerySearchTalentsArgs> =>
  useGraphql({
    query: searchTalentsQuery,
    variables: props,
    options,
  });
