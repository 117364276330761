<script setup lang="ts">
import AddUserForm from '@/components/molecules/AddUserForm.vue';
import GoBack from '@/components/molecules/GoBack.vue';
import Notification from '@/components/atoms/Notification.vue';
import strings from '@/data/CreateUser.json';
import type { MutationUpdateUserArgs, UpdateUserInput } from '@/gql/Gql.types';
import { computed, reactive, ref, watch } from 'vue';
import { getUser } from '@/api/queries/user';
import { NAVIGATION_BACK_DEFAULT_TIME, NOTIFICATION_DURATION } from '@/data/constants';
import { updateUser } from '@/api/mutations/updateUser';
import { useRoute, useRouter } from 'vue-router';

const { userUpdatedSuccessToast, userUpdatedErrorToast } = strings;

const router = useRouter();

const {
  params: { id },
} = useRoute();

const {
  data: user,
  isError: isErrorFetchingUser,
  isSuccess: isSuccessUserLoaded,
  remove,
} = getUser({ userId: id as string });

const headline = computed(() => {
  if (!user.value || !user.value?.user) return '';

  return user.value?.user.firstname + ' ' + user.value?.user?.lastname;
});

const datas = computed(() => !(!user.value || !isSuccessUserLoaded) && user.value.user);

const formData = ref<UpdateUserInput>();
const data = reactive<MutationUpdateUserArgs>({
  newUserData: {} as UpdateUserInput,
  userId: id as string,
});

watch(formData, () => {
  if (!formData.value) return;

  data.newUserData = {
    ...(formData.value.email && { email: formData.value.email }),
    ...(formData.value.calendlyUrl && { calendlyUrl: formData.value.calendlyUrl }),
    ...(formData.value.firstname && { firstname: formData.value.firstname }),
    ...(formData.value.lastname && { lastname: formData.value.lastname }),
    ...(formData.value.username && { username: formData.value.username }),
    ...(formData.value.role && { role: formData.value.role }),
    ...(formData.value.sign && { sign: formData.value.sign }),
    ...(formData.value.password && { password: formData.value.password }),
  };
});

const enabled = computed(() => Object.keys(data.newUserData).length > 0 && !!id);
const { isSuccess, isError } = updateUser(data, { enabled });
const updateUserSubmit = (data: UpdateUserInput) => (formData.value = data);

watch(isErrorFetchingUser, () => isErrorFetchingUser && router.push('/users'));

watch(isSuccess, () => {
  if (!isSuccess.value) return;

  setTimeout(() => {
    remove();
    router.go(-1);
  }, NAVIGATION_BACK_DEFAULT_TIME);
});
</script>
<template>
  <div class="UpdateUser">
    <GoBack :headline="headline" previous-route="/users" />
    <div class="UpdateUser__form container">
      <div class="UpdateUser__form-template start-3 start-xl-4 col-8 col-xl-6">
        <AddUserForm
          v-if="datas"
          v-bind="{ ...datas }"
          @user-data="updateUserSubmit"
          @reset-form="(cb) => isSuccess && cb()"
        />
      </div>
    </div>
    <Notification
      v-if="isError || isSuccess"
      :message="isError ? userUpdatedErrorToast : userUpdatedSuccessToast"
      :status="isError ? 'error' : 'success'"
      :duration="NOTIFICATION_DURATION"
    />
  </div>
</template>
<style scoped lang="scss">
.UpdateUser {
  padding-bottom: 4rem;

  &__form {
    margin-top: 4rem;
  }
}
</style>
