import type { LocationQuery } from 'vue-router';

export const useMappedQueryFilter = (routeFilter: LocationQuery) => {
  if (!routeFilter)
    return {
      operator: 'AND' as string,
      filters: [] as string[],
      keyword: '',
    };

  const { tab, nav, operator, keyword, ...rest } = routeFilter;

  const filters: Record<string, any> = {};

  for (const [key, value] of Object.entries(rest)) {
    filters[key] = (value as string).split(';');
  }

  const filter = {
    operator: operator as string,
    filters: filters,
    keyword: keyword as string,
  };

  return filter;
};
