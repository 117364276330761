import { gql } from '@/api/graphql-client';
import { emailTemplateFragment } from '@/api/fragments/emailTemplate';
import type { MutationUpdateEmailTemplateArgs, EmailTemplate } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateEmailTemplateMutation = gql`
  mutation updateEmailTemplate($data: EmailTemplateInput!, $id: String!) {
    updateEmailTemplate(data: $data, id: $id) {
      ...emailTemplate
    }
  }
  ${emailTemplateFragment}
`;

export const updateEmailTemplate = (
  props?: MutationUpdateEmailTemplateArgs,
  options?: UseQueryOptions<EmailTemplate, MutationUpdateEmailTemplateArgs, EmailTemplate, any[]>
): UseQueryReturnType<EmailTemplate, MutationUpdateEmailTemplateArgs> =>
  useGraphql({
    query: updateEmailTemplateMutation,
    variables: props,
    options,
  });
