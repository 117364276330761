import { gql } from '@/api/graphql-client';
import type { MutationCreateSkillArgs, Skill } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const createSkillMutation = gql`
  mutation CreateSkill($input: CreateSkillInput!) {
    createSkill(input: $input) {
      name
      expertiseId
    }
  }
`;

export const createSkill = (
  props?: MutationCreateSkillArgs,
  options?: UseQueryOptions<Skill, MutationCreateSkillArgs, Skill, any[]>
): UseQueryReturnType<Skill, MutationCreateSkillArgs> =>
  useGraphql({
    query: createSkillMutation,
    variables: props,
    options,
  });
