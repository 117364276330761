<script setup lang="ts">
import { defineProps, ref, watch } from 'vue';
import { type Deal } from '@/gql/Gql.types';
import InputText from '@/components/atoms/InputText.vue';
import Button from '@/components/atoms/Button.vue';
import { useUpdateDeal } from '@/api/mutations/updateDeal';
import Notification, { type INotificationProps } from '@/components/atoms/Notification.vue';

const { mutate, isSuccess, isError } = useUpdateDeal();

const notificationMessage = ref('');
const notificationStatus = ref<INotificationProps['status']>('');
const showNotification = ref(false);

watch([isSuccess, isError], () => {
  if (isSuccess.value) {
    notificationMessage.value = 'Additional Comments updated successfully';
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isError.value) {
    notificationMessage.value = 'Error updating Additional Comments';
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});

const props = defineProps<{
  deal?: Deal;
}>();

const note = ref(props.deal?.note);

const scope: Record<string, string> = {
  CODING: 'Coding',
  DESIGN: 'Design',
  MARKETING_AND_COMUNICATION: 'Marketing & Communication',
  CYBERSECURITY: 'Cybersecurity',
  DATA_AND_AI: 'Data & AI',
  HARDWARE_AND_FIRMWARE: 'Hardware & Firmware',
  MANAGEMENT: 'Management',
  SYSTEM_AND_NETWORK: 'System & Network',
};

const showFullDescription = ref(false);
</script>

<template>
  <Notification
    v-if="showNotification"
    :status="notificationStatus"
    :message="notificationMessage"
    :duration="5000 as number"
  />
  <div class="projectInfo">
    <div class="overview">
      <span>Project Overview</span>
    </div>
    <div class="statusDeal">
      <div class="status">
        <p>Status</p>
        <div class="badge">{{ props.deal?.stage }}</div>
      </div>
      <div class="createdCode">
        <div>
          <span class="label">Created</span>
          <span class="value">{{ new Date(deal?.createdAt)?.toLocaleDateString('it-IT') }}</span>
        </div>
        <div>
          <span class="label">Deal Code</span> <span class="value">{{ deal?.refCode }}</span>
        </div>
      </div>
    </div>
    <div class="titleCompany">
      <h1 class="heading-h1">{{ deal?.title }}</h1>
      <p>{{ deal?.companyName }}</p>
      <div class="divider"></div>
    </div>
    <div class="dataGrid">
      <div class="firstColumn">
        <div class="data">
          <div>
            <span class="label">Owner</span>
            <span class="value">{{ deal?.user?.firstname }} {{ deal?.user?.lastname }}</span>
          </div>
          <div class="scope">
            <span class="label">Scope</span>
            <span class="value">{{ scope[deal?.scope?.[0] as string] }}</span>
          </div>
          <div>
            <span class="label">Exp. Start Date</span>
            <span class="value">{{
              new Date(deal?.expectingStartDate).toLocaleDateString('it-IT')
            }}</span>
          </div>
          <div>
            <span class="label">Specialties</span>
            <span class="value">{{ deal?.speciality }}</span>
          </div>
          <div>
            <span class="label">Field</span>
            <span class="value">{{ deal?.field?.[0] }}</span>
          </div>
          <div>
            <span class="label">Exp. Close Date</span>
            <span class="value">{{
              new Date(deal?.expectingCloseDate).toLocaleDateString('it-IT')
            }}</span>
          </div>
        </div>
        <div class="divider"></div>
        <div class="description">
          <div class="label">Description</div>
          <pre>
            {{
              deal?.description
                ? showFullDescription
                  ? deal.description
                  : deal.description.slice(
                      0,
                      deal.description.indexOf('.', 400) > 0
                        ? deal.description.indexOf('.', 400) + 1
                        : 400
                    )
                : ''
            }}
          </pre>
          <Button
            v-if="deal?.description && deal.description.length > 400"
            :label="showFullDescription ? 'Show Less' : 'Show More'"
            @click="showFullDescription = !showFullDescription"
          />
        </div>
      </div>
      <div class="secondColumn">
        <div class="data">
          <div>
            <span class="label">Max. Purchase Card</span>
            <span class="value">€ {{ deal?.ratecardMax }}</span>
          </div>
          <div>
            <span class="label">Full-time</span>
            <span class="value">{{ deal?.fullTime ? 'Yes' : 'No' }}</span>
          </div>
          <div>
            <span class="label">Full-remote</span>
            <span class="value">{{ deal?.fullRemote ? 'Yes' : 'No' }}</span>
          </div>
          <div>
            <span class="label">Pipeline Talents</span>
            <span class="value"></span>
          </div>
          <div>
            <span class="label">Part-time</span>
            <span class="value">{{ deal?.partTime ? 'Yes' : 'No' }}</span>
          </div>
          <div>
            <span class="label">On-site/Trips</span>
            <span class="value">{{ deal?.onsiteTrip ? 'Yes' : 'No' }}</span>
          </div>
        </div>
        <div class="divider"></div>
        <div class="description">
          <div>
            <div class="label">Additional Comments</div>
            <Button
              label="Save"
              variant="accent"
              @click="() => {
              mutate({
                id: deal?.id as string,
                data: {
                  note,
                },
              });
            }"
            />
          </div>
          <InputText
            type="textarea"
            :initial-value="props.deal?.note"
            @keystart="(value) => (note = value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.projectInfo {
  border: 1px solid $black-10;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;

  .overview {
    display: flex;
    justify-content: space-between;
    & > span {
      text-transform: uppercase;
      color: $black-50;
      font-size: 1.3rem;
      margin-left: auto;
      line-height: 5px;
    }
    padding-bottom: 0.5rem;
  }

  .statusDeal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .status {
      display: flex;
      gap: 1rem;
      align-items: center;

      & > p {
        font-size: 1.5rem;
        color: $black-50;
        font-weight: $primary-font-weight;
      }
    }
  }

  .createdCode {
    display: flex;

    gap: 2rem;
    font-size: 1.3rem;

    & > div {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.3rem;
    }

    & > div > .label {
      color: $black-50;
    }
  }

  .titleCompany {
    & > h1 {
      font-size: 2.5rem;

      font-weight: $primary-font-weight;
      margin-top: 0;
    }

    & > p {
      font-size: 2rem;
      color: $black-30;
      font-weight: $primary-font-weight;
      line-height: 5px;
    }
  }

  .dataGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14rem;
    .description {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.5rem;
      padding-top: 1.5rem;
      max-width: 550px;
    }
  }
}

pre {
  display: block;
  white-space: pre-line;
  font-family: $primary-font-family;
}
.divider {
  width: 150px;
  height: 0.1rem;
  background-color: $black-10;
  margin: 3rem 0 1rem;
}

.data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    font-size: 1.5rem;
  }
  .scope {
    width: 20rem;
  }
}

.label {
  color: $black-50;
  font-size: 1.5rem;
}

.value {
  font-weight: $primary-font-weight;
  font-size: 1.5rem;
}

.badge {
  background-color: $accent;
  color: white;
  text-transform: uppercase;
  padding: 0.75rem;
  border-radius: 4px;
  width: fit-content;
}

.description {
  & > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
</style>
