import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { EmailTemplateCommonDataQuery } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const emailTemplatesCommonDataQuery = gql`
  query EmailTemplateCommonData {
    emailTemplatesCommonData {
      category
      typologies
    }
  }
`;

export const getEmailTemplateCommonData = (
  options?: UseQueryOptions<EmailTemplateCommonDataQuery, void, EmailTemplateCommonDataQuery, any[]>
): UseQueryReturnType<EmailTemplateCommonDataQuery, void> =>
  useGraphql({
    query: emailTemplatesCommonDataQuery,
    options,
  });
