import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetTechnologyQuery, QueryTechnologyArgs } from '@/gql/Gql.types';
import { technologyFragment } from '@/api/fragments/technology';

const technologyQuery = gql`
  query getTechnology($technologyId: String!) {
    technology(technologyId: $technologyId) {
      ...technology
    }
  }
  ${technologyFragment}
`;

export const getTechnology = (
  props?: QueryTechnologyArgs,
  options?: UseQueryOptions<GetTechnologyQuery, QueryTechnologyArgs, GetTechnologyQuery, any[]>
): UseQueryReturnType<GetTechnologyQuery, QueryTechnologyArgs> =>
  useGraphql({
    query: technologyQuery,
    variables: props,
    options,
  });
