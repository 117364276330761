<script setup lang="ts">
import { toRefs } from 'vue';
import Label from '@/components/atoms/Label.vue';
import Button from '@/components/atoms/Button.vue';
import type { IContentCardProps } from '@/models/contentCardModels';

const props = withDefaults(defineProps<IContentCardProps>(), {
  title: '',
  subtitle: '',
});

const { title, subtitle, labelCard, cta } = toRefs(props);
</script>

<template>
  <div class="ContentCard">
    <div class="ContentCard__inner">
      <h2 class="ContentCard__inner-head heading-h4">{{ title }}</h2>
      <p class="ContentCard__inner-subtitle text-body-1" v-html="subtitle"></p>
      <div class="ContentCard__inner-label">
        <Label
          v-if="labelCard"
          v-for="content in labelCard"
          :key="content.label"
          :label="content.label"
          :total="content.count"
        ></Label>
      </div>

      <div class="ContentCard__inner-cta">
        <Button v-if="cta" icon="arrow-right" v-bind="{ ...cta }"></Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ContentCard {
  width: 100%;

  &__inner {
    @include flexing(column, start, start);
    height: 100%;
    @include for-desktop {
      min-width: 24.5rem;
    }

    &-head {
      margin: 0;
      margin-bottom: 0.8rem;
    }

    &-subtitle {
      margin: 0;
      margin-bottom: 1.6rem;
      color: $black-50;
      :deep {
        strong {
          color: $primary-color;
        }
      }
    }

    &-label {
      @include flexing(row, start, start);
      flex-wrap: wrap;
      gap: 0 0.8rem;
      :deep .Label {
        margin-bottom: 1.6rem;
      }

      @include for-desktop {
        gap: 0 0.8rem;
        :deep .Label:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-cta {
      margin-top: 1.5rem;
      &:empty {
        margin: 0;
      }
    }

    &-label:empty + &-cta {
      margin-top: auto;
    }
  }
}
</style>
