import { gql } from '@/api/graphql-client';
import { categoryFragment } from '@/api/fragments/category';

export const experienceFragment = gql`
  fragment Exp on Experience {
    id
    availables
    bookings {
      ...bookings
    }
    category {
      ...category
    }
    categorySlug
    createdAt
    discount
    from
    hasInsurance
    isActive
    isFlexible
    location
    price
    slug
    to
    updatedAt
  }
  ${categoryFragment}
`;
