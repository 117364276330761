<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import { ref, toRefs, watch } from 'vue';

type INavItem = { text: string; value: string };

type INavProps = {
  items: INavItem[];
  activeItem?: string;
};

const props = withDefaults(defineProps<INavProps>(), {
  items: () => [],
});

const { items, activeItem } = toRefs(props);

const emit = defineEmits(['clicked']);

const activated = ref(activeItem?.value ?? items.value[0].value);
watch(
  () => activeItem?.value,
  () => {
    activated.value = activeItem?.value ?? items.value[0].value;
  }
);

const active = (s: { text: string; value: string }) => {
  activated.value = s.value;
  emit('clicked', s);
};
</script>

<template>
  <div class="Nav">
    <nav>
      <Button
        v-for="(item, index) in items"
        :key="item.value + index"
        variant="tab-button"
        :label="item.text"
        :active="activated === item.value"
        @clicked="active(item)"
      />
    </nav>
    <slot name="content"></slot>
  </div>
</template>

<style lang="scss" scoped>
.Nav {
  @include flexing(row, space-between, center);
  padding: 0 4rem;
  position: relative;
  overflow-x: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    border-bottom: 0.1rem solid $black-10;
  }

  :deep .o-btn {
    &--tab-button {
      &:not(:last-child) {
        margin-right: 3.2rem;
      }
    }
  }
}
</style>
