<script lang="ts" setup>
import { ref, watch, onMounted, computed } from 'vue';
import Search from '@/components/atoms/Search.vue';
import { searchTalents } from '@/api/queries/searchTalents';
import Tab from '@/components/atoms/Tab.vue';
import Button from '@/components/atoms/Button.vue';
import { useRouter } from 'vue-router';
import { useRouteQuery, useRouteParams } from '@vueuse/router';
import { TalentStatus } from '@/gql/Gql.types';
import { getTalentCommonData } from '@/api/queries/talentCommonData';
import { refDebounced } from '@vueuse/core';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import SelectFilter from '@/components/organisms/Filters/components/SelectFilter.vue';
import { IFiltersOptions, useFilters } from '@/composables/useFilters';
import { useFilterDataMapper } from '@/composables/talents/useFilterDataMapper';
import { useMappedFilters } from '@/composables/useMappedFilters';
import type { IFilter } from '@/models/selectFilterModel';
import { useForm } from 'vee-validate';
import FiltersString from '@/data/Filters.json';
import Radio from '@/components/atoms/Radio.vue';
import { useCreateDealTalentSelection } from '@/api/mutations/createDealTalentSelection';
import Notification, { type INotificationProps } from '@/components/atoms/Notification.vue';
import { getDealTalentSelection } from '@/api/queries/dealTalentSelection';

const { methodForFilter, typeOfSearch, labelRadio, nameRadioFilter } = FiltersString;

const filterMethod = ref('AND');

const activeFilters = ref<IFilter[]>([]);

const status = useRouteQuery('status');
const scope = useRouteQuery('scope');
const projectId = useRouteParams<string>('id');

const keyword = ref('');

const selectedTalents = ref<any>([]);

const currentPage = ref(1);

const keywordDebounced = refDebounced(keyword, 300);

const router = useRouter();

const filterReset = ref(false);

const scopes: Record<string, string> = {
  CODING: 'Coding',
  DESIGN: 'Design',
  MARKETING_AND_COMUNICATION: 'Marketing & Communication',
  CYBERSECURITY: 'Cybersecurity',
  DATA_AND_AI: 'Data & AI',
  HARDWARE_AND_FIRMWARE: 'Hardware & Firmware',
  MANAGEMENT: 'Management',
  SYSTEM_AND_NETWORK: 'System & Network',
};

const { handleSubmit, setFieldValue, resetField } = useForm({
  initialValues: {
    operator: 'AND',
    specialities: [] as IFilter[],
    seniorities: [] as IFilter[],
    tools: [] as IFilter[],
    languages: [] as IFilter[],
    country: [] as IFilter[],
    workplaces: [] as IFilter[],
    freelance: [] as IFilter[],
    rates: [] as IFilter[],
    industries: [] as IFilter[],
    internationalProjects: [] as IFilter[],
  },
});

const filters = ref<{
  first?: number;
  after?: string;
  before?: string;
  last?: number;
  keyword: string;
  filters: ReturnType<typeof useMappedFilters>;
}>({
  first: 10,
  keyword: keywordDebounced.value,
  filters: {
    status: status.value as TalentStatus,
    specialities: [
      {
        name: scopes[scope.value as keyof typeof scopes],
      },
    ],
  },
});

const { data } = searchTalents(filters.value);

const { data: talentsSelection } = getDealTalentSelection({
  filters: {
    dealId: projectId as unknown as string,
  },
});

const { mutate: createDealTalentSelection, isSuccess, isError } = useCreateDealTalentSelection();

const talents = computed(() =>
  status.value !== 'SELECTED' ? data.value?.searchTalents.edges : selectedTalents.value
);

watch([status, keywordDebounced], () => {
  filters.value.filters = {
    status: status.value !== 'SELECTED' ? (status.value as TalentStatus) : undefined,
    specialities: [
      {
        name: scopes[scope.value as keyof typeof scopes],
      },
    ],
  };
  filters.value.keyword = keywordDebounced.value;
});

const talentCommonData = getTalentCommonData();

const statusTab = computed(() => [
  {
    name: 'ELIGIBLE',
    label: 'Eligible',
  },
  {
    name: 'ALL_STAR',
    label: 'All Star',
  },
  {
    name: 'FUTURE',
    label: 'Future',
  },
  {
    name: `SELECTED`,
    label: `${selectedTalents.value.length} Selected`,
  },
]);

onMounted(() => {
  if (!status.value) {
    status.value = statusTab.value[0].name;
    filters.value.filters = {
      status: status.value as TalentStatus,
      specialities: [
        {
          name: scopes[scope.value as keyof typeof scopes],
        },
      ],
    };
  }
});

const search = (value: string) => {
  keyword.value = value;
  currentPage.value = 1;
  delete filters.value.after;
  delete filters.value.before;
  delete filters.value.last;
  filters.value.first = 10;
};

const changePage = (type: string, cursor: string) => {
  if (type === 'next') {
    if (currentPage.value < Math.ceil((data.value?.searchTalents.totalCount as number) / 10)) {
      currentPage.value += 1;
    }
    filters.value.first = 10;
    delete filters.value.before;
    delete filters.value.last;
    filters.value.after = cursor;
  } else if (type === 'prev') {
    if (currentPage.value > 1) currentPage.value -= 1;
    if (currentPage.value === 1) {
      delete filters.value.after;
      delete filters.value.before;
      delete filters.value.last;
      filters.value.first = 10;
    } else {
      delete filters.value.after;
      delete filters.value.first;
      filters.value.last = 10;
      filters.value.before = cursor;
    }
  } else if (type === 'first') {
    currentPage.value = 1;
    delete filters.value.after;
    delete filters.value.before;
    delete filters.value.last;
    filters.value.first = 10;
  } else if (type === 'last') {
    currentPage.value = Math.ceil((data.value?.searchTalents.totalCount as number) / 10);
    delete filters.value.first;
    delete filters.value.after;
    filters.value.before = cursor;
    filters.value.last =
      (data.value?.searchTalents.totalCount as number) -
      (Math.ceil((data.value?.searchTalents.totalCount as number) / 10) - 1) * 10;
  }
};
const { items: filtersItems } = useFilters({ filters: IFiltersOptions.Talents });

// Populate the filters with correct label and value
const talentsFilterData = computed(() =>
  useFilterDataMapper({
    filters: talentCommonData.data.value,
    filtersItems: filtersItems,
  })
);

const hasFilter = (selectedOptions: (IFilter[] | boolean | string)[]) => {
  const [filter, isChecked, elementDelete] = selectedOptions;

  isChecked && Array.isArray(filter)
    ? filter.forEach((el) => {
        if (el.isLikeRadio && activeFilters.value.length > 0) {
          activeFilters.value = activeFilters.value.filter(
            (options) => options.isLikeRadio !== el.isLikeRadio && options.skills !== elementDelete
          );
        }
        activeFilters.value.push(el);
      })
    : (activeFilters.value = activeFilters.value.filter(
        (options) => options.name !== elementDelete && options.skills !== elementDelete
      ));

  activeFilters.value = [...new Set(activeFilters.value)];
};
const applyFilter = handleSubmit((values) => {
  if (activeFilters.value.length) {
    setFieldValue(
      'languages',
      activeFilters.value.filter((filter) => filter.keyValue === 'languages')
    );
    values.languages = activeFilters.value.filter((filter) => filter.keyValue === 'languages');

    setFieldValue(
      'tools',
      activeFilters.value.filter((filter) => filter.keyValue === 'tools')
    );
    values.tools = activeFilters.value.filter((filter) => filter.keyValue === 'tools');

    const mappedFilters = useMappedFilters(Object.entries(values));

    filters.value.filters = {
      ...filters.value.filters,
      ...mappedFilters,
    };
    filterReset.value = false;
  }
});

const notificationMessage = ref('');
const notificationStatus = ref<INotificationProps['status']>('');
const showNotification = ref(false);

watch([isSuccess, isError], () => {
  if (isSuccess.value) {
    notificationMessage.value = 'Talents added successfully';
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isError.value) {
    notificationMessage.value = 'Talents add failed';
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});
</script>
<template>
  <div class="main">
    <div class="header">
      <Button
        class="goBack"
        icon-size="small"
        icon="chevron-left"
        label="Go back"
        variant="link-tertiary"
        @clicked="router.push(`/projects/${projectId}?tab=POOL`)"
      />
      <Button
        :disabled="selectedTalents.length === 0"
        label="Add talents"
        @clicked="
          () => {
            createDealTalentSelection({ dealId: projectId, talentId: selectedTalents.map((t: any) => t.node.id) });
            selectedTalents = [];
          }
        "
      />
    </div>
    <div class="tabSearch">
      <Tab
        :items="statusTab"
        :initial-active="status as string"
        @clicked="(value: string) => status = value"
      />
      <Search
        placeholder="Search talents"
        @keystart="(value) => search(value)"
        v-if="status !== 'SELECTED'"
      />
    </div>
    <div>
      <form @submit="applyFilter" class="filters">
        <div class="filterMethod">
          <span class="text-body-2">{{ methodForFilter }}</span>
          <Radio
            v-for="(radio, index) in typeOfSearch"
            :key="index"
            :label="labelRadio[index]"
            :name="nameRadioFilter"
            :initial-value="filterMethod"
            :value="radio"
            :rules="{ required: true }"
            @changed="(radio: string) => (filterMethod = radio)"
          />
        </div>
        <div class="selectFilters" v-if="talentsFilterData.length > 0">
          <SelectFilter
            v-for="filter in talentsFilterData"
            v-bind="filter"
            :key="filter.valueSelect"
            @selected="hasFilter"
            :initial-value="{ specialities: [scopes[scope as keyof typeof scopes]] }"
            :reset="filterReset"
          />
        </div>
        <div v-if="talentsFilterData.length === 0">
          <div class="filterSkeleton1"></div>
          <div class="filterSkeleton2"></div>
        </div>
        <div class="buttons">
          <Button native-type="submit" label="Apply filters" />
          <Button
            variant="secondary"
            label="Reset filters"
            @clicked="
              () => {
                resetField('operator');
                filterReset = true;
                filters.filters = {
                  status: status as TalentStatus,
                }
              }
            "
          />
        </div>
      </form>
    </div>
    <div class="talents">
      <div class="talent" v-for="talent in talents" :key="talent.node.id">
        <div class="infoCheckbox">
          <Checkbox
            label=""
            :initial-value="selectedTalents.some((t: any) => t.node.id === talent.node.id)"
            :disabled="Boolean(talentsSelection?.dealTalentSelections.nodes?.find((t: any) => t.talent.id === talent.node.id)) || !talent.node.zohoId"
            @changed="
              (value) => {
                if (value.target.checked) {
                  selectedTalents = [...selectedTalents, talent];
                } else {
                  selectedTalents = selectedTalents.filter((t: any) => t.node.id !== talent.node.id);
                }
              }
            "
          />
          <div class="info">
            <div
              class="alreadyAdded"
              v-if="talentsSelection?.dealTalentSelections.nodes?.find((t: any) => t.talent.id === talent.node.id)"
            >
              Already added
            </div>
            <div class="name">
              {{ talent.node.firstName }} {{ talent.node.lastName }}
              {{
                talentsSelection?.dealTalentSelections.nodes?.find(
                  (t: any) => t.talentId === talent.node.id
                )?.id
              }}
            </div>
            <div class="email">
              {{ talent.node.email }}
            </div>
            <div class="mobile">{{ talent.node.mobile }}</div>
          </div>
        </div>
        <div class="rateInfo">
          <div class="label">Ratecard</div>
          <div class="rate">{{ talent.node.rate }}</div>
        </div>
        <Button
          icon="search-doc"
          variant="accent"
          tag="a"
          :to="`/talents/edit/${talent.node.id}`"
          target="_blank"
          class="viewTalent"
        />
      </div>
      <div class="skeletons" v-if="!data">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <Pagination
    v-if="status !== 'SELECTED' && data"
    :has-next-page="data?.searchTalents.pageInfo.hasNextPage as boolean"
    :has-previous-page="data?.searchTalents.pageInfo.hasPreviousPage as boolean"
    :total-item="data?.searchTalents.totalCount as number"
    :current-page="currentPage"
    :next-page="data?.searchTalents.pageInfo.endCursor as string"
    :prev-page="data?.searchTalents.pageInfo.startCursor as string"
    label="Talents"
    :total-pages="Math.ceil((data?.searchTalents.totalCount as number) / 10) === 0 ? 1 : Math.ceil((data?.searchTalents.totalCount as number) / 10)"
    @change-page="changePage"
  />
  <Notification
    v-if="showNotification"
    :status="notificationStatus"
    :message="notificationMessage"
    :duration="5000 as number"
  />
</template>

<style lang="scss" scoped>
.main {
  margin-left: 2rem;
  margin-right: 2rem;
  min-height: 48rem;
  font-family: $primary-font-family;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem 4rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3.5rem 2rem 3.5rem 2rem;
    border: 1px solid $black-20;
    border-radius: 0.2rem;
    height: 250px;

    .filterMethod {
      display: flex;
      gap: 1rem;
    }
    .selectFilters {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
    .buttons {
      display: flex;
      gap: 1rem;
    }
  }

  .tabSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
}
.talents {
  display: flex;
  flex-direction: column;

  padding-top: 2rem;

  .talent {
    display: flex;
    width: 100%;
    align-items: center;
    height: 11rem;
    padding: 2.5rem 0 2.5rem 0;
    border-bottom: 1px solid $black-10;
    border-radius: 0.2rem;

    .viewTalent {
      margin-left: auto;
    }

    .infoCheckbox {
      display: flex;
      gap: 1rem;
      width: 40%;
      align-items: center;
      .info {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .email,
        .mobile {
          font-size: 1.3rem;
          color: $black-50;
        }
        .name {
          font-size: 1.6rem;
          font-weight: $primary-font-weight;
          font-family: $primary-font-family;
        }
        .alreadyAdded {
          font-size: 1.2rem;
          font-weight: $primary-font-weight;
          font-family: $primary-font-family;
          color: $blue;
        }
      }
    }

    .rateInfo {
      display: flex;
      width: 10%;
      flex-direction: column;
      gap: 1rem;
      .label {
        font-size: 1.3rem;
        color: $black-50;
      }
      .rate {
        font-size: 1.3rem;
        font-weight: $primary-font-weight;
      }
    }
  }
}

.goBack {
  margin: 0 0 1.6rem;

  :deep {
    .o-btn {
      &__wrapper {
        .o-btn__label {
          .text-link {
            @extend .text-body-1;
            padding: 0;
            margin: 0;
            border-bottom: none;
          }
        }
      }
    }
  }
}
.skeletons {
  padding: 3rem 0 3rem 0;
  & > div {
    background-color: $black-10;
    border-radius: 0.4rem;
    height: 60px;
    margin-bottom: 2rem;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
}

.filterSkeleton1 {
  height: 36px;
  width: 90%;
  background-color: $black-10;
  border-radius: 0.4rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  margin-bottom: 0.9rem;
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
}
.filterSkeleton2 {
  height: 36px;
  width: 43%;
  background-color: $black-10;
  border-radius: 0.4rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
}
</style>
