import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { AgenciesCounterQuery, AgenciesCounterQueryVariables } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const agenciesCounterQuery = gql`
  query agenciesCounter {
    agenciesCounter {
      _count
      status
    }
  }
`;

export const getAgenciesCounter = (
  options?: UseQueryOptions<AgenciesCounterQuery, void, AgenciesCounterQuery, any[]>
): UseQueryReturnType<AgenciesCounterQuery, AgenciesCounterQueryVariables> =>
  useGraphql({
    query: agenciesCounterQuery,
    options,
  });
