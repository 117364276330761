<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Icon from '@/components/atoms/Icon.vue';
import InputText from '@/components/atoms/InputText.vue';
import Modal from '@/components/atoms/Modal.vue';
import FiltersString from '@/data/Filters.json';
import GenericStrings from '@/data/GenericStrings.json';
import { REGEX_SQUARE_BRACKETS } from '@/data/constants';
import type { IFilter } from '@/models/selectFilterModel';
import type { Option } from '@/models/selectModel';
import { Field } from 'vee-validate';
import { computed, onMounted, ref, toRefs, watch } from 'vue';

interface OptGroup {
  name: string;
  options: Option[];
}

interface ISelectFilterProps {
  placeholder?: string;
  valueSelect: string;
  optGroup?: OptGroup[];
  options?: Option[];
  initialChecked?: boolean;
  hasLevel?: boolean;
  isBoolean?: boolean;
  hasExpertise?: boolean;
  isLikeRadio?: boolean;
  rules?: any;
  disabled?: boolean;
  reset?: boolean;
  initialValue?: {
    [key: string]: string[];
  };
}

interface ISkillLevels {
  skill: string;
  levels: string[];
}

const labelLevels = {
  Basic: 'Base',
  Intermediate: 'Intermedio',
  Advanced: 'Avanzato',
};

const props = withDefaults(defineProps<ISelectFilterProps>(), {
  disabled: false,
  placeholder: '',
});

const {
  placeholder,
  valueSelect,
  hasExpertise,
  hasLevel,
  isBoolean,
  isLikeRadio,
  options,
  optGroup,
  initialChecked,
  rules,
  disabled,
  reset,
  initialValue,
} = toRefs(props);

const { activeFilter, skillLevel, modalSkill, applyLevel } = FiltersString;

const optionsDropdown = ref(options?.value);
const optionsGroupDropdown = ref(optGroup?.value);
const selectedOptions = ref<IFilter[]>([]);
const openModal = ref(false);
const isChecked = ref(false);
const elementChecked = ref('');
const temporaryLevels = ref<ISkillLevels[]>([]);

const checkInitialValue = () => {
  if ((initialValue?.value && Object.keys(initialValue?.value).length <= 0) || !initialValue?.value)
    return;

  const optionsToCheck = optGroup?.value
    ? optGroup?.value.flatMap((expertise) =>
        expertise.options.map((option) => ({
          ...option,
          name: expertise.name,
        }))
      )
    : options?.value;

  const elementToCheck = optionsToCheck?.filter((option) => {
    if (!initialValue?.value) return;

    return Object.values(initialValue.value).some((valuesToCheck) =>
      valuesToCheck.some((value) => value.split('[')[0] === option.label)
    );
  });

  if (initialValue?.value) {
    for (const [key, value] of Object.entries(initialValue.value)) {
      if (key === valueSelect.value) {
        const allCheckBox = document.querySelectorAll(
          `[data-name="${key}"] .o-drop .o-chk.Checkbox--checkbox`
        );

        allCheckBox.forEach((checkbox) => {
          if (!elementToCheck?.length && checkbox.textContent === value[0]) {
            (checkbox as HTMLInputElement).checked = true;
            (checkbox as HTMLInputElement).click();
          } else {
            const element = elementToCheck?.filter((el) => {
              return el.label === checkbox.textContent;
            });
            if (element && element?.length > 0) {
              (checkbox as HTMLInputElement).checked = true;
              (checkbox as HTMLInputElement).click();
            }
          }
        });
      }
    }
  }

  // add initial TemporaryLevels
  if (selectedOptions.value) {
    const newTemporaryLevels = selectedOptions.value.map((option) => {
      if (option.hasLevel) {
        return {
          skill: option.skills,
          levels: option.levels as string[],
        };
      }
      return { skill: '', levels: [] };
    });

    if (newTemporaryLevels && newTemporaryLevels.length > 0) {
      temporaryLevels.value = [...newTemporaryLevels];
    }
  }
};

const levelAlredySelected = (skills?: string, level?: string) => {
  if (!initialValue?.value || !initialValue?.value[valueSelect.value] || !skills || !level)
    return false;

  let result = false;

  initialValue.value[valueSelect.value].forEach(function (string) {
    const valueToCheck = string.split('[')[0];
    if (valueToCheck.includes(skills)) {
      const matches = string.match(REGEX_SQUARE_BRACKETS);
      if (matches && matches.length > 1) {
        const levels = matches[1];
        const arrayLevel = levels.split(',');
        if (arrayLevel.includes(labelLevels[level as keyof typeof labelLevels])) {
          result = true;
        }
      }
    }
  });

  return result;
};

onMounted(() => {
  checkInitialValue();
});

const autocomplete = (searched: string) => {
  if (searched.length > 0) {
    options?.value
      ? (optionsDropdown.value = options?.value?.filter((option: Option) =>
          option.value.includes(searched.toLowerCase().trim())
        ))
      : (optionsGroupDropdown.value = optGroup?.value
          ?.map((group) => ({
            name: group.name,
            options: group.options.filter((option) =>
              option.value.includes(searched.toLowerCase().trim())
            ),
          }))
          .filter(
            (opt) =>
              opt.options.length > 0 ||
              opt.name.trim().toLowerCase().includes(searched.trim().toLowerCase())
          ));
  } else {
    optionsDropdown.value = options?.value;
    optionsGroupDropdown.value = optGroup?.value;
  }
};

const updatedSelectedOptions = (e: Event, option?: Option, name?: string) => {
  isChecked.value = (e.target as HTMLInputElement).checked;
  elementChecked.value = option?.value || name || '';
  isChecked.value ? pushOption(option, name) : pullOption(option, name);
  isChecked.value ? ((isOverflow.value = false), checkOverflow()) : checkOverflow();
};

const checkAlredySelected = computed(() => (option?: Option, name?: string): [boolean, number] => {
  let indexToPull: number;
  option
    ? (indexToPull = selectedOptions.value.findIndex((options) => options.skills === option?.value))
    : (indexToPull = selectedOptions.value.findIndex((options) => options.name === name));

  return [indexToPull !== -1, indexToPull];
});

const clearIsLikeRadio = () => (selectedOptions.value.length = 0);

const pushOption = (option?: Option, name?: string) => {
  let initialLevel: string[] = [];

  if (option && initialValue?.value && initialValue.value[valueSelect.value]) {
    initialValue.value[valueSelect.value].forEach(function (string) {
      const valueToCheck = string.split('[')[0];
      if (valueToCheck.includes(option?.label)) {
        const matches = string.match(REGEX_SQUARE_BRACKETS);
        if (matches && matches.length > 1) {
          const levels = matches[1];
          const arrayLevel = levels.split(',');
          initialLevel = [...arrayLevel].filter((level) => level !== '');
        }
      }
    });
  }

  if (Array.isArray(selectedOptions.value)) {
    if (isLikeRadio.value && selectedOptions.value.length > 0) {
      clearIsLikeRadio();
    }
    const filter = {
      keyValue: valueSelect.value,
      name: name ?? '',
      skills: option?.value ?? '',
      skillsLabel: option?.label ?? '',
      levels: initialLevel.length
        ? initialLevel
        : skillLevel.map((level) => labelLevels[level as keyof typeof labelLevels]),
      hasExpertise: hasExpertise.value,
      hasLevel: hasLevel.value,
      isBoolean: isBoolean.value,
      isLikeRadio: isLikeRadio.value,
    };

    selectedOptions.value.push(filter);
  }
};

const pullOption = (option?: Option, name?: string) => {
  const [isSelected, indexToPull] = checkAlredySelected.value(option, name);
  if (isSelected) {
    selectedOptions.value.splice(indexToPull, 1);
  }
};

const disabledExpertise = computed(() => {
  let found = false;
  if (selectedOptions.value && selectedOptions.value.length) {
    selectedOptions.value.forEach((option) => {
      found = option.name && !!option.skills.length ? true : false;
    });
  }
  return found;
});

const selectLevel = (e: Event, currentIndex: number, label: string, nameSkill: string) => {
  const existingSkill = temporaryLevels.value.find((item) => item.skill === nameSkill);
  const existingLevels = temporaryLevels.value.find((item) => item.levels.includes(label));
  const isChecked = (e.target as HTMLInputElement).checked;

  if (isChecked) {
    if (!existingSkill) {
      // if skill doesn't exist make the object with skill and level
      temporaryLevels.value.push({
        skill: nameSkill,
        levels: [labelLevels[label as keyof typeof labelLevels]],
      });
    } else if (!existingLevels) {
      // if level doesn't exist push at temporaryLevels with correct index the selected level
      temporaryLevels.value[currentIndex].levels.push(
        labelLevels[label as keyof typeof labelLevels]
      );
    }
  } else {
    const index = temporaryLevels.value[currentIndex].levels.findIndex(
      (level) => level === labelLevels[label as keyof typeof labelLevels]
    );
    if (index !== -1) {
      temporaryLevels.value[currentIndex].levels.splice(index, 1);
    }
  }
};

const addLevel = () => {
  selectedOptions.value = selectedOptions.value.map((opt) => {
    const matchingSkill = temporaryLevels.value.find((obj) => obj.skill === opt.skills);
    if (matchingSkill) {
      opt.levels = matchingSkill.levels;
    }
    return opt;
  });

  openModal.value = false;
};

const isOverflow = ref(false);

const checkOverflow = () => {
  if (isOverflow.value) isOverflow.value = false;
  setTimeout(() => {
    isOverflow.value = document.documentElement.scrollWidth > document.body.clientWidth;
  }, 1);
  window.onresize = () => (isOverflow.value = false);
};

watch(reset, () => {
  reset.value && selectedOptions.value.splice(0);
});

const emit = defineEmits(['selected']);

watch(
  () => selectedOptions.value?.length,
  () => {
    emit('selected', [selectedOptions.value, isChecked.value, elementChecked.value]);
  },
  { deep: true }
);
</script>

<template>
  <Field :name="valueSelect" v-model="selectedOptions" v-slot="{ errors }" :rules="rules">
    <div class="SelectFilter" :data-name="valueSelect">
      <div class="Select">
        <o-dropdown
          :disabled="disabled"
          :class="`SelectFilter__drop ${isOverflow ? '--overflow' : ''}`"
          aria-role="list"
          multiple
        >
          <template #trigger="{ active }">
            <div
              v-if="selectedOptions"
              @click="checkOverflow"
              :class="[
                `SelectFilter__drop-trigger`,
                { active },
                'text-body-2',
                { counter: selectedOptions.length },
              ]"
            >
              <span class="SelectFilter__drop-text" v-if="!!selectedOptions">
                {{ placeholder }}
                <span v-if="selectedOptions?.length > 0">({{ selectedOptions?.length }})</span>
              </span>
              <Icon
                :class="`SelectFilter__drop-icon`"
                :icon="active ? 'chevron-up' : 'chevron-down'"
              />
            </div>
          </template>
          <InputText
            class="SelectFilter__drop-inputSearch"
            :placeholder="GenericStrings.search"
            :standalone="true"
            @keystart="autocomplete"
            v-if="!isBoolean"
          />
          <o-dropdown-item
            v-if="!hasExpertise"
            v-for="(option, index) in optionsDropdown"
            :class="{ '--likeRadio': isLikeRadio }"
            :key="index + option.value"
            aria-role="listitem"
            :value="{ name: option.value, label: option.label }"
          >
            <Checkbox
              :label="option.label"
              as="checkbox"
              :standalone="true"
              @changed="updatedSelectedOptions($event, option)"
              :initial-value="
                initialChecked ||
                (!!selectedOptions.length &&
                  selectedOptions.some((select) => select.skills === option.value))
              "
            />
          </o-dropdown-item>
          <o-dropdown-item
            class="SelectFilter__optGroup"
            v-else
            aria-role="listitem"
            v-for="(group, index) in optionsGroupDropdown"
            :key="index + group.name"
          >
            <Checkbox
              :label="group.name"
              as="checkbox"
              :standalone="true"
              :initial-value="
                initialChecked ||
                (selectedOptions &&
                  !!selectedOptions.length &&
                  selectedOptions.some((option) => option.name === group.name))
              "
              @changed="!disabledExpertise && updatedSelectedOptions($event, undefined, group.name)"
              :disabled="
                disabledExpertise && selectedOptions.some((option) => option.name === group.name)
              "
            />
            <div
              v-for="(option, i) in group.options"
              :key="i + option.value"
              class="SelectFilter__optGroup--options"
            >
              <Checkbox
                :label="option.label"
                as="checkbox"
                :standalone="true"
                :initial-value="initialChecked || checkAlredySelected(option, '')[0]"
                @changed="updatedSelectedOptions($event, option, group.name)"
              />
            </div>
          </o-dropdown-item>
        </o-dropdown>
        <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
      </div>
    </div>
    <Button
      v-if="selectedOptions && selectedOptions.length && hasLevel"
      variant="link-tertiary"
      :label="activeFilter"
      @clicked="() => (openModal = true)"
    />
    <Modal :is-modal-active="openModal" @close-modal="openModal = false" :head="modalSkill">
      <template #modal-main>
        <div class="ModalFiltersSelect" v-for="(skill, index) in selectedOptions" :key="index">
          <h4 class="ModalFiltersSelect-title heading-h4">
            {{ skill.skillsLabel }}
          </h4>
          <div class="ModalFiltersSelect-main">
            <Checkbox
              class="ModalFiltersSelect"
              v-for="(level, i) in skillLevel"
              :key="level + i"
              :label="level"
              :initial-value="levelAlredySelected(skill.skillsLabel, level)"
              as="checkbox"
              @changed="selectLevel($event, index, level, skill.skills)"
            />
          </div>
        </div>
      </template>

      <template #modal-footer>
        <component
          :is="Button"
          variant="primary"
          :label="applyLevel"
          @clicked="addLevel"
        ></component>
      </template>
    </Modal>
  </Field>
</template>

<style scoped lang="scss">
.SelectFilter {
  :deep .o-drop__menu {
    @include flexing(column, start, start);
    max-height: 30rem;
    min-height: max-content;
    width: max-content;
    overflow-y: auto;
    box-shadow: 0 1rem 4.4rem 0 $black-08;

    &::-webkit-scrollbar {
      width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $black-20;
      border-radius: 4rem;
    }

    .o-drop__item {
      width: 100%;

      &.--likeRadio {
        .Checkbox input.o-chk__check {
          border-radius: 50%;
        }
      }

      &:hover {
        :deep .o-chk.Checkbox {
          &__label {
            color: $accent;
          }
          &__check {
            background-color: $primary-color;
          }
        }
      }

      &--active {
        background-color: transparent;
        color: $primary-color;
        border-radius: 0.4rem;
        position: relative;
      }
    }
  }
  &__drop {
    &-trigger {
      @include flexing(row, start, center);
      cursor: pointer;
      padding: 0.8rem 1.6rem;
      border: 0.1rem solid $black-50;
      border-radius: 0.4rem;

      &:hover,
      &.active {
        border-color: $primary-color;
      }

      &.counter {
        background-color: $primary-color;
        color: $secondary-color;
      }
    }

    &-icon {
      margin-left: 0.4rem;
    }

    &-inputSearch {
      :deep .o-input {
        padding: 1.1rem 0.9rem;
        margin-bottom: 1rem;
      }
    }

    &.--overflow {
      :deep .o-drop__menu {
        right: 0;
        left: auto;
      }
    }

    :deep .o-drop__menu {
      padding: 1.6rem;
      margin-top: 0.8rem;
      border-radius: 0.4rem;

      .o-drop__item {
        &:not(:last-of-type) {
          margin-bottom: 1.6rem;
        }
        &.SelectFilter__optGroup {
          margin-bottom: 0;
          &:not(:last-of-type) {
            margin-bottom: 1.6rem;
          }
        }
      }
    }
  }
  &__optGroup {
    &--options {
      padding-left: 1.2rem;

      &:first-of-type {
        margin-top: 1.6rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 1.6rem;
      }
    }
  }
}

.ModalFiltersSelect {
  width: 39rem;
  margin: auto;
  margin-bottom: 4rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid $primary-color;

  &-title {
    margin: 0;
    margin-bottom: 1.6rem;
    text-transform: capitalize;
  }

  &-main {
    @include flexing(row, start, center);
    gap: 1.6rem;
  }
}
</style>
