<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import Icon from './Icon.vue';

interface ISearchProps {
  placeholder: string;
  initialValue?: string;
}

const props = defineProps<ISearchProps>();

const { initialValue } = toRefs(props);

watch(
  () => initialValue?.value,
  (newValue) => {
    model.value = newValue;
  }
);

const model = ref(initialValue?.value);
</script>

<template>
  <o-field class="Search" @input="$emit('search', model)">
    <o-input
      class="Search-input text-body-2"
      type="text"
      :placeholder="placeholder"
      v-model="model"
      :value="model"
      @keyup="$emit('keystart', model)"
    >
    </o-input>
    <Icon class="Search-icon" icon="search"></Icon>
  </o-field>
</template>

<style scoped lang="scss">
.Search {
  position: relative;
  min-width: 31rem;

  &-icon {
    position: absolute;
    right: 0;
    color: $black-20;
  }

  &:hover &-icon {
    color: $black-50;
  }

  :deep {
    &.o-field {
      flex-grow: inherit;
    }
    .o-ctrl-input {
      width: 100%;
      .Search-input {
        color: $primary-color;
        border: none;
        border-bottom: 0.1rem solid $black-20;
        padding-bottom: 0.8rem;
        padding-right: 2rem;

        &::placeholder {
          color: $black-50;
        }

        &:hover {
          border-color: $black-50;
        }

        &:focus-visible {
          outline: none;
          border-color: $primary-color;
        }
      }
    }

    &.o-field {
      &--focused {
        .o-ctrl-input + .Search-icon {
          color: $primary-color;
        }
      }
    }
  }
}
</style>
