import { gql } from '@/api/graphql-client';
import type { MutationExportTalentsArgs, ExportTalentsMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const exportCSVTalentMutation = gql`
  mutation exportTalents($filters: TalentsFilterInput, $talentIds: [String!], $keyword: String) {
    exportTalents(filters: $filters, talentIds: $talentIds, keyword: $keyword)
  }
`;

export const exportCsvTalents = (
  props?: MutationExportTalentsArgs,
  options?: UseQueryOptions<
    ExportTalentsMutation,
    MutationExportTalentsArgs,
    ExportTalentsMutation,
    any[]
  >
): UseQueryReturnType<ExportTalentsMutation, MutationExportTalentsArgs> =>
  useGraphql({
    query: exportCSVTalentMutation,
    variables: props,
    options,
  });
