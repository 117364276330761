<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import InputText from '@/components/atoms/InputText.vue';
import Button from '@/components/atoms/Button.vue';

interface ITextAreaFormProps {
  label?: string;
  placeholder?: string;
  name?: string;
  initialValue?: string;
  rules?: any;
  standalone?: boolean;
  labelBtn?: string;
}

const props = withDefaults(defineProps<ITextAreaFormProps>(), {
  standalone: false,
  rules: 'invalid_char',
});

const { label, placeholder, name, initialValue } = toRefs(props);
const textValue = ref(initialValue?.value ?? '');

const emits = defineEmits(['keystart', 'clicked']);

watch(
  () => initialValue?.value,
  (newValue) => {
    textValue.value = newValue ?? '';
  }
);

const copyValue = (inputValue: string) => {
  navigator.clipboard.writeText(inputValue);
};

const deleteValue = () => {
  textValue.value = '';
  emits('keystart', textValue.value);
};
</script>

<template>
  <div class="TextAreaForm FormGroup">
    <h5 class="text-link" v-if="label">{{ label }}</h5>
    <InputText
      type="textarea"
      @keystart="(char: string) => (textValue = char, emits('keystart', textValue))"
      :name="name"
      :placeholder="placeholder"
      :initial-value="textValue.replace(/<[^>]+>/g, '')"
      :standalone="standalone"
      :rules="rules"
    />
    <div class="TextAreaForm__buttons">
      <Button icon="copy" type="icon" variant="tertiary" @clicked="copyValue(textValue)"></Button>
      <Button icon="delete" type="icon" variant="tertiary" @clicked="deleteValue"></Button>
      <Button v-if="labelBtn" :label="labelBtn" @clicked="$emit('clicked', $event)" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.TextAreaForm {
  .InputText {
    :deep .o-input__textarea {
      resize: vertical;
      max-height: 35rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0.7rem;
      }

      &::-webkit-scrollbar-thumb {
        background: $black-20;
        border-radius: 4rem;
        background-clip: padding-box;
      }

      &::-webkit-scrollbar-track {
        margin-block: 0.8rem;
      }
    }
  }

  &__buttons {
    @include flexing(row, end, start);
    gap: 0.8rem;
    margin-top: 1rem;
  }
}
</style>
