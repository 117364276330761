<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import GoBack from '@/components/molecules/GoBack.vue';
import Button from '@/components/atoms/Button.vue';
import CardParticipant from '@/components/molecules/CardParticipant.vue';
import Notification from '@/components/atoms/Notification.vue';
import partecipantString from '@/data/ParticipantTravel.json';
import { useRoute, useRouter } from 'vue-router';
import { getExperience } from '@/api/queries/experience';
import type { QueryExperienceArgs } from '@/gql/Gql.types';
import moment from 'moment';
import 'moment/dist/locale/it';

const { previousRoute, cta, inputs: inputsString, noSubscriber } = partecipantString;

const { params } = useRoute();
const router = useRouter();

const props = ref<QueryExperienceArgs>({ experienceId: params.id.toString() });
const { data, status, isLoading, error } = getExperience(props.value);

const errorMessage = ref('');

watch(status, () => {
  errorMessage.value = error.value?.toString() ?? '';
  if (status.value === 'error') {
    setTimeout(() => {
      router.push(previousRoute);
    }, 2000);
  }
});

const addParticipantRoute = computed(() => cta.to + params.id.toString());

const correctBirthDate = computed(
  () => (date: string) => moment(date).locale('it').format('YYYY-MM-DD')
);

const participants = computed(() => {
  if (status.value !== 'success' || isLoading.value || !data.value || !data.value?.experience)
    return [];

  return (data.value?.experience.bookings || [])?.map((element, index) => {
    const { firstName, lastName, birthDate, email, role, company, gender, roomType } = element;

    const participant = 'Viaggiatore ' + (index + 1);

    const inputs = [
      {
        label: inputsString.name,
        type: 'text',
        initialValue: firstName,
        placeholder: firstName,
      },
      {
        label: inputsString.surname,
        type: 'text',
        initialValue: lastName,
        placeholder: lastName,
      },
      {
        label: inputsString.dateBirth,
        placeholder: correctBirthDate.value(birthDate),
        initialValue: correctBirthDate.value(birthDate),
        name: 'birthDate',
        type: 'date',
      },
      {
        label: inputsString.email,
        type: 'email',
        placeholder: email,
        initialValue: email,
      },
      {
        label: inputsString.role,
        type: 'text',
        placeholder: role,
        initialValue: role,
      },
      {
        label: inputsString.company,
        type: 'text',
        placeholder: company,
        initialValue: company,
      },
    ];

    const radioParticipant = [
      {
        title: inputsString.gender,
        radioInput: [
          {
            label: inputsString.male,
            name: 'gender',
            value: gender === 'MALE' ? inputsString.male : '',
          },
          {
            label: inputsString.female,
            name: 'gender',
            value: gender === 'FEMALE' ? inputsString.female : '',
          },
        ],
      },
      {
        title: inputsString.roomType,
        radioInput: [
          {
            label: inputsString.single,
            name: 'roomType',
            value: roomType === 'SINGLE' ? inputsString.single : '',
          },
          {
            label: inputsString.double,
            name: 'roomType',
            value: roomType === 'DOUBLE' ? inputsString.double : '',
          },
        ],
      },
    ];

    return { participant, inputs, radioParticipant };
  });
});

const location = computed(() => data.value?.experience.location || '');

const fromTo = computed(() => {
  const from = moment(data.value?.experience.from).locale('it').format('DD/MM/YYYY');
  const to = moment(data.value?.experience.to).locale('it').format('DD/MM/YYYY');
  return 'Data: ' + from + ' - ' + to;
});
</script>

<template>
  <GoBack :headline="location" :subtitle="fromTo" :previous-route="previousRoute">
    <template #action-button>
      <Button tag="router-link" v-bind="{ ...cta }" :to="addParticipantRoute" />
    </template>
  </GoBack>
  <div class="container" v-if="status === 'success' && !isLoading && participants.length > 0">
    <div
      class="col-6 mb-2"
      v-for="(card, index) in participants"
      :key="index"
      v-if="participants.length > 0"
    >
      <CardParticipant
        :title="card.participant"
        :inputs="card.inputs"
        :radio-participant="card.radioParticipant"
      />
    </div>
  </div>
  <div v-else-if="!isLoading && participants.length <= 0" class="container">
    <h2 class="col-12 heading-h4">{{ noSubscriber }}</h2>
  </div>
  <Notification v-if="status === 'error'" :message="errorMessage" status="error" />
</template>

<style scoped lang="scss">
.GoBack {
  margin-bottom: 4rem;
}
.container {
  gap: 0 4rem;
}
</style>
