<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import { toRefs } from 'vue';
import strings from '@/data/GenericStrings.json';

type ITableActionsProps = {
  toInterview?: boolean;
  action?: (id: string) => void;
  icon?: string;
  id: string;
  moveIn?: { label: string; action: (id: string) => void }[];
  label?: string;
  tag: 'button' | 'router-link' | 'a';
  to?: string;
  disabled?: boolean;
  labelTo?: string;
  isInvited?: boolean;
  migrateTo?: { label: string; action: (id: string) => void }[];
};

const props = withDefaults(defineProps<ITableActionsProps>(), {
  action: () => [],
  moveIn: () => [],
  toInterview: false,
  tag: 'button',
  disabled: false,
});

const { toInterview, moveIn, action, label, tag, to, disabled, migrateTo } = toRefs(props);
</script>
<template>
  <div :class="`TableActions ${labelTo ? '--partecipant' : ''}`">
    <div :class="`TableActions__buttons-wrapper ${toInterview ? '--grey-border' : ''}`">
      <Button
        v-if="label && toInterview"
        :label="label"
        variant="primary"
        @clicked="action(id)"
        :disabled="disabled"
      />
      <Button
        v-if="label && isInvited"
        :label="label"
        variant="primary"
        @clicked="action(id)"
        :disabled="disabled"
      />
      <Button
        v-if="to && !labelTo && !labelTo"
        variant="accent"
        :icon="icon"
        :tag="tag"
        :to="to ?? ''"
        :disabled="disabled"
      />
      <Button v-if="to && labelTo" :label="labelTo" variant="primary" :tag="tag" :to="to ?? ''" />
    </div>
    <div v-if="toInterview || isInvited" class="TableActions__actions-wrapper">
      <span class="text-body-2">{{ strings.moveIn }}</span>
      <div class="TableActions__actions-wrapper__buttons">
        <Button
          v-for="(item, index) in moveIn.filter(
            (action) => action.label && action.label.length > 0
          )"
          :key="index + item.label"
          :label="item.label"
          variant="link-tertiary"
          @clicked="item.action(id)"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.TableActions {
  width: 15.2rem;

  @include for-desktop {
    width: 100%;
  }

  &.--partecipant {
    width: 15.2rem;
  }

  .--grey-border {
    padding-bottom: 1.3rem;
    border-bottom: 1px solid $black-20;
  }

  &__buttons-wrapper {
    @include flexing(row, null, center);
    gap: 0.6rem;
    width: fit-content;
    flex-wrap: wrap;

    @include for-desktop {
      @include flexing(column, null, flex-start);
    }
  }

  &__actions-wrapper {
    width: 100%;
    padding-top: 1.3rem;
    @include flexing(column);

    &__buttons {
      @include flexing(row);
      gap: 0 1rem;
      width: 100%;
      flex-wrap: wrap;
    }
  }
}
</style>
