import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import { usersFragment } from '@/api/fragments/users';
import type { GetUsersQuery, GetUsersQueryVariables } from '@/gql/Gql.types';

const usersQuery = gql`
  query getUsers(
    $after: String
    $before: String
    $filters: UsersFilterInput
    $first: Int
    $last: Int
    $orderBy: UserOrder
  ) {
    users(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...node
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${usersFragment}
  ${pageInfo}
`;

export const getUsers = (
  props?: GetUsersQueryVariables,
  options?: UseQueryOptions<GetUsersQuery, GetUsersQueryVariables, GetUsersQuery, any[]>
): UseQueryReturnType<GetUsersQuery, GetUsersQueryVariables> =>
  useGraphql({
    query: usersQuery,
    variables: props,
    options,
  });
