import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { QueryDealArgs, Deal } from '@/gql/Gql.types';

export const dealQuery = gql`
  query getDeal($id: String!) {
    deal(id: $id) {
      id
      title
      companyName
      stage
      createdAt
      updatedAt
      scope
      expectingStartDate
      expectingCloseDate
      field
      ratecardMax
      refCode
      zohoId
      fullTime
      partTime
      onsiteTrip
      description
      speciality
      isArchived
      note
      sentAt
      user {
        firstname
        lastname
      }
    }
  }
`;

export const getDeal = (
  props?: QueryDealArgs,
  options?: UseQueryOptions<
    {
      deal: Deal;
    },
    QueryDealArgs,
    {
      deal: Deal;
    },
    any[]
  >
): UseQueryReturnType<
  {
    deal: Deal;
  },
  QueryDealArgs
> =>
  useGraphql({
    query: dealQuery,
    variables: props,
    options,
  });
