<script setup lang="ts">
import { getUser } from '@/api/queries/user';
import Avatar from '@/components/atoms/Avatar.vue';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Logo from '@/components/atoms/Logo.vue';
import HeaderNavListItem from '@/components/organisms/Header/components/HeaderNavListItem.vue';
import type { INavbarProps } from '@/models/navbarModel';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

const props = withDefaults(defineProps<INavbarProps>(), {
  items: () => [],
});
const { items } = toRefs(props);

const active = ref('');
const path = ref('');

const route = useRoute();

watch(route, () => (path.value = route.path));

const userStore = useUserStore();
const { userId, userRole } = storeToRefs(userStore);
const { logout } = userStore;

const { data: user } = getUser({ userId: (userId.value as string) ?? '' });

const fullname = computed(() =>
  user.value ? `${user.value?.user?.firstname} ${user.value?.user?.lastname}` : ''
);
</script>
<template>
  <header class="Header container-fluid" role="banner">
    <div class="Header__grid col-12">
      <div class="Header__left">
        <router-link class="Header__logo" to="/" title="Cosmico Dashboard, go to Homepage">
          <Logo type="white" />
        </router-link>
        <nav class="Header__nav" role="navigation" aria-label="Cosmico Dashboard Navigation">
          <ul class="Header__nav-list">
            <HeaderNavListItem
              v-for="(item, index) in items"
              :key="item.label + index + path"
              class="Header__nav-list-item"
              :item="item"
              :is-active="path === item.route"
              @clicked="active = item.route"
            />
          </ul>
        </nav>
      </div>
      <div class="Header__right">
        <router-link class="Header__user" :to="`/users/edit/${userId}`">
          <!-- Todo: insert SRC image  -->
          <Avatar :full-name="fullname" size="small" :src="''" />
          <span class="Header__user-name text-body-2">{{ fullname }}</span>
        </router-link>
        <Button
          icon="logout"
          label="Logout"
          icon-size="medium"
          type="navbar"
          variant="tertiary-dark"
          @click="logout"
        />

        <!--  todo: modificare logica click bottone login / logout con la vera logica   -->

        <router-link v-if="userRole === 'ADMIN'" class="Header__settings" to="/settings">
          <Icon icon="options" icon-size="medium" />
        </router-link>
      </div>
    </div>
  </header>
</template>
<style lang="scss" scoped>
.Header {
  background: $black;
  height: 6.2rem;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  &__grid {
    @include row-space-between;
    padding: 0 4rem;
  }

  &__left {
    @include row-center;
    justify-content: start;
  }

  &__right {
    @include row-center;
    justify-content: end;

    &-options {
      filter: invert(1);
    }
  }

  &__user {
    @include row-center-center;
    text-decoration: none;

    &-name {
      color: $white;
      padding-left: 0.8rem;
      line-height: 0;
    }
  }

  &__settings {
    @include row-center-center;
    color: $white;
  }

  &__nav {
    &-list {
      @include row-center;
    }
  }
}
</style>
