<script setup lang="ts">
import { toRefs } from 'vue';
import Icon from '@/components/atoms/Icon.vue';

const props = withDefaults(defineProps<{ toInterview: boolean }>(), {
  toInterview: false,
});

const { toInterview } = toRefs(props);
</script>
<template>
  <div v-if="toInterview" class="TableToInterview">
    <Icon icon="circle" icon-size="micro" />
  </div>
</template>
<style lang="scss" scoped>
.TableToInterview {
  margin-top: 0.2rem;
}
</style>
