import { pageInfo } from '@/api/fragments/pageInfo';
import { experienceFragment } from '@/api/fragments/experience';
import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { GetExperiencesQueryVariables, GetExperiencesQuery } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const experiencesQuery = gql`
  query getExperiences(
    $after: String
    $before: String
    $filters: ExperiencesFilterInput
    $first: Int
    $last: Int
    $orderBy: ExperienceOrder
  ) {
    experiences(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...Exp
        }
      }

      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${experienceFragment}
  ${pageInfo}
`;

export const getExperiences = (
  props?: GetExperiencesQueryVariables,
  options?: UseQueryOptions<
    GetExperiencesQuery,
    GetExperiencesQueryVariables,
    GetExperiencesQuery,
    any[]
  >
): UseQueryReturnType<GetExperiencesQuery, GetExperiencesQueryVariables> =>
  useGraphql({
    query: experiencesQuery,
    variables: props,
    options,
  });
