import App from './App.vue';
import Oruga from '@oruga-ui/oruga-next';
import router from './router';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { QueryClient, VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';

const queryClient = new QueryClient();
const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClient,
};

const app = createApp(App);
app.use(VueQueryPlugin, vueQueryPluginOptions);

import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'local'
  ? Sentry.init({
      app,
      dsn: 'https://24c27b2568351a467d7b2098120afcd0@o4507604971225088.ingest.de.sentry.io/4507605780398160',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'dashboardtalents.wearecosmico.com',
        'cosmico-dashboard-vue.vercel.app',
        'cosmico-dashboard-vue-cosmico.vercel.app',
        'cosmico-dashboard-vue-git-main-cosmico.vercel.app',
      ],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  : null;

app.use(router);
app.use(createPinia());
app.use(Oruga);

app.mount('#app');
