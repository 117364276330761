import { gql } from '@/api/graphql-client';
import { talentFragment } from '@/api/fragments/talent';
import type { MutationUpdateTalentNotesArgs, Talent } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateTalentNotesMutation = gql`
  mutation UpdateTalentNotes($talentId: String!, $notes: String!) {
    updateTalentNotes(talentId: $talentId, notes: $notes) {
      ...talent
    }
  }
  ${talentFragment}
`;

export const updateTalentNotes = (
  props: MutationUpdateTalentNotesArgs,
  options?: UseQueryOptions<Talent, MutationUpdateTalentNotesArgs, Talent, any[]>
): UseQueryReturnType<Talent, MutationUpdateTalentNotesArgs> =>
  useGraphql({
    query: updateTalentNotesMutation,
    variables: props,
    options,
  });
