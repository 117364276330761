import { gql } from '@/api/graphql-client';
import { talentFragment } from '@/api/fragments/talent';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';
import type { QueryTalentArgs, TalentQueryQuery } from '@/gql/Gql.types';

export const talentQuery = gql`
  query TalentQuery($talentId: String!) {
    talent(talentId: $talentId) {
      ...talent
    }
  }
  ${talentFragment}
`;

export const getTalent = (
  props?: QueryTalentArgs,
  options?: UseQueryOptions<TalentQueryQuery, QueryTalentArgs, TalentQueryQuery, any[]>
): UseQueryReturnType<TalentQueryQuery, QueryTalentArgs> =>
  useGraphql({
    query: talentQuery,
    variables: props,
    options,
  });
