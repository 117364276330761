<script setup lang="ts" generic="T">
import type { ITableProps } from '@/models/tableModel';
import { ref, toRefs, watch } from 'vue';
import Highlight from '@/components/atoms/Highlight.vue';

const props = withDefaults(defineProps<ITableProps<T>>(), {
  columns: () => [],
  isLoading: false,
  items: () => [],
  isSmallRow: false,
  checkable: true,
});

const { items, columns, isLoading, action, icon, isSmallRow, highlightText } = toRefs(props);

const emit = defineEmits(['selectItems', 'resetSelection', 'changePage']);

const checkedRows = ref([]);
const rowClass = () => `Table__row${isSmallRow.value ? '-small' : ''}`;

watch(checkedRows, () => emit('selectItems', checkedRows));
watch(items, () => emit('resetSelection', true));

const dataLoading = Array.from({ length: 10 }, () => ({}));
</script>

<template>
  <o-table
    v-model:checked-rows="checkedRows"
    :data="isLoading ? dataLoading : items"
    root-class="Table"
    th-class="Table__header"
    :row-class="rowClass"
    :loading="isLoading"
    backend-pagination
    backend-filtering
    :checkable="checkable"
    checkbox-position="left"
  >
    <template #loading v-if="isLoading">
      <div class="o-load">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </template>
    <o-table-column
      v-for="(column, index) in columns"
      v-bind="column"
      :key="index"
      v-slot="{ row }"
      :th-attrs="() => ({ 'data-label': column.label || 'no-tooltip' })"
      :field="column.label"
    >
      <span v-if="!column.component && !isLoading" class="text-body-2">
        <Highlight :text="row[column.field]" :highlight-text="highlightText" />
      </span>
      <component
        :is="column.component"
        v-else-if="column.component && !isLoading"
        v-bind="{ ...row[column.field], action, id: row.id, icon, highlightText }"
      />
    </o-table-column>
  </o-table>
</template>

<style scoped lang="scss">
.Table {
  :deep table {
    border-collapse: collapse;
  }

  :deep thead {
    background: $black;
    color: $white;
    height: 4.8rem;

    tr {
      th:first-child {
        border-radius: 0.4rem 0 0;
        padding-left: 2.4rem;

        &.o-table__th-checkbox {
          padding-left: 1.6rem;
        }
        .o-chk {
          filter: invert(1);

          &__check {
            width: 2rem;
            height: 2rem;
            border: 0.1rem $primary-color solid;
            border-radius: 0.4rem;

            &:hover {
              background-color: $primary-color;
            }

            &--checked {
              background-color: $primary-color;
              background-image: url('@/assets/icons/check.svg');
              background-repeat: no-repeat;
              background-size: 1.8rem 1.11rem;
            }
          }

          &__label {
            margin-left: 0.8rem;
          }
        }
      }

      th:last-child {
        border-radius: 0 0.4rem 0 0;
        padding-right: 1.6rem;
      }
    }
  }

  :deep &__row {
    .o-table__td {
      padding: 3rem 0.25rem;
      &:first-child {
        padding-left: 2.4rem;
        text-transform: capitalize;
      }
    }

    .o-table__td-checkbox {
      padding: 2rem 1rem 2rem 1.6rem;

      .o-chk {
        filter: invert(0);

        &__check {
          width: 2rem;
          height: 2rem;
          border: 0.1rem $primary-color solid;
          border-radius: 0.4rem;

          &:hover {
            background-color: $primary-color;
          }

          &--checked {
            background-color: $primary-color;
            background-image: url('@/assets/icons/check.svg');
            background-repeat: no-repeat;
            background-size: 1.8rem 1.11rem;
          }
        }

        &__label {
          margin-left: 0.8rem;
        }
      }
    }

    height: 8.1rem;
    border-right: 0.1rem solid $black-04;
    border-left: 0.1rem solid $black-04;

    &:nth-child(2n + 2) {
      background: $black-04;
      border-right: 0;
    }

    &:nth-of-type(n + 2):last-of-type {
      border-bottom: 0.1rem solid $black-04;
    }
  }

  :deep &__row-small {
    .o-table__td {
      padding-top: 2.7rem;

      &[data-label='Azioni'] {
        padding: 1.4rem 0;
      }
    }

    .o-table__td-checkbox {
      padding: 2.5rem 1rem 2rem 1.6rem;

      .o-chk {
        filter: invert(0);

        &__check {
          width: 2rem;
          height: 2rem;
          border: 0.1rem $primary-color solid;
          border-radius: 0.4rem;

          &:hover {
            background-color: $primary-color;
          }

          &--checked {
            background-color: $primary-color;
            background-image: url('@/assets/icons/check.svg');
            background-repeat: no-repeat;
            background-size: 1.8rem 1.11rem;
          }
        }

        &__label {
          margin-left: 0.8rem;
        }
      }
    }

    max-height: 7rem;
    height: 7rem;
    border: 0.1rem solid $black-04;
    border-top: 0;
  }

  :deep &__header {
    font-family: $primary-font-family;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: $primary-font-weight;
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;

    &[data-label]::before {
      $content: attr(data-label);

      background: $primary-color;
      border-radius: 1rem;
      border: 0.1rem solid $white-60;
      bottom: 3.5rem;
      color: $secondary-color;
      content: $content;
      display: block;
      opacity: 0;
      padding: 0.8rem;
      pointer-events: none;
      position: absolute;
      transition: 0.3s;
    }

    &:hover:not([data-label='no-tooltip'])::before {
      opacity: 1;
    }

    span {
      display: inline-block;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 1440px) {
        max-width: 6rem;
      }
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
