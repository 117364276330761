<script setup lang="ts">
import { ref, toRefs, computed } from 'vue';
import { useForm } from 'vee-validate';
import FormGroup from './FormGroup.vue';
import InputText from '../atoms/InputText.vue';
import Radio from '../atoms/Radio.vue';
import Button from '../atoms/Button.vue';
import addParticipantString from '@/data/AddParticipant.json';

const { title, inputs, genderLabel, radioGender, roomLabel, radioRoom, addParticipantBtn } =
  addParticipantString;

interface IAddParticipantForm {
  numParticipant: string | number;
}

const props = defineProps<IAddParticipantForm>();

const { numParticipant } = toRefs(props);

const participantNumber = computed(() => {
  return title + ' ' + numParticipant.value.toString();
});

const isWriteData = ref(false);

const writeData = (date: string, input: string) => {
  if (input === 'date') {
    isWriteData.value = date.length >= 1;
  }
};

const { handleSubmit } = useForm();

const emits = defineEmits(['addParticipantSubmit', 'resetForm']);

const onSubmit = handleSubmit((values, actions) => {
  emits('addParticipantSubmit', Object.assign(values));
  emits('resetForm', actions.resetForm);
});
</script>

<template>
  <form class="AddParticipant" @submit="onSubmit">
    <FormGroup :title="participantNumber">
      <template #formContent>
        <InputText
          :class="[input.type === 'date' ? 'inputData' : '', { 'data-write': isWriteData }]"
          v-for="(input, index) in inputs"
          :key="index"
          v-bind="{ ...input }"
          @keystart="(s: string) => writeData(s, input.type)"
        />
        <div class="AddParticipant-label text-label">{{ genderLabel }}</div>
        <div class="AddParticipant-radio">
          <Radio
            class="AddParticipant-radio"
            v-for="(radio, index) in radioGender"
            :key="index"
            v-bind="{ ...radio }"
          />
        </div>

        <div class="AddParticipant-label text-label">{{ roomLabel }}</div>
        <div class="AddParticipant-radio">
          <Radio v-for="(radio, index) in radioRoom" :key="index" v-bind="{ ...radio }" />
        </div>
      </template>
    </FormGroup>
    <Button
      class="AddParticipant-button"
      :label="addParticipantBtn.label"
      variant="primary"
      native-type="submit"
    />
  </form>
</template>

<style scoped lang="scss">
.AddParticipant {
  :deep {
    .FormGroup {
      margin-bottom: 4rem;
      &-title {
        margin-bottom: 3.2rem;
      }
      .InputText {
        margin-bottom: 2.4rem;

        .o-field__message {
          display: none;
        }
        &.inputData {
          .--error {
            .o-input {
              color: $status;
              &:before {
                content: '';
              }
            }
          }
          .o-input {
            color: transparent;
            text-transform: uppercase;

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
              display: none;
              -webkit-appearance: none;
            }

            &::before {
              content: 'DD/MM/YYYY';
              color: $black-50;
            }

            &--danger {
              color: $status;
              &::before {
                content: '';
              }
            }

            &:focus-visible {
              &:before {
                content: '';
              }
              color: $primary-color;
            }
          }
          &.data-write {
            .o-input {
              color: $primary-color;

              &::before {
                content: '';
              }
            }
          }
        }
      }
    }
  }

  &-label {
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }

  &-radio {
    &:not(:last-child) {
      margin-bottom: 3.2rem;
    }
    :deep {
      .Radio {
        color: $black-50;
        &:not(:last-child) {
          margin-right: 1.6rem;
        }
        .o-radio__check {
          border-color: $black-20;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      .error-message {
        &:not(:last-child) {
          display: none;
        }
      }
    }
  }

  &-button {
    display: block;
    margin: auto;
  }
}
</style>
