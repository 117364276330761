<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import ProjectOverview from './components/ProjectOverview.vue';
import { getDeal } from '@/api/queries/deal';

import { useRouteParams } from '@vueuse/router';
import TalentPool from './components/TalentPool.vue';
import { useRouter } from 'vue-router';

const id = useRouteParams<string>('id');

const router = useRouter();

const { data: deal } = getDeal({
  id: id.value,
});
</script>
<template>
  <div class="container">
    <Button
      class="goBack"
      icon-size="small"
      icon="chevron-left"
      label="Go back"
      variant="link-tertiary"
      @clicked="router.push(`/projects/`)"
    />
    <ProjectOverview :deal="deal?.deal" :v-if="deal?.deal" />
    <TalentPool :v-if="deal?.deal.id" :deal="deal?.deal" />
  </div>
</template>

<style lang="scss" scoped>
.container {
  font-family: $primary-font-family;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-bottom: 4rem;
}

.badge {
  background-color: $accent;
  color: white;
  text-transform: uppercase;
  padding: 0.75rem;
  border-radius: 4px;
  width: fit-content;
}

.goBack {
  margin: 0 0 1.6rem;

  :deep {
    .o-btn {
      &__wrapper {
        .o-btn__label {
          .text-link {
            @extend .text-body-1;
            padding: 0;
            margin: 0;
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
