<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';
import InputText from '../atoms/InputText.vue';
import Button from '../atoms/Button.vue';
import CheckPassword from '../atoms/CheckPassword.vue';
import generatePassword from '@/composables/generatePassword';
import type { IButtonProps } from '@/models/buttonModel';
import type { IInputProps } from '@/models/inputModel';

interface IPasswordGeneratorProps {
  cta: IButtonProps;
  input: IInputProps;
  lenghtPass: number;
  password?: string;
  required?: boolean;
  initialValue?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<IPasswordGeneratorProps>(), {
  required: true,
  initialValue: '',
});

const { cta, input, password, lenghtPass, required, initialValue, disabled } = toRefs(props);

const passwd = ref(password!.value || initialValue.value);

const rules = required.value ? { required, min: 12, password: true } : { required: false };

watch(
  () => initialValue.value,
  (newValue) => {
    passwd.value = newValue;
  }
);
</script>

<template>
  <Button
    :label="cta.label"
    @clicked="() => (passwd = generatePassword(lenghtPass))"
    :disabled="disabled"
  ></Button>
  <InputText
    :label="input.label"
    :placeholder="input.placeholder"
    :initial-value="passwd"
    type="password"
    :name="input.name"
    :rules="rules"
    :disabled="disabled"
    @keystart="(pass: string) => (passwd = pass)"
  />
  <CheckPassword :password="passwd" />
</template>
