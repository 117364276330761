<script setup lang="ts">
import { inviteAgency } from '@/api/mutations/inviteAgency';
import { migrateToTalent } from '@/api/mutations/migrateToTalent';
import { rejectAgency } from '@/api/mutations/rejectAgency';
import { removeAgencyLanguage } from '@/api/mutations/removeAgencyLanguage';
import { removeAgencyTool } from '@/api/mutations/removeAgencyTool';
import { softDeleteAgencies } from '@/api/mutations/softDeleteAgencies';
import { updateAgencyBiography } from '@/api/mutations/updateAgencyBiography';
import { updateAgencyNotes } from '@/api/mutations/updateAgencyNotes';
import Accordion from '@/components/atoms/Accordion.vue';
import Button from '@/components/atoms/Button.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import InputText from '@/components/atoms/InputText.vue';
import Modal from '@/components/atoms/Modal.vue';
import Notification from '@/components/atoms/Notification.vue';
import Radio from '@/components/atoms/Radio.vue';
import FormGroup from '@/components/molecules/FormGroup.vue';
import LinkModule from '@/components/molecules/LinkModule.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import BoxCategorySkills from '@/components/organisms/BoxCategorySkills/BoxCategorySkills.vue';
import MultiButtonDocs from '@/components/organisms/CreateUpdateForm/components/MultiButtonDocs.vue';
import MultiCheckbox from '@/components/organisms/CreateUpdateForm/components/MultiCheckbox.vue';
import { useCheckError } from '@/composables/useCheckErrorForm';
import agenciesString from '@/data/Agencies.json';
import GenericStrings from '@/data/GenericStrings.json';
import talenstString from '@/data/Talents.json';
import FormString from '@/data/UpdateAgenciesForm.json';
import {
  NAVIGATION_BACK_DEFAULT_TIME,
  NOTIFICATION_DURATION,
  mappedStatus,
} from '@/data/constants';
import type {
  Agency,
  CreateAgencyInput,
  DocumentOutputType,
  LanguageType,
  MutationRejectAgencyArgs,
  MutationSoftDeleteAgenciesArgs,
  MutationUpdateAgencyBiographyArgs,
  MutationUpdateAgencyNotesArgs,
  NotesDetailsType,
  PortfolioLinkInput,
  PortfolioLinkType,
  SpecialityType,
  ToolType,
  UpdateAgencyInput,
} from '@/gql/Gql.types';
import { Country, TalentStatus } from '@/gql/Gql.types';
import type { Expertise } from '@/models/boxCategorySkillsModel';
import { useTalentCommonData } from '@/stores/talentCommonData';
import { useUserStore } from '@/stores/user';
import moment from 'moment';
import 'moment/dist/locale/it';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import {
  computed,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  type ComputedRef,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

type actionModal = 'interview' | 'reject' | 'delete' | 'migrate';
type NotificationStatus = 'success' | 'error' | '';

const router = useRouter();
const { fullPath } = useRoute();

const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const isEdit = computed(() => fullPath.includes('edit'));

const {
  params: { id },
} = useRoute();

const {
  delete: deleteString,
  confirmDeleteButton,
  registry,
  specialitiesTitle,
  specialitiesName,
  toolsTitle,
  toolsName,
  experience,
  collaborations: collabStrings,
  note,
  biography: biographyString,
  manageAgency,
  categoryTitle,
  categoryName,
  seniorityAgencyTitle,
  seniorityAgencyName,
  workplaceTitle,
  workplaceName,
  countryTitle,
  countryName,
  industriesTitle,
  industriesName,
  languageTitle,
  languagesName,
  internationalProjects,
  paragraphModal,
  feedback,
  employees,
  additionalInfo: additionalInfoString,
} = FormString;

const {
  deleteAgenciesError,
  deleteAgenciesSuccess,
  inviteInterview,
  interviewParagraphModal,
  rejectParagraphModal,
  interviewSentModal,
  rejectError,
  rejectAgencySentModal,
  migrateAgencyPargraph,
  agency: agencyString,
} = agenciesString;

const {
  inspectDoc,
  downloadDoc,
  errorViewPDF,
  cancel,
  genericError,
  interview,
  moveIn,
  future,
  cantera,
  confirmButton,
  eligible,
  interviewSentString,
  reminderSentString,
  interviewRecapSentString,
  subscribedNewsletter,
  migrateTo,
  migrateSuccessString,
  migrateErrorsString,
  saveNote,
  successSavedNote,
  errorSavedNote,
  saveBiography,
  successSavedBiography,
  errorSavedBiography,
  user,
  lastUpdate,
} = GenericStrings;

const { talent: talentString } = talenstString;

interface AgencyProps extends Omit<Agency, 'id'> {
  submitted: boolean;
}

const props = withDefaults(defineProps<AgencyProps>(), {
  additionalInfo: '',
  biography: '',
  collaborationNotes: '',
  collaborations: () => [] as string[],
  country: '' as Country,
  companyName: '',
  createdAt: '',
  deletedAt: '',
  documents: () => [] as DocumentOutputType[],
  email: '',
  employees: '',
  experience: '',
  industries: () => [],
  internationalProjects: false,
  interviewSent: false,
  interviewRecapSent: false,
  languages: () => [] as LanguageType[],
  mobile: '',
  newsletter: false,
  notes: '',
  notesDetails: {} as (props: {}) => NotesDetailsType,
  portfolioLinks: () => [] as PortfolioLinkType[],
  rejectSent: false,
  reminderSent: false,
  search: () => [] as string[],
  specialities: () => [] as SpecialityType[],
  status: TalentStatus.NotVerified,
  termsAcceptance: false,
  tools: () => [] as ToolType[],
  updatedAt: '',
  vat: '',
  website: '',
  workplace: '',
  submitted: false,
});

const confirmDelete = ref(false);
const actionOpenAgencyModal = ref(false);
const refsProps = toRefs(props);
const docsModal = ref(false);
const technologyId = ref();
const expertise = ref();
const urlPdf = ref();
const typeModal = ref<actionModal>();
const sendingActionAgency = ref(false);
const talentStatus = ref(props.status);
const confirmMigrate = ref(false);
const isNoteSaved = ref(false);
const isBiographySaved = ref(false);

const talentCommonData = useTalentCommonData();
const commonData = storeToRefs(talentCommonData);

const categories = computed(() => commonData.status.value || []);
const collaborations = computed(() => commonData.collaborations.value || []);
const commonLangs = computed(() => commonData.languages.value || []);
const industries = computed(() => commonData.industries.value || []);
const seniorities = computed(() => commonData.seniorities.value || []);
const specialities = computed(() => commonData.specialities.value || []);
const tools = computed(() => commonData.tools.value || []);
const workplaces = computed(() => commonData.workplaces.value || []);
const countries = computed(() => commonData.countries.value || []);

const allSelectedCollaborations = ref(refsProps.collaborations?.value || []);
const allSelectedEmployees = ref(refsProps.employees?.value || '');
const allSelectedSpecialities = ref(refsProps.specialities?.value || []);
const allSelectedTools = ref(refsProps.tools?.value || []);
const allSelectedPortfolioLinks = ref(refsProps.portfolioLinks?.value || []);
const allSelectedLanguages = ref(refsProps.languages?.value || []);
const allSelectedIndustries = ref(refsProps.industries?.value || []);
const internationalProjectsSelected = ref(refsProps.internationalProjects.value || false);
const documents = ref(refsProps.documents.value || []);
const biography = ref(refsProps.biography.value || '');
const notes = ref(refsProps.notes?.value || '');
const category = ref(refsProps.status?.value || '');
const seniority = ref(refsProps.experience?.value || '');
const workplace = ref(refsProps.workplace?.value || '');
const selectedCountry = ref(refsProps.country?.value || '');
const additionalInfo = ref(refsProps.additionalInfo?.value || '');
const notesDetails = ref(refsProps.notesDetails.value || {});

watch(
  () => refsProps.specialities.value,
  (newSpecialities) => {
    allSelectedSpecialities.value = [...newSpecialities];
  }
);

watch(
  () => refsProps.languages.value,
  (newLanguage) => {
    allSelectedLanguages.value = [...newLanguage];
  }
);

watch(
  () => refsProps.documents.value,
  (newDoc) => {
    documents.value = [...newDoc];
  }
);

watch(
  () => refsProps.portfolioLinks.value,
  (newPortfolio) => {
    allSelectedPortfolioLinks.value = [...newPortfolio];
  }
);

const fullname = computed(() => refsProps.companyName);

const showFeedback = computed(() => {
  const feedbacks = [];

  if (props.interviewSent) {
    feedbacks.push(interviewSentString);
  }

  if (props.reminderSent) {
    feedbacks.push(reminderSentString);
  }

  if (props.rejectSent) {
    feedbacks.push(feedback);
  }

  if (props.interviewRecapSent) {
    feedbacks.push(interviewRecapSentString);
  }

  return feedbacks;
});

const allWorkplaces = computed(() => {
  if (workplaces.value.includes(workplace.value) && isEdit.value) return workplaces.value;
  const newWorkplaces = [...workplaces.value];
  workplace.value ? newWorkplaces.push(workplace.value) : '';
  if (!isEdit.value) {
    newWorkplaces.sort();
    return newWorkplaces;
  }
  return newWorkplaces.sort();
});

onMounted(() => {
  watchEffect(() => {
    if (workplace.value && allWorkplaces.value.includes(workplace.value)) {
      const place = document.querySelector(
        '.AgenciesUpdateForm__workplaces-items .o-radio--checked'
      );
      const workplaceContainer = document.querySelector('.AgenciesUpdateForm__workplaces-group');
      const scrollToY =
        (place as HTMLInputElement)?.offsetTop -
        (workplaceContainer as HTMLElement)?.clientHeight / 2 +
        (place as HTMLInputElement)?.clientHeight / 2;

      workplaceContainer?.scrollTo({
        top: scrollToY,
        behavior: 'smooth',
      });
    }
  });
});

/**
 * Set the initialValue of Agency.expertise
 */
const initialValueExpertiseAgency = computed(() => {
  if (!allSelectedSpecialities.value.length && !allSelectedTools.value.length)
    return [
      {
        name: 'Project Management & Data',
        skills: [],
        technologies: [],
      },
      {
        name: 'Communication',
        skills: [],
        technologies: [],
      },
      {
        name: 'Coding',
        skills: [],
        technologies: [],
      },
      {
        name: 'Design',
        skills: [],
        technologies: [],
      },
    ];

  return allSelectedSpecialities.value.flatMap((expertise) => {
    const matchingExpertise = allSelectedTools.value.find(
      (tool) => tool.expertise === expertise.expertise
    );

    const technologyInput = matchingExpertise?.technologies.flatMap((tech) => {
      return {
        level: tech.level,
        technology: tech.technology,
      };
    });

    if (matchingExpertise) {
      return {
        name: expertise.expertise,
        skills: expertise.skills,
        technologies: technologyInput,
      };
    }
    return [];
  });
});

/**
 * Set the initialValue of Agency.languages
 */
const initialvalueLanguageAgency = computed(() => {
  if (!allSelectedLanguages.value.length) return [];

  return allSelectedLanguages.value.map((lang) => ({
    language: lang.language,
    level: lang.level,
  }));
});

const initialValuePortfolioAgency = computed(() => {
  if (!allSelectedPortfolioLinks.value.length) return [];

  return allSelectedPortfolioLinks.value.reduce((acc, curr) => {
    if (curr.link && curr.url) {
      acc.push({
        link: curr.link,
        url: curr.url,
      });
    }
    return acc;
  }, [] as PortfolioLinkInput[]);
});

const agency = reactive<UpdateAgencyInput | CreateAgencyInput>({
  expertise: initialValueExpertiseAgency.value,
  languages: initialvalueLanguageAgency.value,
  portfolioLinks: initialValuePortfolioAgency.value,
});

!isEdit.value ? ((agency as CreateAgencyInput).termsAcceptance = true) : '';

const { handleSubmit, errors } = useForm({ initialValues: { ...agency } });

const emits = defineEmits([
  'agencyData',
  'resetForm',
  'updateDocument',
  'manageAgency',
  'savedNotes',
  'savedBiography',
]);

const onSubmit = handleSubmit((values, actions) => {
  emits('agencyData', deleteEmptyInputValues(Object.assign(values, agency)));
  emits('resetForm', actions.resetForm);
});

const deleteEmptyInputValues = (input: UpdateAgencyInput | CreateAgencyInput) => {
  const result = { ...input };

  !isEdit.value ? delete (result as UpdateAgencyInput).status : '';

  isEdit.value ? delete (result as CreateAgencyInput).cv : '';
  isEdit.value ? delete (result as CreateAgencyInput).portfolio : '';

  if ('tools' in result) {
    delete result.tools;
  }

  if ('specialities' in result) {
    delete result.specialities;
  }

  if ('internationalProjects' in result) {
    result.internationalProjects = internationalProjectsSelected.value;
  }

  for (const [prop, value] of Object.entries(result)) {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (prop !== 'cv' &&
        prop !== 'portfolioLinks' &&
        typeof value === 'object' &&
        Object.keys(value).length === 0 &&
        !(value instanceof File))
    ) {
      delete result[prop as keyof typeof input];
    }
  }

  return result;
};

watch(errors, () => {
  useCheckError();
});

watch(refsProps.submitted, () => {
  if (refsProps.submitted.value) {
    onSubmit();
  }
});

const agencyId = { agencyIds: id };

const enabledActionAgency = (isInterview: boolean): ComputedRef<boolean> =>
  computed(() => sendingActionAgency.value && isInterview === (typeModal.value === 'interview'));

const {
  isSuccess: successSentInterview,
  isError: errorSentInterview,
  remove: removeSentInterval,
} = inviteAgency(
  { agencyId: id as string },
  {
    enabled: enabledActionAgency(true),
  }
);

const paramReject = reactive<MutationRejectAgencyArgs>({
  status: talentStatus.value as TalentStatus,
  agencyId: id as string,
});

watch(talentStatus, () => {
  paramReject.status = talentStatus.value as TalentStatus;
});

const {
  isSuccess: successRejectAgency,
  isError: errorRejectAgency,
  remove: removeRejectAgency,
} = rejectAgency(paramReject, {
  enabled: enabledActionAgency(false),
});

const enabledMigrateAgency = computed(() => confirmMigrate.value && typeModal.value === 'migrate');

const {
  isSuccess: successMigrate,
  isError: errorMigrate,
  remove: removeMigrate,
} = migrateToTalent(
  { agencyId: agencyId.agencyIds as string },
  {
    enabled: enabledMigrateAgency,
  }
);

const enableDeleteAgency = computed(() => confirmDelete.value && typeModal.value === 'delete');

const {
  isSuccess: successSoftDelete,
  isError: errorSoftDelete,
  remove: removeSoftDelete,
} = softDeleteAgencies(agencyId as MutationSoftDeleteAgenciesArgs, {
  enabled: enableDeleteAgency,
});

// init update notes
const paramsUpdateNote = reactive<MutationUpdateAgencyNotesArgs>({
  agencyId: id as string,
  notes: notes.value,
});

const enabledSaveNote = computed(() => isNoteSaved.value && paramsUpdateNote.notes.length > 0);

watch(notes, () => {
  paramsUpdateNote.notes = notes.value.replace(/<[^>]+>/g, '');
});

// Update Agency Notes
const {
  isSuccess: successuUpdateNote,
  isError: errorUpdateNote,
  remove: removeUpdateNote,
} = updateAgencyNotes(paramsUpdateNote, {
  enabled: enabledSaveNote,
});

// init update biography
const paramsUpdateBiography = reactive<MutationUpdateAgencyBiographyArgs>({
  agencyId: id as string,
  biography: biography.value,
});

const enabledSaveBiography = computed(
  () => isBiographySaved.value && paramsUpdateBiography.biography.length > 0
);

watch(biography, () => {
  paramsUpdateBiography.biography = biography.value;
});

// Update Agency Biographys
const {
  isSuccess: successUpdateBiography,
  isError: errorUpdateBiography,
  remove: removeUpdateBiography,
} = updateAgencyBiography(paramsUpdateBiography, {
  enabled: enabledSaveBiography,
});

watch(
  [
    successSentInterview,
    successRejectAgency,
    successSoftDelete,
    successuUpdateNote,
    successMigrate,
    successUpdateBiography,
  ],
  () => {
    switch (true) {
      case successSentInterview.value:
        emits('manageAgency', successSentInterview.value);
        actionOpenAgencyModal.value = false;
        break;

      case successRejectAgency.value:
        emits('manageAgency', successRejectAgency.value);
        actionOpenAgencyModal.value = false;
        break;

      case successMigrate.value:
        setTimeout(() => {
          removeSoftDelete();
          router.push('/agencies');
        }, NAVIGATION_BACK_DEFAULT_TIME);
        actionOpenAgencyModal.value = false;
        break;

      case successSoftDelete.value:
        setTimeout(() => {
          removeSoftDelete();
          router.back();
        }, NAVIGATION_BACK_DEFAULT_TIME);
        actionOpenAgencyModal.value = false;
        break;

      case successuUpdateNote.value:
        emits('savedNotes', successuUpdateNote.value);
        break;

      case successUpdateBiography.value:
        emits('savedBiography', successUpdateBiography.value);
        break;

      default:
        actionOpenAgencyModal.value = false;
        return;
    }
  }
);

const showCorrectModaltext = computed(() => {
  switch (typeModal.value) {
    case 'interview':
      return {
        head: inviteInterview,
        body: interviewParagraphModal,
        confirm: confirmButton,
      };

    case 'reject':
      return {
        head: moveIn + ' ' + (talentStatus.value === 'FUTURE' ? future : cantera),
        body: rejectParagraphModal + ' ' + (talentStatus.value === 'FUTURE' ? future : cantera),
        confirm: confirmButton,
      };

    case 'migrate':
      return {
        head: migrateTo + ' ' + talentString,
        body: migrateAgencyPargraph,
        confirm: confirmButton,
      };

    case 'delete':
      return {
        head: deleteString,
        body: paragraphModal,
        confirm: confirmDeleteButton,
      };

    default:
      return {
        head: '',
        body: genericError,
        confirm: '',
      };
  }
});

const messageNotification = computed(() => {
  switch (true) {
    case successSentInterview.value:
      return {
        status: 'success',
        message: interviewSentModal,
      };

    case errorSentInterview.value:
      removeSentInterval();
      return {
        status: 'error',
        message: rejectError,
      };

    case successRejectAgency.value:
      return {
        status: 'success',
        message: rejectAgencySentModal + ' ' + (talentStatus.value === 'FUTURE' ? future : cantera),
      };

    case errorRejectAgency.value:
      removeRejectAgency();
      return {
        status: 'error',
        message: rejectError,
      };

    case successSoftDelete.value:
      return {
        status: 'success',
        message: deleteAgenciesSuccess,
      };

    case errorSoftDelete.value:
      removeSoftDelete();
      return {
        status: 'error',
        message: deleteAgenciesError,
      };

    case successuUpdateNote.value:
      removeUpdateNote();
      return {
        status: 'success',
        message: successSavedNote,
      };

    case errorUpdateNote.value:
      removeUpdateNote();
      return {
        status: 'error',
        message: errorSavedNote,
      };

    case successMigrate.value:
      return {
        status: 'success',
        message: migrateSuccessString.replace('{name}', agencyString),
      };

    case errorMigrate.value:
      removeMigrate();
      return {
        status: 'error',
        message: migrateErrorsString,
      };

    case successUpdateBiography.value:
      removeUpdateBiography();
      return {
        status: 'success',
        message: successSavedBiography,
      };

    case errorUpdateBiography.value:
      return {
        status: 'error',
        message: errorSavedBiography,
      };

    default:
      return {
        status: 'error',
        message: genericError,
      };
  }
});

watch([successuUpdateNote, successUpdateBiography], () => {
  if (isNoteSaved.value) {
    setTimeout(() => {
      isNoteSaved.value = false;
    }, NOTIFICATION_DURATION);
  }

  if (isBiographySaved.value) {
    setTimeout(() => {
      isBiographySaved.value = false;
    }, NOTIFICATION_DURATION);
  }
});

const selectedSpecialitiesByExpertise = computed(
  () => (expertise: string) =>
    (allSelectedSpecialities?.value || []).find((el) => el.expertise === expertise)?.skills || []
);

const addSkill = (expertiseSkill: string, skill: string) => {
  expertise.value = expertiseSkill;

  const currentSpecialities = allSelectedSpecialities.value || [];
  const matchingIndex = currentSpecialities.findIndex((item) => item.expertise === expertiseSkill);

  const updatedSpecialities = [...currentSpecialities];

  if (matchingIndex !== -1) {
    updatedSpecialities[matchingIndex] = {
      expertise: expertiseSkill,
      skills: !currentSpecialities[matchingIndex].skills.includes(skill)
        ? [...currentSpecialities[matchingIndex].skills, skill]
        : currentSpecialities[matchingIndex].skills.filter((el) => el !== skill),
    };
  } else {
    updatedSpecialities.push({
      expertise: expertiseSkill,
      skills: [skill],
    });
  }

  allSelectedSpecialities.value = updatedSpecialities;

  const agencyExpertise = agency.expertise?.find(
    (agencyExpertise) => agencyExpertise.name === expertiseSkill
  );
  if (agencyExpertise) {
    agencyExpertise.skills = [...selectedSpecialitiesByExpertise.value(expertiseSkill)];
  }
};

// It is only used for validation
const selectedSkills = computed(() => {
  return allSelectedSpecialities.value
    .map((item) => {
      const filteredSkills = item.skills.filter(Boolean);

      return filteredSkills.length > 0
        ? { expertise: item.expertise, skills: filteredSkills }
        : undefined;
    })
    .filter(Boolean);
});

const technologies = computed(
  () =>
    tools.value?.map(({ expertise, technologies }) => ({
      expertise,
      technologies: technologies.map((technology) => ({
        technology: technology,
        technologyId:
          (allSelectedTools.value || [])
            .find((el) => el.expertise === expertise)
            ?.technologies?.find((el) => el.technology === technology)?.technologyId ?? '',
        level:
          (allSelectedTools.value || [])
            .find((el) => el.expertise === expertise)
            ?.technologies?.find((el) => el.technology === technology)?.level ?? '',
      })),
    })) || []
);

const languages = computed(() => {
  if (commonLangs.value.length < 1) return [];
  return [
    {
      expertise: languageTitle,
      technologies: commonLangs.value?.map((lang) => ({
        technology: lang,
        languageId:
          (allSelectedLanguages.value || []).find((el) => el.language === lang)?.languageId ?? '',
        level: (allSelectedLanguages.value || []).find((el) => el.language === lang)?.level ?? '',
      })),
    },
  ];
});

const getInitialValue = (name: string) => refsProps[name as keyof typeof refsProps]?.value ?? '';

const addPortfolios = (portfolioLinksUpdated: PortfolioLinkInput[]) =>
  (agency.portfolioLinks = portfolioLinksUpdated.map((portfolio) => {
    return {
      link: portfolio.link,
      url: portfolio.url,
    };
  }));

const addLanguages = (languagesUpdated: Expertise[]) =>
  (agency.languages = languagesUpdated[0].technologies
    .map(({ level, technology: language }) => ({
      level,
      language,
    }))
    .filter((lang) => lang.level.length > 0));

/**
 * for collaborations
 */
const initialCheckCollaborations = computed(() =>
  collaborations.value.filter((partner) => allSelectedCollaborations.value.includes(partner))
);

/**
 * for industries
 */
const initialCheckIndustries = computed(() =>
  industries.value.filter((industry) => allSelectedIndustries.value.includes(industry))
);

const addTechnologies = (expertisesUpdated: Expertise[]) => {
  expertisesUpdated.forEach((expertise) => {
    const selectedTechnology = expertise.technologies
      .flat()
      .filter((technology) => technology.level.length > 0)
      .map((obj) => {
        const { technologyId, ...rest } = obj;
        return rest;
      });

    const agencyExpertise = agency.expertise?.find(
      (agencyExpertise) => agencyExpertise.name === expertise.expertise
    );

    if (agencyExpertise) {
      agencyExpertise.technologies = [...selectedTechnology];
    }
  });
};

const isToolsExpertise = computed(() =>
  technologies.value.some((tech) => tech.expertise === expertise.value)
);

const removeTool = reactive({
  agencyId: id as string,
  technologyId: '',
});

const removeLanguage = reactive({
  agencyId: id as string,
  languageId: '',
});

watch(technologyId, () => {
  if (isToolsExpertise.value) {
    removeTool.technologyId = technologyId.value;
    removeLanguage.languageId = '';
  } else {
    removeLanguage.languageId = technologyId.value;
    removeTool.technologyId = '';
  }
});

const enableDeleteBoxCategory = (isTechnology: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      isTechnology === isToolsExpertise.value &&
      !!technologyId.value &&
      (removeTool.technologyId.length > 0 || removeLanguage.languageId.length > 0)
  );

const {
  isSuccess: successRemoveTool,
  isError: errorRemoveTool,
  remove: removeSuccessTool,
} = removeAgencyTool(removeTool, {
  enabled: enableDeleteBoxCategory(true),
});

const {
  isSuccess: successRemoveLanguage,
  isError: errorRemoveLanguage,
  remove: removeSuccessLanguage,
} = removeAgencyLanguage(removeLanguage, {
  enabled: enableDeleteBoxCategory(false),
});

watch([successRemoveTool, successRemoveLanguage], () => {
  if (successRemoveTool.value) {
    removeSuccessTool();
  }
  if (successRemoveLanguage.value) {
    removeSuccessLanguage();
  }
});
</script>

<template>
  <form class="container-fluid" @submit="onSubmit">
    <div class="AgenciesUpdateForm col-6">
      <div
        class="AgenciesUpdateForm__feedback text-body-1"
        v-for="(feedback, index) in showFeedback"
        :key="index"
      >
        {{ feedback }}
      </div>
      <div class="AgenciesUpdateForm__feedback text-body-1" v-if="props.newsletter">
        <span class="text-body-1">{{ subscribedNewsletter }}</span>
      </div>
      <FormGroup :title="registry.title">
        <template #formContent>
          <InputText
            v-for="(input, index) in registry.input"
            :key="index"
            :label="input.label"
            :placeholder="input.placeholder"
            :initial-value="getInitialValue(input.name)"
            :name="input.name"
            :type="`${input.name === 'telephone' ? 'tel' : 'text'}`"
            :rules="input.rules"
            :disabled="isEdit && userRole !== 'ADMIN'"
          />
          <h3 class="AgencyUpdateForm__employees-title heading-h5">
            {{ employees.title }}
          </h3>
          <div class="AgenciesUpdateForm__employees-radio">
            <Radio
              v-for="(option, index) in employees.options"
              as="radio"
              :key="index + option"
              :name="employees.name"
              :label="option"
              :value="option"
              :initial-value="allSelectedEmployees"
              :rules="{ required: true }"
            />
          </div>
        </template>
      </FormGroup>
      <FormGroup :title="specialitiesTitle">
        <template #formContent>
          <Field
            v-model="selectedSkills"
            v-slot="{ field, errors }"
            :name="specialitiesName"
            :rules="{ required: true }"
          >
            <Accordion
              v-for="(speciality, index) in specialities"
              :key="index"
              :index="index"
              :label="speciality.expertise"
              :count="selectedSpecialitiesByExpertise(speciality.expertise).length"
              has-count
            >
              <template #content>
                <div class="AgenciesUpdateForm__specialities-checkbox">
                  <o-inputitems v-model="selectedSkills" v-bind="field" />
                  <Checkbox
                    v-for="(item, i) in speciality.skills"
                    :key="i"
                    as="checkbox"
                    :initial-value="
                      selectedSpecialitiesByExpertise(speciality.expertise).includes(item)
                    "
                    :name="item"
                    :label="item"
                    :standalone="true"
                    @changed="addSkill(speciality.expertise, item)"
                  />
                </div>
              </template>
            </Accordion>
            <p class="error-message text-body-2" v-if="errors[0]">
              {{ errors[0] }}
            </p>
          </Field>
        </template>
      </FormGroup>
      <FormGroup :title="toolsTitle">
        <template #formContent>
          <BoxCategorySkills
            v-if="technologies.length > 0"
            :expertises="technologies"
            :name="toolsName"
            :talent-name="fullname.value"
            :talent-mail="refsProps.email.value"
            :success-delete-skill="successRemoveTool"
            :enabled-add-skills="allSelectedSpecialities"
            @technologies="addTechnologies"
            @technology-id="([id, category]) => ((technologyId = id), (expertise = category))"
            :rules="{ required: true }"
          />
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__experience" :title="experience.title">
        <template #formContent>
          <LinkModule
            :portfolio-links="allSelectedPortfolioLinks"
            @portfolios="addPortfolios"
            :rules="{ required: false }"
          />
          <MultiButtonDocs
            v-bind="{ ...experience.documentation }"
            :documents="documents"
            @open-modal-c-v="([open, url]) => ((docsModal = open), (urlPdf = url))"
            @update-doc="(doc) => $emit('updateDocument', doc)"
          />
          <Modal
            class="popupDocs"
            :is-modal-active="docsModal"
            @close-modal="docsModal = false"
            :head="inspectDoc"
          >
            <template #modal-main>
              <div class="popupDocs__title heading-h4">{{ fullname }}</div>
              <div class="popupDocs__subtitle text-body-1">CV</div>
              <div class="popupDocs__pdf">
                <iframe
                  v-if="urlPdf"
                  width="500px"
                  height="700px"
                  frameBorder="0"
                  :src="urlPdf + '#toolbar=0'"
                >
                </iframe>
                <p v-else class="text-body-1">{{ errorViewPDF }}</p>
              </div>
            </template>

            <template #modal-footer>
              <component
                :is="Button"
                :label="downloadDoc"
                tag="a"
                :to="urlPdf"
                target="_blank"
                :download="urlPdf"
              ></component>
            </template>
          </Modal>
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__collabs" :title="collabStrings.title">
        <template #formContent>
          <MultiCheckbox
            v-if="isEdit ? initialCheckIndustries : true"
            :name="collabStrings.name"
            :initial-checked="initialCheckCollaborations"
            :options="collaborations"
          />
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__industries" :title="industriesTitle">
        <template #formContent>
          <MultiCheckbox
            v-if="isEdit ? initialCheckIndustries : true"
            :name="industriesName"
            :initial-checked="initialCheckIndustries"
            :options="industries"
          />
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__intProject" :title="internationalProjects.title">
        <template #formContent>
          <div class="AgenciesUpdateForm__intProject-radio">
            <Radio
              v-for="(option, index) in internationalProjects.options"
              as="radio"
              :name="internationalProjects.name"
              :key="index + option"
              :label="option === 'Si' ? 'Yes' : option"
              :value="option"
              :initial-value="internationalProjectsSelected ? 'Si' : 'No'"
              @changed="(val) => (internationalProjectsSelected = val === 'Si' ? true : false)"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup :title="languageTitle">
        <template #formContent>
          <BoxCategorySkills
            v-if="commonLangs.length > 0"
            :name="languagesName"
            :expertises="languages"
            :success-delete-skill="successRemoveLanguage"
            @technologies="addLanguages"
            @technology-id="([id, category]) => ((technologyId = id), (expertise = category))"
            :rules="{ required: true }"
          />
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__note" :title="note.title">
        <template #formContent>
          <div class="AgenciesUpdateForm__note__details mb-1" v-if="notesDetails.userId">
            <p class="AgenciesUpdateForm__note__details--info">
              {{ lastUpdate }}
              {{ moment(notesDetails.updatedAt).locale('it').format('YYYY-MM-DD, ddd H:mm') }}
            </p>
            <p class="AgenciesUpdateForm__note__details--info">
              {{ user }}: {{ notesDetails.firstName }} {{ notesDetails.lastName }}
            </p>
            <p class="AgenciesUpdateForm__note__details--info">
              {{ user }} ID: {{ notesDetails.userId }}
            </p>
          </div>
          <TextAreaForm
            class="AgenciesUpdateForm__note--textArea"
            :name="note.name"
            :placeholder="note.placeholder"
            :label="note.paragraph"
            :initial-value="notes"
            :label-btn="isEdit ? saveNote : ''"
            @clicked="() => (isNoteSaved = true)"
            @keystart="(s: string) => ( notes = s )"
          />
        </template>
      </FormGroup>

      <FormGroup :title="biographyString.title">
        <template #formContent>
          <TextAreaForm
            :name="biographyString.name"
            :placeholder="biographyString.placeholder"
            :label="biographyString.paragraph"
            :initial-value="biography"
            :label-btn="isEdit ? saveBiography : ''"
            @clicked="() => (isBiographySaved = true)"
            @keystart="(s: string) => ( biography = s )"
          />
        </template>
      </FormGroup>

      <FormGroup :title="additionalInfoString.title">
        <template #formContent>
          <TextAreaForm
            :name="additionalInfoString.name"
            :placeholder="additionalInfoString.placeholder"
            :label="additionalInfoString.paragraph"
            :initial-value="additionalInfo"
          />
        </template>
      </FormGroup>
    </div>

    <div class="AgenciesUpdateForm col-5">
      <FormGroup class="AgenciesUpdateForm__manageAgency" :title="manageAgency.title" v-if="isEdit">
        <template #formContent>
          <div class="AgenciesUpdateForm__manageAgency__interview" v-if="status === 'INBOX'">
            <Button
              :label="interview"
              @clicked="() => ((actionOpenAgencyModal = true), (typeModal = 'interview'))"
            />
          </div>
          <div class="AgenciesUpdateForm__manageAgency__reject" v-if="status === 'INBOX'">
            <div class="AgenciesUpdateForm__manageAgency__reject-title text-body-1">
              {{ moveIn }}
            </div>
            <div class="AgenciesUpdateForm__manageAgency__reject-buttons">
              <Button
                :label="future"
                variant="link-tertiary"
                @clicked="
                  () => (
                    (actionOpenAgencyModal = true),
                    (typeModal = 'reject'),
                    (talentStatus = TalentStatus.Future)
                  )
                "
              />
              <Button
                :label="cantera"
                variant="link-tertiary"
                @clicked="
                  () => (
                    (actionOpenAgencyModal = true),
                    (typeModal = 'reject'),
                    (talentStatus = TalentStatus.Cantera)
                  )
                "
              />
              <Button
                :label="eligible"
                variant="link-tertiary"
                @clicked="
                  () => (
                    (actionOpenAgencyModal = true),
                    (typeModal = 'reject'),
                    (talentStatus = TalentStatus.Eligible)
                  )
                "
              />
            </div>
          </div>
          <div class="AgenciesUpdateForm__manageAgency__migrate">
            <h6 class="AgenciesUpdateForm__manageAgency__migrate-title text-body-1">
              {{ migrateTo }}
            </h6>
            <Button
              class="AgenciesUpdateForm__manageAgency__migrate-button"
              variant="link-tertiary"
              :label="talentString"
              @clicked="() => ((actionOpenAgencyModal = true), (typeModal = 'migrate'))"
            />
          </div>
          <div class="AgenciesUpdateForm__manageAgency__delete">
            <h6 class="AgenciesUpdateForm__manageAgency__delete-title text-body-1">
              {{ manageAgency.deleteAgency }}
            </h6>
            <Button
              class="AgenciesUpdateForm__manageAgency__delete-button"
              variant="link-tertiary-status"
              :label="manageAgency.deleteButton"
              @clicked="() => ((actionOpenAgencyModal = true), (typeModal = 'delete'))"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__categoryAgency" :title="categoryTitle">
        <template #formContent>
          <div
            class="AgenciesUpdateForm__categoryAgency-items"
            v-for="(status, index) in categories"
            :key="index"
          >
            <Radio
              as="radio"
              :label="mappedStatus[status]"
              :name="categoryName"
              :initial-value="category"
              :value="status"
              @changed="(val) => (category = val)"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__seniorityAgency" :title="seniorityAgencyTitle">
        <template #formContent>
          <div
            class="AgenciesUpdateForm__seniorityAgency-items"
            v-for="(year, index) in seniorities"
            :key="index"
          >
            <Radio
              as="radio"
              :value="year"
              :initial-value="seniority"
              :label="year"
              :name="seniorityAgencyName"
              @changed="(val) => (seniority = val)"
              :rules="{ required: true }"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup class="AgenciesUpdateForm__workplaces" :title="workplaceTitle">
        <template #formContent>
          <div class="AgenciesUpdateForm__workplaces-group">
            <!--<div
              class="AgenciesUpdateForm__workplaces-items"
              v-for="(place, index) in allWorkplaces"
              :key="index"
            >
              <Radio
                as="radio"
                :label="place"
                :name="workplaceName"
                :value="place"
                :initial-value="workplace"
                :rules="{ required: false }"
              />
            </div>-->
            <InputText placeholder="Workplace" :initial-value="workplace" :name="workplaceName" />
          </div>
        </template>
      </FormGroup>
      <FormGroup class="AgenciesUpdateForm__country" :title="countryTitle">
        <template #formContent>
          <div class="AgenciesUpdateForm__country-group">
            <div
              class="AgenciesUpdateForm__country-items"
              v-for="(country, index) in countries"
              :key="index"
            >
              <Radio
                as="radio"
                :label="country"
                :name="countryName"
                :value="country"
                :initial-value="selectedCountry"
                :rules="{ required: true }"
              />
            </div>
          </div>
        </template>
      </FormGroup>
    </div>
  </form>

  <Modal
    :is-modal-active="actionOpenAgencyModal"
    @close-modal="actionOpenAgencyModal = false"
    :head="showCorrectModaltext.head"
  >
    <template #modal-main>
      <div class="popupInterview">
        <div class="popupInterview-main">
          <p class="popupInterview-main-paragraph heading-h4">
            {{ showCorrectModaltext.body }}
          </p>
        </div>
      </div>
    </template>

    <template #modal-footer v-if="showCorrectModaltext.confirm.length > 1">
      <component
        :is="Button"
        variant="secondary"
        :label="cancel"
        @clicked="actionOpenAgencyModal = false"
      ></component>
      <component
        :is="Button"
        variant="primary"
        :label="showCorrectModaltext.confirm"
        @clicked="
          typeModal === 'migrate'
            ? (confirmMigrate = true)
            : typeModal === 'delete'
            ? (confirmDelete = true)
            : (sendingActionAgency = true)
        "
      ></component>
    </template>
  </Modal>

  <Notification
    v-if="
      successSentInterview ||
      errorSentInterview ||
      successRejectAgency ||
      errorRejectAgency ||
      successSoftDelete ||
      errorSoftDelete ||
      successMigrate ||
      errorMigrate ||
      errorRemoveTool ||
      errorRemoveLanguage ||
      successuUpdateNote ||
      errorUpdateNote ||
      successUpdateBiography ||
      errorUpdateBiography
    "
    :message="messageNotification.message"
    :status="(messageNotification.status as NotificationStatus)"
    :duration="NOTIFICATION_DURATION"
  />
</template>

<style scoped lang="scss">
.AgenciesUpdateForm {
  &__feedback {
    display: list-item;
    margin: 0 0 2rem 2.4rem;
    position: relative;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.1rem;
      left: -2rem;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: $accent;
      background-image: url(@/assets/icons/check.svg);
      background-repeat: no-repeat;
      background-size: 1.2rem 1.2rem;
      background-position: center;
    }
  }

  &__employees {
    &-title {
      margin: 0 0 1.6rem;
    }

    &-radio {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;

      :deep .error-message {
        display: none;

        &:last-of-type {
          display: block;
        }
      }
    }
  }

  &__specialities {
    &-checkbox {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;
      margin-bottom: 2.4rem;

      :deep .o-inputit {
        display: none;
      }
    }
  }

  &__experience {
    &--status {
      &-title {
        margin: 0 0 1.6rem;
      }

      &-radio {
        @include flexing(row, start, center);
        gap: 1.6rem;
        flex-wrap: wrap;

        :deep .error-message {
          display: none;

          &:last-of-type {
            display: block;
          }
        }
      }
    }
  }

  &__intProject {
    &-title {
      margin: 0 0 1.6rem;
    }

    &-radio {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;

      :deep .error-message {
        display: none;

        &:last-of-type {
          display: block;
        }
      }
    }
  }

  &__collabs {
    &-checkbox {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;
      margin-bottom: 2.4rem;
    }
  }

  &__industries {
    &-checkbox {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;
    }
  }

  &__note {
    &__details {
      &--info {
        @extend .text-body-2;
        margin: 1rem 0;
      }
    }
    &--textArea {
      margin-bottom: 2.4rem;
    }
  }

  &__manageAgency {
    &__interview {
      margin-bottom: 1.6rem;
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid $black-20;
    }

    &__reject {
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid $black-20;

      &-title {
        margin-bottom: 0.8rem;
      }

      &-buttons {
        @include flexing(row, start, start);
        gap: 1.6rem;

        :deep .Button {
          &.--no-icon.o-btn--link-tertiary {
            min-height: auto;
          }
        }
      }
    }

    &__migrate {
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid $black-20;
    }

    &__migrate,
    &__delete {
      :deep .FormGroup-title {
        padding-bottom: 2.4rem;
        border-bottom: 0.1rem solid $black-20;
      }

      &-title {
        margin: 0 0 0.8rem;
      }

      &-button {
        :deep &.Button {
          &.--no-icon {
            display: inline-block;
            min-width: auto;
            min-height: auto;
          }
        }
      }
    }
  }

  &__categoryAgency,
  &__seniorityAgency {
    :deep .FormGroup-title,
    &-items {
      margin-bottom: 2.4rem;
    }
  }

  &__seniorityAgency {
    &-items {
      :deep &:not(:last-of-type) .error-message {
        display: none;
      }
    }
  }

  &__workplaces,
  &__country {
    position: relative;

    &-items {
      :deep &:not(:first-of-type) .error-message {
        display: none;
      }

      :deep .error-message {
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
      }
    }
  }

  &.col-5 {
    grid-column-start: 8;
  }

  &__workplaces,
  &__country {
    &-group {
      height: 30.7rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0.7rem;
      }

      &::-webkit-scrollbar-thumb {
        background: $black-20;
        border-radius: 4rem;
        background-clip: padding-box;
      }

      &::-webkit-scrollbar-track {
        margin-block: 0.8rem;
      }
    }

    :deep .FormGroup-title,
    &-items {
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }
  }

  &__country {
    &-group {
      height: max-content;
    }
  }
}

.popupInterview {
  text-align: center;
  margin: auto;

  &-skeleton {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.8rem;

    &.--title {
      :deep(.o-sklt__item) {
        height: 3rem;
      }

      width: 40%;
    }

    &.--subtitle {
      :deep(.o-sklt__item) {
        height: 2.5rem;
      }

      width: 60%;
    }
  }

  &-title {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  &-subtitle {
    color: $black-50;
  }

  &-main {
    margin: 3.2rem 0;
    padding: 0 2.5rem;

    &-paragraph {
      margin: 0;
    }
  }
}

.popupDocs {
  text-align: center;

  &__title {
    margin-bottom: 0.8rem;
  }

  &__subtitle {
    color: $black-50;
  }

  &__pdf {
    margin-top: 3.2rem;
  }

  :deep .Modal__content-footer {
    margin-top: 3.2rem;
  }
}
</style>
