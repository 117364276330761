import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetAgencyInterviewQuery, GetAgencyInterviewQueryVariables } from '@/gql/Gql.types';

const agencyInterviewQuery = gql`
  query getAgencyInterview($agencyId: String!) {
    agency(agencyId: $agencyId) {
      companyName
      email
      notes
      notesDetails {
        firstName
        lastName
        updatedAt
        userId
      }
    }
  }
`;

export const getAgencyInterview = (
  props?: GetAgencyInterviewQueryVariables,
  options?: UseQueryOptions<
    GetAgencyInterviewQuery,
    GetAgencyInterviewQueryVariables,
    GetAgencyInterviewQuery,
    any[]
  >
): UseQueryReturnType<GetAgencyInterviewQuery, GetAgencyInterviewQueryVariables> =>
  useGraphql({
    query: agencyInterviewQuery,
    variables: props,
    options,
  });
