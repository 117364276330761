import { gql } from 'graphql-request';
import type {
  RemindInviteTalentMutationMutation,
  MutationRemindInviteTalentArgs,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const remindInviteTalentMutation = gql`
  mutation remindInviteTalentMutation($talentId: String!) {
    remindInviteTalent(talentId: $talentId)
  }
`;

export const remindInviteTalent = (
  props?: MutationRemindInviteTalentArgs,
  options?: UseQueryOptions<
    RemindInviteTalentMutationMutation,
    MutationRemindInviteTalentArgs,
    RemindInviteTalentMutationMutation,
    any[]
  >
): UseQueryReturnType<RemindInviteTalentMutationMutation, MutationRemindInviteTalentArgs> =>
  useGraphql({
    query: remindInviteTalentMutation,
    variables: props,
    options,
  });
