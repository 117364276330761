import { gql } from '@/api/graphql-client';

export const skillFragment = gql`
  fragment skill on Skill {
    id
    name
    expertiseId
    expertise {
      id
      name
    }
  }
`;
