<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watch, watchEffect } from 'vue';
import Radio from '@/components/atoms/Radio.vue';
import SelectFilter from './SelectFilter.vue';
import Button from '@/components/atoms/Button.vue';
import FiltersString from '@/data/Filters.json';
import type { Option } from '@/models/selectModel';
import type { IFilter } from '@/models/selectFilterModel';
import { useForm } from 'vee-validate';
import type { ExportTalentsMutation, ExportAgenciesMutation } from '@/gql/Gql.types';
import type { FiltersQuery } from '../Filters.vue';

import { useRouter } from 'vue-router';

type IFiltersSelectProps = {
  selectItems: {
    placeholder: string;
    valueSelect: string;
    hasLevel?: boolean;
    isBoolean?: boolean;
    hasExpertise?: boolean;
    isLikeRadio?: boolean;
    optGroup?: {
      name: string;
      options: Option[];
    }[];
    options?: Option[];
  }[];
  downloadCSV?: ExportTalentsMutation | ExportAgenciesMutation;
  loadingDownload?: boolean;
  filtersQuery?: FiltersQuery;
};

const props = defineProps<IFiltersSelectProps>();

const { selectItems, downloadCSV, loadingDownload, filtersQuery } = toRefs(props);

const {
  methodForFilter,
  typeOfSearch,
  labelRadio,
  nameRadioFilter,
  buttonApplyFilter,
  buttonResetFilter,
  downloadCSVLabel,
} = FiltersString;

const router = useRouter();

const { handleSubmit, resetField, setFieldValue } = useForm({
  initialValues: {
    operator: 'AND',
    specialities: [] as IFilter[],
    seniorities: [] as IFilter[],
    tools: [] as IFilter[],
    languages: [] as IFilter[],
    country: [] as IFilter[],
    workplaces: [] as IFilter[],
    freelance: [] as IFilter[],
    rates: [] as IFilter[],
    industries: [] as IFilter[],
    internationalProjects: [] as IFilter[],
  },
});

const activeFilters = ref<IFilter[]>([]);
const filterApplied = ref(false);
const filterReset = ref(false);
const radioReset = ref(filtersQuery?.value?.operator || 'AND');
const downloadLoading = ref(loadingDownload.value);
const exportCSV = ref();
const initialFilter = ref(filtersQuery?.value?.filters);

watch(
  () => downloadCSV?.value,
  (newValue) => {
    exportCSV.value = newValue;
    if (exportCSV.value) {
      downloadLoading.value = true;
      const key = Object.keys(exportCSV.value)[0];
      const url = exportCSV.value[key];
      const downloadCSV = document.createElement('a');
      downloadCSV.href = url;
      document.body.appendChild(downloadCSV);
      downloadCSV.click();
      document.body.removeChild(downloadCSV);
      setTimeout(() => {
        downloadLoading.value = false;
      }, 300);
    }
  }
);

/**
 Function that handles adding and removing objects from
 the activeFilters array based on the filter option and the isChecked boolean value.
 If reset runs, not add filter in the array activeFilter
 @param {Array<IFilter[] | boolean>} selectedOptions - Array that contains filter and isChecked value.
 */
const hasFilter = (selectedOptions: (IFilter[] | boolean | string)[]) => {
  const [filter, isChecked, elementDelete] = selectedOptions;

  isChecked && Array.isArray(filter)
    ? filter.forEach((el) => {
        if (el.isLikeRadio && activeFilters.value.length > 0) {
          activeFilters.value = activeFilters.value.filter(
            (options) => options.isLikeRadio !== el.isLikeRadio && options.skills !== elementDelete
          );
        }
        activeFilters.value.push(el);
      })
    : (activeFilters.value = activeFilters.value.filter(
        (options) => options.name !== elementDelete && options.skills !== elementDelete
      ));

  activeFilters.value = [...new Set(activeFilters.value)];
};

const resetFilters = () => {
  activeFilters.value = [];
  filterApplied.value = false;
  filterReset.value = true;
  radioReset.value = 'AND';
  resetField('operator');
  emit('reset', activeFilters.value);
};

const resetChecked = computed(() => {
  if (activeFilters.value.length <= 0) {
    return false;
  }
});

const mappedFilterQuery = (filter: object | undefined, query: Record<string, any>) => {
  if (!filter) return {};

  for (const [key, value] of Object.entries(filter)) {
    if (value && value.length > 0 && key !== 'filters') {
      if (Array.isArray(value)) {
        const paramValue = value
          .map(({ name, skillsLabel, levels, hasLevel }) =>
            !skillsLabel ? name : hasLevel ? skillsLabel.concat('[', levels, ']') : skillsLabel
          )
          .join(';');
        query[key] = paramValue;
      } else {
        query[key] = value;
      }
    }
  }
  return query;
};

const filterQuery = (filter: object) => {
  let queryObject: Record<string, any> = {};

  const queryFilter = mappedFilterQuery(filter, queryObject);
  const queryArgs = mappedFilterQuery(filtersQuery?.value, queryObject);

  queryObject = { ...queryFilter, ...queryArgs };
  router.replace({ query: { ...queryObject } });
};

const emit = defineEmits(['active', 'reset', 'startDownload']);

const applyFilter = handleSubmit((values) => {
  if (activeFilters.value.length) {
    setFieldValue(
      'languages',
      activeFilters.value.filter((filter) => filter.keyValue === 'languages')
    );
    values.languages = activeFilters.value.filter((filter) => filter.keyValue === 'languages');

    setFieldValue(
      'tools',
      activeFilters.value.filter((filter) => filter.keyValue === 'tools')
    );
    values.tools = activeFilters.value.filter((filter) => filter.keyValue === 'tools');

    filterQuery(values);
    emit('active', Object.entries(values));
    filterApplied.value = true;
    filterReset.value = false;
  }
});

watchEffect(() => {
  if (
    initialFilter.value &&
    Object.keys(initialFilter.value).length > 0 &&
    Object.values(initialFilter.value).some(
      (element) => element && element.length > 0 && !filterApplied.value
    )
  )
    applyFilter();
});

watch(loadingDownload, (newValue) => {
  downloadLoading.value = newValue;
});

const handleStartDownload = () => {
  emit('startDownload', true);
};
</script>

<template>
  <form @submit="applyFilter">
    <div class="FiltersSelect">
      <div class="FiltersSelect__radio">
        <span class="text-body-2">{{ methodForFilter }}</span>
        <Radio
          v-for="(radio, index) in typeOfSearch"
          :key="index"
          :label="labelRadio[index]"
          :name="nameRadioFilter"
          :initial-value="radioReset"
          :value="radio"
          :rules="{ required: true }"
          @changed="(radio: string) => (radioReset = radio)"
        />
      </div>
      <div class="FiltersSelect__select">
        <o-skeleton
          class="FiltersSelect__select--skeleton"
          v-if="!selectItems.length"
          v-for="skeleton in 7"
          :key="skeleton"
          :animated="true"
        />
        <div v-else v-for="(item, index) in selectItems" :key="index + item.placeholder">
          <SelectFilter
            :placeholder="item.placeholder"
            :value-select="item.valueSelect"
            :options="item.options"
            :opt-group="item.optGroup"
            :has-expertise="item.hasExpertise"
            :has-level="item.hasLevel"
            :is-boolean="item.isBoolean"
            :is-like-radio="item.isLikeRadio"
            :reset="filterReset"
            :initial-checked="resetChecked"
            :initial-value="initialFilter"
            @selected="hasFilter"
          />
        </div>
      </div>
      <div class="FiltersSelect__button">
        <Button :label="buttonApplyFilter" native-type="submit" />
        <Button :label="buttonResetFilter" variant="secondary" @clicked="resetFilters" />
        <Button variant="link-tertiary" :label="downloadCSVLabel" @clicked="handleStartDownload" />
        <div class="lds-ring" v-if="downloadLoading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped lang="scss">
.FiltersSelect {
  padding: 2.4rem;
  border: 0.1rem solid $black-20;
  margin-bottom: 3.8rem;

  &__radio {
    @include flexing(row, start, center);
    gap: 2.4rem;
    margin-bottom: 2.4rem;

    :deep .error-message {
      display: none;

      &:last-of-type {
        display: block;
        color: $status;
        margin: 0;
      }
    }
  }

  &__select {
    @include flexing(row, start, start);
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-bottom: 2.4rem;

    &--skeleton {
      height: 3.5rem;
      width: 12.6rem;

      :deep .o-sklt__item {
        border-radius: 0.4rem;
        height: 100%;
      }
    }
  }

  &__button {
    @include flexing(row, start, center);
    gap: 1.6rem;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 0;
  border: 4px solid $black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $black transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
