import { TalentStatus } from '@/gql/Gql.types';

export const BREAKPOINTS = {
  lg: 1024,
  xl: 1440,
};

export const PASSWORD_LENGTH = 18;

export const NOTIFICATION_DURATION = 3000;
export const NAVIGATION_BACK_DEFAULT_TIME = 2000;

export const COMMON_DATA_CACHETIME = 60000;

export const TIME_DELAY_SEARCH = 600;
export const MIN_LENGTH_SEARCH = 2;

export const TIME_TO_RESTORE_VARIABLES = 200;

export const mappedStatus: { [key in TalentStatus]: string } = {
  [TalentStatus.Inbox]: 'Da valutare',
  [TalentStatus.Invited]: 'Invitati',
  [TalentStatus.AllStar]: 'All Star',
  [TalentStatus.Eligible]: 'Eligible',
  [TalentStatus.Future]: 'Future Talent',
  [TalentStatus.Cantera]: 'Cantera',
  [TalentStatus.NotVerified]: 'Non verificato',
};

export const REGEX_SQUARE_BRACKETS = /\[(.*?)\]/;
