import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { QueryDealsArgs, DealConnection, DealStage } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { BaseTransitionPropsValidators, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useQuery } from '@tanstack/vue-query';
import { is } from '@vee-validate/rules';

type UseGraphqlProps<TResult, TVariables> = {
  headers?: HeadersInit;
  options?: UseQueryOptions<TResult, TVariables, TResult, (string | TVariables | undefined)[]>;
  query: string;
  variables?: TVariables;
};

type FetcherProps<TVariables> = {
  query: string;
  variables?: TVariables;
  headers?: HeadersInit;
};

export const fetcher = async <TResult, TVariables>({
  query,
  variables,
  headers,
}: FetcherProps<TVariables>): Promise<TResult> => {
  if (!import.meta.env.VITE_GRAPHQL_DEV) {
    throw new Error(`Missing correct key .env file`);
  }

  const response: Response = await fetch(import.meta.env.VITE_GRAPHQL_DEV, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (response.status !== 200) {
    throw new Error(`Error: ${response.statusText}. Body: ${await response.text()}`);
  }

  const json = await response.json();

  if (!json.data && json.errors.length > 0) {
    const { extensions, message } = json.errors[0];

    throw new Error(`Error: ${extensions.code}. Body: ${message}`);
  }

  return json.data;
};

export const dealsQuery = gql`
  query getDeals(
    $first: Int
    $filters: DealsFilterInput
    $after: String
    $before: String
    $last: Int
  ) {
    deals(filters: $filters, first: $first, after: $after, before: $before, last: $last) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        title
        companyName
        stage
        createdAt
        updatedAt
        isArchived
        poolCount
        shortlistCount
        user {
          firstname
          lastname
        }
      }
    }
  }
`;

export const getDeals = (
  props?: QueryDealsArgs & { params: any },
  options?: UseQueryOptions<
    {
      deals: DealConnection;
    },
    QueryDealsArgs,
    {
      deals: DealConnection;
    },
    any[]
  >
): UseQueryReturnType<
  {
    deals: DealConnection;
  },
  QueryDealsArgs
> => {
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);
  const retries = ref<number>(0);

  const keys = [dealsQuery, props];

  const fn = () => {
    return fetcher<string, {}>({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
      },
      query: dealsQuery,
      variables: {
        first: (props as any)?.params.value.first,
        last: (props as any)?.params.value.last,
        after: (props as any)?.params.value.after,
        before: (props as any)?.params.value.before,
        filters: {
          stage: (props?.filters?.stage as any)?.value,
          query: (props?.filters?.query as any)?.value,
          isArchived: (props?.filters?.isArchived as any)?.value === 'ARCHIVED' ? true : false,
        },
      },
    });
  };

  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  };

  const request = useQuery(keys, fn as any, queryOptions);

  watch([request.error], async () => {
    const isUnauthorized = `${request.error.value as string}`
      .toLowerCase()
      .split(' ')
      .some((el) => ['unauthorized', 'unauthenticated'].includes(el));

    if (retries.value < 1 && isUnauthorized) {
      await userStore.refreshToken(jwt.value);
      await request.refetch();
      return retries.value++;
    }

    if (isUnauthorized) {
      await userStore.logout();
    }
  });

  return request;
};
