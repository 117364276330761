<script setup lang="ts">
import { getExperiences } from '@/api/queries/experiences';
import Select from '@/components/atoms/Select.vue';
import Tab from '@/components/atoms/Tab.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import Table from '@/components/organisms/Table/Table.vue';
import { usePagination } from '@/composables/usePagination';
import { ITab, useTabOptions } from '@/composables/useTabOptions';
import { useTable } from '@/composables/useTable';
import ExperienceString from '@/data/Experience.json';
import type { GetExperiencesQueryVariables } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { ITableColumns } from '@/models/tableModel';
import moment from 'moment';
import 'moment/dist/locale/it';
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const { title, selectVisibleExp, viewParticipant } = ExperienceString;
const { columns, selectItems } = useTable();

const route = useRoute();

const {
  items: tabItems,
  setActiveTab,
  activeTab,
} = useTabOptions({
  tab: ITab.Experiences,
  pathName: route.matched[0].name as string,
});

const isActive = ref(true);

const visibleResult = ref(+selectVisibleExp.options[0].value);
const page = ref<number>(1);

const {
  data: dataCount,
  status: statusCount,
  isLoading: isLoadingCount,
  refetch: refetchCount,
} = getExperiences();

const params = reactive<GetExperiencesQueryVariables>({
  first: visibleResult.value,
  filters: { isActive: isActive.value },
});

const { data, status, isLoading, refetch } = getExperiences(params);

const countExperiences = computed(() => {
  if (
    statusCount.value !== 'success' ||
    isLoadingCount.value ||
    !dataCount.value ||
    !dataCount.value?.experiences
  )
    return [];

  return (dataCount.value?.experiences?.edges || [])?.reduce(
    (acc: number[], item) => {
      const active = item.node.isActive;
      active ? acc[0]++ : acc[1]++;
      return acc;
    },
    [0, 0]
  );
});

const statusFilterTabs = computed(() =>
  tabItems.map(({ label, name }, index: number) => ({
    name,
    label: `${label} ${countExperiences.value[index] ?? 0}`,
  }))
);

watch(activeTab, () => {
  isActive.value = (activeTab.value as string).includes(tabItems[0].name);
});

const fromTo = computed(() => (dateFrom: string, dateTo: string) => {
  const from = moment(dateFrom).locale('it').format('DD/MM/YYYY');
  const to = moment(dateTo).locale('it').format('DD/MM/YYYY');

  return from + '<br> - <br>' + to;
});

const totalPages = computed(() => Math.ceil(countExperiences.value[0] / visibleResult.value));

const paginationExperienceValue = computed(
  () => countExperiences.value[0] - visibleResult.value * (totalPages.value - 1)
);

const items = computed(() => {
  if (status.value !== 'success' || isLoading.value || !data.value || !data.value?.experiences)
    return [];

  return (data.value?.experiences?.edges || [])?.map((element) => {
    const { node: experiences } = element;

    return {
      id: experiences.id,
      createdAt: experiences.createdAt,
      lastEdit: experiences.updatedAt,
      category: experiences.category?.[0].title,
      location: experiences.location,
      fromTo: fromTo.value(experiences.from, experiences.to),
      availables: experiences.availables,
      price: '&#x20AC; ' + experiences.price,
      discount: experiences.discount + ' &#37;',
      insurance: experiences.hasInsurance ? 'Si' : 'No',
      flexibleDeleting: experiences.isFlexible ? 'Si' : 'No',
      bookings: experiences.bookings.length,
      actions: {
        labelTo: viewParticipant,
        tag: 'router-link',
        to: '/experiences/details/' + experiences.id,
      },
    };
  });
});

const experiencesPagination = computed(() => {
  if (!data.value?.experiences.pageInfo || !data.value?.experiences.totalCount) return undefined;
  const {
    endCursor: nextPage,
    hasNextPage,
    hasPreviousPage,
    startCursor: prevPage,
  } = data.value.experiences.pageInfo;
  return {
    label: title,
    currentPage: page.value ?? 1,
    nextPage,
    hasNextPage,
    prevPage,
    hasPreviousPage,
    totalItem: countExperiences.value[0],
    totalPages: totalPages.value,
  };
});
const changePage = async (type: 'first' | 'prev' | 'next' | 'last', cursor: string | undefined) => {
  const { currentPage, paginationParams } = usePagination(
    type,
    cursor,
    page.value,
    visibleResult.value,
    totalPages.value,
    paginationExperienceValue.value,
    params
  );
  page.value = currentPage;
  Object.assign(params, paginationParams);
};

watch(
  () => [isActive.value, visibleResult.value],
  async () => {
    page.value = 1;
    delete params.after;
    delete params.before;
    delete params.last;
    params.first = visibleResult.value;
    params.filters = {
      isActive: isActive.value,
    };
    await refetchCount();
  }
);

const selectVisibleResult = (result: Option) => (visibleResult.value = +result.value);
</script>

<template>
  <main class="container">
    <div class="Experience col-12">
      <h1 class="Experience__title heading-h1">{{ title }}</h1>
      <Tab
        class="Experience__tab"
        :items="statusFilterTabs"
        :initial-active="(activeTab as string)"
        @clicked="setActiveTab"
      />
      <div class="Experience__select">
        <Select
          :label="selectVisibleExp.label"
          :options="selectVisibleExp.options"
          :initial-value="selectVisibleExp.options[0]"
          variant="table"
          @selected="selectVisibleResult"
        />
      </div>
      <Table
        :checkable="false"
        :items="items || []"
        :columns="columns[ITableColumns.Experience]"
        :is-loading="isLoading"
        @select-items="selectItems"
      />
      <Pagination
        v-if="experiencesPagination"
        v-bind="{ ...experiencesPagination }"
        @change-page="changePage"
      />
    </div>
  </main>
</template>

<style scoped lang="scss">
.Experience {
  &__title {
    margin: 0 0 4rem;
  }

  &__tab {
    margin-bottom: 2.4rem;
  }

  &__select {
    margin-bottom: 4rem;

    :deep .Select {
      justify-content: end;
    }
  }
}
</style>
