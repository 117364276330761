import { gql } from 'graphql-request';
import type { RestoreTalentsMutation, MutationRestoreTalentsArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const restoreTalentsMutation = gql`
  mutation RestoreTalents($talentIds: [String!]!) {
    restoreTalents(talentIds: $talentIds)
  }
`;

export const restoreTalents = (
  props?: MutationRestoreTalentsArgs,
  options?: UseQueryOptions<
    RestoreTalentsMutation,
    MutationRestoreTalentsArgs,
    RestoreTalentsMutation,
    any[]
  >
): UseQueryReturnType<RestoreTalentsMutation, MutationRestoreTalentsArgs> =>
  useGraphql({
    query: restoreTalentsMutation,
    variables: props,
    options,
  });
