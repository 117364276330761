import { computed } from 'vue';

const useCheckPasswordStrength = computed(() => {
  return (password: string) => {
    let score = 0;
    if (password.length >= 8) {
      score += 1;
    }

    if (/[a-z]/.test(password)) {
      score += 1;
    }

    if (/[A-Z]/.test(password)) {
      score += 1;
    }

    if (/[0-9]/.test(password)) {
      score += 1;
    }

    if (/[^a-zA-Z0-9]/.test(password)) {
      score += 1;
    }

    if (score < 3) {
      return 'Weak';
    } else if (score < 5) {
      return 'Medium';
    } else {
      return 'Strong';
    }
  };
});

export default useCheckPasswordStrength;
