<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import type { INavbarListItem } from '@/models/navbarModel';
import { toRefs } from 'vue';

const props = defineProps<INavbarListItem>();
const { item, isActive } = toRefs(props);
</script>
<template>
  <li>
    <Button
      :active="isActive"
      :label="item.label"
      :to="item.route"
      :href="item.route"
      :target="item.label === 'Analytics' ? '_blank' : ''"
      :tag="item.label === 'Analytics' ? 'a' : 'router-link'"
      type="navbar"
      variant="tertiary-dark"
    />
  </li>
</template>
