import { gql } from 'graphql-request';
import type { MutationHardDeleteSkillsArgs, HardDeleteSkillsMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const hardDeleteSkillsMutation = gql`
  mutation HardDeleteSkills($skillIds: [String!]!) {
    hardDeleteSkills(skillIds: $skillIds)
  }
`;

export const hardDeleteSkills = (
  props?: MutationHardDeleteSkillsArgs,
  options?: UseQueryOptions<
    HardDeleteSkillsMutation,
    MutationHardDeleteSkillsArgs,
    HardDeleteSkillsMutation,
    any[]
  >
): UseQueryReturnType<HardDeleteSkillsMutation, MutationHardDeleteSkillsArgs> =>
  useGraphql({
    query: hardDeleteSkillsMutation,
    variables: props,
    options,
  });
