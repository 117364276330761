<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import type { IButtonProps } from '@/models/buttonModel';
import { Field } from 'vee-validate';
import { ref, toRefs, watch } from 'vue';

const file = ref();

interface IUploadButton extends IButtonProps {
  name?: string;
  rules?: any;
  standalone?: boolean;
  success?: boolean;
}

const props = withDefaults(defineProps<IUploadButton>(), {
  tag: 'a',
  standalone: false,
  success: false,
});

const { success } = toRefs(props);

const isSuccess = ref(success);

watch(success, (newValue) => {
  isSuccess.value = newValue;
  file.value = '';
});

const emit = defineEmits(['upload']);

watch(file, () => {
  if (file.value.name) {
    emit('upload', file.value);
  }
});
</script>

<template>
  <div class="Upload">
    <Field
      v-slot="{ field, errors }"
      :name="name ?? ''"
      v-model="file"
      :rules="rules"
      :standalone="standalone"
    >
      <o-field>
        <o-upload v-model="file">
          <Button v-bind="{ ...props }" />
        </o-upload>
      </o-field>
      <p v-if="file && !isSuccess" class="text-label">
        {{ file.name }}
        <Icon icon="close" icon-size="micro" @click="file = ''" />
      </p>
      <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
    </Field>
  </div>
</template>

<style scoped lang="scss">
.Upload {
  :deep {
    .icon__svg {
      cursor: pointer;
    }
  }
}
</style>
