<script setup lang="ts">
import { toRefs } from 'vue';
import Icon from '@/components/atoms/Icon.vue';
import FormGroup from '@/components/molecules/FormGroup.vue';
import InputText from '@/components/atoms/InputText.vue';
import Button from '@/components/atoms/Button.vue';
import type { IButtonProps } from '@/models/buttonModel';
import { useForm } from 'vee-validate';

useForm();

type Input = {
  label: string;
  placeholder: string;
  type: string;
  name?: string;
  rules?: any;
};

interface ILoginForm {
  title: string;
  formTitle: string;
  inputs: Input[];
  primaryBtn: IButtonProps;
  linkBtn?: IButtonProps;
  message?: string;
  sending?: boolean;
  backToLogin?: IButtonProps;
}

const props = defineProps<ILoginForm>();

const { title, formTitle, inputs, primaryBtn, linkBtn } = toRefs(props);

const { handleSubmit } = useForm();

const emit = defineEmits(['submitted']);

const onSubmit = handleSubmit((values) => emit('submitted', Object.assign(values)));
</script>

<template>
  <div class="LoginPage">
    <Icon icon="logo" icon-size="large"></Icon>
    <h1 class="LoginPage__title heading-h1">{{ title }}</h1>
    <form class="LoginPage__form-group FormGroup">
      <FormGroup :title="formTitle" #formContent>
        <InputText
          v-if="!sending"
          v-for="(input, index) in inputs"
          :key="index"
          :label="input.label"
          :placeholder="input.placeholder"
          :type="input.type"
          :name="input.name ?? input.label"
          :rules="input.rules"
        />
        <p v-else class="LoginPage__form-group-message text-body-1">{{ message }}</p>
        <div class="LoginPage__form-group-buttons">
          <Button
            v-if="!sending"
            :label="primaryBtn.label"
            native-type="submit"
            @clicked="onSubmit"
          />
          <Button v-else :label="backToLogin?.label" tag="router-link" :to="backToLogin?.to" />
          <Button
            v-if="linkBtn && !sending"
            :label="linkBtn?.label"
            variant="link-tertiary"
            tag="router-link"
            :to="linkBtn?.to"
          />
        </div>
      </FormGroup>
    </form>
  </div>
</template>

<style scoped lang="scss">
.LoginPage {
  height: 100vh;
  width: 100%;
  @include flexing(column, center, center);

  &__title {
    margin: 2.4rem 0 3.2rem;
  }

  &__form-group {
    width: 43.8rem;

    :deep {
      .FormGroup {
        margin-bottom: 0;
        &-title {
          text-align: center;
          margin-bottom: 2.4rem;
        }
      }
    }

    &-message {
      text-align: center;
    }

    &-buttons {
      :deep .Button {
        display: block;
        margin: auto;

        &.o-btn--primary {
          width: 100%;
          min-height: auto;
          margin: 2.4rem 0 1rem;
          .o-btn__label {
            justify-content: center;
          }
        }

        &.o-btn--link-tertiary {
          min-width: auto;
          min-height: auto;
        }
      }
    }
  }
}
</style>
