<script setup lang="ts">
import { toRefs } from 'vue';
import InputText from '../atoms/InputText.vue';
import Radio from '../atoms/Radio.vue';
import type { IInputProps } from '@/models/inputModel';
import type { IRadioProps } from '@/models/radioModel';

interface IRadioParticipant {
  title: string;
  radioInput: IRadioProps[];
}

interface ICardParticipantProps {
  title: string;
  inputs: IInputProps[];
  radioParticipant: IRadioParticipant[];
}

const props = defineProps<ICardParticipantProps>();

const { title, inputs, radioParticipant } = toRefs(props);
</script>

<template>
  <div class="CardParticipant">
    <h4 class="CardParticipant__title heading-h4">{{ title }}</h4>
    <div class="CardParticipant__input">
      <InputText
        :class="input.type === 'date' ? 'inputData' : ''"
        v-for="(input, index) in inputs"
        :key="index"
        :label="input.label"
        :placeholder="input.placeholder"
        :name="input.name"
        :type="input.type"
        :initial-value="input.initialValue"
        :disabled="true"
      />
    </div>
    <div class="CardParticipant__radio" v-for="(element, index) in radioParticipant" :key="index">
      <div class="CardParticipant__radio-title text-label">{{ element.title }}</div>
      <div class="CardParticipant__radio-item">
        <Radio
          v-for="(radio, j) in element.radioInput"
          :key="j"
          :label="radio.label"
          :name="radio.name"
          :initial-value="radio.value"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.CardParticipant {
  border: 0.1rem solid $black-20;
  border-radius: 0.4rem;
  padding: 2.4rem;

  &__title {
    margin: 0 0 3.2rem;
  }

  &__input {
    @include flexing(row, start, center);
    flex-wrap: wrap;
    gap: 0 2.4rem;

    :deep .InputText {
      width: calc(50% - 1.2rem);
      margin-bottom: 3.2rem;

      &.inputData {
        .o-input {
          color: $black-50;
          text-transform: uppercase;
          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }

          &:focus-visible {
            color: $primary-color;
          }
        }
      }
    }
  }

  &__radio {
    &:not(:last-child) {
      margin-bottom: 3.2rem;
    }
    &-title {
      margin: 0 0 1.6rem;
      text-transform: uppercase;
    }

    &-item {
      @include flexing(row, start, center);
      gap: 2.4rem;
    }
    :deep {
      .o-radio {
        &--disabled {
          color: $primary-color;
          pointer-events: none;
        }
        &__check {
          &--checked.o-radio__check {
            background-color: $black-50;
          }
        }
      }
    }
  }
}
</style>
