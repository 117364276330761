import { gql } from 'graphql-request';
import type {
  MutationHardDeleteTechnologiesArgs,
  HardDeleteTechnologiesMutation,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const hardDeleteTechnologiesMutation = gql`
  mutation HardDeleteTechnologies($technologyIds: [String!]!) {
    hardDeleteTechnologies(technologyIds: $technologyIds)
  }
`;

export const hardDeleteTechnologies = (
  props?: MutationHardDeleteTechnologiesArgs,
  options?: UseQueryOptions<
    HardDeleteTechnologiesMutation,
    MutationHardDeleteTechnologiesArgs,
    HardDeleteTechnologiesMutation,
    any[]
  >
): UseQueryReturnType<HardDeleteTechnologiesMutation, MutationHardDeleteTechnologiesArgs> =>
  useGraphql({
    query: hardDeleteTechnologiesMutation,
    variables: props,
    options,
  });
