import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { Coupon, QueryCouponArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { couponFragment } from './../fragments/coupon';

const couponQuery = gql`
  query CouponQuery($couponId: String!) {
    coupon(couponId: $couponId) {
      ...coupon
    }
  }
  ${couponFragment}
`;

export const getCoupon = (
  props?: QueryCouponArgs,
  options?: UseQueryOptions<{ coupon: Coupon }, QueryCouponArgs, { coupon: Coupon }, any[]>
): UseQueryReturnType<{ coupon: Coupon }, QueryCouponArgs> =>
  useGraphql({
    query: couponQuery,
    variables: props,
    options,
  });
