import { gql } from '@/api/graphql-client';

export const extraFragment = gql`
  fragment extra on Extra {
    id
    flexible
    insurance
    singleRoom
  }
`;
