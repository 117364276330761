import { couponFragment } from '../fragments/coupon';
import { pageInfo } from '@/api/fragments/pageInfo';
import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { GetCouponsQueryVariables, GetCouponsQuery } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const couponsQuery = gql`
  query getCoupons(
    $after: String
    $before: String
    $filters: CouponFilterInput
    $first: Int
    $last: Int
    $orderBy: CouponsOrder
  ) {
    coupons(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...coupon
        }
      }

      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${couponFragment}
  ${pageInfo}
`;

export const getCoupons = (
  props?: GetCouponsQueryVariables,
  options?: UseQueryOptions<GetCouponsQuery, GetCouponsQueryVariables, GetCouponsQuery, any[]>
): UseQueryReturnType<GetCouponsQuery, GetCouponsQueryVariables> =>
  useGraphql({
    query: couponsQuery,
    variables: props,
    options,
  });
