import { gql } from 'graphql-request';
import type { MutationHardDeleteAgenciesArgs, HardDeleteAgencieMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const hardDeleteAgencieMutation = gql`
  mutation HardDeleteAgencie($agencyIds: [String!]!) {
    hardDeleteAgencies(agencyIds: $agencyIds)
  }
`;

export const hardDeleteAgencies = (
  props?: MutationHardDeleteAgenciesArgs,
  options?: UseQueryOptions<
    HardDeleteAgencieMutation,
    MutationHardDeleteAgenciesArgs,
    HardDeleteAgencieMutation,
    any[]
  >
): UseQueryReturnType<HardDeleteAgencieMutation, MutationHardDeleteAgenciesArgs> =>
  useGraphql({
    query: hardDeleteAgencieMutation,
    variables: props,
    options,
  });
