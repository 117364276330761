import { gql } from 'graphql-request';
import type { InviteTalentMutationMutation, MutationInviteTalentArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const inviteTalentMutation = gql`
  mutation InviteTalentMutation($talentId: String!) {
    inviteTalent(talentId: $talentId)
  }
`;

export const inviteTalent = (
  props?: MutationInviteTalentArgs,
  options?: UseQueryOptions<
    InviteTalentMutationMutation,
    MutationInviteTalentArgs,
    InviteTalentMutationMutation,
    any[]
  >
): UseQueryReturnType<InviteTalentMutationMutation, MutationInviteTalentArgs> =>
  useGraphql({
    query: inviteTalentMutation,
    variables: props,
    options,
  });
