<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';
import Button from '@/components/atoms/Button.vue';
import BoxString from '@/data/BoxCategorySkillAccordion.json';
import type { Expertise, Technology } from '@/models/boxCategorySkillsModel';

interface IBoxButtonProps {
  selectedExpertise: Expertise;
  startToAdd: boolean;
  disabled?: boolean;
}

const props = defineProps<IBoxButtonProps>();
const { selectedExpertise, startToAdd, disabled } = toRefs(props);

const unabledButton = ref(disabled.value);

watch(disabled, (newDisabled) => {
  unabledButton.value = newDisabled;
});

const { addTool } = BoxString;

const emit = defineEmits(['changed', 'addSkill', 'technologyPressed']);

const selected = ref<Technology>();

const showCategory = (technology: string, expertise: string) => {
  emit('changed', {
    startToAdd: false,
    selectedTechnology: technology,
    selectedCategory: expertise,
  });
};

const addSkills = () => {
  emit('addSkill', { startToAdd: true });
};

const selectedTechnologies = computed(() =>
  selectedExpertise.value.technologies.filter((tech: Technology) => tech.level !== '')
);
</script>
<template>
  <div class="BoxButton">
    <div v-for="(tech, index) in selectedTechnologies" :key="index" class="BoxButton-skills">
      <Button
        v-if="tech"
        :label="tech.technology"
        :variant="tech.technology === selected?.technology ? 'primary' : 'secondary'"
        @clicked="
          showCategory(tech.technology, selectedExpertise.expertise);
          selected = tech;
          $emit('technologyPressed', tech);
        "
      />
    </div>
    <Button v-if="startToAdd" class="BoxButton-newTool" :label="addTool" variant="secondary" />
    <Button
      class="BoxButton-add"
      variant="secondary"
      icon="plus"
      icon-size="small"
      @clicked="addSkills"
      :disabled="unabledButton"
    />
  </div>
</template>
<style scoped lang="scss">
.BoxButton {
  margin-bottom: 2.4rem;

  &-skills {
    display: inline-block;
  }

  &-add {
    :deep &.Button {
      width: 4rem;
    }
  }

  &-newTool {
    :deep &.Button {
      color: $black-50;

      &:hover {
        color: $black-50;
        background-color: transparent;
        border-color: $primary-color;
      }
    }
  }
}
</style>
