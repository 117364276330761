import agenciesString from '@/data/Agencies.json';
import talentsString from '@/data/Talents.json';
import genericString from '@/data/GenericStrings.json';
import roles from '@/data/Roles.json';
import FormString from '@/data/UpdateTalentsForm.json';
import type {
  AgencyConnection,
  AgencyFragment,
  GetUsersQuery,
  TalentConnection,
  TalentFragment,
} from '@/gql/Gql.types';
import { TalentStatus, UserRole } from '@/gql/Gql.types';
import moment from 'moment';
import 'moment/dist/locale/it';
import { computed } from 'vue';

const { digitalNomad } = FormString;

const { labelStatus } = genericString;

const { agency: agencyString } = agenciesString;
const { talent: talentString } = talentsString;

interface ActionFunction {
  // TODO ADD NEW ACTIONS
  moveIn: (status: TalentStatus, id: string) => void;
  addNote: (id: string) => void;
  evaluateStatus?: (id: string) => void;
}

type ShareableConditions = {
  status: 'error' | 'success' | 'loading';
  isLoading: boolean;
};

type IGetUsersQueryMapper = ShareableConditions & {
  data: GetUsersQuery | undefined;
  userRole: UserRole;
};

type IGetTalentsOrAgenciesQueryMapper = ShareableConditions & {
  data: TalentConnection | AgencyConnection | undefined;
  actions: ActionFunction;
  userRole: UserRole;
};

const highlight = ['notes', 'CV', 'PORTFOLIO'];

export const useTableItemsMapper = () => {
  const getUsersQueryMapper = ({ data, status, isLoading, userRole }: IGetUsersQueryMapper) => {
    if (status !== 'success' || isLoading || !data || !data?.users) return [];

    return (data?.users?.edges || [])?.map((element) => {
      const { node: user } = element;

      return {
        id: user.id,
        name: user.firstname + ' ' + user.lastname,
        email: user.email,
        role: user.role,
        roleValue: roles[user.role],
        actions: {
          tag: 'router-link',
          to: 'users/edit/' + user.id,
          disabled: userRole === 'ADMIN' ? false : true,
        },
      };
    });
  };

  const correctDate = computed(
    () => (date: string) => moment(date).locale('it').format('DD/MM/YYYY')
  );

  const documentsTalents = computed(() => (documents?: TalentFragment['documents']) => {
    if (!documents)
      return [
        {
          src: '',
          label: '',
        },
      ];

    return documents.map((docs) => ({
      src: docs.url,
      label:
        docs.type && docs.type !== 'CV'
          ? docs.type?.charAt(0).toUpperCase() + docs.type.slice(1).toLowerCase()
          : docs.type,
    }));
  });

  const specialitiesTalents = computed(() => (specialities?: TalentFragment['specialities']) => {
    if (!specialities) return [];
    return Object.entries(specialities).flatMap((specility) =>
      specility[1].skills.flatMap((skills) => skills)
    );
  });

  const toolsTalents = computed(() => (tools?: TalentFragment['tools']) => {
    if (!tools) return [];

    return Object.entries(tools).flatMap((tool) =>
      tool[1].technologies.flatMap((tech) => tech.technology)
    );
  });

  const languagesTalents = computed(() => (languages?: TalentFragment['languages']) => {
    if (!languages) return '';
    return languages.map((lang) => lang.language);
  });

  const notesTalens = computed(
    () => (notes?: string) => !notes ? genericString.add : genericString.read
  );

  const industriesAgencies = computed(() => (industries?: AgencyFragment['industries']) => {
    if (!industries) return '';
    return industries.map((industries) => industries);
  });

  const getTalentsQueryMapper = ({
    data,
    status,
    isLoading,
    actions,
    userRole,
  }: IGetTalentsOrAgenciesQueryMapper) => {
    if (status !== 'success' || data === undefined) return [];

    return ((data as TalentConnection).edges || []).map((element) => {
      const { node: talent } = element;

      return {
        id: talent.id,
        name: talent.firstName ?? '',
        email: talent.email,
        nameView: {
          name: `${talent.firstName ?? ''} ${talent.lastName ?? ''}`,
          email: talent.email,
          createdAt: correctDate.value(talent.createdAt),
          lastEdit: correctDate.value(talent.updatedAt),
        },
        createdAt: correctDate.value(talent.createdAt),
        deletedAt: talent.deletedAt,
        lastEdit: correctDate.value(talent.updatedAt),
        documents: {
          links: [
            ...documentsTalents.value(talent.documents).map((link) => ({
              ...link,
              target: '_blank',
              highlight: talent.search?.includes('CV'),
            })),
          ],
        },
        seniority: {
          label: '',
          range: talent.experience ? `${talent.experience} year` : '',
        },
        specialities: {
          items: specialitiesTalents.value(talent.specialities),
        },
        tools: {
          items: toolsTalents.value(talent.tools),
        },
        languages: {
          items: languagesTalents.value(talent.languages),
        },
        workplace: talent.digitalNomad ? digitalNomad : talent.workplace,
        country: talent.country,
        freelance: labelStatus[talent.freelance as keyof typeof labelStatus],
        notes: {
          links: [
            {
              tag: 'button',
              label: notesTalens.value(talent.notes),
              action: () => actions.addNote(talent.id),
              highlight: talent.search?.includes('notes'),
            },
          ],
        },
        prediction: {
          links: talent.prediction
            ? [
                {
                  tag: 'button',
                  label: talent.prediction,
                  action: () => actions.evaluateStatus && actions.evaluateStatus(talent.id),
                },
              ]
            : [],
        },
        actions: {
          label:
            talent.status === 'INVITED' ? genericString.remindInterview : genericString.interview,
          tag: 'router-link',
          to: 'talents/edit/' + talent.id,
          disabled: userRole === 'ADMIN' || userRole === 'SUPERVISOR' ? false : true,
          moveIn:
            talent.status === 'INVITED'
              ? [
                  {
                    label: genericString.eligible,
                    action: (id: string) => actions.moveIn(TalentStatus.Eligible, id),
                  },
                ]
              : [
                  {
                    label: genericString.cantera,
                    action: (id: string) => actions.moveIn(TalentStatus.Cantera, id),
                  },
                  {
                    label: genericString.future,
                    action: (id: string) => actions.moveIn(TalentStatus.Future, id),
                  },
                ],
          toInterview: talent.status === 'INBOX',
          isInvited: talent.status === 'INVITED',
        },
      };
    });
  };

  const getAgenciesQueryMapper = ({
    data,
    status,
    isLoading,
    actions,
    userRole,
  }: IGetTalentsOrAgenciesQueryMapper) => {
    if (status !== 'success' || data === undefined) return [];

    return ((data as AgencyConnection).edges || []).map((element) => {
      const { node: agency } = element;

      return {
        id: agency.id,
        companyName: agency.companyName ?? '',
        email: agency.email,
        nameView: {
          companyName: agency.companyName ?? '',
          email: agency.email,
          createdAt: correctDate.value(agency.createdAt),
          lastEdit: correctDate.value(agency.updatedAt),
        },
        createdAt: correctDate.value(agency.createdAt),
        deletedAt: agency.deletedAt,
        lastEdit: correctDate.value(agency.updatedAt),
        documents: {
          links: [
            ...documentsTalents
              .value(agency.documents)
              .map((link) => ({ ...link, target: '_blank' })),
          ],
        },
        seniority: agency.experience ? `${agency.experience} year` : '',
        collaborators: String(agency.collaborations?.length),
        specialities: {
          items: specialitiesTalents.value(agency.specialities),
        },
        tools: {
          items: toolsTalents.value(agency.tools),
        },
        workplace: agency.workplace,
        country: agency.country,
        industries: {
          items: industriesAgencies.value(agency.industries),
        },
        notes: {
          links: [
            {
              tag: 'button',
              label: notesTalens.value(agency.notes),
              action: () => actions.addNote(agency.id),
              highlight: agency.search?.includes('notes'),
            },
          ],
        },
        actions: {
          label:
            agency.status === 'INVITED' ? genericString.remindInterview : genericString.interview,
          tag: 'router-link',
          to: 'agencies/edit/' + agency.id,
          disabled: userRole === 'ADMIN' || userRole === 'SUPERVISOR' ? false : true,
          moveIn: [
            {
              label: genericString.cantera,
              action: (id: string) => actions.moveIn(TalentStatus.Cantera, id),
            },
            {
              label: genericString.future,
              action: (id: string) => actions.moveIn(TalentStatus.Future, id),
            },
            agency.status === 'INVITED'
              ? {
                  label: genericString.eligible,
                  action: (id: string) => actions.moveIn(TalentStatus.Eligible, id),
                }
              : {},
          ],
          toInterview: agency.status === 'INBOX',
          isInvited: agency.status === 'INVITED',
        },
      };
    });
  };

  return {
    getUsersQueryMapper,
    getTalentsQueryMapper,
    getAgenciesQueryMapper,
  };
};
