<script setup lang="ts">
import { ref, reactive, computed, watch } from 'vue';
import GoBack from '@/components/molecules/GoBack.vue';
import AddParticipantForm from '@/components/molecules/AddParticipantForm.vue';
import Notification from '@/components/atoms/Notification.vue';
import { addParticipant } from '@/api/mutations/addParticipant';
import { getLocationExperience } from '@/api/queries/locationExperience';
import { useRoute, useRouter } from 'vue-router';
import strings from '@/data/AddParticipant.json';
import type {
  CreateFreeOrderInput,
  GuestInput,
  MutationCreateFreeOrderArgs,
  QueryExperienceArgs,
} from '@/gql/Gql.types';
import moment from 'moment';
import 'moment/dist/locale/it';

const { addParticipantSuccess, addParticipantError, addParticipantText } = strings;
const { params } = useRoute();

const router = useRouter();

const data = ref<CreateFreeOrderInput>();
const orderData = reactive<MutationCreateFreeOrderArgs>({
  data: data.value as CreateFreeOrderInput,
});

const enabled = computed(() => !!orderData.data);

const { isError, isSuccess, refetch } = addParticipant(orderData, { enabled });

const propsGetLocation = ref<QueryExperienceArgs>({ experienceId: params.id.toString() });
const { data: dataLocation } = getLocationExperience(propsGetLocation.value);

const location = computed(() => {
  if (!dataLocation.value?.experience.location) return;
  return `${dataLocation.value?.experience.location ?? ''} - ${addParticipantText}`;
});

const fromTo = computed(() => {
  const from = moment(dataLocation.value?.experience.from).locale('it').format('DD/MM/YYYY');
  const to = moment(dataLocation.value?.experience.to).locale('it').format('DD/MM/YYYY');
  return 'Data: ' + from + ' - ' + to;
});

const numParticipants = computed(() => {
  const totParticipants = dataLocation.value?.experience.bookings.length;
  const result = totParticipants ? totParticipants + 1 : 1;
  return result;
});

const addParticipantHandle = (formData: GuestInput) => {
  const inputData = {
    guests: [formData],
    experienceId: params.id.toString(),
  };

  inputData.guests[0].isOwner = true;
  data.value = inputData;

  orderData.data = data.value;
};

watch([isSuccess, orderData], () => {
  if (isError.value) {
    refetch();
  }

  if (isSuccess.value) {
    setTimeout(() => {
      router.back();
    }, 2000);
  }
});
</script>

<template>
  <div class="AddParticipant">
    <Notification
      v-if="isError || isSuccess"
      :message="isError ? addParticipantError : addParticipantSuccess"
      :status="isError ? 'error' : 'success'"
      :duration="3000"
    />
    <GoBack
      class="AddParticipant-goBack"
      :headline="location"
      :subtitle="fromTo"
      :previous-route="'/experiences/details/' + params.id.toString()"
    />
    <div class="AddParticipant-form container">
      <AddParticipantForm
        :num-participant="numParticipants"
        class="col-6 start-4 start-lx-3"
        @add-participant-submit="addParticipantHandle"
        @reset-form="(reset) => isSuccess && reset()"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.AddParticipant {
  &-goBack {
    margin-bottom: 6.4rem;
  }

  &-form {
    margin-bottom: 4.3rem;
  }
}
</style>
