<script setup lang="ts">
import { ref, computed, toRefs, watch } from 'vue';
import Icon from './Icon.vue';
import { Field } from 'vee-validate';
import type { IInputProps } from '@/models/inputModel';
import moment from 'moment';
import 'moment/dist/locale/it';

const props = withDefaults(defineProps<IInputProps>(), {
  type: 'text',
  label: '',
  disabled: false,
  placeholder: '',
  standalone: false,
  initialValue: '',
  maxLenght: undefined,
});

const { type, label, disabled, placeholder, initialValue, name, rules, maxLenght } = toRefs(props);

const model = ref(
  type.value === 'date'
    ? moment(initialValue.value).locale('it').format('YYYY-MM-DD')
    : initialValue.value
);

watch(
  () => initialValue.value,
  (newValue) => {
    model.value = newValue;
  }
);

const inputType = ref(type.value);

const showPassword = () => {
  inputType.value === 'password' ? (inputType.value = 'text') : (inputType.value = 'password');
};

const maxLength = ref(maxLenght.value && type.value === 'textarea' ? 250 : '');

const getMaxLength = computed(() => {
  return rules.value?.max ?? maxLength.value;
});

const currentInputLength = computed(() => {
  return +getMaxLength.value - model.value.length < 0
    ? 0
    : +getMaxLength.value - model.value.length;
});
</script>

<template>
  <div class="InputText">
    <Field
      v-slot="{ field, errors }"
      v-model="model"
      :name="name ?? label"
      :rules="rules"
      :standalone="standalone"
    >
      <o-field
        :label="label"
        :class="['text-label', `${errors[0] ? '--error' : ''}`, { '--disabled': disabled }]"
      >
        <o-input
          v-model="model"
          :class="['text-button', { '--disabled': disabled, '--password': type === 'password' }]"
          :type="inputType"
          :value="
            type === 'date' ? moment(initialValue).locale('it').format('YYYY-MM-DD') : initialValue
          "
          :disabled="disabled"
          :placeholder="placeholder"
          :maxlength="getMaxLength"
          v-bind="field"
          @keyup="$emit('keystart', model)"
        />
        <Icon
          v-if="type === 'password'"
          class="InputText--passwordIcon"
          :icon="`${inputType === 'password' ? 'eye-close' : 'eye'}`"
          icon-size="medium"
          @click="showPassword"
        />
        <span class="count-text text-body-2" v-if="maxLenght && type === 'textarea'">
          {{ currentInputLength }}
        </span>
      </o-field>
      <p class="error-message text-body-2" v-if="errors[0]">{{ errors[0] }}</p>
    </Field>
  </div>
</template>

<style scoped lang="scss">
.InputText {
  :deep {
    .o-field {
      &__label {
        text-transform: uppercase;
        margin-bottom: 0.8rem;
      }

      &--addons {
        display: block;
        position: relative;
      }
    }

    .o-input {
      border: 0.1rem solid $black-20;
      border-radius: 0.4rem;
      padding: 1.9rem 1.6rem;

      &.--password {
        padding-right: 4.1rem;
      }

      @include placeholder {
        color: $black-50;
      }

      &:hover {
        border: 0.1rem solid $black-50;
      }

      &:focus-visible {
        border: 0.1rem solid $primary-color;
        outline: transparent;
        @include placeholder {
          color: transparent;
        }
      }

      &__textarea {
        min-height: 18.8rem;
        resize: none;
        overflow: hidden;
      }
    }
  }

  &.--disabled {
    :deep {
      .o-field {
        &__label {
          color: $black-20;
        }
      }

      .o-input {
        border-color: $black-20;

        @include placeholder {
          color: $black-20;
        }

        &:hover,
        &:focus-visible {
          border-color: $black-20;
        }
      }
    }

    .count-text {
      color: $black-20;
    }
  }

  .count-text {
    position: absolute;
    bottom: 2.4rem;
    right: 2.4rem;
    color: $black-50;
  }

  &--passwordIcon {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
