<script setup lang="ts">
import { toRefs } from 'vue';
import checkPasswordStrength from '@/composables/checkPassword';

const props = defineProps({
  password: {
    type: String,
    required: false,
    default: '',
  },
});

const { password } = toRefs(props);
</script>

<template>
  <div :class="`CheckPassword text-button --${checkPasswordStrength(password).toLowerCase()}`">
    {{ checkPasswordStrength(password) }}
  </div>
</template>

<style scoped lang="scss">
.CheckPassword {
  @include flexing(row, center, center);
  width: 100%;
  height: 3.6rem;
  color: $secondary-color;

  &.--weak {
    background-color: $red;
  }

  &.--medium {
    background-color: $orange;
  }

  &.--strong {
    background-color: $green;
  }
}
</style>
