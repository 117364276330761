import { gql } from '@/api/graphql-client';

export const emailTemplateFragment = gql`
  fragment emailTemplate on EmailTemplate {
    body
    category
    country
    createdAt
    heading
    id
    sign
    subject
    title
    typology
    updatedAt
    wishes
  }
`;
