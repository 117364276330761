import { gql } from '@/api/graphql-client';
import { type MutationAuthenticateExternalDomainArgs } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { fetcher } from '@/api/use-graphql';
import { useMutation } from '@tanstack/vue-query';

export const authenticateExternalDomain = gql`
  mutation authenticateExternalDomain($externalDomain: String!) {
    authenticateExternalDomain(externalDomain: $externalDomain)
  }
`;

export const useAuthenticateExternalDomain = () => {
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);

  const fn = (props: MutationAuthenticateExternalDomainArgs) =>
    fetcher<
      {
        authenticateExternalDomain: boolean;
      },
      MutationAuthenticateExternalDomainArgs
    >({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
      },
      query: authenticateExternalDomain,
      variables: props,
    });

  const mutation = useMutation({
    mutationFn: fn,
  });

  return mutation;
};
