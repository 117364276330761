<script setup lang="ts">
import { createCoupon } from '@/api/mutations/createCoupon';
import { updateCoupon } from '@/api/mutations/updateCoupon';
import { getExperiences } from '@/api/queries/experiences';
import Button from '@/components/atoms/Button.vue';
import InputText from '@/components/atoms/InputText.vue';
import Notification from '@/components/atoms/Notification.vue';
import Select from '@/components/atoms/Select.vue';
import couponString from '@/data/Coupon.json';
import { NOTIFICATION_DURATION } from '@/data/constants';
import type {
  CreateCouponInput,
  MutationCreateCouponArgs,
  MutationUpdateCouponArgs,
  UpdateCouponInput,
} from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { useForm } from 'vee-validate';
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import FormGroup from './FormGroup.vue';
import string from '@/data/GenericStrings.json';

const router = useRouter();

const {
  headingForm,
  inputs,
  createCodeButton,
  updateCodeButton,
  selectPlaceholder,
  selectName,
  addCouponSuccess,
  addCouponError,
  updateCouponSuccess,
  updateCouponError,
} = couponString;

const isEdit = computed(() => useRoute().fullPath.includes('edit'));

type CouponDataForm = {
  id?: string;
  associatedDomain?: string;
  associatedEmail?: string;
  code?: string;
  discount?: number;
  experienceId?: string;
  expirationDate?: string;
  maxRedemptions?: number;
  name?: string;
};

type CouponInputData = {
  associatedDomain?: string;
  associatedEmail?: string;
  code: string;
  discount: string;
  experienceId?: string | Option;
  expirationDate?: string;
  maxRedemptions: string;
  name: string;
};

type NotificationStatus = 'success' | 'error' | '';

const props = withDefaults(defineProps<CouponDataForm>(), {
  id: '',
  associatedDomain: '',
  associatedEmail: '',
  code: '',
  discount: 0,
  experienceId: '',
  expirationDate: '',
  maxRedemptions: 0,
  name: '',
});

const {
  id,
  associatedDomain,
  associatedEmail,
  code,
  discount,
  experienceId,
  expirationDate,
  maxRedemptions,
  name,
} = toRefs(props);

const percentage = ref(String(discount.value));
const redemptions = ref(String(maxRedemptions.value));

const { data: experiences, isLoading } = getExperiences();

const experiencesInfo = computed(() => {
  if (isLoading.value || !experiences.value?.experiences) return [];

  return (experiences.value?.experiences?.edges || [])?.map((element) => {
    const { node: experiences } = element;

    return {
      value: experiences.id,
      label: experiences.category?.[0].title + ' ' + experiences.location,
    };
  });
});

const isWriteData = ref(false);

const writeData = (date: string, input: string) => {
  if (input === 'date') {
    isWriteData.value = date.length >= 1;
  }
};

const coupon = reactive<CouponInputData>({
  associatedDomain: associatedDomain?.value || '',
  associatedEmail: associatedEmail?.value || '',
  code: code?.value || '',
  discount: percentage?.value || '',
  experienceId: experienceId?.value || '',
  expirationDate: expirationDate?.value || '',
  maxRedemptions: redemptions?.value || '',
  name: name?.value || '',
});

const getInitialValue = computed(
  () => (name: string) => String(coupon[name as keyof typeof coupon]) ?? ''
);

const dataCreateInput = ref<CreateCouponInput>();
const dataCreateCoupon = reactive<MutationCreateCouponArgs>({
  data: dataCreateInput.value as CreateCouponInput,
});
const enabledCreate = computed(
  () => !isEdit.value && !!(dataCreateCoupon.data && Object.keys(dataCreateCoupon.data).length > 0)
);

const { isSuccess: successCreateCoupon, isError: errorCreateCoupon } = createCoupon(
  dataCreateCoupon,
  {
    enabled: enabledCreate,
  }
);

const dataUpdateInput = ref<UpdateCouponInput>();
const dataUpdateCoupon = reactive<MutationUpdateCouponArgs>({
  data: dataUpdateInput.value as UpdateCouponInput,
  couponId: id.value as string,
});
const enabledUpdate = computed(() => isEdit.value && !!dataUpdateCoupon.data);

const { isSuccess: successUpdateCoupon, isError: errorUpdateCoupon } = updateCoupon(
  dataUpdateCoupon,
  {
    enabled: enabledUpdate,
  }
);

watch([successUpdateCoupon, successCreateCoupon], () => {
  if (!successUpdateCoupon.value && !successCreateCoupon.value) return;

  if (successCreateCoupon.value) {
    setTimeout(() => {
      dataCreateCoupon.data = {} as CreateCouponInput;
    }, NOTIFICATION_DURATION);
  }

  if (successUpdateCoupon.value) {
    setTimeout(() => {
      router.back();
    }, NOTIFICATION_DURATION);
  }
});

const { handleSubmit } = useForm({ initialValues: { ...coupon } });

const deleteEmptyInputValues = (input: CreateCouponInput | UpdateCouponInput) => {
  const result = { ...input };

  for (const [prop, value] of Object.entries(result)) {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      delete result[prop as keyof typeof input];
    }
  }

  return result;
};

const onSubmit = handleSubmit((values) => {
  const couponData = {
    ...values,
    discount: +values.discount,
    maxRedemptions: +values.maxRedemptions,
    experienceId: (values.experienceId as Option)?.value,
    expirationDate: values.expirationDate ? new Date(String(values.expirationDate)) : '',
  };

  if (!isEdit.value) {
    dataCreateInput.value = deleteEmptyInputValues(couponData) as CreateCouponInput;
    dataCreateCoupon.data = { ...dataCreateInput.value };
  } else {
    dataUpdateInput.value = deleteEmptyInputValues(couponData);
    dataUpdateCoupon.data = { ...dataUpdateInput.value };
  }
});

const correctMessage = computed(() => {
  switch (true) {
    case successCreateCoupon.value:
      return {
        status: 'success' as NotificationStatus,
        message: addCouponSuccess,
      };

    case errorCreateCoupon.value:
      return {
        status: 'error' as NotificationStatus,
        message: addCouponError,
      };

    case successUpdateCoupon.value:
      return {
        status: 'success' as NotificationStatus,
        message: updateCouponSuccess,
      };

    case errorUpdateCoupon.value:
      return {
        status: 'error' as NotificationStatus,
        message: updateCouponError,
      };
    default:
      return {
        status: 'error' as NotificationStatus,
        message: string.genericError,
      };
  }
});
</script>

<template>
  <form class="CouponForm" @submit="onSubmit">
    <FormGroup :title="headingForm">
      <template #formContent>
        <InputText
          :class="[
            input.class,
            input.type === 'date' ? 'inputData' : '',
            { 'data-write': isWriteData },
            input.type === 'date' && getInitialValue(input.name) ? 'inputData--value' : '',
          ]"
          v-for="(input, index) in inputs"
          :key="index"
          v-bind="{ ...input }"
          :initial-value="getInitialValue(input.name)"
          @keystart="(s: string) => writeData(s, input.type)"
        />
        <Select
          :options="experiencesInfo"
          :placeholder="selectPlaceholder"
          :name="selectName"
          :initial-value="experiencesInfo.find((exp) => exp.value === experienceId)"
        />
      </template>
    </FormGroup>
    <Button
      class="CouponForm-button"
      :label="id ? updateCodeButton : createCodeButton"
      :variant="id ? 'secondary' : 'primary'"
      native-type="submit"
    />
    <Notification
      v-if="successCreateCoupon || errorCreateCoupon || successUpdateCoupon || errorUpdateCoupon"
      :message="correctMessage?.message"
      :status="correctMessage?.status"
      :duration="NOTIFICATION_DURATION"
    />
  </form>
</template>

<style scoped lang="scss">
.CouponForm {
  :deep {
    .FormGroup {
      margin-bottom: 4rem;
      &-title {
        margin-bottom: 3.2rem;
      }
      .InputText {
        margin-bottom: 2.4rem;
        clear: both;
        .o-field__message {
          display: none;
        }

        &.splitted {
          width: calc(50% - 1.2rem);
          clear: none;
        }

        &.left {
          float: left;
        }

        &.right {
          float: right;
        }

        &.inputData {
          .--error {
            .o-input {
              color: $status;
              &:before {
                content: '';
              }
            }
          }
          .o-input {
            color: transparent;
            text-transform: uppercase;

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
              display: none;
              -webkit-appearance: none;
            }

            &::before {
              content: 'DD/MM/YYYY';
              color: $black-50;
            }

            &--danger {
              color: $status;
              &::before {
                content: '';
              }
            }

            &:focus-visible {
              &:before {
                content: '';
              }
              color: $primary-color;
            }
          }
          &.data-write {
            .o-input {
              color: $primary-color;

              &::before {
                content: '';
              }
            }
          }
          &--value {
            .o-input {
              color: $black;
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &-label {
    text-transform: uppercase;
    margin-bottom: 1.6rem;
  }

  &-button {
    display: block;
    margin: auto;
  }
}
</style>
