import { usersFragment } from '@/api/fragments/users';
import { gql } from '@/api/graphql-client';

export const talentFragment = gql`
  fragment talent on Talent {
    additionalInfo
    biography
    collaborationNotes
    collaborations
    country
    createdAt
    deletedAt
    digitalNomad
    prediction
    documents {
      documentId
      type
      url
    }
    email
    experience
    firstName
    freelance
    github
    id
    zohoId
    industries
    inferenceReview {
      createdAt
      id
      note
      prediction
      review
      updatedAt
      user {
        ...node
      }
    }
    internationalProjects
    interviewSent
    interviewRecapSent
    languages {
      language
      languageId
      level
    }
    lastName
    mobile
    newsletter
    notes
    notesDetails {
      firstName
      lastName
      updatedAt
      userId
    }
    openToEmployment
    portfolioLinks {
      link
      url
    }
    rate
    rejectSent
    reminderSent
    search
    specialities {
      expertise
      skills
    }
    status
    termsAcceptance
    tools {
      expertise
      technologies {
        level
        technology
        technologyId
      }
    }
    updatedAt
    workplace
  }
  ${usersFragment}
`;
