import { gql } from '@/api/graphql-client';

export const bookingsFragment = gql`
  fragment bookings on Guest {
    id
    birthDate
    company
    createdAt
    email
    firstName
    gender
    isOwner
    lastName
    role
    roomType
    updatedAt
  }
`;
