import json from '@/data/FiltersOptions.json';

export enum IFiltersOptions {
  Agencies = 'Agencies',
  Talents = 'Talents',
}

type IFiltersProps = { filters: IFiltersOptions };

export const useFilters = (props: IFiltersProps) => {
  const { filters } = props;

  const items = json[filters].select;

  return { items };
};
