<script setup lang="ts">
import Nav from '@/components/molecules/Nav.vue';
import Tab from '@/components/atoms/Tab.vue';
import Search from '@/components/atoms/Search.vue';
import Button from '@/components/atoms/Button.vue';
import Modal from '@/components/atoms/Modal.vue';
import { getDeals } from '@/api/queries/deals';
import { useArchiveDeals } from '@/api/mutations/archiveDeals';
import { onMounted, ref, watch } from 'vue';
import { DealStage } from '@/gql/Gql.types';
import { useRouteQuery } from '@vueuse/router';
import Pagination from '@/components/molecules/Pagination.vue';
import Notification, { type INotificationProps } from '@/components/atoms/Notification.vue';

const tab = useRouteQuery('tab');
const nav = useRouteQuery('nav');
const query = useRouteQuery('query');

const notificationMessage = ref('');
const notificationStatus = ref<INotificationProps['status']>('');
const showNotification = ref(false);

const params = ref<{
  first?: number;
  after?: string;
  before?: string;
  last?: number;
}>({
  first: 10,
});

const currentPage = ref(1);

const archiveModalId = ref('');

const setTab = (value: string) => {
  tab.value = value;
};

const setNav = (value: string) => {
  nav.value = value;
};

const search = (value: string) => {
  query.value = value;
  currentPage.value = 1;
  params.value.first = 10;
  delete params.value.after;
  delete params.value.before;
  delete params.value.last;
};

const { data } = getDeals({
  params,
  filters: {
    stage: tab as unknown as DealStage[],
    query: query as unknown as string,
    isArchived: nav as unknown as boolean,
  },
});

const changePage = (type: string, cursor: string) => {
  if (type === 'next') {
    if (currentPage.value < Math.ceil((data.value?.deals.totalCount as number) / 10)) {
      currentPage.value += 1;
    }
    params.value.first = 10;
    delete params.value.before;
    delete params.value.last;
    params.value.after = cursor;
  } else if (type === 'prev') {
    if (currentPage.value > 1) currentPage.value -= 1;
    if (currentPage.value === 1) {
      delete params.value.after;
      delete params.value.before;
      delete params.value.last;
    } else {
      delete params.value.after;
      delete params.value.first;
      params.value.last = 10;
      params.value.before = cursor;
    }
  } else if (type === 'first') {
    currentPage.value = 1;
    delete params.value.after;
    delete params.value.before;
    delete params.value.last;
    params.value.first = 10;
  } else if (type === 'last') {
    currentPage.value = Math.ceil((data.value?.deals.totalCount as number) / 10);
    delete params.value.first;
    delete params.value.after;
    params.value.before = cursor;
    params.value.last =
      (data.value?.deals.totalCount as number) -
      (Math.ceil((data.value?.deals.totalCount as number) / 10) - 1) * 10;
  }
};

const { mutate, isSuccess, isError } = useArchiveDeals();

const archiveProject = (id: string) => {
  mutate({
    ids: [id],
    archive: nav.value === 'ARCHIVED' ? false : true,
  });
  archiveModalId.value = '';
};

watch([isSuccess, isError], () => {
  if (isSuccess.value) {
    notificationMessage.value = `Project ${
      nav.value === 'ARCHIVED' ? 'moved to In progress' : 'moved to Archive'
    } successfully`;
    notificationStatus.value = 'success';
    showNotification.value = true;
  } else if (isError.value) {
    notificationMessage.value = `Error moving project ${
      nav.value === 'ARCHIVED' ? 'to In progress' : 'to Archive'
    }`;
    notificationStatus.value = 'error';
    showNotification.value = true;
  } else {
    showNotification.value = false;
  }
});

onMounted(() => {
  if (!tab.value) {
    setTab('MATCHING');
  }
  if (!nav.value) {
    setNav('IN_PROGRESS');
  }
});

const navItems = [
  {
    value: 'IN_PROGRESS',
    text: 'In progress',
  },
  {
    value: 'ARCHIVED',
    text: 'Archived',
  },
];
const tabItems = [
  {
    name: 'MATCHING',
    label: 'Matching',
  },
  {
    name: 'SENT',
    label: 'Sent',
  },
  {
    name: 'INTERVIEW',
    label: 'Interview',
  },
  {
    name: 'HOT',
    label: 'HOT',
  },
];
</script>
<template>
  <Nav
    :items="navItems"
    :active-item="nav as string"
    @clicked="
      (nav) => {
        setNav(nav.value);
        currentPage = 1;
        params = {
          first: 10,
        };
      }
    "
  ></Nav>
  <div class="tabSearch">
    <Tab
      :items="tabItems"
      :initial-active="tab as string"
      @clicked="(value: string) => {
        setTab(value);
        currentPage = 1;
        params = {
          first: 10,
        }
      }"
    />
    <Search placeholder="Search projects" @keystart="search" />
  </div>
  <div v-if="data">
    <div class="projectsList" v-for="deal in data?.deals.nodes" :key="deal.title">
      <div class="project">
        <div class="projectInfo">
          <div>
            <RouterLink :to="`/projects/${deal.id}`" class="projectLink">
              <h2 class="heading-h4">{{ deal.title }}</h2>
            </RouterLink>
            <p class="company">{{ deal.companyName }}</p>
            <div class="divider"></div>
          </div>
          <div class="projectData">
            <div class="element">
              <span class="title">Status</span>
              <span class="badge">{{ deal.stage }}</span>
            </div>
            <div class="element">
              <span class="title">Pool</span>
              <p>{{ deal.poolCount }} Candidates</p>
            </div>
            <div class="element">
              <span class="title">Created</span>
              <p>{{ new Date(deal.createdAt).toLocaleDateString('it-IT') }}</p>
            </div>
            <div class="element">
              <span class="title">TG Owner</span>
              <p>{{ deal.user?.firstname }} {{ deal.user?.lastname }}</p>
            </div>
            <div class="element">
              <span class="title">Shortlist</span>
              <p>{{ deal.shortlistCount }} Candidates</p>
            </div>
            <div class="element">
              <span class="title">Modified</span>
              <p>{{ new Date(deal.updatedAt).toLocaleDateString('it-IT') }}</p>
            </div>
          </div>
        </div>

        <div class="actions">
          <RouterLink :to="`/projects/${deal.id}`" class="viewProject">View Project</RouterLink>
          <Button
            :label="`Move to ${nav === 'ARCHIVED' ? 'In progress' : 'Archived'}`"
            variant="secondary"
            @click="archiveModalId = deal.id"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="skeletons" v-if="!data">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <Modal
    head="Archive Project"
    :is-modal-active="Boolean(archiveModalId)"
    @close-modal="archiveModalId = ''"
  >
    <template #modal-main>
      <div class="archiveProject">Are you sure you want to archive this project?</div>
    </template>
    <template #modal-footer>
      <Button label="Cancel" variant="secondary" @click="archiveModalId = ''" />
      <Button
        :label="`Move to ${nav === 'ARCHIVED' ? 'In progress' : 'Archived'}`"
        variant="primary"
        @click="archiveProject(archiveModalId)"
      />
    </template>
  </Modal>
  <Pagination
    :has-next-page="data?.deals.pageInfo.hasNextPage as boolean"
    :has-previous-page="data?.deals.pageInfo.hasPreviousPage as boolean"
    :total-item="data?.deals.totalCount as number"
    :current-page="currentPage"
    :next-page="data?.deals.pageInfo.endCursor as string"
    :prev-page="data?.deals.pageInfo.startCursor as string"
    label="projects"
    :total-pages="Math.round((data?.deals.totalCount as number) / 10) === 0 ? 1 : Math.ceil((data?.deals.totalCount as number) / 10)"
    @change-page="changePage"
  />
  <Notification
    v-if="showNotification"
    :status="notificationStatus"
    :message="notificationMessage"
    :duration="5000 as number"
  />
</template>

<style lang="scss" scoped>
.skeletons {
  padding: 5rem 4rem 5rem 4rem;
  & > div {
    background-color: $black-10;
    border-radius: 0.4rem;
    height: 366px;
    margin-bottom: 2rem;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
}
.viewProject {
  background-color: $blue;
  padding: 1.1rem 1.6rem;
  border-radius: 0.4rem;
  text-decoration: none;
  color: white;
  font-family: $primary-font-family;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
}
.projectLink {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: $blue;
  }
  width: 20rem;
}
.tabSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 4rem 2rem;
}
.projectsList {
  padding: 4rem;
  &:nth-child(odd) {
    .project {
      background-color: $black-02;
    }
  }
}
.project {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  font-family: $primary-font-family;
  padding: 5rem 6rem 5rem 6rem;

  .projectInfo {
    .heading-h4 {
      height: 20px;
      margin-top: 0rem;
    }
    .company {
      font-size: 2rem;
      color: $black-30;
      font-weight: $primary-font-weight;
      height: 5px;
    }

    .divider {
      width: 220px;
      height: 0.1rem;
      background-color: $black-10;
      margin: 4rem 0;
    }

    .projectData {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 4rem 8rem;

      .element {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 50px;

        .title {
          font-size: 1.5rem;
          color: $black-50;
          font-weight: $primary-font-weight;
        }
        & p {
          font-size: 1.5rem;
          font-weight: $primary-font-weight;
          height: 15px;
          line-height: 1px;
        }
        .badge {
          background-color: $accent;
          color: white;
          text-transform: uppercase;
          padding: 0.75rem;
          border-radius: 4px;
          width: fit-content;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .accentButton {
      width: auto;
    }
  }
}
.archiveProject {
  font-size: 2rem;
  color: $black;
  font-weight: $primary-font-weight;
  font-family: $primary-font-family;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
}
</style>
