import { gql } from 'graphql-request';
import type { MutationHardDeleteCouponArgs, HardDeleteCouponMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const hardDeleteCouponMutation = gql`
  mutation HardDeleteCoupon($couponId: String!) {
    hardDeleteCoupon(couponId: $couponId)
  }
`;

export const hardDeleteCoupon = (
  props?: MutationHardDeleteCouponArgs,
  options?: UseQueryOptions<
    HardDeleteCouponMutation,
    MutationHardDeleteCouponArgs,
    HardDeleteCouponMutation,
    any[]
  >
): UseQueryReturnType<HardDeleteCouponMutation, MutationHardDeleteCouponArgs> =>
  useGraphql({
    query: hardDeleteCouponMutation,
    variables: props,
    options,
  });
