<script setup lang="ts">
import Header from '@/components/organisms/Header/Header.vue';
import strings from '@/data/GenericStrings.json';
import { useUserStore } from '@/stores/user';
import { useCustomMessage } from '@/utils/customErrorMessagges';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

onBeforeMount(() => {
  useCustomMessage();
});

const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const route = useRoute();
const path = computed(() => route.path);
const excludePageHeader = ['/login', '/lost-password', '/reset-password'];

const currentRole = ref(userRole.value);

watch(
  path,
  () => {
    if (userRole.value) {
      currentRole.value = userRole.value;
    }
  },
  { immediate: true }
);
const includeHeader = computed(() => {
  return !excludePageHeader.some((excludedPath) => path.value.includes(excludedPath));
});

const items = computed(() => {
  const commonRoutes = [
    {
      label: strings.Talents,
      route: '/talents',
    },
    {
      label: strings.agencies,
      route: '/agencies',
    },
    {
      label: 'Projects',
      route: '/projects',
    },
    {
      label: strings.Analytics,
      route: 'https://analytics.wearecosmico.com',
    },
  ];

  if (currentRole.value === 'EVENT_MANAGER') {
    return [
      {
        label: strings.Experiences,
        route: '/experiences',
      },
      {
        label: strings.coupon,
        route: '/coupon',
      },
    ];
  }

  if (currentRole.value === 'JUNIOR' || currentRole.value === 'SUPERVISOR') {
    return commonRoutes;
  }

  return [
    ...commonRoutes,
    {
      label: strings.UsersManagement,
      route: '/users',
    },
    {
      label: strings.MailManagement,
      route: '/mails',
    },
    {
      label: strings.coupon,
      route: '/coupon',
    },
  ];
});
</script>
<template>
  <div
    :class="`${
      includeHeader ? (route.name === 'Analytics' ? 'Analytics_wrapper' : 'App__wrapper') : ''
    }`"
  >
    <Header v-if="includeHeader" :items="items" />
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import '@/styles/main.scss';

.App__wrapper {
  padding-top: 10.2rem;
  overflow: auto;
}
.Analytics_wrapper {
  padding-top: 30px !important;
  overflow: hidden;
}
</style>
