import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetSkillQuery, QuerySkillArgs } from '@/gql/Gql.types';
import { skillFragment } from '@/api/fragments/skill';

const skillQuery = gql`
  query getSkill($skillId: String!) {
    skill(skillId: $skillId) {
      ...skill
    }
  }
  ${skillFragment}
`;

export const getSkill = (
  props?: QuerySkillArgs,
  options?: UseQueryOptions<GetSkillQuery, QuerySkillArgs, GetSkillQuery, any[]>
): UseQueryReturnType<GetSkillQuery, QuerySkillArgs> =>
  useGraphql({
    query: skillQuery,
    variables: props,
    options,
  });
