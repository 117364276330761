import { gql } from 'graphql-request';
import type {
  RemoveAgencyToolMutationMutation,
  MutationRemoveAgencyToolArgs,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const removeAgencyToolMutation = gql`
  mutation RemoveAgencyToolMutation($technologyId: String!, $agencyId: String!) {
    removeAgencyTool(technologyId: $technologyId, agencyId: $agencyId)
  }
`;

export const removeAgencyTool = (
  props?: MutationRemoveAgencyToolArgs,
  options?: UseQueryOptions<
    RemoveAgencyToolMutationMutation,
    MutationRemoveAgencyToolArgs,
    RemoveAgencyToolMutationMutation,
    any[]
  >
): UseQueryReturnType<RemoveAgencyToolMutationMutation, MutationRemoveAgencyToolArgs> =>
  useGraphql({
    query: removeAgencyToolMutation,
    variables: props,
    options,
  });
