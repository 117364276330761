import { gql } from '@/api/graphql-client';

export const agencyFragment = gql`
  fragment agency on Agency {
    additionalInfo
    biography
    collaborationNotes
    collaborations
    companyName
    country
    createdAt
    deletedAt
    documents {
      documentId
      type
      url
    }
    email
    employees
    experience
    id
    industries
    internationalProjects
    interviewSent
    languages {
      language
      languageId
      level
    }
    mobile
    newsletter
    notes
    notesDetails {
      firstName
      lastName
      updatedAt
      userId
    }
    portfolioLinks {
      link
      url
    }
    rejectSent
    reminderSent
    search
    specialities {
      expertise
      skills
    }
    status
    termsAcceptance
    tools {
      expertise
      technologies {
        level
        technology
        technologyId
      }
    }
    updatedAt
    vat
    website
    workplace
  }
`;
