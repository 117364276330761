import { gql } from '@/api/graphql-client';
import { agencyFragment } from '@/api/fragments/agency';
import type { MutationUpdateAgencyNotesArgs, Agency } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateAgencyNotesMutation = gql`
  mutation UpdateAgencyNotes($agencyId: String!, $notes: String!) {
    updateAgencyNotes(agencyId: $agencyId, notes: $notes) {
      ...agency
    }
  }
  ${agencyFragment}
`;

export const updateAgencyNotes = (
  props: MutationUpdateAgencyNotesArgs,
  options?: UseQueryOptions<Agency, MutationUpdateAgencyNotesArgs, Agency, any[]>
): UseQueryReturnType<Agency, MutationUpdateAgencyNotesArgs> =>
  useGraphql({
    query: updateAgencyNotesMutation,
    variables: props,
    options,
  });
