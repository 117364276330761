import { gql } from 'graphql-request';
import type { MutationSoftDeleteAgenciesArgs, SoftDeleteAgenciesMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const softDeleteAgenciesMutation = gql`
  mutation SoftDeleteAgencies($agencyIds: [String!]!) {
    softDeleteAgencies(agencyIds: $agencyIds)
  }
`;

export const softDeleteAgencies = (
  props?: MutationSoftDeleteAgenciesArgs,
  options?: UseQueryOptions<
    SoftDeleteAgenciesMutation,
    MutationSoftDeleteAgenciesArgs,
    SoftDeleteAgenciesMutation,
    any[]
  >
): UseQueryReturnType<SoftDeleteAgenciesMutation, MutationSoftDeleteAgenciesArgs> =>
  useGraphql({
    query: softDeleteAgenciesMutation,
    variables: props,
    options,
  });
