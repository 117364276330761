import { experienceFragment } from '@/api/fragments/experience';
import { gql } from '@/api/graphql-client';

export const couponFragment = gql`
  fragment coupon on Coupon {
    associatedDomain
    associatedEmail
    code
    createdAt
    discount
    experience {
      ...Exp
    }
    experienceId
    expirationDate
    id
    maxRedemptions
    name
    redemptions {
      billing {
        ...billing
      }
      coupon
      createdAt
      experience {
        ...Exp
      }
      experienceId
      flexible
      guests {
        ...bookings
      }
      id
      insurance
      paymentId
      status
      totalAmount
      updatedAt
    }
    updatedAt
    deletedAt
  }
  ${experienceFragment}
`;
