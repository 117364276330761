export const useCheckError = () => {
  setTimeout(() => {
    const errorMessages = document.querySelectorAll('.error-message');

    for (const errorMessage of errorMessages) {
      const style = window.getComputedStyle(errorMessage);
      if (style.display !== 'none') {
        errorMessage.scrollIntoView({ block: 'center', behavior: 'smooth' });
        break;
      }
    }
  }, 100);
};
