import { gql } from '@/api/graphql-client';
import { type MutationAutoMatchingArgs, type AutoMatchingOutput } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { fetcher } from '@/api/use-graphql';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { dealTalentSelectionQuery } from '../queries/dealTalentSelection';

export const autoMatchingMutation = gql`
  mutation autoMatching($id: String!) {
    autoMatching(id: $id) {
      api_version
      count
      environment
      query
      status
    }
  }
`;

export const useAutoMatching = () => {
  const queryClient = useQueryClient();
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);

  const fn = (props: MutationAutoMatchingArgs) =>
    fetcher<
      {
        autoMatching: AutoMatchingOutput;
      },
      MutationAutoMatchingArgs
    >({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
      },
      query: autoMatchingMutation,
      variables: props,
    });

  const mutation = useMutation({
    mutationFn: fn,
    onSuccess: () => {
      queryClient.invalidateQueries([dealTalentSelectionQuery]);
    },
  });

  return mutation;
};
