import { loginMutation } from '@/api/mutations/login';
import { logoutMutation } from '@/api/mutations/logout';
import { refreshTokenMutation } from '@/api/mutations/refreshToken';
import { resetPasswordMutation } from '@/api/mutations/resetPassword';
import { sendEmailResetPasswordMutation } from '@/api/mutations/sendResetPasswordEmail';
import { fetcher } from '@/api/use-graphql';
import type {
  LoginInput,
  LoginMutation,
  LoginMutationVariables,
  Mutation,
  MutationRefreshTokenArgs,
  MutationResetPasswordArgs,
  MutationSendResetPasswordEmailArgs,
  ResetPasswordInput,
  Token,
} from '@/gql/Gql.types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export const useUserStore = defineStore('user', () => {
  const router = useRouter();

  const jwt = ref(JSON.parse(<string>localStorage.getItem('jwt')) || '');
  const userRole = ref(JSON.parse(<string>localStorage.getItem('userRole')) || '');
  const userId = ref(JSON.parse(<string>localStorage.getItem('userId')) || '');

  const returnUrl = ref<string>('');

  const login = async ({ password, email }: LoginInput) => {
    const loggedUser = await fetcher<LoginMutation, LoginMutationVariables>({
      query: loginMutation,
      variables: { data: { password, email } },
    });

    jwt.value = loggedUser.login.accessToken;
    userRole.value = loggedUser.login.user.role;
    userId.value = loggedUser.login.user.id;

    localStorage.setItem('jwt', JSON.stringify(jwt.value));
    localStorage.setItem('userRole', JSON.stringify(userRole.value));
    localStorage.setItem('userId', JSON.stringify(userId.value));

    if (userRole.value === 'ALL_STAR') {
      await router.push('/login');
      throw new Error('You are not authorized to access this page');
    }
    await router.push(returnUrl.value || '/');
    console.log(await fetch('http://localhost:3000/api/auth/csrf'), 'test');
  };

  const refreshToken = async (token: string) => {
    const refreshedToken = await fetcher<Token, MutationRefreshTokenArgs>({
      query: refreshTokenMutation,
      variables: { token },
    }).catch((err) => {
      /**
       * If a request fails, try to request a new token. If it fails 2 times, proceed to log out.
       */
      jwt.value = '';
      logout();
      return err;
    });

    if (!jwt.value || !refreshedToken.accessToken) return;

    jwt.value = refreshedToken.accessToken;

    localStorage.setItem('jwt', JSON.stringify(refreshedToken.accessToken));
  };

  const logout = async () => {
    if (jwt.value) {
      await fetcher({
        query: logoutMutation,
        headers: {
          Authorization: `Bearer ${jwt.value}`,
        },
      });
    }

    jwt.value = '';
    userRole.value = '';
    userId.value = '';

    localStorage.removeItem('jwt');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userId');
    localStorage.removeItem('filter-talent');
    localStorage.removeItem('filter-agency');
    localStorage.removeItem('talents-visible-result');
    localStorage.removeItem('agencies-visible-result');

    router.push('/login');
  };

  const sendEmailResetPassword = async ({ email }: MutationSendResetPasswordEmailArgs) => {
    await fetcher<Mutation['sendResetPasswordEmail'], MutationSendResetPasswordEmailArgs>({
      query: sendEmailResetPasswordMutation,
      variables: { email },
    });
  };

  const resetPassword = async ({ newPassword, token }: ResetPasswordInput) => {
    await fetcher<Mutation['resetPassword'], MutationResetPasswordArgs>({
      query: resetPasswordMutation,
      variables: { data: { newPassword, token } },
    });
  };

  // eslint-disable-next-line no-undef
  if (import.meta.env.MODE === 'development') {
    document.cookie = `cosmico-analytics-jwt=${jwt.value}; path=/;`;
  } else {
    document.cookie = `cosmico-analytics-jwt=${jwt.value}; path=/;domain=wearecosmico.com;`;
  }

  return {
    jwt,
    userRole,
    userId,
    login,
    logout,
    refreshToken,
    returnUrl,
    sendEmailResetPassword,
    resetPassword,
  };
});
