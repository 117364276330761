import { gql } from 'graphql-request';
import type {
  RemindInviteAgencyMutationMutation,
  MutationRemindInviteAgencyArgs,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const remindInviteAgencyMutation = gql`
  mutation remindInviteAgencyMutation($agencyId: String!) {
    remindInviteAgency(agencyId: $agencyId)
  }
`;

export const remindInviteAgency = (
  props?: MutationRemindInviteAgencyArgs,
  options?: UseQueryOptions<
    RemindInviteAgencyMutationMutation,
    MutationRemindInviteAgencyArgs,
    RemindInviteAgencyMutationMutation,
    any[]
  >
): UseQueryReturnType<RemindInviteAgencyMutationMutation, MutationRemindInviteAgencyArgs> =>
  useGraphql({
    query: remindInviteAgencyMutation,
    variables: props,
    options,
  });
