const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const number = '0123456789';
const special = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
const characterSet = lowercase + uppercase + number + special;

const getRandomCharacter = (charset: string) => {
  return charset.charAt(Math.floor(Math.random() * charset.length));
};

const useGeneratePassword = (lenght: number) => {
  let password = '';

  password += getRandomCharacter(lowercase);
  password += getRandomCharacter(uppercase);
  password += getRandomCharacter(number);
  password += getRandomCharacter(special);

  for (let i = 4; i < lenght; i++) {
    password += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
  }

  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');
  return password;
};

export default useGeneratePassword;
