import { mappedStatus } from '@/data/constants';
import strings from '@/data/GenericStrings.json';
import type { TalentCommonDataQuery } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';

const { years, labelStatus } = strings;

interface IData {
  filters: TalentCommonDataQuery | undefined;
  filtersItems: {
    label: string;
    value: string;
    hasExpertise: boolean;
    hasLevel: boolean;
    isBoolean: boolean;
    isLikeRadio: boolean;
  }[];
}

const mapDefaultOption = (key: string, option: string) => {
  switch (key) {
    case 'status':
      return {
        label: mappedStatus[option as keyof typeof mappedStatus],
        value: option,
      };

    case 'seniorities':
      return {
        label: `${option} ${years}`,
        value: option,
      };

    case 'freelance':
      return {
        label: labelStatus[option as keyof typeof labelStatus],
        value: option.trim().toLowerCase(),
      };

    case 'prediction':
      return {
        label: option,
        value: option,
      };

    default:
      return {
        label: option,
        value: option.trim().toLowerCase(),
      };
  }
};

const removeDuplicates = (array: Option[]): Option[] => {
  if (!array) return [];

  const uniqueOptions = Array.from(new Set(array.map((option) => JSON.stringify(option)))).map(
    (jsonOption) => JSON.parse(jsonOption)
  );

  return uniqueOptions;
};

export const useFilterDataMapper = ({ filters, filtersItems }: IData) => {
  if (!filters || !filters?.talentCommonData) {
    return [];
  }

  const talentCommonData = filters?.talentCommonData;

  return filtersItems.map((el) => {
    const key = el.value;
    const commonData = talentCommonData[key as keyof typeof talentCommonData] || [];
    const isBoolean = el.isBoolean || false;
    const { isLikeRadio } = el;

    let options: any;
    let optGroup;

    if (el.hasExpertise) {
      optGroup = commonData
        .flatMap((option) => {
          if (typeof option !== 'object') {
            return option;
          }
          return {
            name: option.expertise,
            options: 'skills' in option ? option.skills : option.technologies,
          };
        })
        .map((option) => ({
          name: (typeof option !== 'string' && option.name) || '',
          options: (typeof option === 'object' &&
            option.options.map((value) => ({ label: value, value: value.toLowerCase() }))) || [
            { label: '', value: '' },
          ],
        }));
    } else {
      options = commonData
        .flatMap((option) => {
          if (typeof option !== 'object') return option;
          return 'skills' in option ? option.skills : option.technologies;
        })
        .map((option) => mapDefaultOption(key, option));
    }

    return {
      valueSelect: el.value === 'countries' ? 'country' : el.value,
      placeholder: el.label,
      optGroup,
      options:
        isBoolean && isLikeRadio
          ? [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]
          : isBoolean && !isLikeRadio
          ? [{ label: 'Yes', value: 'true' }]
          : optGroup
          ? options
          : removeDuplicates(options),
      hasExpertise: el.hasExpertise,
      hasLevel: !!el.hasLevel,
      isBoolean: isBoolean,
      isLikeRadio: isLikeRadio,
    };
  });
};
