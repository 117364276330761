<script setup lang="ts">
import { ref } from 'vue';
import LoginForm from '@/components/molecules/LoginForm.vue';
import LostPasswdString from '@/data/LostPassword.json';
import { useUserStore } from '@/stores/user';
import type { MutationSendResetPasswordEmailArgs } from '@/gql/Gql.types';
import Notification from '@/components/atoms/Notification.vue';

const { title, formTitle, inputs, recoverBtn, returnLogin, messageRecoverPasswd, backToLogin } =
  LostPasswdString;
const userStore = useUserStore();

const { sendEmailResetPassword } = userStore;

const emailSend = ref(false);

const error = ref();

const sendEmailPasswd = async (s: MutationSendResetPasswordEmailArgs) => {
  emailSend.value = true;
  error.value = '';
  try {
    await sendEmailResetPassword(s);
  } catch (e) {
    error.value = e;
  }
};
</script>

<template>
  <Notification v-if="error" :message="error" status="error" />
  <LoginForm
    :title="title"
    :form-title="formTitle"
    :inputs="inputs"
    :primary-btn="recoverBtn"
    :link-btn="returnLogin"
    :message="messageRecoverPasswd"
    :sending="emailSend"
    :back-to-login="backToLogin"
    @submitted="sendEmailPasswd"
  />
</template>
