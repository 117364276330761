import json from '@/data/TabOptions.json';
import { onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export enum ITab {
  Agencies = 'Agencies',
  Talents = 'Talents',
  Experiences = 'Experiences',
  Coupon = 'Coupon',
  // todo: all picker groups options in here
}

type IUseTabOptions = {
  tab: ITab;
  pathName: string;
};

export const useTabOptions = (props: IUseTabOptions) => {
  const { tab, pathName } = props;

  const route = useRoute();
  const router = useRouter();

  const items = json[tab];
  const activeTab = ref<string | { label: string; name: string }>();

  onBeforeMount(() => {
    const savedTab = localStorage.getItem(`activeTab-${pathName}`);
    const setActive = json[tab].find((item) => item.name === savedTab);

    if (route.query?.tab) {
      router.push({ query: { ...route.query } });
      const setActiveLink = json[tab].find((item) => item.name === route.query?.tab);
      activeTab.value = setActiveLink?.name;
      localStorage.setItem(`activeTab-${pathName}`, setActiveLink?.name ?? 'TOTAL');
      return;
    }

    if (setActive) {
      activeTab.value = setActive.name;
      router.push({
        query: {
          ...route.query,
          tab:
            typeof activeTab.value === 'string'
              ? activeTab.value
              : (
                  activeTab.value as {
                    label: string;
                    name: string;
                  }
                ).name,
        },
      });
    } else {
      activeTab.value = items[0].name;
      router.push({ query: { ...route.query, tab: items[0].name } });
    }
  });

  const setActiveTab = (e: { label: string; name: string }) => {
    activeTab.value = e;
    router.push({ query: { ...route.query, tab: typeof e === 'string' ? e : e.name } });
    localStorage.setItem(`activeTab-${pathName}`, typeof e === 'string' ? e : e.name);
  };

  return {
    items,
    activeTab,
    setActiveTab,
  };
};
