<script setup lang="ts">
import { computed, ref, toRefs, watchEffect } from 'vue';
import { Field } from 'vee-validate';
import Checkbox from '@/components/atoms/Checkbox.vue';

interface IMulticheckbox {
  name?: string;
  options: string[];
  initialChecked?: string[];
}

const props = defineProps<IMulticheckbox>();

const { name, options, initialChecked } = toRefs(props);

const selected = ref<string[]>(initialChecked?.value || []);

watchEffect(() => {
  selected.value = initialChecked?.value || [];
});

const selectedItem = computed(() => {
  if (selected.value.length !== 0) {
    return [...selected.value];
  } else {
    return undefined;
  }
});

const addSelected = (item: string) => {
  selected.value.includes(item)
    ? selected.value.splice(selected.value.indexOf(item), 1)
    : selected.value.push(item);
};
</script>

<template>
  <div class="MultiCheckbox">
    <Field
      v-slot="{ field, errors }"
      v-model="selectedItem"
      :name="name ?? ''"
      :rules="{ required: true }"
    >
      <o-inputitems v-model="selectedItem" v-bind="field" />
      <Checkbox
        v-for="(item, index) in options"
        :key="index"
        :label="item"
        :initial-value="initialChecked?.some((initial) => initial === item)"
        @changed="() => addSelected(item)"
        :standalone="true"
      />

      <p class="error-message text-body-2" v-if="errors[0]">
        {{ errors[0] }}
      </p>
    </Field>
  </div>
</template>

<style scoped lang="scss">
.MultiCheckbox {
  @include flexing(row, start, center);
  gap: 1.6rem;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;

  :deep .o-inputit {
    display: none;
  }
}
</style>
