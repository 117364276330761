<script setup lang="ts">
import Button from '@/components/atoms/Button.vue';
import Modal from '@/components/atoms/Modal.vue';
import Select from '@/components/atoms/Select.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import GenericStrings from '@/data/GenericStrings.json';
import type { InferenceReview } from '@/gql/Gql.types';
import type { Option } from '@/models/multiSelectModel';
import moment from 'moment';
import 'moment/dist/locale/it';
import { useForm } from 'vee-validate';
import { computed, reactive, ref, toRefs, watch } from 'vue';

interface IModalEvaleateStatus {
  open: boolean;
  prediction?: string;
  options: Option[];
  talentId: string;
  inferenceReview?: InferenceReview;
  note: string;
}

const props = defineProps<IModalEvaleateStatus>();

const { open, prediction, options, inferenceReview, talentId, note } = toRefs(props);

const { evaluateStatus, statusRecommended, cancel, confirmButton, lastUpdate, user, firstReview } =
  GenericStrings;

const openModalEvaluateStatus = ref(open.value);

watch(open, (newValue) => {
  openModalEvaluateStatus.value = newValue;
});

const initialSelect = computed(() => {
  return {
    value: inferenceReview?.value?.review || prediction?.value || '',
    label: inferenceReview?.value?.review || prediction?.value || '',
  };
});

const emits = defineEmits(['resetModal', 'inferenceReview']);

watch(openModalEvaluateStatus, (newValue) => {
  emits('resetModal', newValue);
});

const formEvaluateStatus = reactive({
  review: '',
  note: '',
});

const { handleSubmit } = useForm({
  initialValues: {
    review: initialSelect.value,
    note: '',
  },
});

const sendEvaluateStatus = handleSubmit((values) => {
  formEvaluateStatus.review = values.review.value;
  formEvaluateStatus.note = values.note;

  emits('inferenceReview', formEvaluateStatus);
  setTimeout(() => {
    openModalEvaluateStatus.value = false;
  }, 1000);
});
</script>

<template>
  <form @submit="sendEvaluateStatus">
    <Modal
      :is-modal-active="openModalEvaluateStatus"
      @close-modal="openModalEvaluateStatus = false"
      :head="evaluateStatus.title"
    >
      <template #modal-main>
        <div class="popupEvaluate">
          <div :class="'popupEvaluate-main'">
            <div class="popupEvaluate-main--data mb-1">
              <span v-if="inferenceReview?.updatedAt">
                {{ firstReview }}:
                {{ moment(inferenceReview?.createdAt).locale('it').format('YYYY-MM-DD, ddd H:mm') }}
              </span>
              <span v-if="inferenceReview?.updatedAt">
                {{ lastUpdate }}:
                {{ moment(inferenceReview?.updatedAt).locale('it').format('YYYY-MM-DD, ddd H:mm') }}
              </span>
              <span v-if="inferenceReview?.user">
                {{ user }}: {{ inferenceReview?.user.firstname }}
                {{ inferenceReview?.user.lastname }}
              </span>
              <span v-if="talentId">Candidate id: {{ talentId }}</span>
              <div class="text-body-1" v-if="prediction">
                {{ statusRecommended }}: {{ prediction }}
              </div>
            </div>
            <div class="mb-1 FormGroup">
              <Select
                v-if="inferenceReview?.prediction || talentId"
                :placeholder="evaluateStatus.placeholderOption"
                :options="options"
                :name="evaluateStatus.nameSelect"
                :rules="{ required: true }"
                :initial-value="initialSelect"
              />
            </div>
            <TextAreaForm
              :name="evaluateStatus.nameNote"
              :placeholder="evaluateStatus.placeholderNote"
              :initial-value="note"
            />
          </div>
        </div>
      </template>

      <template #modal-footer>
        <component
          :is="Button"
          variant="secondary"
          :label="cancel"
          @clicked="openModalEvaluateStatus = false"
        ></component>
        <component
          :is="Button"
          variant="primary"
          :label="confirmButton"
          native-type="submit"
        ></component>
      </template>
    </Modal>
  </form>
</template>

<style scoped lang="scss">
.popupEvaluate {
  text-align: center;
  margin: auto;

  &-main {
    margin-bottom: 3.2rem;
    padding: 0 2.5rem;

    &--data {
      border: 0.1rem solid $black-20;
      border-radius: 0.4rem;
      @include flexing(column, center, center);
      gap: 0.8rem;
      @extend .text-body-2;
      padding: 2rem 0;
    }
  }
}
</style>
