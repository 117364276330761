<script setup lang="ts">
import Search from '@/components/atoms/Search.vue';
import Tab from '@/components/atoms/Tab.vue';
import FiltersString from '@/data/Filters.json';
import type { ExportAgenciesMutation, ExportTalentsMutation } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { onMounted, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import FiltersSelect from './components/FiltersSelect.vue';

export interface ISelectOptions {
  placeholder: string;
  valueSelect: string;
  hasLevel?: boolean;
  isBoolean?: boolean;
  hasExpertise?: boolean;
  isLikeRadio?: boolean;
  optGroup?: {
    name: string;
    options: Option[];
  }[];
  options?: Option[];
}

export interface FiltersQuery {
  operator?: string;
  filters?: {
    [key: string]: string[];
  };
  keyword?: string;
}

type IFiltersProps = {
  tabItems: { label: string; name: string }[];
  activeTab?: string;
  isDisabled?: boolean;
  selectOptions: ISelectOptions[];
  downloadCSV?: ExportTalentsMutation | ExportAgenciesMutation;
  loadingDownload?: boolean;
  filtersQuery?: FiltersQuery;
  hiddenTab?: boolean;
};

const { searchPlaceholder } = FiltersString;

const props = defineProps<IFiltersProps>();

const { tabItems, activeTab, selectOptions, isDisabled, downloadCSV, filtersQuery } = toRefs(props);

const router = useRouter();
const route = useRoute();

const emit = defineEmits([
  'keystart',
  'active',
  'reset',
  'tabSelected',
  'selectedFilters',
  'startDownload',
]);

const writeInput = ref('');

onMounted(() => {
  const keyword = filtersQuery?.value?.keyword;

  if (keyword) {
    writeInput.value = keyword;
    router.replace({ query: { ...route.query, keyword: writeInput.value } });
    emit('keystart', writeInput.value);
  }
});

const search = (text: string) => {
  writeInput.value = text;
  router.replace({ query: { ...route.query, keyword: writeInput.value } });
  emit('keystart', text);
};

const active = (obj: []) => {
  emit('active', obj);
};

const reset = (obj: []) => {
  writeInput.value = '';
  emit('reset', obj);
};

const startDownload = (start: boolean) => {
  emit('startDownload', start);
};
</script>

<template>
  <div class="Filters">
    <div class="Filters__tabSearch">
      <Tab
        v-if="!hiddenTab"
        :items="tabItems"
        :initial-active="activeTab"
        @clicked="(data) => emit('tabSelected', data)"
      />
      <Search :placeholder="searchPlaceholder" :initial-value="writeInput" @keystart="search" />
    </div>
    <FiltersSelect
      @active="active"
      @reset="reset"
      :select-items="selectOptions"
      :download-c-s-v="downloadCSV"
      :loading-download="loadingDownload"
      @start-download="startDownload"
      :filters-query="filtersQuery"
    />
  </div>
</template>

<style scoped lang="scss">
.Filters {
  &__tabSearch {
    @include flexing(row, space-between, center);
    flex-wrap: wrap;

    :deep {
      .Tab,
      .Search {
        margin-bottom: 4rem;
      }

      .Search {
        margin-left: auto;
      }
    }
  }
}
</style>
