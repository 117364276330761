<script setup lang="ts">
import { toRefs } from 'vue';
import Button from '@/components/atoms/Button.vue';
import type { IPagination } from '@/models/paginationModel.';

const props = defineProps<IPagination>();

const { label, currentPage, nextPage, hasNextPage, prevPage, hasPreviousPage, totalPages } =
  toRefs(props);

const emit = defineEmits(['change-page']);

const changePage = (type: 'first' | 'prev' | 'next' | 'last', cursor: string | undefined) =>
  emit('change-page', type, cursor);
</script>

<template>
  <div class="Pagination mt-2 mb-2">
    <span class="Pagination__total text-body-2">{{ totalItem }} {{ label }}</span>
    <div class="Pagination__buttons">
      <Button
        icon="double-chevron-left"
        :disabled="!(currentPage > 1)"
        @clicked="changePage('first', undefined)"
      />
      <Button
        icon="chevron-left"
        :disabled="!hasPreviousPage"
        @clicked="changePage('prev', prevPage)"
      />
      <Button variant="secondary" :label="currentPage.toString()" />
      <span class="Pagination__buttons__total-page text-label">di {{ totalPages }}</span>
      <Button
        icon="chevron-right"
        :disabled="!hasNextPage"
        @clicked="changePage('next', nextPage)"
      />
      <Button
        icon="double-chevron-right"
        :disabled="currentPage === totalPages"
        @clicked="changePage('last', undefined)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.Pagination {
  @include flexing(row, center, center);
  &__total {
    margin: 0 1.2rem 0;
  }

  &__buttons {
    :deep .Button {
      max-width: 2.4rem;
      max-height: 2.4rem;
      min-width: 2.4rem;
      min-height: 2.4rem;
      padding: 0.4rem;
      border-radius: 0.2rem;
      &.--no-icon {
        max-width: 2.4rem;
        max-height: 2.4rem;
        min-width: 2.4rem;
        min-height: 2.4rem;
        padding: 0.4rem;
        border-radius: 0.2rem;
      }
      .o-btn__label {
        justify-content: center;
      }
    }
    @include flexing(row, center, center);
    gap: 0.4rem;

    &__total-page {
      margin: 0 0.8rem;
    }
  }
}
</style>
