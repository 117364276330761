import { gql } from '@/api/graphql-client';
import { bookingsFragment } from '@/api/fragments/bookings';
import { orderFragment } from '@/api/fragments/order';
import { extraFragment } from '@/api/fragments/extra';

export const categoryFragment = gql`
  fragment category on Category {
    id
    createdAt
    updatedAt
    slug
    title
    experiences {
      id
      availables
      bookings {
        ...bookings
      }
      category {
        id
        createdAt
        updatedAt
        slug
        title
      }
      categorySlug
      createdAt
      discount
      extra {
        ...extra
      }
      from
      hasInsurance
      isActive
      isFlexible
      location
      orders {
        ...order
      }
      price
      slug
      to
      updatedAt
    }
  }
  ${bookingsFragment}
  ${orderFragment}
  ${extraFragment}
`;
