import { gql } from '@/api/graphql-client';

export const inferenceReviewFragment = gql`
  fragment inferenceReview on InferenceReview {
    createdAt
    id
    note
    prediction
    review
    updatedAt
  }
`;
