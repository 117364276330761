<script setup lang="ts">
import { createInferenceReview } from '@/api/mutations/createInferenceReview';
import { inviteTalent } from '@/api/mutations/inviteTalent';
import { migrateToAgency } from '@/api/mutations/migrateToAgency';
import { rejectTalent } from '@/api/mutations/rejectTalent';
import { removeTalentLanguage } from '@/api/mutations/removeTalentLanguage';
import { removeTalentTool } from '@/api/mutations/removeTalentTool';
import { softDeleteTalents } from '@/api/mutations/softDeleteTalents';
import { updateInferenceReview } from '@/api/mutations/updateInferenceReview';
import { updateTalentBiography } from '@/api/mutations/updateTalentBiography';
import { updateTalentNotes } from '@/api/mutations/updateTalentNotes';
import Accordion from '@/components/atoms/Accordion.vue';
import Button from '@/components/atoms/Button.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import InputText from '@/components/atoms/InputText.vue';
import Modal from '@/components/atoms/Modal.vue';
import Notification from '@/components/atoms/Notification.vue';
import Radio from '@/components/atoms/Radio.vue';
import FormGroup from '@/components/molecules/FormGroup.vue';
import LinkModule from '@/components/molecules/LinkModule.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import BoxCategorySkills from '@/components/organisms/BoxCategorySkills/BoxCategorySkills.vue';
import ModalEvaluateStatus from '@/components/organisms/CreateUpdateForm/components/ModalEvaluateStatus.vue';
import MultiButtonDocs from '@/components/organisms/CreateUpdateForm/components/MultiButtonDocs.vue';
import MultiCheckbox from '@/components/organisms/CreateUpdateForm/components/MultiCheckbox.vue';
import { useCheckError } from '@/composables/useCheckErrorForm';
import agenciesString from '@/data/Agencies.json';
import GenericStrings from '@/data/GenericStrings.json';
import talentsString from '@/data/Talents.json';
import FormString from '@/data/UpdateTalentsForm.json';
import {
  NAVIGATION_BACK_DEFAULT_TIME,
  NOTIFICATION_DURATION,
  mappedStatus,
} from '@/data/constants';
import type {
  CreateInferenceReviewInput,
  CreateTalentInput,
  DocumentOutputType,
  InferenceReview,
  LanguageType,
  MutationCreateInferenceReviewArgs,
  MutationRejectTalentArgs,
  MutationSoftDeleteTalentsArgs,
  MutationUpdateTalentBiographyArgs,
  MutationUpdateTalentNotesArgs,
  NotesDetailsType,
  PortfolioLinkInput,
  PortfolioLinkType,
  SpecialityType,
  Talent,
  ToolType,
  UpdateTalentInput,
} from '@/gql/Gql.types';
import { Country, TalentStatus } from '@/gql/Gql.types';
import type { Expertise } from '@/models/boxCategorySkillsModel';
import { useTalentCommonData } from '@/stores/talentCommonData';
import { useUserStore } from '@/stores/user';
import moment from 'moment';
import 'moment/dist/locale/it';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import {
  computed,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  type ComputedRef,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

type actionModal = 'interview' | 'reject' | 'delete' | 'migrate';
type NotificationStatus = 'success' | 'error' | '';

const router = useRouter();
const { fullPath } = useRoute();

const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const isEdit = computed(() => fullPath.includes('edit'));

const {
  params: { id },
} = useRoute();

const {
  delete: deleteString,
  confirmDeleteButton,
  registry,
  specialitiesTitle,
  specialitiesName,
  toolsTitle,
  toolsName,
  experience,
  collaborations: collabStrings,
  note,
  rateTitle,
  rateName,
  biography: biographyString,
  manageTalent,
  categoryTitle,
  categoryName,
  seniorityTalentTitle,
  seniorityTalentName,
  workplaceTitle,
  workplaceName,
  countryTitle,
  countryName,
  industriesTitle,
  industriesName,
  languageTitle,
  languagesName,
  freelanceName,
  digitalNomad,
  internationalProjects,
  paragraghModal,
  feedback,
  additionalInfo: additionalInfoString,
} = FormString;

const {
  deleteTalentsSuccess,
  deleteTalentsError,
  inviteInterview,
  interviewParagraphModal,
  rejectParagraphModal,
  interviewSentModal,
  rejectError,
  rejectTalentSentModal,
  migrateTalentPargraph,
  talent: talentString,
} = talentsString;

const {
  inspectDoc,
  downloadDoc,
  errorViewPDF,
  cancel,
  genericError,
  interview,
  moveIn,
  future,
  cantera,
  confirmButton,
  interviewSentString,
  reminderSentString,
  interviewRecapSentString,
  subscribedNewsletter,
  saveNote,
  labelStatus,
  clear,
  statusRecommended,
  evaluateStatus,
  successSendReview,
  errorSendReview,
  successUpdateReview,
  errorUpdateReview,
  migrateTo,
  migrateSuccessString,
  migrateErrorsString,
  successSavedNote,
  errorSavedNote,
  saveBiography,
  successSavedBiography,
  errorSavedBiography,
  eligible,
  lastUpdate,
  user,
} = GenericStrings;

const { agency: agencyString } = agenciesString;

interface TalentProps extends Omit<Talent, 'id'> {
  submitted: boolean;
}

const props = withDefaults(defineProps<TalentProps>(), {
  additionalInfo: '',
  biography: '',
  collaborationNotes: '',
  collaborations: () => [] as string[],
  country: '' as Country,
  createdAt: '',
  deletedAt: '',
  digitalNomad: false,
  prediction: '',
  documents: () => [] as DocumentOutputType[],
  email: '',
  experience: '',
  firstName: '',
  freelance: '',
  github: '',
  industries: () => [],
  inferenceReview: {} as (props: {}) => InferenceReview,
  internationalProjects: false,
  interviewSent: false,
  interviewRecapSent: false,
  languages: () => [] as LanguageType[],
  lastName: '',
  mobile: '',
  newsletter: false,
  notes: '',
  notesDetails: {} as (props: {}) => NotesDetailsType,
  openToEmployment: false,
  portfolioLinks: () => [] as PortfolioLinkType[],
  rate: '',
  rejectSent: false,
  reminderSent: false,
  search: () => [] as string[],
  specialities: () => [] as SpecialityType[],
  status: TalentStatus.NotVerified,
  termsAcceptance: false,
  tools: () => [] as ToolType[],
  updatedAt: '',
  workplace: '',
  submitted: false,
});

const confirmDelete = ref(false);
const actionOpenTalentModal = ref(false);
const refsProps = toRefs(props);
const docsModal = ref(false);
const technologyId = ref();
const expertise = ref();
const urlPdf = ref();
const typeModal = ref<actionModal>();
const sendingActionTalent = ref(false);
const talentStatus = ref(props.status);
const isNoteSaved = ref(false);
const openModalEvaluateStatus = ref(false);
const confirmMigrate = ref(false);
const isBiographySaved = ref(false);

const talentCommonData = useTalentCommonData();
const commonData = storeToRefs(talentCommonData);

const categories = computed(() => commonData.status.value || []);
const collaborations = computed(() => commonData.collaborations.value || []);
const commonLangs = computed(() => commonData.languages.value || []);
const industries = computed(() => commonData.industries.value || []);
const rates = computed(() => commonData.rates.value || []);
const seniorities = computed(() => commonData.seniorities.value || []);
const specialities = computed(() => commonData.specialities.value || []);
const tools = computed(() => commonData.tools.value || []);
const workplaces = computed(() => commonData.workplaces.value || []);
const countries = computed(() => commonData.countries.value || []);

const optionsEvaluateStatus = computed(() =>
  categories.value
    .filter((cat) => cat !== 'ALL_STAR' && cat !== 'INVITED')
    .map((category) => ({ label: category, value: category }))
);

const allSelectedCollaborations = ref(refsProps.collaborations?.value || []);
const collaborationNotes = ref(refsProps.collaborationNotes.value || '');
const allSelectedSpecialities = ref(refsProps.specialities?.value || []);
const allSelectedTools = ref(refsProps.tools?.value || []);
const allSelectedPortfolioLinks = ref(refsProps.portfolioLinks?.value || []);
const allSelectedLanguages = ref(refsProps.languages?.value || []);
const allSelectedIndustries = ref(refsProps.industries?.value || []);
const internationalProjectsSelected = ref(refsProps.internationalProjects.value || false);
const openToEmploymentSelected = ref(refsProps.openToEmployment.value || false);
const documents = ref(refsProps.documents.value || []);
const biography = ref(refsProps.biography.value || '');
const notes = ref(refsProps.notes?.value || '');
const freelance = ref(refsProps.freelance?.value || '');
const rate = ref(refsProps.rate?.value || '');
const category = ref(refsProps.status?.value || '');
const prediction = ref(refsProps.prediction?.value || '');
const seniority = ref(refsProps.experience?.value || '');
const workplace = ref(refsProps.workplace?.value || '');
const isDigitalNomad = ref(refsProps.digitalNomad.value || false);
const selectedCountry = ref(refsProps.country?.value || '');
const inferenceReview = ref(refsProps.inferenceReview?.value || {});
const additionalInfo = ref(refsProps.additionalInfo?.value || '');
const notesDetails = ref(refsProps.notesDetails.value || {});

watch(
  () => refsProps.specialities.value,
  (newSpecialities) => {
    allSelectedSpecialities.value = [...newSpecialities];
  }
);

watch(
  () => refsProps.languages.value,
  (newLanguage) => {
    allSelectedLanguages.value = [...newLanguage];
  }
);

watch(
  () => refsProps.documents.value,
  (newDoc) => {
    documents.value = [...newDoc];
  }
);

watch(
  () => refsProps.portfolioLinks.value,
  (newPortfolio) => {
    allSelectedPortfolioLinks.value = [...newPortfolio];
  }
);

const fullname = computed(() => refsProps.firstName.value + ' ' + refsProps.lastName.value);

const showFeedback = computed(() => {
  const feedbacks = [];

  if (props.interviewSent) {
    feedbacks.push(interviewSentString);
  }

  if (props.reminderSent) {
    feedbacks.push(reminderSentString);
  }

  if (props.rejectSent) {
    feedbacks.push(feedback);
  }

  if (props.interviewRecapSent) {
    feedbacks.push(interviewRecapSentString);
  }

  return feedbacks;
});

const allWorkplaces = computed(() => {
  if (workplaces.value.includes(workplace.value) && isEdit.value) return workplaces.value;
  const newWorkplaces = [...workplaces.value];
  workplace.value ? newWorkplaces.push(workplace.value) : '';

  return newWorkplaces.sort();
});

onMounted(() => {
  if (isDigitalNomad.value) workplace.value = digitalNomad;

  watchEffect(() => {
    if (
      (workplace.value && allWorkplaces.value.includes(workplace.value)) ||
      isDigitalNomad.value
    ) {
      setTimeout(() => {
        const place = document.querySelector(
          '.TalentsUpdateForm__workplaces-items .o-radio--checked'
        );

        const workplaceContainer = document.querySelector('.TalentsUpdateForm__workplaces-group');
        const scrollToY =
          (place as HTMLInputElement)?.offsetTop -
          (workplaceContainer as HTMLElement)?.clientHeight / 2 +
          (place as HTMLInputElement)?.clientHeight / 2;

        workplaceContainer?.scrollTo({
          top: scrollToY,
          behavior: 'smooth',
        });
      }, 100);
    }
  });
});

/**
 * Set the initialValue of Talent.expertise
 */
const initialValueExpertiseTalent = computed(() => {
  if (!allSelectedSpecialities.value.length && !allSelectedTools.value.length)
    return [
      {
        name: 'Project Management & Data',
        skills: [],
        technologies: [],
      },
      {
        name: 'Communication',
        skills: [],
        technologies: [],
      },
      {
        name: 'Coding',
        skills: [],
        technologies: [],
      },
      {
        name: 'Design',
        skills: [],
        technologies: [],
      },
    ];

  return allSelectedSpecialities.value.flatMap((expertise) => {
    const matchingExpertise = allSelectedTools.value.find(
      (tool) => tool.expertise === expertise.expertise
    );

    const technologyInput = matchingExpertise?.technologies.flatMap((tech) => {
      return {
        level: tech.level,
        technology: tech.technology,
      };
    });

    if (matchingExpertise) {
      return {
        name: expertise.expertise,
        skills: expertise.skills,
        technologies: technologyInput,
      };
    }
    return [];
  });
});

/**
 * Set the initialValue of Talent.languages
 */
const initialvalueLanguageTalent = computed(() => {
  if (!allSelectedLanguages.value.length) return [];

  return allSelectedLanguages.value.map((lang) => ({
    language: lang.language,
    level: lang.level,
  }));
});

const initialValuePortfolioTalent = computed(() => {
  if (!allSelectedPortfolioLinks.value.length) return [];

  return allSelectedPortfolioLinks.value.reduce((acc, curr) => {
    if (curr.link && curr.url) {
      acc.push({
        link: curr.link,
        url: curr.url,
      });
    }
    return acc;
  }, [] as PortfolioLinkInput[]);
});

const talent = reactive<UpdateTalentInput | CreateTalentInput>({
  expertise: initialValueExpertiseTalent.value,
  languages: initialvalueLanguageTalent.value,
  portfolioLinks: initialValuePortfolioTalent.value,
});

!isEdit.value ? ((talent as CreateTalentInput).termsAcceptance = true) : '';

const { handleSubmit, errors } = useForm({ initialValues: { ...talent } });

const emits = defineEmits([
  'talentData',
  'resetForm',
  'updateDocument',
  'manageTalent',
  'successInference',
  'savedNotes',
  'savedBiography',
]);

const onSubmit = handleSubmit((values, actions) => {
  emits('talentData', deleteEmptyInputValues(Object.assign(values, talent)));
  emits('resetForm', actions.resetForm);
});

const deleteEmptyInputValues = (input: UpdateTalentInput | CreateTalentInput) => {
  const result = { ...input };

  !isEdit.value ? delete (result as UpdateTalentInput).status : '';

  isEdit.value ? delete (result as CreateTalentInput).cv : '';
  isEdit.value ? delete (result as CreateTalentInput).portfolio : '';

  if ('tools' in result) {
    delete result.tools;
  }

  if ('specialities' in result) {
    delete result.specialities;
  }

  if ('internationalProjects' in result) {
    result.internationalProjects = internationalProjectsSelected.value;
  }

  if ('openToEmployment' in result) {
    result.openToEmployment = openToEmploymentSelected.value;
  }

  if ('expertise' in result && result.expertise) {
    result.expertise = result.expertise.filter(
      (obj) => obj.skills.length && obj.technologies?.length
    );
  }

  for (const [prop, value] of Object.entries(result)) {
    if (
      prop !== 'expertise' &&
      (value === null ||
        value === undefined ||
        value === '' ||
        (prop !== 'cv' &&
          prop !== 'portfolioLinks' &&
          typeof value === 'object' &&
          Object.keys(value).length === 0 &&
          !(value instanceof File)))
    ) {
      delete result[prop as keyof typeof input];
    }
  }

  return result;
};

watch(errors, () => {
  useCheckError();
});

watch(refsProps.submitted, () => {
  if (refsProps.submitted.value) {
    onSubmit();
  }
});

const talentId = { talentIds: id };

const enabledActionTalent = (isInterview: boolean): ComputedRef<boolean> =>
  computed(() => sendingActionTalent.value && isInterview === (typeModal.value === 'interview'));

const {
  isSuccess: successSentInterview,
  isError: errorSentInterview,
  remove: removeSentInterval,
} = inviteTalent(
  { talentId: id as string },
  {
    enabled: enabledActionTalent(true),
  }
);

const paramReject = reactive<MutationRejectTalentArgs>({
  status: talentStatus.value as TalentStatus,
  talentId: id as string,
});

watch(talentStatus, () => {
  paramReject.status = talentStatus.value as TalentStatus;
});

const {
  isSuccess: successRejectTalent,
  isError: errorRejectTalent,
  remove: removeRejectTalent,
} = rejectTalent(paramReject, {
  enabled: enabledActionTalent(false),
});

const enabledMigrateTalent = computed(() => confirmMigrate.value && typeModal.value === 'migrate');

const {
  isSuccess: successMigrate,
  isError: errorMigrate,
  remove: removeMigrate,
} = migrateToAgency(
  { talentId: talentId.talentIds as string },
  {
    enabled: enabledMigrateTalent,
  }
);

const enableDeleteTalent = computed(() => confirmDelete.value && typeModal.value === 'delete');

const {
  isSuccess: successSoftDelete,
  isError: errorSoftDelete,
  remove: removeSoftDelete,
} = softDeleteTalents(talentId as MutationSoftDeleteTalentsArgs, {
  enabled: enableDeleteTalent,
});

// init update notes
const paramsUpdateNote = reactive<MutationUpdateTalentNotesArgs>({
  talentId: id as string,
  notes: notes.value,
});

const enabledSaveNote = computed(() => isNoteSaved.value && paramsUpdateNote.notes.length > 0);

watch(notes, () => {
  paramsUpdateNote.notes = notes.value.replace(/<[^>]+>/g, '');
});

// Update Talent Notes
const {
  isSuccess: successuUpdateNote,
  isError: errorUpdateNote,
  remove: removeUpdateNote,
} = updateTalentNotes(paramsUpdateNote, {
  enabled: enabledSaveNote,
});

// init update biography
const paramsUpdateBiography = reactive<MutationUpdateTalentBiographyArgs>({
  talentId: id as string,
  biography: biography.value,
});

const enabledSaveBiography = computed(
  () => isBiographySaved.value && paramsUpdateBiography.biography.length > 0
);

watch(biography, () => {
  paramsUpdateBiography.biography = biography.value;
});

// Update Talent Biographys
const {
  isSuccess: successuUpdateBiography,
  isError: errorUpdateBiography,
  remove: removeUpdateBiography,
} = updateTalentBiography(paramsUpdateBiography, {
  enabled: enabledSaveBiography,
});

const showCorrectModaltext = computed(() => {
  switch (typeModal.value) {
    case 'interview':
      return {
        head: inviteInterview,
        body: interviewParagraphModal,
        confirm: confirmButton,
      };

    case 'reject':
      return {
        head:
          moveIn +
          ' ' +
          (talentStatus.value === 'ELIGIBLE'
            ? eligible
            : talentStatus.value === 'FUTURE'
            ? future
            : cantera),
        body:
          rejectParagraphModal +
          ' ' +
          (talentStatus.value === 'ELIGIBLE'
            ? eligible
            : talentStatus.value === 'FUTURE'
            ? future
            : cantera),
        confirm: confirmButton,
      };

    case 'migrate':
      return {
        head: migrateTo + ' ' + agencyString,
        body: migrateTalentPargraph,
        confirm: confirmButton,
      };

    case 'delete':
      return {
        head: deleteString,
        body: paragraghModal,
        confirm: confirmDeleteButton,
      };

    default:
      return {
        head: '',
        body: genericError,
        confirm: '',
      };
  }
});

const messageNotification = computed(() => {
  switch (true) {
    case successSentInterview.value:
      return {
        status: 'success',
        message: interviewSentModal,
      };

    case errorSentInterview.value:
      removeSentInterval();
      return {
        status: 'error',
        message: rejectError,
      };

    case successRejectTalent.value:
      return {
        status: 'success',
        message:
          rejectTalentSentModal +
          ' ' +
          (talentStatus.value === 'ELIGIBLE'
            ? eligible
            : talentStatus.value === 'FUTURE'
            ? future
            : cantera),
      };

    case errorRejectTalent.value:
      removeRejectTalent();
      return {
        status: 'error',
        message: rejectError,
      };

    case successSoftDelete.value:
      return {
        status: 'success',
        message: deleteTalentsSuccess,
      };

    case errorSoftDelete.value:
      removeSoftDelete();
      return {
        status: 'error',
        message: deleteTalentsError,
      };

    case successMigrate.value:
      return {
        status: 'success',
        message: migrateSuccessString.replace('{name}', talentString),
      };

    case errorMigrate.value:
      removeMigrate();
      return {
        status: 'error',
        message: migrateErrorsString,
      };

    case successCreateInferenceReview.value:
      removeCreateInferenceReview();
      return {
        status: 'success',
        message: successSendReview,
      };

    case errorCreateInferenceReview.value:
      return {
        status: 'error',
        message: errorSendReview,
      };

    case successUpdateInferenceReview.value:
      removeupdateInferenceReview();
      return {
        status: 'success',
        message: successUpdateReview,
      };

    case errorUpdateInferenceReview.value:
      return {
        status: 'error',
        message: errorUpdateReview,
      };

    case successuUpdateNote.value &&
      !successCreateInferenceReview.value &&
      !successUpdateInferenceReview.value:
      removeUpdateNote();
      return {
        status: 'success',
        message: successSavedNote,
      };

    case errorUpdateNote.value &&
      !errorCreateInferenceReview.value &&
      !errorUpdateInferenceReview.value:
      return {
        status: 'error',
        message: errorSavedNote,
      };

    case successuUpdateBiography.value:
      removeUpdateBiography();
      return {
        status: 'success',
        message: successSavedBiography,
      };

    case errorUpdateBiography.value:
      return {
        status: 'error',
        message: errorSavedBiography,
      };

    default:
      return {
        status: 'error',
        message: genericError,
      };
  }
});

watch([successuUpdateNote, successuUpdateBiography], () => {
  if (isNoteSaved.value) {
    setTimeout(() => {
      isNoteSaved.value = false;
    }, NOTIFICATION_DURATION);
  }

  if (isBiographySaved.value) {
    setTimeout(() => {
      isBiographySaved.value = false;
    }, NOTIFICATION_DURATION);
  }
});

const selectedSpecialitiesByExpertise = computed(
  () => (expertise: string) =>
    (allSelectedSpecialities?.value || []).find((el) => el.expertise === expertise)?.skills || []
);

const addSkill = (expertiseSkill: string, skill: string) => {
  expertise.value = expertiseSkill;

  const currentSpecialities = allSelectedSpecialities.value || [];
  const matchingIndex = currentSpecialities.findIndex((item) => item.expertise === expertiseSkill);

  const updatedSpecialities = [...currentSpecialities];

  if (matchingIndex !== -1) {
    updatedSpecialities[matchingIndex] = {
      expertise: expertiseSkill,
      skills: !currentSpecialities[matchingIndex].skills.includes(skill)
        ? [...currentSpecialities[matchingIndex].skills, skill]
        : currentSpecialities[matchingIndex].skills.filter((el) => el !== skill),
    };
  } else {
    updatedSpecialities.push({
      expertise: expertiseSkill,
      skills: [skill],
    });
  }

  allSelectedSpecialities.value = updatedSpecialities;

  const talentExpertise = talent.expertise?.find(
    (talentExpertise) => talentExpertise.name === expertiseSkill
  );

  if (talentExpertise) {
    talentExpertise.skills = [...selectedSpecialitiesByExpertise.value(expertiseSkill)];
  }
};

// It is only used for validation
const selectedSkills = computed(() => {
  return allSelectedSpecialities.value
    .map((item) => {
      const filteredSkills = item.skills.filter(Boolean);

      return filteredSkills.length > 0
        ? { expertise: item.expertise, skills: filteredSkills }
        : undefined;
    })
    .filter(Boolean);
});

const technologies = computed(
  () =>
    tools.value?.map(({ expertise, technologies }) => ({
      expertise,
      technologies: technologies.map((technology) => ({
        technology: technology,
        technologyId:
          (allSelectedTools.value || [])
            .find((el) => el.expertise === expertise)
            ?.technologies?.find((el) => el.technology === technology)?.technologyId ?? '',
        level:
          (allSelectedTools.value || [])
            .find((el) => el.expertise === expertise)
            ?.technologies?.find((el) => el.technology === technology)?.level ?? '',
      })),
    })) || []
);

const languages = computed(() => {
  if (commonLangs.value.length < 1) return [];
  return [
    {
      expertise: languageTitle,
      technologies: commonLangs.value?.map((lang) => ({
        technology: lang,
        languageId:
          (allSelectedLanguages.value || []).find((el) => el.language === lang)?.languageId ?? '',
        level: (allSelectedLanguages.value || []).find((el) => el.language === lang)?.level ?? '',
      })),
    },
  ];
});

const getInitialValue = (name: string) => refsProps[name as keyof typeof refsProps]?.value ?? '';

const addPortfolios = (portfolioLinksUpdated: PortfolioLinkInput[]) =>
  (talent.portfolioLinks = portfolioLinksUpdated.map((portfolio) => {
    return {
      link: portfolio.link,
      url: portfolio.url,
    };
  }));

const addLanguages = (languagesUpdated: Expertise[]) =>
  (talent.languages = languagesUpdated[0].technologies
    .map(({ level, technology: language }) => ({
      level,
      language,
    }))
    .filter((lang) => lang.level.length > 0));
/**
 * for collaborations
 */
const initialCheckCollaborations = computed(() =>
  collaborations.value.filter((partner) => allSelectedCollaborations.value.includes(partner))
);

/**
 * for industries
 */
const initialCheckIndustries = computed(() =>
  industries.value.filter((industry) => allSelectedIndustries.value.includes(industry))
);

const addTechnologies = (expertisesUpdated: Expertise[]) => {
  expertisesUpdated.forEach((expertise) => {
    const selectedTechnology = expertise.technologies
      .flat()
      .filter((technology) => technology.level.length > 0)
      .map((obj) => {
        const { technologyId, ...rest } = obj;
        return rest;
      });

    const talentExpertise = talent.expertise?.find(
      (talentExpertise) => talentExpertise.name === expertise.expertise
    );

    if (talentExpertise) {
      talentExpertise.technologies = [...selectedTechnology];
    }
  });
};

const selectWorkplace = (workplace: string) => {
  if (!isEdit.value && workplace === 'Digital Nomad') {
    (talent as CreateTalentInput).digitalNomad = true;
  } else {
    (talent as CreateTalentInput).digitalNomad = false;
  }
};

const isToolsExpertise = computed(() =>
  technologies.value.some((tech) => tech.expertise === expertise.value)
);

const removeTool = reactive({
  talentId: id as string,
  technologyId: '',
});

const removeLanguage = reactive({
  talentId: id as string,
  languageId: '',
});

watch(technologyId, () => {
  if (isToolsExpertise.value) {
    removeTool.technologyId = technologyId.value;
    removeLanguage.languageId = '';
  } else {
    removeLanguage.languageId = technologyId.value;
    removeTool.technologyId = '';
  }
});

const enableDeleteBoxCategory = (isTechnology: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      isTechnology === isToolsExpertise.value &&
      !!technologyId.value &&
      (removeTool.technologyId.length > 0 || removeLanguage.languageId.length > 0)
  );

const disableAccordionSpecialities = computed(() => (speciality: string) => {
  const emptySelected = allSelectedSpecialities.value.some(
    (specialities) => specialities.skills.length > 0
  );

  if (!emptySelected || isEdit.value) return false;

  return allSelectedSpecialities.value.some(
    (specialities) => specialities.expertise !== speciality
  );
});

/**
 * index of selected accordion
 */
const selectedAccordion = ref<string>('');

/**
 * computed switching selection of the accordion
 */
const selectAccordion = computed(() => (expertise: string) => {
  selectedAccordion.value = expertise;
});

const enableGithub = computed(() =>
  allSelectedSpecialities.value.some(
    (specialities) => specialities.expertise === 'Coding' && specialities.skills.length > 0
  )
);

const {
  isSuccess: successRemoveTool,
  isError: errorRemoveTool,
  remove: removeSuccessTool,
} = removeTalentTool(removeTool, {
  enabled: enableDeleteBoxCategory(true),
});

const {
  isSuccess: successRemoveLanguage,
  isError: errorRemoveLanguage,
  remove: removeSuccessLanguage,
} = removeTalentLanguage(removeLanguage, {
  enabled: enableDeleteBoxCategory(false),
});

watch([successRemoveTool, successRemoveLanguage], () => {
  if (successRemoveTool.value) {
    removeSuccessTool();
  }
  if (successRemoveLanguage.value) {
    removeSuccessLanguage();
  }
});

const inferenceReviewTalent = reactive<MutationCreateInferenceReviewArgs>({
  input: {
    talentId: id as string,
    review: '' as TalentStatus,
    note: '',
  },
});

const enableInferenceReviewTalent = (isCreadted: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      isCreadted === !inferenceReview.value.createdAt &&
      inferenceReviewTalent.input.review.length > 0 &&
      !!prediction.value
  );

const {
  isSuccess: successCreateInferenceReview,
  isError: errorCreateInferenceReview,
  remove: removeCreateInferenceReview,
} = createInferenceReview(inferenceReviewTalent, {
  enabled: enableInferenceReviewTalent(true),
});

const {
  isSuccess: successUpdateInferenceReview,
  isError: errorUpdateInferenceReview,
  remove: removeupdateInferenceReview,
} = updateInferenceReview(inferenceReviewTalent, {
  enabled: enableInferenceReviewTalent(false),
});

watch(
  [
    successSentInterview,
    successRejectTalent,
    successMigrate,
    successSoftDelete,
    successCreateInferenceReview,
    successUpdateInferenceReview,
    successuUpdateNote,
    successuUpdateBiography,
  ],
  () => {
    switch (true) {
      case successSentInterview.value:
        emits('manageTalent', successSentInterview.value);
        actionOpenTalentModal.value = false;
        break;

      case successRejectTalent.value:
        emits('manageTalent', successRejectTalent.value);
        actionOpenTalentModal.value = false;
        break;

      case successMigrate.value:
        setTimeout(() => {
          removeSoftDelete();
          router.push('/talents');
        }, NAVIGATION_BACK_DEFAULT_TIME);
        actionOpenTalentModal.value = false;
        break;

      case successSoftDelete.value:
        setTimeout(() => {
          removeSoftDelete();
          router.back();
        }, NAVIGATION_BACK_DEFAULT_TIME);
        actionOpenTalentModal.value = false;
        break;

      case successCreateInferenceReview.value:
        emits('successInference', successCreateInferenceReview.value);
        break;

      case successUpdateInferenceReview.value:
        emits('successInference', successUpdateInferenceReview.value);
        break;

      case successuUpdateNote.value:
        emits('savedNotes', successuUpdateNote.value);
        break;

      case successuUpdateBiography.value:
        emits('savedBiography', successuUpdateBiography.value);
        break;

      default:
        actionOpenTalentModal.value = false;
        return;
    }
  }
);
</script>

<template>
  <form class="container-fluid" @submit="onSubmit">
    <div class="TalentsUpdateForm col-6">
      <div
        class="TalentsUpdateForm__feedback text-body-1"
        v-for="(fb, index) in showFeedback"
        :key="index"
      >
        {{ fb }}
      </div>
      <div class="TalentsUpdateForm__feedback text-body-1" v-if="props.newsletter">
        <span class="text-body-1">{{ subscribedNewsletter }}</span>
      </div>
      <FormGroup :title="registry.title">
        <template #formContent>
          <InputText
            v-for="(input, index) in registry.input"
            :key="index"
            :label="input.label"
            :placeholder="input.placeholder"
            :initial-value="getInitialValue(input.name)"
            :name="input.name"
            :type="`${input.name === 'telephone' ? 'tel' : 'text'}`"
            :rules="input.rules"
            :disabled="isEdit && userRole !== 'ADMIN'"
          />
        </template>
      </FormGroup>
      <FormGroup :title="specialitiesTitle">
        <template #formContent>
          <Field
            v-model="selectedSkills"
            v-slot="{ field, errors }"
            :name="specialitiesName"
            :rules="{ required: true }"
          >
            <Accordion
              v-for="(speciality, index) in specialities"
              :key="index"
              :index="index"
              :label="speciality.expertise"
              :count="selectedSpecialitiesByExpertise(speciality.expertise).length"
              has-count
              :disabled="disableAccordionSpecialities(speciality.expertise)"
              :is-open="selectedAccordion === speciality.expertise"
              @open="selectAccordion(speciality.expertise)"
            >
              <template #content>
                <div class="TalentsUpdateForm__specialities-checkbox">
                  <o-inputitems v-model="selectedSkills" v-bind="field" />
                  <Checkbox
                    v-for="(item, i) in speciality.skills"
                    :key="i"
                    as="checkbox"
                    :initial-value="
                      selectedSpecialitiesByExpertise(speciality.expertise).includes(item)
                    "
                    :name="item"
                    :label="item"
                    :standalone="true"
                    @changed="addSkill(speciality.expertise, item)"
                    :disabled="disableAccordionSpecialities(speciality.expertise)"
                  />
                </div>
              </template>
            </Accordion>
            <p class="error-message text-body-2" v-if="errors[0]">
              {{ errors[0] }}
            </p>
          </Field>
        </template>
      </FormGroup>
      <FormGroup :title="toolsTitle">
        <template #formContent>
          <BoxCategorySkills
            v-if="technologies.length > 0"
            :expertises="technologies"
            :name="toolsName"
            :talent-name="fullname"
            :talent-mail="refsProps.email.value"
            :success-delete-skill="successRemoveTool"
            :enabled-add-skills="allSelectedSpecialities"
            @technologies="addTechnologies"
            @technology-id="([id, category]) => ((technologyId = id), (expertise = category))"
            :rules="{ required: true }"
          />
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__experience" :title="experience.title">
        <template #formContent>
          <LinkModule
            :portfolio-links="allSelectedPortfolioLinks"
            @portfolios="addPortfolios"
            :rules="{ required: false }"
          />
          <MultiButtonDocs
            v-bind="{ ...experience.documentation }"
            :documents="documents"
            @open-modal-c-v="([open, url]) => ((docsModal = open), (urlPdf = url))"
            @update-doc="(doc) => $emit('updateDocument', doc)"
          />
          <Modal
            class="popupDocs"
            :is-modal-active="docsModal"
            @close-modal="docsModal = false"
            :head="inspectDoc"
          >
            <template #modal-main>
              <div class="popupDocs__title heading-h4">{{ fullname }}</div>
              <div class="popupDocs__subtitle text-body-1">CV</div>
              <div class="popupDocs__pdf">
                <iframe
                  v-if="urlPdf"
                  width="500px"
                  height="700px"
                  frameBorder="0"
                  :src="urlPdf + '#toolbar=0'"
                >
                </iframe>
                <p v-else class="text-body-1">{{ errorViewPDF }}</p>
              </div>
            </template>

            <template #modal-footer>
              <component
                :is="Button"
                :label="downloadDoc"
                tag="a"
                :to="urlPdf"
                target="_blank"
                :download="urlPdf"
              ></component>
            </template>
          </Modal>

          <div v-if="enableGithub">
            <h3 class="TalentsUpdateForm__experience--github-title heading-h5">
              {{ experience.github.title }}
            </h3>
            <InputText
              :label="experience.github.label"
              :placeholder="experience.github.placeholder"
              :initial-value="getInitialValue(experience.github.name)"
              :name="experience.github.name"
              :rules="enableGithub && !isEdit ? experience.github.rules : { required: false }"
            />
          </div>

          <h3 class="TalentsUpdateForm__experience--status-title heading-h5">
            {{ experience.status.title }}
          </h3>
          <p class="text-link">{{ experience.status.paragraph }}</p>
          <div class="TalentsUpdateForm__experience--status-radio mb-1">
            <Radio
              v-for="(option, index) in experience.status.options"
              as="radio"
              :key="index + option"
              :name="freelanceName"
              :label="labelStatus[option as keyof typeof labelStatus]"
              :value="option"
              :initial-value="freelance"
              @changed="(val) => (freelance = val)"
              :rules="{ required: true }"
            />
          </div>

          <h3 class="TalentsUpdateForm__openToEmployment--status-title heading-h5">
            {{ experience.openToEmployment.title }}
          </h3>
          <p class="text-link">{{ experience.openToEmployment.paragraph }}</p>
          <div class="TalentsUpdateForm__openToEmployment--status-radio">
            <Radio
              v-for="(option, index) in experience.openToEmployment.options"
              as="radio"
              :name="experience.openToEmployment.name"
              :key="index + option"
              :label="option === 'Si' ? 'Yes' : option"
              :value="option"
              :initial-value="openToEmploymentSelected ? 'Si' : 'No'"
              @changed="(val) => (openToEmploymentSelected = val === 'Si' ? true : false)"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__collabs" :title="collabStrings.title">
        <template #formContent>
          <MultiCheckbox
            v-if="isEdit ? initialCheckIndustries : true"
            :name="collabStrings.name"
            :initial-checked="initialCheckCollaborations"
            :options="collaborations"
          />
          <TextAreaForm
            :placeholder="collabStrings.placeholderTextArea"
            :name="collabStrings.nameTextArea"
            :initial-value="collaborationNotes"
          />
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__industries" :title="industriesTitle">
        <template #formContent>
          <MultiCheckbox
            v-if="isEdit ? initialCheckIndustries : true"
            :name="industriesName"
            :initial-checked="initialCheckIndustries"
            :options="industries"
          />
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__intProject" :title="internationalProjects.title">
        <template #formContent>
          <div class="TalentsUpdateForm__intProject-radio">
            <Radio
              v-for="(option, index) in internationalProjects.options"
              as="radio"
              :name="internationalProjects.name"
              :key="index + option"
              :label="option === 'Si' ? 'Yes' : option"
              :value="option"
              :initial-value="internationalProjectsSelected ? 'Si' : 'No'"
              @changed="(val) => (internationalProjectsSelected = val === 'Si' ? true : false)"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup :title="languageTitle">
        <template #formContent>
          <BoxCategorySkills
            v-if="commonLangs.length > 0"
            :name="languagesName"
            :expertises="languages"
            :success-delete-skill="successRemoveLanguage"
            @technologies="addLanguages"
            @technology-id="([id, category]) => ((technologyId = id), (expertise = category))"
            :rules="{ required: true }"
          />
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__note" :title="note.title">
        <template #formContent>
          <div class="TalentsUpdateForm__note__details mb-1" v-if="notesDetails.userId">
            <p class="TalentsUpdateForm__note__details--info">
              {{ lastUpdate }}:
              {{ moment(notesDetails.updatedAt).locale('it').format('YYYY-MM-DD, ddd H:mm') }}
            </p>
            <p class="TalentsUpdateForm__note__details--info">
              {{ user }}: {{ notesDetails.firstName }} {{ notesDetails.lastName }}
            </p>
            <p class="TalentsUpdateForm__note__details--info">
              {{ user }} ID: {{ notesDetails.userId }}
            </p>
          </div>
          <TextAreaForm
            class="TalentsUpdateForm__note--textArea"
            :name="note.name"
            :placeholder="note.placeholder"
            :label="note.paragraph"
            :initial-value="notes"
            :label-btn="isEdit ? saveNote : ''"
            @clicked="() => (isNoteSaved = true)"
            @keystart="(s: string) => ( notes = s )"
          />
          <h4 class="TalentsUpdateForm__note--rate-title heading-h4">{{ rateTitle }}</h4>
          <div class="TalentsUpdateForm__note--rate-radio mb-1">
            <Radio
              v-for="(quote, i) in rates"
              :key="i"
              :name="rateName"
              :label="quote"
              :initial-value="rate"
              :value="quote"
              @changed="(val) => (rate = val)"
            />
          </div>
          <Button variant="secondary" :label="clear" @clicked="() => (rate = 'empty')" />
        </template>
      </FormGroup>

      <FormGroup :title="biographyString.title">
        <template #formContent>
          <TextAreaForm
            :name="biographyString.name"
            :placeholder="biographyString.placeholder"
            :label="biographyString.paragraph"
            :initial-value="biography"
            :label-btn="isEdit ? saveBiography : ''"
            @clicked="() => (isBiographySaved = true)"
            @keystart="(s: string) => ( biography = s )"
          />
        </template>
      </FormGroup>

      <FormGroup :title="additionalInfoString.title">
        <template #formContent>
          <TextAreaForm
            :name="additionalInfoString.name"
            :placeholder="additionalInfoString.placeholder"
            :label="additionalInfoString.paragraph"
            :initial-value="additionalInfo"
          />
        </template>
      </FormGroup>
    </div>

    <div class="TalentsUpdateForm col-5">
      <FormGroup class="TalentsUpdateForm__manageTalent" :title="manageTalent.title" v-if="isEdit">
        <template #formContent>
          <div class="TalentsUpdateForm__manageTalent__interview" v-if="status === 'INBOX'">
            <Button
              :label="interview"
              @clicked="() => ((actionOpenTalentModal = true), (typeModal = 'interview'))"
            />
          </div>
          <div
            class="TalentsUpdateForm__manageTalent__reject"
            v-if="status === 'INBOX' || status === 'INVITED'"
          >
            <div class="TalentsUpdateForm__manageTalent__reject-title text-body-1">
              {{ moveIn }}
            </div>
            <div class="TalentsUpdateForm__manageTalent__reject-buttons">
              <Button
                v-if="status === 'INBOX'"
                :label="future"
                variant="link-tertiary"
                @clicked="
                  () => (
                    (actionOpenTalentModal = true),
                    (typeModal = 'reject'),
                    (talentStatus = TalentStatus.Future)
                  )
                "
              />
              <Button
                v-if="status === 'INBOX'"
                :label="cantera"
                variant="link-tertiary"
                @clicked="
                  () => (
                    (actionOpenTalentModal = true),
                    (typeModal = 'reject'),
                    (talentStatus = TalentStatus.Cantera)
                  )
                "
              />
              <Button
                v-if="status === 'INVITED'"
                :label="eligible"
                variant="link-tertiary"
                @clicked="
                  () => (
                    (actionOpenTalentModal = true),
                    (typeModal = 'reject'),
                    (talentStatus = TalentStatus.Eligible)
                  )
                "
              />
            </div>
          </div>
          <div class="TalentsUpdateForm__manageTalent__migrate">
            <h6 class="TalentsUpdateForm__manageTalent__migrate-title text-body-1">
              {{ migrateTo }}
            </h6>
            <Button
              class="TalentsUpdateForm__manageTalent__migrate-button"
              variant="link-tertiary"
              :label="agencyString"
              @clicked="() => ((actionOpenTalentModal = true), (typeModal = 'migrate'))"
            />
          </div>
          <div class="TalentsUpdateForm__manageTalent__delete">
            <h6 class="TalentsUpdateForm__manageTalent__delete-title text-body-1">
              {{ manageTalent.deleteTalent }}
            </h6>
            <Button
              class="TalentsUpdateForm__manageTalent__delete-button"
              variant="link-tertiary-status"
              :label="manageTalent.deleteButton"
              @clicked="() => ((actionOpenTalentModal = true), (typeModal = 'delete'))"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__categoryTalent" :title="categoryTitle">
        <template #formContent>
          <Button
            class="TalentsUpdateForm__categoryTalent-evaluateStatus"
            variant="link-tertiary"
            :label="evaluateStatus.title"
            :disabled="!prediction"
            @clicked="() => (openModalEvaluateStatus = true)"
          />

          <div class="mb-1 text-body-1" v-if="prediction">
            {{ statusRecommended }}: {{ prediction }}
          </div>
          <div
            class="TalentsUpdateForm__categoryTalent-items"
            v-for="(status, index) in categories"
            :key="index"
          >
            <Radio
              as="radio"
              :label="mappedStatus[status]"
              :name="categoryName"
              :initial-value="category"
              :value="status"
              @changed="(val) => (category = val)"
            />
          </div>
          <ModalEvaluateStatus
            :inference-review="inferenceReview"
            :talent-id="(id as string)"
            :open="openModalEvaluateStatus"
            :prediction="prediction"
            :options="optionsEvaluateStatus"
            :note="notes"
            @reset-modal="(reset: boolean) => openModalEvaluateStatus = reset"
            @inference-review="
              (formValue: CreateInferenceReviewInput) => ((inferenceReviewTalent.input = { ...formValue, talentId: id as string }), notes = formValue.note || '')
            "
          />
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__seniorityTalent" :title="seniorityTalentTitle">
        <template #formContent>
          <div
            class="TalentsUpdateForm__seniorityTalent-items"
            v-for="(year, index) in seniorities"
            :key="index"
          >
            <Radio
              as="radio"
              :value="year"
              :initial-value="seniority"
              :label="year"
              :name="seniorityTalentName"
              @changed="(val) => (seniority = val)"
              :rules="{ required: true }"
            />
          </div>
        </template>
      </FormGroup>

      <FormGroup class="TalentsUpdateForm__workplaces" :title="workplaceTitle">
        <template #formContent>
          <div class="TalentsUpdateForm__workplaces-group">
            <!--<div
              class="TalentsUpdateForm__workplaces-items"
              v-for="(place, index) in allWorkplaces"
              :key="index"
            >
              <Radio
                as="radio"
                :label="place"
                :name="workplaceName"
                :value="place"
                :initial-value="workplace"
                @changed="selectWorkplace"
                :rules="{ required: false }"
              />
            </div>-->

            <InputText placeholder="Workplace" :initial-value="workplace" :name="workplaceName" />
          </div>
        </template>
      </FormGroup>
      <FormGroup class="TalentsUpdateForm__country" :title="countryTitle">
        <template #formContent>
          <div class="TalentsUpdateForm__country-group">
            <div
              class="TalentsUpdateForm__country-items"
              v-for="(country, index) in countries"
              :key="index"
            >
              <Radio
                as="radio"
                :label="country"
                :name="countryName"
                :value="country"
                :initial-value="selectedCountry"
                :rules="{ required: true }"
              />
            </div>
          </div>
        </template>
      </FormGroup>
    </div>
  </form>

  <Modal
    :is-modal-active="actionOpenTalentModal"
    @close-modal="actionOpenTalentModal = false"
    :head="showCorrectModaltext.head"
  >
    <template #modal-main>
      <div class="popupInterview">
        <div class="popupInterview-main">
          <p class="popupInterview-main-paragraph heading-h4">
            {{ showCorrectModaltext.body }}
          </p>
        </div>
      </div>
    </template>

    <template #modal-footer v-if="showCorrectModaltext.confirm.length > 1">
      <component
        :is="Button"
        variant="secondary"
        :label="cancel"
        @clicked="actionOpenTalentModal = false"
      ></component>
      <component
        :is="Button"
        variant="primary"
        :label="showCorrectModaltext.confirm"
        @clicked="
          typeModal === 'migrate'
            ? (confirmMigrate = true)
            : typeModal === 'delete'
            ? (confirmDelete = true)
            : (sendingActionTalent = true)
        "
      ></component>
    </template>
  </Modal>

  <Notification
    v-if="
      successSentInterview ||
      errorSentInterview ||
      successRejectTalent ||
      errorRejectTalent ||
      successMigrate ||
      errorMigrate ||
      successSoftDelete ||
      errorSoftDelete ||
      errorRemoveLanguage ||
      errorRemoveTool ||
      successCreateInferenceReview ||
      errorCreateInferenceReview ||
      successUpdateInferenceReview ||
      errorUpdateInferenceReview ||
      successuUpdateNote ||
      errorUpdateNote ||
      successuUpdateBiography ||
      errorUpdateBiography
    "
    :message="messageNotification.message"
    :status="(messageNotification.status as NotificationStatus)"
    :duration="NOTIFICATION_DURATION"
  />
</template>

<style scoped lang="scss">
.TalentsUpdateForm {
  &__feedback {
    display: list-item;
    margin: 0 0 2rem 2.4rem;
    position: relative;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.1rem;
      left: -2rem;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: $accent;
      background-image: url(@/assets/icons/check.svg);
      background-repeat: no-repeat;
      background-size: 1.2rem 1.2rem;
      background-position: center;
    }
  }

  &__specialities {
    &-checkbox {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;
      margin-bottom: 2.4rem;

      :deep .o-inputit {
        display: none;
      }
    }
  }

  &__experience,
  &__openToEmployment {
    &--status {
      &-title {
        margin: 0 0 1.6rem;
      }

      &-radio {
        @include flexing(row, start, center);
        gap: 1.6rem;
        flex-wrap: wrap;

        :deep .error-message {
          display: none;

          &:last-of-type {
            display: block;
          }
        }
      }
    }
  }

  &__intProject {
    &-title {
      margin: 0 0 1.6rem;
    }

    &-radio {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;

      :deep .error-message {
        display: none;

        &:last-of-type {
          display: block;
        }
      }
    }
  }

  &__collabs {
    &-checkbox {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;
      margin-bottom: 2.4rem;
    }
  }

  &__industries {
    &-checkbox {
      @include flexing(row, start, center);
      gap: 1.6rem;
      flex-wrap: wrap;
    }
  }

  &__note {
    &__details {
      &--info {
        @extend .text-body-2;
        margin: 1rem 0;
      }
    }

    &--textArea {
      margin-bottom: 2.4rem;
    }

    &--rate {
      &-title {
        margin: 0 0 1rem;
      }

      &-radio {
        @include flexing(row, start, center);
        gap: 2.4rem;
        flex-wrap: wrap;
      }
    }
  }

  &__manageTalent {
    &__interview {
      margin-bottom: 1.6rem;
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid $black-20;
    }

    &__reject {
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid $black-20;

      &-title {
        margin-bottom: 0.8rem;
      }

      &-buttons {
        @include flexing(row, start, start);
        gap: 1.6rem;

        :deep .Button {
          &.--no-icon.o-btn--link-tertiary {
            min-height: auto;
          }
        }
      }
    }

    &__migrate {
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid $black-20;
    }

    &__migrate,
    &__delete {
      :deep .FormGroup-title {
        padding-bottom: 2.4rem;
        border-bottom: 0.1rem solid $black-20;
      }

      &-title {
        margin: 0 0 0.8rem;
      }

      &-button {
        :deep &.Button {
          &.--no-icon {
            display: inline-block;
            min-width: auto;
            min-height: auto;
          }
        }
      }
    }
  }

  &__categoryTalent {
    &-evaluateStatus {
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
    }
  }

  &__categoryTalent,
  &__seniorityTalent {
    position: relative;
    :deep .FormGroup-title,
    &-items {
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }
  }

  &__seniorityTalent {
    &-items {
      :deep &:not(:last-of-type) .error-message {
        display: none;
      }
    }
  }

  &__workplaces,
  &__country {
    position: relative;

    &-items {
      :deep &:not(:first-of-type) .error-message {
        display: none;
      }

      :deep .error-message {
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
      }
    }
  }

  &.col-5 {
    grid-column-start: 8;
  }

  &__workplaces,
  &__country {
    &-group {
      height: 30.7rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0.7rem;
      }

      &::-webkit-scrollbar-thumb {
        background: $black-20;
        border-radius: 4rem;
        background-clip: padding-box;
      }

      &::-webkit-scrollbar-track {
        margin-block: 0.8rem;
      }
    }

    :deep .FormGroup-title,
    &-items {
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }
  }

  &__country {
    &-group {
      height: max-content;
    }
  }
}

.popupInterview {
  text-align: center;
  margin: auto;

  &-skeleton {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.8rem;

    &.--title {
      :deep(.o-sklt__item) {
        height: 3rem;
      }

      width: 40%;
    }

    &.--subtitle {
      :deep(.o-sklt__item) {
        height: 2.5rem;
      }

      width: 60%;
    }
  }

  &-title {
    margin: 0;
    margin-bottom: 0.4rem;
  }

  &-subtitle {
    color: $black-50;
  }

  &-main {
    margin: 3.2rem 0;
    padding: 0 2.5rem;

    &-paragraph {
      margin: 0;
    }
  }
}

.popupDocs {
  text-align: center;

  &__title {
    margin-bottom: 0.8rem;
  }

  &__subtitle {
    color: $black-50;
  }

  &__pdf {
    margin-top: 3.2rem;
  }

  :deep .Modal__content-footer {
    margin-top: 3.2rem;
  }
}
</style>
