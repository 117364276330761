import { gql } from 'graphql-request';
import type { MutationSoftDeleteCouponArgs, SoftDeleteCouponMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const softDeleteCouponMutation = gql`
  mutation SoftDeleteCoupon($couponId: String!) {
    softDeleteCoupon(couponId: $couponId)
  }
`;

export const softDeleteCoupon = (
  props?: MutationSoftDeleteCouponArgs,
  options?: UseQueryOptions<
    SoftDeleteCouponMutation,
    MutationSoftDeleteCouponArgs,
    SoftDeleteCouponMutation,
    any[]
  >
): UseQueryReturnType<SoftDeleteCouponMutation, MutationSoftDeleteCouponArgs> =>
  useGraphql({
    query: softDeleteCouponMutation,
    variables: props,
    options,
  });
