import { gql } from 'graphql-request';
import type { RestoreUsersMutation, MutationRestoreUserArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const restoreUsersMutation = gql`
  mutation RestoreUsers($userIds: [String!]!) {
    restoreUser(userIds: $userIds)
  }
`;

export const restoreUsers = (
  props?: MutationRestoreUserArgs,
  options?: UseQueryOptions<
    RestoreUsersMutation,
    MutationRestoreUserArgs,
    RestoreUsersMutation,
    any[]
  >
): UseQueryReturnType<RestoreUsersMutation, MutationRestoreUserArgs> =>
  useGraphql({
    query: restoreUsersMutation,
    variables: props,
    options,
  });
