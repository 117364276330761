<script setup lang="ts">
import { type ComputedRef, computed, reactive, ref, watch } from 'vue';
import GoBack from '@/components/molecules/GoBack.vue';
import FormGroup from '@/components/molecules/FormGroup.vue';
import Button from '@/components/atoms/Button.vue';
import InputText from '@/components/atoms/InputText.vue';
import Select from '@/components/atoms/Select.vue';
import Modal from '@/components/atoms/Modal.vue';
import strings from '@/data/Settings.json';
import Notification from '@/components/atoms/Notification.vue';
import { useRoute, useRouter } from 'vue-router';
import { getSkill } from '@/api/queries/skill';
import { getTechnology } from '@/api/queries/technology';
import { getExpertise } from '@/api/queries/expertise';
import type {
  MutationUpdateSkillArgs,
  MutationUpdateTechnologyArgs,
  UpdateSkillInput,
  UpdateTechnologyInput,
} from '@/gql/Gql.types';
import { updateSkill } from '@/api/mutations/updateSkill';
import { updateTechnology } from '@/api/mutations/updateTechnology';
import { useForm } from 'vee-validate';
import { NAVIGATION_BACK_DEFAULT_TIME } from '@/data/constants';
import MultiSelect from '@/components/atoms/MultiSelect.vue';
import type { Option } from '@/models/selectModel';
import { hardDeleteSkills } from '@/api/mutations/hardDeleteSkills';
import { hardDeleteTechnologies } from '@/api/mutations/hardDeleteTechnologies';
import GenericString from '@/data/GenericStrings.json';

const { update } = GenericString;

const router = useRouter();

const {
  params: { name, id },
} = useRoute();

const isSkill = computed(() => name === 'skill');

const openModal = ref(false);
const skillDeleted = ref(false);

const enabled = (isTech: boolean): ComputedRef<boolean> =>
  computed(() => isTech === !isSkill.value);

const { data: skill, remove: removeSkill } = getSkill(
  { skillId: id as string },
  { enabled: enabled(false) }
);

const { data: technology, remove: removeTechnology } = getTechnology(
  {
    technologyId: id as string,
  },
  { enabled: enabled(true) }
);

const { data: expertise, status: statusExpertise, isLoading: loadingExpertise } = getExpertise();

const nameItem = computed(() => {
  if (isSkill.value && (!skill.value || !skill.value?.skill.name)) return '';
  if (!isSkill.value && (!technology.value || !technology.value?.technology.name)) return '';

  return isSkill.value ? skill.value?.skill.name : technology.value?.technology.name;
});

const expertises = computed(() => {
  if (isSkill.value && (!skill.value || !skill.value?.skill.expertise))
    return { label: '', value: '' };
  if (!isSkill.value && (!technology.value || !technology.value?.technology.expertises))
    return [{ label: '', value: '' }];

  return isSkill.value
    ? {
        label: skill.value?.skill.expertise.name ?? '',
        value: skill.value?.skill.expertiseId ?? '',
      }
    : technology.value?.technology.expertises.map((tech) => ({
        label: tech.name,
        value: tech.id,
      }));
});

const expertiseOptions = computed(() => {
  if (
    statusExpertise.value !== 'success' ||
    loadingExpertise.value ||
    !expertise.value ||
    !expertise.value?.expertises
  )
    return [];

  return (expertise.value?.expertises.edges || [])?.map((element) => {
    const { node: exprtise } = element;

    return {
      label: exprtise.name,
      value: exprtise.id,
    };
  });
});

const inputData = ref<UpdateSkillInput | UpdateTechnologyInput>();

const dataSkill = reactive<MutationUpdateSkillArgs>({
  input: {} as UpdateSkillInput,
  skillId: id as string,
});

const dataTechnology = reactive<MutationUpdateTechnologyArgs>({
  input: {} as UpdateTechnologyInput,
  technologyId: id as string,
});

const { handleSubmit } = useForm();

const onSubmit = handleSubmit((values) => {
  if (isSkill.value) {
    inputData.value = {
      name: values.name,
      expertiseId: values.expertiseId.value,
    };
  } else {
    inputData.value = {
      name: values.name,
      expertiseIds: values.expertiseIds.map((exp: Option) => exp.value),
    };
  }

  if (isSkill.value) {
    dataSkill.input = inputData.value as UpdateSkillInput;
  } else {
    dataTechnology.input = inputData.value as UpdateTechnologyInput;
  }
});

const enabledUpdateSkill = computed(() => !!inputData.value && isSkill.value);

const { isSuccess: successUpdateSkill, isError: errorUpdateSkill } = updateSkill(dataSkill, {
  enabled: enabledUpdateSkill,
});

const enabledUpdateTechnology = computed(() => !!inputData.value && !isSkill.value);

const { isSuccess: successUpdateTechnology, isError: errorUpdateTechnology } = updateTechnology(
  dataTechnology,
  {
    enabled: enabledUpdateTechnology,
  }
);

const enableDeleteSkills = computed(() => skillDeleted.value && isSkill.value);

const enableDeleteTechnologies = computed(() => skillDeleted.value && !isSkill.value);

const { isSuccess: hardSuccessDeleteSkills, isError: hardErrorDeleteSkills } = hardDeleteSkills(
  { skillIds: new Array(id as string) },
  {
    enabled: enableDeleteSkills,
  }
);

const { isSuccess: hardSuccessDeleteTechnologies, isError: hardErrorDeleteTechnologies } =
  hardDeleteTechnologies(
    { technologyIds: new Array(id as string) },
    {
      enabled: enableDeleteTechnologies,
    }
  );

watch(
  [
    successUpdateSkill,
    successUpdateTechnology,
    hardSuccessDeleteSkills,
    hardSuccessDeleteTechnologies,
  ],
  () => {
    setTimeout(() => {
      removeSkill();
      removeTechnology();
      router.push('/settings');
    }, NAVIGATION_BACK_DEFAULT_TIME);
  }
);
</script>

<template>
  <div class="UpdateSkill">
    <GoBack class="mb-2" :headline="nameItem" previous-route="/settings" />
    <div class="container">
      <div
        class="UpdateSkill__form-template start-3 start-xl-4 col-8 col-xl-6"
        v-if="nameItem && expertises"
      >
        <form @submit="onSubmit">
          <FormGroup
            class="FormGroup"
            :title="strings.detail.replace('{skill}', isSkill ? strings.skill : strings.tech)"
          >
            <template #formContent>
              <div class="UpdateSkill__input-label text-label">{{ strings.nameSkill }}</div>
              <InputText
                class="UpdateSkill__input-text"
                :initial-value="nameItem"
                :name="strings.InputName"
                :rules="{ required: true }"
              />
              <Select
                v-if="isSkill"
                :label="strings.parentSkill"
                :placeholder="strings.selectParentSkills"
                :initial-value="!Array.isArray(expertises) ? expertises : { label: '', value: '' }"
                :name="strings.selectName"
                :options="expertiseOptions"
                :rules="{ required: true }"
              />
              <MultiSelect
                v-else
                :label="strings.parentTech"
                :placeholder="strings.selectParentTech"
                :initial-value="Array.isArray(expertises) ? expertises : []"
                :name="strings.multiSelectName"
                :options="expertiseOptions"
                :rules="{ required: true }"
              />
            </template>
          </FormGroup>
          <div class="UpdateSkill__form-template-buttons-wrapper">
            <Button
              :label="update.replace('{name}', isSkill ? strings.skill : strings.tech)"
              native-type="submit"
            />
            <Button
              :label="strings.delete.replace('{skill}', isSkill ? strings.skill : strings.tech)"
              variant="link-tertiary-status"
              @clicked="openModal = true"
            />
          </div>
        </form>
      </div>
    </div>
    <Modal
      :is-modal-active="openModal"
      @close-modal="openModal = false"
      :head="strings.delete.replace('{skill}', isSkill ? strings.skill : strings.tech)"
    >
      <template #modal-main>
        <div class="popupModalSettings">
          <h4 class="popupModalSettings-title heading-h4">{{ nameItem }}</h4>
          <div class="popupModalSettings-main">
            <p v-if="!skillDeleted" class="popupModalSettings-main-paragraph heading-h4">
              {{
                strings.paragraphModal.replace('{skill}', isSkill ? strings.skill : strings.tech)
              }}
            </p>
            <p v-else class="popupModalSettings-main-paragraph heading-h4">
              {{
                strings.deletedParagraph.replace('{skill}', isSkill ? strings.skill : strings.tech)
              }}
            </p>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <component
          v-if="!skillDeleted"
          :is="Button"
          variant="secondary"
          :label="strings.cancel"
          @clicked="openModal = false"
        ></component>
        <component
          v-if="!skillDeleted"
          :is="Button"
          variant="primary"
          :label="strings.confirm"
          @clicked="skillDeleted = true"
        ></component>

        <component
          :is="Button"
          variant="primary"
          :label="strings.understand"
          @clicked="openModal = false"
          v-else
        ></component>
      </template>
    </Modal>
    <Notification
      v-if="
        errorUpdateSkill ||
        successUpdateSkill ||
        successUpdateTechnology ||
        errorUpdateTechnology ||
        hardErrorDeleteSkills ||
        hardErrorDeleteTechnologies
      "
      :message="
        hardErrorDeleteSkills
          ? strings.deleteSkillError
          : hardErrorDeleteTechnologies
          ? strings.deleteTechError
          : errorUpdateTechnology
          ? strings.updateTechnologyError
          : successUpdateTechnology
          ? strings.updateTechnologySuccess
          : errorUpdateSkill
          ? strings.updateSkillError
          : strings.updateSkillSuccess
      "
      :status="errorUpdateSkill || errorUpdateTechnology ? 'error' : 'success'"
    />
  </div>
</template>

<style scoped lang="scss">
.UpdateSkill {
  &__form-template {
    :deep(.FormGroup) {
      margin-bottom: 3.2rem;
    }
  }

  &__input {
    &-label {
      text-transform: uppercase;
      margin-bottom: 0.8rem;
    }

    &-text.InputText {
      margin: 0 0 2.4rem;
    }
  }

  &__form-template {
    &-buttons-wrapper {
      @include flexing(column, center, center);
      gap: 2.4rem 0;
    }
  }
}

.popupModalSettings {
  width: 41.6rem;
  margin: 0 auto;
  text-align: center;

  &-title,
  &-main-paragraph {
    margin: 0 0 3.2rem;
  }
}
</style>
