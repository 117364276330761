import { gql } from '@/api/graphql-client';
import type { MutationUpdateInferenceReviewArgs, InferenceReview } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';
import { inferenceReviewFragment } from '../fragments/inferenceReview';

export const updateInferenceReviewMutation = gql`
  mutation UpdateInferenceReview($input: UpdateInferenceReviewInput!) {
    updateInferenceReview(input: $input) {
      ...inferenceReview
    }
  }
  ${inferenceReviewFragment}
`;

export const updateInferenceReview = (
  props?: MutationUpdateInferenceReviewArgs,
  options?: UseQueryOptions<
    InferenceReview,
    MutationUpdateInferenceReviewArgs,
    InferenceReview,
    any[]
  >
): UseQueryReturnType<InferenceReview, MutationUpdateInferenceReviewArgs> =>
  useGraphql({
    query: updateInferenceReviewMutation,
    variables: props,
    options,
  });
