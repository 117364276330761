<script setup lang="ts">
import { createSkill } from '@/api/mutations/createSkill';
import { createTechnology } from '@/api/mutations/createTechnology';
import { hardDeleteSkills } from '@/api/mutations/hardDeleteSkills';
import { hardDeleteTechnologies } from '@/api/mutations/hardDeleteTechnologies';
import { getExpertise } from '@/api/queries/expertise';
import { getSkills } from '@/api/queries/skills';
import { getTechnologies } from '@/api/queries/technologies';
import Button from '@/components/atoms/Button.vue';
import InputText from '@/components/atoms/InputText.vue';
import MultiSelect from '@/components/atoms/MultiSelect.vue';
import Notification from '@/components/atoms/Notification.vue';
import Select from '@/components/atoms/Select.vue';
import Nav from '@/components/molecules/Nav.vue';
import Pagination from '@/components/molecules/Pagination.vue';
import Table from '@/components/organisms/Table/Table.vue';
import { INavOptions, useNav } from '@/composables/useNav';
import { usePagination } from '@/composables/usePagination';
import { useTable } from '@/composables/useTable';
import strings from '@/data/Settings.json';
import { NOTIFICATION_DURATION } from '@/data/constants';
import type {
  CreateSkillInput,
  CreateTechnologyInput,
  MutationCreateSkillArgs,
  MutationCreateTechnologyArgs,
  MutationHardDeleteSkillsArgs,
  MutationHardDeleteTechnologiesArgs,
  QuerySkillsArgs,
  QueryTechnologiesArgs,
} from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { ITableColumns } from '@/models/tableModel';
import { useForm } from 'vee-validate';
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

type NotificationStatus = 'success' | 'error' | '';

const route = useRoute();

const {
  activeNav,
  setActiveNav,
  items: navItems,
} = useNav({ nav: INavOptions.Settings, pathName: route.matched[0].name as string });
const isTechnologies = computed(() => activeNav.value.value === 'TECHNOLOGIES');

const { selectedItems, selectItems, resetSelection, columns } = useTable();

const page = ref(1);
const visibleResult = ref(+strings.visibleResultPerPage);
const action = ref('');
const submitted = ref(false);
const notificationPopup = ref(false);

watch(activeNav, () => {
  page.value = 1;
  delete params.last;
  delete params.after;
  delete params.before;
  params.first = visibleResult.value;
  action.value = '';
});

const params = reactive<QuerySkillsArgs | QueryTechnologiesArgs>({ first: visibleResult.value });
const {
  data: dataSkills,
  status: statusSkills,
  isLoading: loadingSkills,
  refetch: refetchSkills,
} = getSkills(params);

const {
  data: dataTechnologies,
  status: statusTechnologies,
  isLoading: loadingTechnologies,
  refetch: refetchTechnologies,
} = getTechnologies(params, { enabled: isTechnologies });

const { data: expertise, status: statusExpertise, isLoading: loadingExpertise } = getExpertise();

const items = computed(() => {
  if (
    !isTechnologies.value &&
    (statusSkills.value !== 'success' ||
      loadingSkills.value ||
      !dataSkills.value ||
      !dataSkills.value?.skills)
  )
    return [];

  if (
    isTechnologies.value &&
    (statusTechnologies.value !== 'success' ||
      loadingTechnologies.value ||
      !dataTechnologies.value ||
      !dataTechnologies.value?.technologies)
  )
    return [];

  const dataToMap = !isTechnologies.value
    ? dataSkills.value?.skills
    : dataTechnologies.value?.technologies;

  return (dataToMap?.edges || [])?.map((element) => {
    const { node: item } = element;

    return {
      id: item.id,
      name: item.name,
      actions: {
        tag: 'router-link',
        to: `/settings/edit${isTechnologies.value ? '/tech/' + item.id : '/skill/' + item.id}`,
      },
    };
  });
});

const expertiseOptions = computed(() => {
  if (
    statusExpertise.value !== 'success' ||
    loadingExpertise.value ||
    !expertise.value ||
    !expertise.value?.expertises
  )
    return [];

  return (expertise.value?.expertises.edges || [])?.map((element) => {
    const { node: exprtise } = element;

    return {
      label: exprtise.name,
      value: exprtise.id,
    };
  });
});

const inputSkill = ref<CreateSkillInput>();
const addSkill = reactive<MutationCreateSkillArgs>({ input: inputSkill.value as CreateSkillInput });

const inputTech = ref<CreateTechnologyInput>();
const addTech = reactive<MutationCreateTechnologyArgs>({
  input: inputTech.value as CreateTechnologyInput,
});

const { handleSubmit } = useForm();

const onSubmit = handleSubmit((values, actions) => {
  if (isTechnologies.value) {
    inputTech.value = {
      name: values.name,
      expertiseIds: values.expertiseIds.map((expertise: Option) => expertise.value),
    };
  } else {
    inputSkill.value = {
      name: values.name,
      expertiseId: values.expertiseId.value,
    };
  }

  actions.resetForm();
});

watch([inputSkill, inputTech], () => {
  if (inputSkill.value) {
    addSkill.input = inputSkill.value;
  }

  if (inputTech.value) {
    addTech.input = inputTech.value;
  }
});

const enabledCreateSkill = computed(() =>
  addSkill.input ? addSkill.input.name.length > 0 && !isTechnologies.value : false
);

const { isError: errorCreateSkill, isSuccess: successCreateSkill } = createSkill(addSkill, {
  enabled: enabledCreateSkill,
});

const enabledCreateTech = computed(() =>
  addTech.input ? addTech.input.name.length > 0 && isTechnologies.value : false
);

const { isError: errorCreateTech, isSuccess: successCreateTech } = createTechnology(addTech, {
  enabled: enabledCreateTech,
});

const skills = ref<MutationHardDeleteSkillsArgs>({ skillIds: [''] });
const enableDeleteSkills = computed(
  () => !!skills.value && submitted.value && !isTechnologies.value
);

const technologies = ref<MutationHardDeleteTechnologiesArgs>({ technologyIds: [''] });
const enableDeleteTechnologies = computed(
  () => !!skills.value && submitted.value && isTechnologies.value
);

const submit = () => {
  const skillsToDelete = selectedItems.value.map((item) => (item as (typeof items.value)[0]).id);
  if (action.value === 'delete') {
    skills.value.skillIds = skillsToDelete;
  }

  const technologiesToDelete = selectedItems.value.map(
    (item) => (item as (typeof items.value)[0]).id
  );
  if (action.value === 'delete') {
    technologies.value.technologyIds = technologiesToDelete;
  }
  submitted.value = true;
};

const { isSuccess: hardSuccessDeleteSkills, isError: hardErrorDeleteSkills } = hardDeleteSkills(
  skills.value,
  {
    enabled: enableDeleteSkills,
  }
);

const { isSuccess: hardSuccessDeleteTechnologies, isError: hardErrorDeleteTechnologies } =
  hardDeleteTechnologies(technologies.value, {
    enabled: enableDeleteTechnologies,
  });

watch(
  [successCreateSkill, hardSuccessDeleteSkills, successCreateTech, hardSuccessDeleteTechnologies],
  () => {
    page.value = 1;
    delete params.last;
    delete params.after;
    delete params.before;
    params.first = visibleResult.value;
    action.value = '';

    if (successCreateTech.value || successCreateSkill.value) {
      setTimeout(() => {
        addTech.input ? (addTech.input.name = '') : '';
        addSkill.input ? (addSkill.input.name = '') : '';

        skills.value.skillIds = [];
        technologies.value.technologyIds = [];
      }, NOTIFICATION_DURATION);
    }

    refetchSkills();
    refetchTechnologies();
  }
);

const setAction = (opt: Option) => (action.value = opt.value);

const isApplyButtonDisabled = computed(
  () => selectedItems.value.length <= 0 || action.value === ''
);

const countItems = computed(() =>
  !isTechnologies.value
    ? dataSkills.value?.skills.totalCount ?? 0
    : dataTechnologies.value?.technologies.totalCount ?? 0
);

const totalPages = computed(() => Math.ceil(countItems.value / visibleResult.value));

const paginationSettingsValue = computed(
  () => countItems.value - visibleResult.value * (totalPages.value - 1)
);

const settingsPagination = computed(() => {
  if (
    !isTechnologies.value &&
    (statusSkills.value !== 'success' ||
      loadingSkills.value ||
      !dataSkills.value?.skills.pageInfo ||
      !dataSkills.value.skills.totalCount)
  )
    return undefined;

  if (
    isTechnologies.value &&
    (statusTechnologies.value !== 'success' ||
      loadingTechnologies.value ||
      !dataTechnologies.value ||
      !dataTechnologies.value.technologies.pageInfo ||
      !dataTechnologies.value.technologies.totalCount)
  )
    return undefined;

  const {
    endCursor: nextPage,
    hasNextPage,
    hasPreviousPage,
    startCursor: prevPage,
  } = !isTechnologies.value
    ? dataSkills.value!.skills.pageInfo
    : dataTechnologies.value!.technologies.pageInfo;

  return {
    label: !isTechnologies.value ? strings.skill : strings.technologies,
    currentPage: page.value ?? 1,
    nextPage,
    hasNextPage,
    prevPage,
    hasPreviousPage,
    totalItem: countItems.value,
    totalPages: totalPages.value,
  };
});
const changePage = async (type: 'first' | 'prev' | 'next' | 'last', cursor: string | undefined) => {
  const { currentPage, paginationParams } = usePagination(
    type,
    cursor,
    page.value,
    visibleResult.value,
    totalPages.value,
    paginationSettingsValue.value,
    params
  );
  page.value = currentPage;
  Object.assign(params, paginationParams);
};

const messageNotification = computed(() => {
  switch (true) {
    case hardSuccessDeleteSkills.value:
      return {
        status: 'success',
        message: strings.deleteSkillSuccess,
      };

    case hardErrorDeleteSkills.value:
      return {
        status: 'error',
        message: strings.deleteSkillError,
      };

    case errorCreateSkill.value:
      return {
        status: 'error',
        message: strings.createSkillError,
      };

    case successCreateSkill.value:
      return {
        status: 'success',
        message: strings.createSkillSuccess,
      };

    case errorCreateTech.value:
      return {
        status: 'error',
        message: strings.createTechError,
      };

    case successCreateTech.value:
      return {
        status: 'success',
        message: strings.createTechSuccess,
      };

    case hardSuccessDeleteTechnologies.value:
      return {
        status: 'success',
        message: strings.deleteTechSuccess,
      };

    case hardErrorDeleteTechnologies.value:
      return {
        status: 'error',
        message: strings.deleteTechError,
      };

    default:
      return {
        status: 'error',
        message: strings.genericError,
      };
  }
});

watch(
  [
    errorCreateSkill,
    errorCreateTech,
    successCreateSkill,
    successCreateTech,
    hardSuccessDeleteSkills,
    hardErrorDeleteSkills,
    hardSuccessDeleteTechnologies,
    hardErrorDeleteTechnologies,
  ],
  () => {
    if (
      errorCreateSkill.value ||
      errorCreateTech.value ||
      successCreateSkill.value ||
      successCreateTech.value ||
      hardSuccessDeleteSkills.value ||
      hardErrorDeleteSkills.value ||
      hardErrorDeleteTechnologies.value ||
      hardSuccessDeleteTechnologies
    ) {
      notificationPopup.value = true;
      setTimeout(() => {
        notificationPopup.value = false;
      }, NOTIFICATION_DURATION);
    } else {
      notificationPopup.value = false;
    }
  }
);
</script>

<template>
  <main class="Settings container-fluid">
    <div class="container col-12">
      <h1 class="heading-h1 col-12">{{ strings.settings }}</h1>
    </div>
    <Nav
      class="col-12 mb-2"
      :items="navItems"
      :active-item="activeNav.value"
      @clicked="setActiveNav"
    />
    <div class="container col-12">
      <div class="col-5 col-xl-4">
        <h3 class="Settings-heading heading-h3">{{ strings.addNewOption }}</h3>
        <div class="Settings-label text-label">
          {{ isTechnologies ? strings.nameTech : strings.nameSkill }}
        </div>
        <form class="FormGroup Settings__form" @submit="onSubmit">
          <InputText
            class="Settings__form-input"
            :placeholder="isTechnologies ? strings.addTech : strings.addSkill"
            :name="strings.InputName"
            :rules="{ required: true }"
          />
          <MultiSelect
            v-if="isTechnologies"
            :label="strings.parentTech"
            :placeholder="strings.selectParentTech"
            :name="strings.multiSelectName"
            :options="expertiseOptions"
            :initial-value="successCreateTech ? [] : undefined"
            :rules="{ required: true }"
          />
          <Select
            v-else
            :label="strings.parentSkill"
            :placeholder="strings.selectParentSkills"
            :name="strings.selectName"
            :options="expertiseOptions"
            :rules="{ required: true }"
          />
          <Button class="Settings__button" :label="strings.addButton" native-type="submit" />
        </form>
      </div>

      <div class="col-6 start-7">
        <h3 class="Settings-heading heading-h3">
          {{ isTechnologies ? strings.techsList : strings.skillsList }}
        </h3>
        <div class="Settings__actions">
          <Select
            :standalone="true"
            :placeholder="strings.optionsActionPlaceholder"
            :options="strings.optionsAction"
            :initial-value="
              action.length > 0
                ? strings.optionsAction.find((opt) => opt.value === action)
                : { label: '', value: '' }
            "
            size="small"
            @selected="setAction"
          />
          <Button
            :label="strings.applyButton"
            :disabled="isApplyButtonDisabled"
            @clicked="submit"
          />
        </div>
        <Table
          :items="items || []"
          :columns="columns[ITableColumns.Settings]"
          :is-loading="!isTechnologies ? loadingSkills : loadingTechnologies"
          icon="edit"
          is-small-row
          @select-items="selectItems"
          @reset-selection="resetSelection"
        />
        <Pagination
          v-if="settingsPagination"
          v-bind="{ ...settingsPagination }"
          @change-page="changePage"
        />
      </div>
    </div>
    <Notification
      v-if="notificationPopup"
      :message="messageNotification.message"
      :status="(messageNotification.status as NotificationStatus)"
    />
  </main>
</template>

<style scoped lang="scss">
.Settings {
  .Table {
    :deep(.o-table__td) {
      padding-bottom: 2.7rem;
      vertical-align: middle;
    }
  }

  &-heading {
    margin: 0 0 2.6rem;
  }

  &-label {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__form {
    &-input.InputText {
      margin: 0 0 2.4rem;
    }
  }

  &__button {
    margin: 2.4rem 0 0;
  }

  &__actions {
    @include flexing(row, start, start);
    gap: 0 0.8rem;
    margin: 0 0 2.4rem;
  }

  :deep(.Table) {
    .Table__header:last-of-type {
      text-align: end;
      padding-right: 3.4rem;

      &::before {
        right: 2.5rem;
      }
    }

    .o-table__td:last-of-type {
      .TableActions {
        width: 100%;
        padding-right: 3.3rem;
        &__buttons-wrapper {
          width: 100%;
          justify-content: end;
          @include for-desktop {
            justify-content: end;
            flex-flow: row;
          }
        }
      }
    }
  }
}
</style>
