import { gql } from '@/api/graphql-client';
import type { MutationUpdateTalentDocumentArgs, Talent } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

export const updateTalentDocumentMutation = gql`
  mutation UpdateTalentDocument($data: UpdateTalentDocumentInput!) {
    updateTalentDocument(data: $data)
  }
`;

const fetcherWithFile = async ({
  query,
  variables,
  headers,
}: {
  query: string;
  variables?: MutationUpdateTalentDocumentArgs;
  headers?: HeadersInit;
}) => {
  const formData = new FormData();
  const newVariable = { data: { ...variables?.data, file: null } };
  const { file } = variables!.data;

  formData.append('operations', JSON.stringify({ query, variables: newVariable }));
  formData.append('map', JSON.stringify({ 0: ['variables.data.file'] }));
  formData.append('0', file);

  const response: Response = await fetch(import.meta.env.VITE_GRAPHQL_DEV, {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...(headers || {}),
    },
    body: formData,
  });

  if (response.status !== 200) {
    throw new Error(`Error: ${response.statusText}. Body: ${await response.text()}`);
  }

  const json = await response.json();

  if (!json.data && json.errors.length > 0) {
    const { extensions, message } = json.errors[0];
    throw new Error(`Error: ${extensions.code}. Body: ${message}`);
  }

  return json.data;
};

export const updateTalentDocument = (
  props: MutationUpdateTalentDocumentArgs,
  options?: UseQueryOptions<Talent, MutationUpdateTalentDocumentArgs, Talent, any[]>
) => {
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);
  const retries = ref<number>(0);

  const query = updateTalentDocumentMutation;
  const variables = props;

  const keys = [query, variables];

  const fn = () =>
    fetcherWithFile({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
        'Apollo-Require-Preflight': 'true',
      },
      query,
      variables,
    });

  const queryOptions = {
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  };

  const request = useQuery(keys, fn, queryOptions);

  watch([request.error], async () => {
    const isUnauthorized = `${request.error.value}`
      .toLowerCase()
      .split(' ')
      .some((el) => ['unauthorized', 'unauthenticated'].includes(el));

    if (retries.value < 1 && isUnauthorized) {
      await userStore.refreshToken(jwt.value);
      await request.refetch();
      retries.value++;
    } else if (isUnauthorized) {
      await userStore.logout();
    }
  });

  return request;
};
