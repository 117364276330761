import { gql } from 'graphql-request';
import type { RejectAgencyMutationMutation, MutationRejectAgencyArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const rejectAgencyMutation = gql`
  mutation RejectAgencyMutation($status: TalentStatus!, $agencyId: String!) {
    rejectAgency(status: $status, agencyId: $agencyId)
  }
`;

export const rejectAgency = (
  props?: MutationRejectAgencyArgs,
  options?: UseQueryOptions<
    RejectAgencyMutationMutation,
    MutationRejectAgencyArgs,
    RejectAgencyMutationMutation,
    any[]
  >
): UseQueryReturnType<RejectAgencyMutationMutation, MutationRejectAgencyArgs> =>
  useGraphql({
    query: rejectAgencyMutation,
    variables: props,
    options,
  });
