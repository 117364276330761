import { gql } from '@/api/graphql-client';
import { billingFragment } from '@/api/fragments/billing';

export const orderFragment = gql`
  fragment order on Order {
    id
    billing {
      ...billing
    }
    coupon
    createdAt
    experience {
      id
      availables
      bookings {
        ...bookings
      }
      category {
        id
        createdAt
        updatedAt
        slug
        title
        experiences {
          id
          availables
          bookings {
            ...bookings
          }
          category {
            id
            createdAt
            updatedAt
            slug
            title
          }
          categorySlug
          createdAt
          discount
          extra {
            ...extra
          }
          from
          hasInsurance
          isActive
          isFlexible
          location
          orders {
            id
            billing {
              ...billing
            }
            coupon
            createdAt
            experienceId
            flexible
            guests {
              ...bookings
            }
            insurance
            paymentId
            status
            totalAmount
            updatedAt
          }
          price
          slug
          to
          updatedAt
        }
      }
      categorySlug
      createdAt
      discount
      from
      hasInsurance
      isActive
      isFlexible
      location
      orders {
        id
        billing {
          ...billing
        }
        coupon
        createdAt
        experienceId
        flexible
        guests {
          ...bookings
        }
        insurance
        paymentId
        status
        totalAmount
        updatedAt
      }
      price
      slug
      to
      updatedAt
    }
    experienceId
    flexible
    guests {
      ...bookings
    }
    insurance
    paymentId
    status
    totalAmount
    updatedAt
  }
  ${billingFragment}
`;
