import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import { agencyFragment } from '@/api/fragments/agency';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetAgenciesQuery, QueryAgenciesArgs } from '@/gql/Gql.types';

const agenciesQuery = gql`
  query getAgencies(
    $after: String
    $before: String
    $filters: AgenciesFilterInput
    $first: Int
    $last: Int
    $orderBy: AgenciesOrder
  ) {
    agencies(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...agency
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${agencyFragment}
  ${pageInfo}
`;

export const getAgencies = (
  props?: QueryAgenciesArgs,
  options?: UseQueryOptions<GetAgenciesQuery, QueryAgenciesArgs, GetAgenciesQuery, any[]>
): UseQueryReturnType<GetAgenciesQuery, QueryAgenciesArgs> =>
  useGraphql({
    query: agenciesQuery,
    variables: props,
    options,
  });
