import { gql } from '@/api/graphql-client';
import { type MutationTalentHtmlCvArgs } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { fetcher } from '@/api/use-graphql';
import { useMutation } from '@tanstack/vue-query';

export const generateCV = gql`
  mutation GenerateCV($talentId: String!) {
    talentHtmlCV(talentId: $talentId)
  }
`;

export const useGenerateCV = (props: MutationTalentHtmlCvArgs) => {
  const userStore = useUserStore();
  const { jwt } = storeToRefs(userStore);

  const keys = [generateCV, props];

  const fn = () =>
    fetcher<
      {
        talentHtmlCV: string;
      },
      MutationTalentHtmlCvArgs
    >({
      headers: {
        ...(jwt.value && {
          Authorization: `Bearer ${jwt.value}`,
        }),
      },
      query: generateCV,
      variables: props,
    });

  const mutation = useMutation(keys, fn);

  return mutation;
};
