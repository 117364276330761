import { gql } from '@/api/graphql-client';
import { skillFragment } from '@/api/fragments/skill';
import type { MutationUpdateSkillArgs, Skill } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateSkillMutation = gql`
  mutation UpdateSkill($skillId: String!, $input: UpdateSkillInput!) {
    updateSkill(skillId: $skillId, input: $input) {
      ...skill
    }
  }
  ${skillFragment}
`;

export const updateSkill = (
  props?: MutationUpdateSkillArgs,
  options?: UseQueryOptions<Skill, MutationUpdateSkillArgs, Skill, any[]>
): UseQueryReturnType<Skill, MutationUpdateSkillArgs> =>
  useGraphql({
    query: updateSkillMutation,
    variables: props,
    options,
  });
