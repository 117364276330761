<script setup lang="ts">
import { toRefs } from 'vue';
import Icon from '@/components/atoms/Icon.vue';
import type { IAccordionProps } from '@/models/accordionModel';

const props = withDefaults(defineProps<IAccordionProps>(), {
  count: 0,
  isOpen: false,
  hasCount: false,
  disabled: false,
});

const { label, count, isOpen, hasCount, index, disabled } = toRefs(props);
</script>

<template>
  <o-collapse
    :aria-id="label"
    :class="['Accordion text-body-1', { '--disabled': disabled }]"
    animation="slide"
    :open="isOpen"
    @open="$emit('click', index)"
  >
    <template #trigger="props">
      <div class="title" :aria-controls="label">
        <div>
          <span> {{ label }} </span> <span v-if="hasCount">({{ count ?? 0 }})</span>
        </div>
        <Icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></Icon>
      </div>
    </template>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </o-collapse>
</template>

<style scoped lang="scss">
.Accordion {
  margin-bottom: 2.4rem;
  border-bottom: 0.1rem solid $black-50;

  &.--disabled {
    pointer-events: none;
    border-color: $black-20;
    color: $black-20;
  }

  &:hover {
    border-color: $primary-color;
  }

  &--open {
    border-color: $primary-color;
  }
  :deep .o-clps {
    &__trigger {
      margin-bottom: 2.4rem;
      .title {
        @include flexing(row, space-between, center);

        &-title {
          margin-top: 0;
          margin-bottom: 2.4rem;
        }
      }
    }

    &__content {
      margin-bottom: 2.4rem;
    }
  }
}
</style>
