<script setup lang="ts">
import { toRefs } from 'vue';
import Button from '@/components/atoms/Button.vue';
import strings from '@/data/GenericStrings.json';

const props = withDefaults(
  defineProps<{
    headline?: string;
    subtitle?: string;
    previousRoute: string;
  }>(),
  {
    previousRoute: '/',
  }
);

const { headline, subtitle, previousRoute } = toRefs(props);

/**
 * state interface is:
 * {back, current, forward, position, replaced, scroll}
 */
</script>
<template>
  <div class="GoBack container">
    <div class="col-12">
      <Button
        class="GoBack__button"
        variant="link-tertiary"
        :label="strings.goBack"
        tag="router-link"
        :to="previousRoute"
        icon="chevron-left"
      />
    </div>
    <div class="GoBack__head col-12">
      <o-skeleton v-if="!headline" width="50%" size="large" height="36px" :animated="true" />
      <div v-else>
        <h1 class="GoBack__head-title heading-h2">{{ headline }}</h1>
        <div v-if="subtitle" class="GoBack__head-subtitle text-body-1">{{ subtitle }}</div>
      </div>
      <!--  slot for action button on the right of the section   -->
      <slot name="action-button"></slot>
    </div>
  </div>
</template>
<style scoped lang="scss">
.GoBack {
  &__button {
    margin: 0 0 1.6rem;

    :deep {
      .o-btn {
        &__wrapper {
          .o-btn__label {
            .text-link {
              @extend .text-body-1;
              padding: 0;
              margin: 0;
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &__head {
    @include flexing(row, space-between, center);

    &-title {
      margin: 0;
    }

    &-subtitle {
      margin-top: 0.8rem;
      color: $black-50;
    }
  }
}
</style>
