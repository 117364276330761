<script setup lang="ts">
import { addTalent } from '@/api/mutations/addTalent';
import Button from '@/components/atoms/Button.vue';
import Notification from '@/components/atoms/Notification.vue';
import GoBack from '@/components/molecules/GoBack.vue';
import TalentsUpdateForm from '@/components/organisms/CreateUpdateForm/TalentsUpdateForm.vue';
import strings from '@/data/Talents.json';
import { NAVIGATION_BACK_DEFAULT_TIME, NOTIFICATION_DURATION } from '@/data/constants';
import type { CreateTalentInput, MutationAddTalentArgs } from '@/gql/Gql.types';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const { talentAddErrorToast, talentAddSuccessToast, talentSubmitLabelConfirm, addNewTalent } =
  strings;

const router = useRouter();
const submitted = ref(false);

const formData = ref<CreateTalentInput>();
const data = reactive<MutationAddTalentArgs>({
  input: {} as CreateTalentInput,
});

watch(formData, () => (data.input = { ...formData.value } as CreateTalentInput));

const enabled = computed(() => Object.keys(data.input).length > 0);
const { isSuccess, isError } = addTalent(data, { enabled });

const addTalentSubmit = (data: CreateTalentInput) => (
  (formData.value = data), (submitted.value = false)
);

watch(isSuccess, () => {
  if (!isSuccess.value) return;

  setTimeout(() => {
    router.back();
  }, NAVIGATION_BACK_DEFAULT_TIME);
});

const onSubmit = () => {
  submitted.value = true;

  setTimeout(() => {
    submitted.value = false;
  }, 300);
};
</script>

<template>
  <div class="AddTalent">
    <GoBack :headline="addNewTalent" previous-route="/talents" />
    <div class="AddTalent__submit">
      <Button :label="talentSubmitLabelConfirm" @clicked="onSubmit" />
    </div>
    <div class="AddTalent__form container">
      <div class="col-12">
        <TalentsUpdateForm
          :submitted="submitted"
          @talent-data="addTalentSubmit"
          @reset-form="(cb) => isSuccess && cb()"
        />
      </div>
    </div>
    <Notification
      v-if="isError || isSuccess"
      :message="isError ? talentAddErrorToast : talentAddSuccessToast"
      :status="isError ? 'error' : 'success'"
      :duration="NOTIFICATION_DURATION"
    />
  </div>
</template>
<style scoped lang="scss">
.AddTalent {
  position: relative;

  &__form {
    margin-top: 4rem;
  }

  &__submit {
    position: absolute;
    right: 4rem;
    top: 4rem;
  }
}
</style>
