import HomeString from '@/data/Home.json';
import type { CommonAnalyticsQuery } from '@/gql/Gql.types';
import type { IButtonProps, IContentCardProps } from '@/models/contentCardModels';
import type { ILabelCardProps } from '@/models/labelModels';

type ElementsCommonAnalytics = {
  item?: CommonAnalyticsQuery;
  type: string;
};

type MappedCommonAnalytics = {
  elements?: ElementsCommonAnalytics[];
  key: 'today' | 'period';
};

type Expertises = {
  expertise: string;
  count: number;
};

type AnalyticsCard = {
  count: number;
  labelCard?: ILabelCardProps[];
};

type ButtonData = {
  title: string;
  subtitle: string;
  labelButton?: string;
  linkButton?: string;
};

const { today, period } = HomeString.contentCard;

const mapLabelCard = (expertises: Expertises[]) =>
  expertises
    .filter((expertise) => expertise.count !== 0)
    .map((expertise) => ({ count: expertise.count, label: expertise.expertise }));

const mapAnalytics = (items: CommonAnalyticsQuery['commonAnalytics']) =>
  items.map((item) => ({ count: item.totalCount, labelCard: mapLabelCard(item.expertises || []) }));

const createCard = (type: string, key: string, analytics: AnalyticsCard[]) => {
  const labelCard = analytics[0]?.labelCard ?? [];

  const buttonData: ButtonData =
    key === 'today' ? today[type as keyof typeof today] : period[type as keyof typeof period];

  const cta: IButtonProps | null = buttonData.labelButton
    ? { label: buttonData.labelButton, to: buttonData.linkButton, tag: 'router-link' }
    : null;

  return {
    title:
      key === 'today'
        ? today[type as keyof typeof today].title
        : period[type as keyof typeof period].title,
    subtitle:
      key === 'today'
        ? today[type as keyof typeof today].subtitle.replace('{num}', String(analytics[0]?.count))
        : period[type as keyof typeof period].subtitle.replace(
            '{num}',
            String(analytics[0]?.count)
          ),
    labelCard: labelCard,
    cta: cta,
  };
};

export const useMappedCommonAnalytics = ({
  key,
  elements,
}: MappedCommonAnalytics): IContentCardProps[] => {
  if (!elements?.every((element) => element.item)) return [];

  return elements.map((element) => {
    const itemAnalytics = mapAnalytics(element.item!.commonAnalytics);
    return createCard(element.type, key, itemAnalytics);
  });
};
