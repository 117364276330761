<script setup lang="ts">
import type { IRadioProps } from '@/models/radioModel';
import { Field } from 'vee-validate';
import { ref, watch, toRefs } from 'vue';

const props = withDefaults(defineProps<IRadioProps>(), {
  label: '',
  as: 'radio',
  disabled: false,
  name: '',
  initialValue: '',
});

const { label, as, disabled, variant, name, rules, value, initialValue, description } =
  toRefs(props);

const radio = ref(initialValue.value);

const emit = defineEmits(['changed']);

watch([radio, initialValue], ([newRadio, newInitialValue], [oldRadio, oldInitialVlaue]) => {
  radio.value === oldRadio ? (radio.value = newInitialValue) : newRadio;
  emit('changed', radio.value);
});
</script>
<template>
  <Field
    v-slot="{ field, errors }"
    v-model="radio"
    :name="name"
    type="radio"
    :value="value ?? label"
    :rules="rules"
  >
    <o-radio
      v-model="radio"
      v-bind="field"
      :class="[`Radio Radio--${as}`, variant]"
      :disabled="disabled"
      :name="name"
      :value="value ?? label"
      :native-value="value ?? label"
    >
      <span :class="`${as === 'radio' ? 'text-button' : 'text-body-2'}`">{{ label }}</span>
      <span v-if="description" :class="`${as === 'radio' ? 'text-button' : 'text-body-2'}`">{{
        ` - `
      }}</span>
      <span v-if="description" class="text-body-2 Radio__description">{{ description }}</span>
    </o-radio>
    <p v-if="errors[0]" class="error-message text-body-2">{{ errors[0] }}</p>
  </Field>
</template>

<style lang="scss" scoped>
.Radio {
  &__description {
    color: $black-50;
  }

  &--radio {
    &.o-radio--disabled {
      color: $black-20;

      :deep .o-radio {
        &__check {
          border-color: $black-20;
          opacity: 1;

          &:hover {
            background-color: transparent;
          }

          &--checked {
            background-color: $black-20;

            &:hover {
              background-color: $black-20;
            }
          }
        }
      }
    }

    :deep .o-radio {
      width: 0.2rem;
      height: 0.2rem;

      &__check {
        width: 2.4rem;
        height: 2.4rem;
        border: 1px $primary-color solid;
        background: $secondary-color;

        &:hover {
          background: $black-10;
        }

        &:focus-visible {
          border: none;
          outline: none;
        }

        &--checked {
          background: $primary-color;
          border-color: transparent;
          position: relative;

          &:hover {
            background: $primary-color;
          }

          &::after {
            content: '';
            position: absolute;
            width: 0.8rem;
            height: 0.8rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $secondary-color;
            border-radius: 50%;
          }
        }
      }

      &__label {
        margin-left: 0.8rem;
      }
    }
  }

  &--picker {
    color: $black-50;
    border-radius: 7.2rem;
    padding: 1.1rem 2.4rem;

    &:hover {
      color: $primary-color;
    }

    &.o-radio--checked {
      background-color: $primary-color;
      color: $secondary-color;
    }

    &.o-radio--disabled {
      color: $black-20;
    }

    :deep .o-radio {
      &__check {
        display: none;
      }
    }

    &.dark {
      color: $white-60;

      &:hover {
        color: $secondary-color;
      }

      &.o-radio--checked {
        background-color: $secondary-color;
        color: $primary-color;
      }

      &.o-radio--disabled {
        color: $white-20;
      }
    }
  }
}
</style>
