import { useGraphql } from '@/api/use-graphql';
import type {
  MutationRemoveAgencyLanguageArgs,
  RemoveAgencyLanguageMutationMutation,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { gql } from 'graphql-request';

export const removeAgencyLanguageMutation = gql`
  mutation RemoveAgencyLanguageMutation($languageId: String!, $agencyId: String!) {
    removeAgencyLanguage(languageId: $languageId, agencyId: $agencyId)
  }
`;

export const removeAgencyLanguage = (
  props?: MutationRemoveAgencyLanguageArgs,
  options?: UseQueryOptions<
    RemoveAgencyLanguageMutationMutation,
    MutationRemoveAgencyLanguageArgs,
    RemoveAgencyLanguageMutationMutation,
    any[]
  >
): UseQueryReturnType<RemoveAgencyLanguageMutationMutation, MutationRemoveAgencyLanguageArgs> =>
  useGraphql({
    query: removeAgencyLanguageMutation,
    variables: props,
    options,
  });
