<script setup lang="ts">
import { updateEmailTemplate } from '@/api/mutations/updateEmailTemplate';
import Button from '@/components/atoms/Button.vue';
import InputText from '@/components/atoms/InputText.vue';
import Notification from '@/components/atoms/Notification.vue';
import FormGroup from '@/components/molecules/FormGroup.vue';
import TextAreaForm from '@/components/molecules/TextAreaForm.vue';
import string from '@/data/MailManagement.json';
import { NOTIFICATION_DURATION } from '@/data/constants';
import type {
  EmailTemplate,
  EmailTemplateInput,
  MutationUpdateEmailTemplateArgs,
} from '@/gql/Gql.types';
import { useForm } from 'vee-validate';
import { computed, reactive, ref, toRefs, watch } from 'vue';
import BoxTextArea from './components/BoxTextArea.vue';

const { MailForm } = string;

const props = defineProps<EmailTemplate>();

const { id, body, heading, sign, title, subject, wishes } = toRefs(props);

watch(id, () => {
  dataTemplate.id = id.value;
  dataTemplate.data = {};
});

const template = reactive({
  body: body.value || '',
  heading: heading.value || '',
  sign: sign.value || '',
  subject: subject.value || '',
  wishes: wishes.value || '',
});

const { handleSubmit } = useForm({ initialValues: { ...template } });

const dataTemplate = reactive<MutationUpdateEmailTemplateArgs>({
  data: {} as EmailTemplateInput,
  id: id.value || '',
});

const emits = defineEmits(['updateSuccess']);

const onSubmit = handleSubmit((values) => {
  dataTemplate.data = { ...values };
});

const enabled = computed(() => Object.keys(dataTemplate.data).length > 0 && !!id.value);

const { isSuccess, isError } = updateEmailTemplate(dataTemplate, { enabled });

watch(isSuccess, () => {
  if (isSuccess.value) emits('updateSuccess', isSuccess.value);
});
</script>

<template>
  <form class="MailForm" @submit="onSubmit">
    <FormGroup :title="MailForm.object">
      <template #formContent>
        <InputText
          :initial-value="subject"
          :label="MailForm.inputObject.label"
          :placeholder="MailForm.inputObject.placeholder"
          :name="MailForm.inputObject.name"
          :rules="{ required: false }"
        />
      </template>
    </FormGroup>
    <FormGroup :title="MailForm.heading">
      <template #formContent>
        <InputText
          :initial-value="heading"
          :placeholder="MailForm.inputHeading.placeholder"
          :name="MailForm.inputHeading.name"
          type="textarea"
          :rules="{ required: false }"
        />
      </template>
    </FormGroup>
    <FormGroup :title="MailForm.body">
      <template #formContent>
        <BoxTextArea
          :initial-value="body"
          :label="MailForm.inputParagraph.label"
          :placeholder="MailForm.inputParagraph.placeholder"
          :name="MailForm.inputParagraph.name"
          :rules="{ required: false }"
        />
      </template>
    </FormGroup>
    <FormGroup :title="MailForm.wishes">
      <template #formContent>
        <TextAreaForm
          :initial-value="wishes"
          :label="MailForm.wishesBox.label"
          :placeholder="MailForm.wishesBox.placeholder"
          :name="MailForm.wishesBox.name"
          :rules="{ required: false }"
        />
      </template>
    </FormGroup>
    <FormGroup :title="MailForm.signature">
      <template #formContent>
        <BoxTextArea
          :initial-value="sign"
          :label="MailForm.signatureBox.label"
          :placeholder="MailForm.signatureBox.placeholder"
          :name="MailForm.signatureBox.name"
          :rules="{ required: false }"
        />
      </template>
    </FormGroup>
    <Button class="MailForm-btn" :label="MailForm.buttonForm" native-type="submit" />

    <Notification
      v-if="isError || isSuccess"
      :message="isError ? MailForm.updateError : MailForm.updateSuccess"
      :status="isError ? 'error' : 'success'"
      :duration="NOTIFICATION_DURATION"
    />
  </form>
</template>

<style scoped lang="scss">
.MailForm {
  :deep {
    .FormGroup-title {
      margin-bottom: 2.4rem;
    }
  }

  &-btn {
    :deep &.Button.o-btn--primary {
      display: block;
      margin: 3.2rem auto 4.6rem;
    }
  }
}
</style>
