import { gql } from '@/api/graphql-client';
import { talentFragment } from '@/api/fragments/talent';
import type { MutationUpdateTalentArgs, Talent } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateTalentMutation = gql`
  mutation UpdateTalent($talentId: String!, $newTalentData: UpdateTalentInput!) {
    updateTalent(talentId: $talentId, newTalentData: $newTalentData) {
      ...talent
    }
  }
  ${talentFragment}
`;

export const updateTalent = (
  props: MutationUpdateTalentArgs,
  options?: UseQueryOptions<Talent, MutationUpdateTalentArgs, Talent, any[]>
): UseQueryReturnType<Talent, MutationUpdateTalentArgs> =>
  useGraphql({
    query: updateTalentMutation,
    variables: props,
    options,
  });
