import type { TalentConnection } from '@/gql/Gql.types';
import { MIN_LENGTH_SEARCH } from '@/data/constants';
interface IData {
  data: TalentConnection | undefined;
  searchData: TalentConnection | undefined;
  searchTerms: string;
}

export const useTotalCount = ({ data, searchData, searchTerms }: IData) => {
  if (searchData?.totalCount && searchTerms.length > MIN_LENGTH_SEARCH) {
    return searchData.totalCount;
  }

  if (data?.totalCount && searchTerms.length <= MIN_LENGTH_SEARCH) {
    return data.totalCount;
  }

  return 0;
};
