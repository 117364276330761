<script setup lang="ts">
import { toRefs } from 'vue';
import Highlight from '@/components/atoms/Highlight.vue';

type ITableSeniority = {
  label: string;
  range: string;
  highlightText?: string;
};

const props = withDefaults(defineProps<ITableSeniority>(), {
  label: '',
  range: '',
});

const { label, range } = toRefs(props);
</script>
<template>
  <div class="TableSeniority">
    <span class="text-body-2" v-if="label.length > 0"
      ><Highlight :text="label" :highlight-text="highlightText"
    /></span>
    <span class="text-body-2"><Highlight :text="range" :highlight-text="highlightText" /></span>
  </div>
</template>
<style scoped lang="scss">
.TableSeniority {
  @include flexing(column);
}
</style>
