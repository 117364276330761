import { gql } from '@/api/graphql-client';
import { agencyFragment } from '@/api/fragments/agency';
import type { MutationUpdateAgencyArgs, Agency } from '@/gql/Gql.types';
import type { UseQueryReturnType, UseQueryOptions } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const updateAgencyMutation = gql`
  mutation UpdateAgency($agencyId: String!, $newAgencyData: UpdateAgencyInput!) {
    updateAgency(agencyId: $agencyId, newAgencyData: $newAgencyData) {
      ...agency
    }
  }
  ${agencyFragment}
`;

export const updateAgency = (
  props: MutationUpdateAgencyArgs,
  options?: UseQueryOptions<Agency, MutationUpdateAgencyArgs, Agency, any[]>
): UseQueryReturnType<Agency, MutationUpdateAgencyArgs> =>
  useGraphql({
    query: updateAgencyMutation,
    variables: props,
    options,
  });
