<script setup lang="ts">
import { hardDeleteUser } from '@/api/mutations/hardDeleteUser';
import { softDeleteUser } from '@/api/mutations/softDeleteUser';
import { getUsers } from '@/api/queries/users';
import Button from '@/components/atoms/Button.vue';
import Notification from '@/components/atoms/Notification.vue';
import Select from '@/components/atoms/Select.vue';
import Nav from '@/components/molecules/Nav.vue';
import Table from '@/components/organisms/Table/Table.vue';
import { INavOptions, useNav } from '@/composables/useNav';
import { useTable } from '@/composables/useTable';
import { useTableItemsMapper } from '@/composables/useTableItemsMapper';
import strings from '@/data/GenericStrings.json';
import usersManagement from '@/data/Users.json';
import { NOTIFICATION_DURATION } from '@/data/constants';
import {
  UserRole,
  type GetUsersQueryVariables,
  type MutationSoftDeleteUserArgs,
  MutationRestoreUserArgs,
} from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { ITableColumns } from '@/models/tableModel';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed, ref, watch, type ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { restoreUsers } from '@/api/mutations/restoreUsers';
import { default as GenericStrings } from '@/data/GenericStrings.json';

type NotificationStatus = 'success' | 'error' | '';

const route = useRoute();
const router = useRouter();

const { selectedItems, selectItems, resetSelection, columns } = useTable();
const {
  activeNav,
  setActiveNav,
  items: navItems,
} = useNav({ nav: INavOptions.UsersManagement, pathName: route.matched[0].name as string });

const isDeleted = computed(() => activeNav.value.value === 'DELETED');

const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const { deleteUserError, deleteUserSuccess, addUser, restoreSuccessful } = usersManagement;
const { genericError } = GenericStrings;

const submitted = ref(false);
const action = ref('');
const notificationPopup = ref(false);

const props = ref<GetUsersQueryVariables>({ filters: { role: activeNav.value.value as UserRole } });

const setAction = (opt: Option) => (action.value = opt.value);
const isApplyButtonDisabled = computed(
  () =>
    selectedItems.value.length <= 0 ||
    action.value === '' ||
    (userRole.value === 'JUNIOR' && isDeleted.value)
);

const { data, status, isLoading, refetch } = getUsers(props.value);

watch(
  activeNav,
  async () => {
    props.value.filters = {
      ...(!isDeleted.value && { role: activeNav.value.value as UserRole }),
      deleted: isDeleted.value,
    };
    submitted.value = false;
    action.value = '';
    await refetch();
  },
  { immediate: true }
);

const { getUsersQueryMapper } = useTableItemsMapper();

const items = computed(() =>
  getUsersQueryMapper({
    data: data.value,
    status: status.value,
    isLoading: isLoading.value,
    userRole: userRole.value,
  })
);

const user = ref<MutationSoftDeleteUserArgs | MutationRestoreUserArgs>({ userIds: [''] });

const enableDelete = (isHardDelete: boolean): ComputedRef<boolean> =>
  computed(
    () =>
      !!user.value &&
      action.value === 'delete' &&
      submitted.value &&
      isHardDelete === isDeleted.value
  );

/**
 * @param {number} id of table element
 * @return {void} action for selected elements
 */
const itemAction = (id: string) => router.push(`users/edit/${id}`);

/**
 * @return {void} action for selected elements
 */
const submit = () => {
  const usersSelected = selectedItems.value.map((item) => (item as (typeof items.value)[0]).id);
  if (action.value === 'delete') {
    user.value.userIds = usersSelected;
  }

  if (action.value === 'restore' && isDeleted.value) {
    user.value.userIds = usersSelected;
  }
  submitted.value = true;
};

const { isSuccess, isError } = softDeleteUser(user.value, { enabled: enableDelete(false) });
const { isSuccess: hardSuccess, isError: hardError } = hardDeleteUser(user.value, {
  enabled: enableDelete(true),
});

const enableRestore = computed(
  () =>
    !!user.value.userIds &&
    action.value === 'restore' &&
    submitted.value &&
    activeNav.value.value === 'DELETED'
);

const { isSuccess: successRestore, isError: errorRestore } = restoreUsers(user.value, {
  enabled: enableRestore,
});

watch([isSuccess, hardSuccess, successRestore], () => {
  if (isSuccess.value || hardSuccess.value || successRestore.value) {
    refetch();
  }
  submitted.value = false;
});

const messageNotification = computed(() => {
  switch (true) {
    case isSuccess.value:
      return {
        status: 'success',
        message: deleteUserSuccess,
      };

    case isError.value:
      return {
        status: 'error',
        message: deleteUserError,
      };

    case hardSuccess.value:
      return {
        status: 'success',
        message: deleteUserSuccess,
      };

    case hardError.value:
      return {
        status: 'error',
        message: deleteUserError,
      };

    case successRestore.value:
      return {
        status: 'success',
        message: restoreSuccessful,
      };

    default:
      return {
        status: 'error',
        message: genericError,
      };
  }
});

watch([isSuccess, isError, hardSuccess, hardError, successRestore, errorRestore], () => {
  if (
    isSuccess.value ||
    isError.value ||
    hardSuccess.value ||
    hardError.value ||
    successRestore.value ||
    errorRestore.value
  ) {
    notificationPopup.value = true;
    setTimeout(() => {
      notificationPopup.value = false;
    }, NOTIFICATION_DURATION);
  } else {
    notificationPopup.value = false;
  }
});
</script>
<template>
  <main class="UsersManagement container-fluid">
    <div class="container col-12">
      <h1 class="heading-h1 col-12">{{ strings.UsersManagement }}</h1>
    </div>
    <Nav class="col-12" :items="navItems" :active-item="activeNav.value" @clicked="setActiveNav">
      <template #content>
        <Button
          variant="primary"
          tag="router-link"
          to="/users/create"
          :label="addUser"
          icon="plus"
          :disabled="userRole !== 'ADMIN'"
        />
      </template>
    </Nav>
    <div class="container col-12">
      <div class="col-3 mt-2">
        <Select
          :placeholder="strings.selectAction"
          size="small"
          :options="!isDeleted ? usersManagement.options : usersManagement.optionsDeleted"
          @selected="setAction"
        />
      </div>
      <div class="col-2 mt-2">
        <Button
          :label="strings.apply"
          variant="secondary"
          native-type="submit"
          :disabled="isApplyButtonDisabled"
          @click="submit"
        />
      </div>
    </div>
    <div class="container col-12 mt-2">
      <div class="col-12">
        <Table
          :items="items || []"
          :columns="
            columns[
              !isDeleted ? ITableColumns.UsersManagement : ITableColumns.UsersManagementDelete
            ]
          "
          :action="itemAction"
          :is-loading="isLoading"
          icon="edit"
          is-small-row
          @select-items="selectItems"
          @reset-selection="resetSelection"
        />
      </div>
    </div>
    <Notification
      v-if="notificationPopup"
      :message="messageNotification.message"
      :status="(messageNotification.status as NotificationStatus)"
      :duration="NOTIFICATION_DURATION"
    />
  </main>
</template>

<style lang="scss" scope>
.UsersManagement {
  .Table__row-small {
    .o-table__td {
      padding-bottom: 2.7rem;
      vertical-align: middle;
    }
  }
}
</style>
