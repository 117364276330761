import { gql } from 'graphql-request';
import type {
  RemoveTalentLanguageMutationMutation,
  MutationRemoveTalentLanguageArgs,
} from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const removeTalentLanguageMutation = gql`
  mutation RemoveTalentLanguageMutation($languageId: String!, $talentId: String!) {
    removeTalentLanguage(languageId: $languageId, talentId: $talentId)
  }
`;

export const removeTalentLanguage = (
  props?: MutationRemoveTalentLanguageArgs,
  options?: UseQueryOptions<
    RemoveTalentLanguageMutationMutation,
    MutationRemoveTalentLanguageArgs,
    RemoveTalentLanguageMutationMutation,
    any[]
  >
): UseQueryReturnType<RemoveTalentLanguageMutationMutation, MutationRemoveTalentLanguageArgs> =>
  useGraphql({
    query: removeTalentLanguageMutation,
    variables: props,
    options,
  });
