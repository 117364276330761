import { gql } from '@/api/graphql-client';

export const residenceFragment = gql`
  fragment residence on Residence {
    address
    addressDetails
    city
    country
    province
    zipCode
  }
`;
