import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { MutationCreateCouponArgs, Coupon } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

export const createCouponMutation = gql`
  mutation CreateCoupon($data: CreateCouponInput!) {
    createCoupon(data: $data) {
      associatedDomain
      associatedEmail
      code
      discount
      experienceId
      expirationDate
      maxRedemptions
      name
    }
  }
`;

export const createCoupon = (
  props?: MutationCreateCouponArgs,
  options?: UseQueryOptions<Coupon, MutationCreateCouponArgs, Coupon, any[]>
): UseQueryReturnType<Coupon, MutationCreateCouponArgs> =>
  useGraphql({
    query: createCouponMutation,
    variables: props,
    options,
  });
