import { gql } from '@/api/graphql-client';
import { useGraphql } from '@/api/use-graphql';
import type { CommonAnalyticsQuery, QueryCommonAnalyticsArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';

const commonAnalyticsQuery = gql`
  query commonAnalytics($filters: CommonsAnalyticsFilterInput) {
    commonAnalytics(filters: $filters) {
      range
      totalCount
      expertises {
        expertise
        count
      }
    }
  }
`;

export const getCommonAnalytics = (
  props?: QueryCommonAnalyticsArgs,
  options?: UseQueryOptions<
    CommonAnalyticsQuery,
    QueryCommonAnalyticsArgs,
    CommonAnalyticsQuery,
    any[]
  >
): UseQueryReturnType<CommonAnalyticsQuery, QueryCommonAnalyticsArgs> =>
  useGraphql({
    query: commonAnalyticsQuery,
    variables: props,
    options,
  });
