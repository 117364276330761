<script setup lang="ts">
import { getEmailTemplates } from '@/api/queries/emailTemplates';
import { getEmailTemplateCommonData } from '@/api/queries/emailTemplatesCommonData';
import Select from '@/components/atoms/Select.vue';
import Nav from '@/components/molecules/Nav.vue';
import PickerGroup from '@/components/molecules/PickerGroup.vue';
import MailForm from '@/components/organisms/MailForm/MailForm.vue';
import { useMappedPickerEmail } from '@/composables/useMappedPickerEmail';
import { useMappedSelect } from '@/composables/useMappedSelect';
import { INavOptions, useNav } from '@/composables/useNav';
import string from '@/data/MailManagement.json';
import { TemplateCategory, TemplateType } from '@/gql/Gql.types';
import type { EmailTemplate, QueryEmailTemplatesArgs } from '@/gql/Gql.types';
import type { Option } from '@/models/selectModel';
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

const { title, placeholderSelect } = string;

const route = useRoute();

const { data: emailTemplateCommonData } = getEmailTemplateCommonData();

const {
  activeNav,
  setActiveNav,
  items: navItems,
} = useNav({ nav: INavOptions.Mail, pathName: route.matched[0].name as string });

const json = localStorage.getItem('mail-country') || '';

const country = ref(json ? JSON.parse(json) : '');
const isTemplateUpdated = ref(false);

const category = computed(
  (): TemplateCategory => TemplateCategory[activeNav.value.text as keyof typeof TemplateCategory]
);

const typologiesEmail = computed(() =>
  useMappedPickerEmail(emailTemplateCommonData?.value, category.value)
);

const typology = ref(typologiesEmail.value[0]?.value || '');

const setCountry = (opt: Option) => {
  if (opt.value.length < 1) return;
  country.value = opt;
  localStorage.setItem('mail-country', JSON.stringify(opt));
};

const params = reactive<QueryEmailTemplatesArgs>({
  filters: {
    category: category.value || '',
    typology: (typology.value as TemplateType) || '',
  },
});

const enabled = computed(() => !!params.filters?.category && !!params.filters.typology);

const { data, status, isLoading, refetch } = getEmailTemplates(params, { enabled: enabled });

watch(typologiesEmail, () => {
  if (typologiesEmail.value[0]) typology.value = typologiesEmail.value[0].value;
});

watch(category, () => {
  delete params.filters?.country;
  if (params.filters) params.filters.category = category.value;
});

watch(typology, () => {
  delete params.filters?.country;
  if (params.filters) params.filters.typology = typology.value as TemplateType;
});

const updateCountryAndTemplateStatus = async () => {
  isTemplateUpdated.value = false;
  country.value = JSON.parse(localStorage.getItem('mail-country') || '');
};

watch(isTemplateUpdated, async () => {
  if (!isTemplateUpdated.value) return;
  await refetch();
  await updateCountryAndTemplateStatus();
});

const templates = computed(() => {
  if (status.value !== 'success' || isLoading.value || !data.value || !data.value.emailTemplates)
    return [];

  return data.value.emailTemplates.flatMap((element) => {
    const { templates: emailTemplates } = element;
    return emailTemplates!.map((template) => {
      return {
        id: template.id,
        createdAt: template.createdAt,
        updatedAt: template.updatedAt,
        body: template.body,
        category: template.category,
        country: template.country,
        heading: template.heading,
        sign: template.sign,
        title: template.title,
        typology: template.typology,
        subject: template.subject,
        wishes: template.wishes,
      };
    });
  });
});

const getCountry = computed(() => templates.value.map((template) => template?.country));

const options = computed(() => useMappedSelect(getCountry.value));

watch(options, () => {
  if (country.value) return;
  country.value = options.value[0];
});

onMounted(() => {
  watchEffect(() => {
    if (country.value) return;
    country.value = options.value[0];
  });
});

const template = computed(
  (): EmailTemplate =>
    templates.value.filter((template) =>
      country.value
        ? template.country === country.value.value
        : template.country === options.value[0].value
    )[0]
);

const titleTemplate = computed(() => (template.value ? template.value.title : ''));
</script>

<template>
  <div class="mailManagement">
    <div class="container">
      <h1 class="col-12 heading-h1 col-12 mb-2">{{ title }}</h1>
    </div>
    <div class="container-fluid mb-2">
      <Nav
        class="col-12"
        :items="navItems"
        :active-item="activeNav.value"
        @clicked="setActiveNav"
      />
    </div>

    <div class="mailManagement-tabSelect container mb-1">
      <div class="col-8">
        <PickerGroup
          v-if="!!typology"
          :items="typologiesEmail"
          :initial-value="typology"
          @changed="(s: string) => typology = s"
        />
      </div>
      <div class="start-11 start-xl-12 col-2 col-xl-1">
        <o-skeleton
          v-if="!country && isLoading"
          width="16.6rem"
          height="6rem"
          :rounded="true"
          :animated="true"
        />
        <Select
          v-if="country"
          :label="placeholderSelect"
          size="small"
          :placeholder="placeholderSelect"
          :options="options"
          :initial-value="country"
          :keep-initial-value="true"
          @selected="setCountry"
        />
      </div>
    </div>

    <div class="container" v-if="titleTemplate">
      <h3 class="col-12 heading-h3 mb-2">{{ titleTemplate }}</h3>
    </div>
    <div class="container mb-2">
      <o-skeleton
        v-if="!template && isLoading"
        class="col-6 start-4 start-lx-3"
        width="100%"
        height="78rem"
        :animated="true"
      />
      <MailForm
        v-if="template"
        v-bind="{ ...template }"
        class="start-3 start-xl-4 col-8 col-xl-6"
        @update-success="(update: boolean) => isTemplateUpdated = update"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mailManagement {
  &-tabSelect {
    :deep(.PickerGroup) {
      .Radio--picker {
        text-transform: capitalize;
        min-width: max-content;
      }
    }
  }
}
</style>
