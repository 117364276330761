import { gql } from 'graphql-request';
import type { MutationHardDeleteTalentsArgs, HardDeleteTalentMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const hardDeleteTalentMutation = gql`
  mutation HardDeleteTalent($talentIds: [String!]!) {
    hardDeleteTalents(talentIds: $talentIds)
  }
`;

export const hardDeleteTalents = (
  props?: MutationHardDeleteTalentsArgs,
  options?: UseQueryOptions<
    HardDeleteTalentMutation,
    MutationHardDeleteTalentsArgs,
    HardDeleteTalentMutation,
    any[]
  >
): UseQueryReturnType<HardDeleteTalentMutation, MutationHardDeleteTalentsArgs> =>
  useGraphql({
    query: hardDeleteTalentMutation,
    variables: props,
    options,
  });
