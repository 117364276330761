import { gql } from '@/api/graphql-client';
import { usersFragment } from '@/api/fragments/users';
import type { MutationCreateUserArgs, User } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const createUserMutation = gql`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      ...node
    }
  }
  ${usersFragment}
`;

export const createUser = (
  props?: MutationCreateUserArgs,
  options?: UseQueryOptions<User, MutationCreateUserArgs, User, any[]>
): UseQueryReturnType<User, MutationCreateUserArgs> =>
  useGraphql({
    query: createUserMutation,
    variables: props,
    options,
  });
