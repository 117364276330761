<script setup lang="ts">
import { toRefs } from 'vue';
import ContentCard from '@/components/molecules/ContentCard.vue';
import type { IContentCardProps } from '@/models/contentCardModels';

interface ICardsBlockProps {
  title: string;
  label?: string;
  items: IContentCardProps[];
  splitted?: boolean;
}

const props = withDefaults(defineProps<ICardsBlockProps>(), {
  title: '',
  splitted: false,
});

const { title, label, items, splitted } = toRefs(props);
</script>

<template>
  <section class="CardsBlock">
    <div class="CardsBlock__head">
      <h2 class="CardsBlock__head-title heading-h3">{{ title }}</h2>
      <span class="CardsBlock__head-label text-body-2" v-if="label">{{ label }}</span>
    </div>
    <div :class="`CardsBlock__main ${splitted ? '--splitted' : ''}`">
      <ContentCard v-for="(card, index) in items" :key="card.title + index" v-bind="{ ...card }" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.CardsBlock {
  &:first-child {
    margin-bottom: 2.4rem;
  }

  &__head {
    @include flexing(row, space-between, center);
    margin: 0 0 2.4rem 0;

    &-label {
      text-transform: capitalize;
    }

    &-title {
      margin: 0;
    }
  }
  :deep {
    .ContentCard {
      border-radius: 0.4rem;
      border: 0.1rem solid $black-20;
      padding: 2.4rem;
    }
  }

  &__main {
    @include flexing(row, space-between, stretch);
    gap: 4rem;
    @include for-desktop {
      gap: 2.4rem;
    }

    &-contentCard {
      @include flexing(row, start, center);
      flex-wrap: wrap;
      gap: 0.8rem;
    }

    &.--splitted {
      @include flexing(row, start, start);
      border: 0.1rem solid $black-20;
      border-radius: 0.4rem;
      padding: 2.4rem;
      margin-bottom: 0;
      gap: 0;
      :deep {
        .ContentCard {
          padding: 0;
          border: none;
          margin-bottom: 0;
          &:last-child {
            margin-left: 4rem;
          }
          &:not(:last-child) {
            padding-right: 4rem;
            border-right: 0.1rem solid $black-20;
          }
        }
      }
    }
  }
}
</style>
