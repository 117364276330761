import { gql } from 'graphql-request';
import type { RejectTalentMutationMutation, MutationRejectTalentArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const rejectTalentMutation = gql`
  mutation RejectTalentMutation($status: TalentStatus!, $talentId: String!) {
    rejectTalent(status: $status, talentId: $talentId)
  }
`;

export const rejectTalent = (
  props?: MutationRejectTalentArgs,
  options?: UseQueryOptions<
    RejectTalentMutationMutation,
    MutationRejectTalentArgs,
    RejectTalentMutationMutation,
    any[]
  >
): UseQueryReturnType<RejectTalentMutationMutation, MutationRejectTalentArgs> =>
  useGraphql({
    query: rejectTalentMutation,
    variables: props,
    options,
  });
