import { UserRole } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import AddAgency from '@/views/Agencies/AddAgency.vue';
import Agencies from '@/views/Agencies/Agencies.vue';
import UpdateAgency from '@/views/Agencies/UpdateAgency.vue';
import AnalyticsOld from '@/views/Analytics.vue';
import Coupon from '@/views/Coupons/Coupon.vue';
import EditAddCouponVue from '@/views/Coupons/EditAddCoupon.vue';
import AddParticipant from '@/views/Experiences/AddParticipant.vue';
import Experiences from '@/views/Experiences/Experiences.vue';
import PartecipantTravel from '@/views/Experiences/ParticipantTravel.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import LostPassword from '@/views/LostPassword.vue';
import MailManagement from '@/views/Mail/MailManagement.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Settings from '@/views/Settings/Settings.vue';
import UpdateSkill from '@/views/Settings/UpdateSkill.vue';
import AddTalent from '@/views/Talents/AddTalent.vue';
import Talents from '@/views/Talents/Talents.vue';
import UpdateTalent from '@/views/Talents/UpdateTalent.vue';
import CreateUser from '@/views/Users/CreateUser.vue';
import UpdateUser from '@/views/Users/UpdateUser.vue';
import UsersManagement from '@/views/Users/UsersManagement.vue';
import Projects from '@/views/Projects/Projects.vue';
import Project from '@/views/Projects/Project.vue';
import AddTalents from '@/views/Projects/AddTalents.vue';
import Analytics from '@/views/Analytics/Analytics.vue';
import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory, type RouteRecordName } from 'vue-router';

type AccessibleRoutes = { [key in UserRole]?: RouteRecordName[] };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/users',
      name: 'UsersManagement',
      component: UsersManagement,
    },
    {
      path: '/users/create',
      name: 'CreateUser',
      component: CreateUser,
    },
    {
      path: '/users/edit/:id',
      name: 'UpdateUser',
      component: UpdateUser,
    },
    {
      path: '/lost-password',
      name: 'LostPassword',
      component: LostPassword,
    },
    {
      path: '/reset-password/:token?',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/talents',
      name: 'Talents',
      component: Talents,
    },
    {
      path: '/talents/edit/:id',
      name: 'UpdateTalent',
      component: UpdateTalent,
    },
    {
      path: '/talents/add',
      name: 'AddTalent',
      component: AddTalent,
    },
    {
      path: '/projects',
      name: 'Projects',
      component: Projects,
    },
    {
      path: '/projects/:id',
      name: 'Project',
      component: Project,
    },
    {
      path: '/projects/addTalents/:id',
      name: 'Project Add Talents',
      component: AddTalents,
    },
    {
      path: '/agencies',
      name: 'Agencies',
      component: Agencies,
    },
    {
      path: '/agencies/edit/:id',
      name: 'UpdateAgency',
      component: UpdateAgency,
    },
    {
      path: '/agencies/add',
      name: 'AddAgency',
      component: AddAgency,
    },
    {
      path: '/experiences',
      name: 'Experiences',
      component: Experiences,
    },
    {
      path: '/experiences/add/:id',
      name: 'AddParticipant',
      component: AddParticipant,
    },
    {
      path: '/experiences/details/:id',
      name: 'Experience',
      component: PartecipantTravel,
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: Analytics,
    },
    {
      path: '/mails',
      name: 'MailManagement',
      component: MailManagement,
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
    },
    {
      path: '/settings/edit/:name/:id',
      name: 'UpdateSkill',
      component: UpdateSkill,
    },
    {
      path: '/coupon',
      name: 'Coupon',
      component: Coupon,
    },
    {
      path: '/coupon/edit/:id',
      name: 'EditCoupon',
      component: EditAddCouponVue,
    },
    {
      path: '/coupon/add',
      name: 'AddCoupon',
      component: EditAddCouponVue,
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: Analytics,
    },
  ],
});

const publicPages: RouteRecordName[] = ['Login', 'LostPassword', 'ResetPassword'];
const allRoutes = router.getRoutes().map((route) => route.name);

// Routes that a user does not have access to and which are deleted from the route array
const excludeRoutes = (routes?: RouteRecordName[]) =>
  routes?.length
    ? (allRoutes.filter((route) => !routes.includes(route || '')) as RouteRecordName[])
    : (allRoutes as RouteRecordName[]);

// Routes that a user do have the just access to and can go
const onlyRoutes = (routes?: RouteRecordName[]) =>
  routes?.length
    ? (allRoutes.filter((route) => routes.includes(route || '')) as RouteRecordName[])
    : [];

// todo: update routes accessibility by role by Name of the route
const accessibleRoutes: AccessibleRoutes = {
  [UserRole.Admin]: excludeRoutes(),
  // [UserRole.Client]: excludeRoutes(),
  [UserRole.EventManager]: onlyRoutes([
    'Home',
    'Experiences',
    'Experience',
    'AddParticipant',
    'Coupon',
    'UpdateUser',
  ]),
  [UserRole.Junior]: onlyRoutes(['Home', 'Talents', 'Agencies', 'UpdateUser', 'Projects']),
  // [UserRole.Mentor]: excludeRoutes(),
  [UserRole.Supervisor]: onlyRoutes([
    'Home',
    'Talents',
    'Agencies',
    'UpdateUser',
    'UpdateTalent',
    'UpdateAgency',
    'Projects',
  ]),
};

router.beforeEach(async (to) => {
  const isAuthRequired = !publicPages.includes(<RouteRecordName>to.name);

  const userStore = useUserStore();
  const { jwt, returnUrl, userRole } = storeToRefs(userStore);

  if (
    jwt.value &&
    !accessibleRoutes[userRole.value as UserRole]?.includes(to.name as RouteRecordName)
  ) {
    return { name: 'Home' };
  }

  /**
   * redirect to homepage when trying to access to public routes
   */
  if (jwt.value && !isAuthRequired) {
    return { name: 'Home' };
  }

  /**
   * redirect to login page if trying to access private pages
   * returnUrl redirect user to the requested page after logging in
   */
  if (isAuthRequired && !jwt.value) {
    returnUrl.value = to.fullPath;
    return { name: 'Login' };
  }

  /**
   * role base navigation guards
   */
  const role: UserRole = userRole.value;
  if ([UserRole.Admin].includes(role)) return;
});

export default router;
