<script setup lang="ts">
import { getCommonAnalytics } from '@/api/queries/commonAnalytics';
import { getUser } from '@/api/queries/user';
import { getUserAnalytics } from '@/api/queries/userAnalytics';
import Avatar from '@/components/atoms/Avatar.vue';
import Tab from '@/components/atoms/Tab.vue';
import CardsBlock from '@/components/molecules/CardsBlock.vue';
import RecapListItem from '@/components/molecules/RecapListItem.vue';
import { useMappedCommonAnalytics } from '@/composables/analytics/useMappedCommonAnalytics';
import HomeString from '@/data/Home.json';
import { EntityType, TimeRange } from '@/gql/Gql.types';
import { useUserStore } from '@/stores/user';
import moment from 'moment';
import 'moment/dist/locale/it';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

const { welcome, contentCard } = HomeString;
const userStore = useUserStore();
const { userId, userRole } = storeToRefs(userStore);

const { data: user } = getUser({ userId: (userId.value as string) ?? '' });

const { data: weekAnalytics } = getUserAnalytics();

const { data: todayTalents, isLoading: todayTalentsLoading } = getCommonAnalytics({
  filters: { entity: EntityType.Talent, timeRange: [TimeRange.Today] },
});

const { data: todayAgencies, isLoading: todayAgenciesLoading } = getCommonAnalytics({
  filters: { entity: EntityType.Agency, timeRange: [TimeRange.Today] },
});

const { data: weekTalents, isLoading: weekLoading } = getCommonAnalytics({
  filters: { entity: EntityType.Talent, timeRange: [TimeRange.Week] },
});

const { data: monthTalents, isLoading: monthLoading } = getCommonAnalytics({
  filters: { entity: EntityType.Talent, timeRange: [TimeRange.Month] },
});

const dataToday = computed(() =>
  useMappedCommonAnalytics({
    elements: [
      { item: todayTalents.value, type: 'talents' },
      { item: todayAgencies.value, type: 'agencies' },
    ],
    key: 'today',
  })
);

const dataPeriod = computed(() =>
  useMappedCommonAnalytics({
    elements: [
      { item: weekTalents.value, type: 'week' },
      { item: monthTalents.value, type: 'month' },
    ],
    key: 'period',
  })
);

const talentsValued = computed(() => {
  const count = weekAnalytics.value?.userAnalytics.analytics[0].totalCount;
  if (count === 1) {
    return welcome.subtitle.replace('{num} new Talents', String(count) + ' new Talent');
  }
  return welcome.subtitle.replace('{num}', String(count ?? 0));
});

const userFullname = computed(() => {
  const fullname = user.value?.user
    ? `${user.value?.user?.firstname} ${user.value?.user?.lastname}`
    : '';
  if (!fullname) return '';
  if (fullname.length >= 9 && window.innerWidth <= 1400) {
    return fullname.substring(0, 9) + '...';
  }
  return fullname;
});

const fullname = computed(() =>
  user.value?.user ? `${user.value?.user?.firstname} ${user.value?.user?.lastname}` : ''
);

const firstName = computed(() => {
  return (fullname: string) => {
    if (!fullname) return '';
    const [firstname] = fullname.split(' ');
    return firstname;
  };
});

const tabSelection = [
  {
    name: 'screening',
    label: 'Screening',
  },
  {
    name: 'interview',
    label: 'Interview',
  },
];

const pickerActive = ref(tabSelection[0].name ?? '');

const select = (s: string) => {
  pickerActive.value = s;
};

const screening = computed(() => weekAnalytics.value?.userAnalytics.analytics[0].screening);
const interview = computed(() => weekAnalytics.value?.userAnalytics.analytics[0].interview);

const selectList = computed(() => {
  if (pickerActive.value.toLowerCase() === 'screening') {
    return screening.value?.expertises;
  }
  if (pickerActive.value.toLowerCase() === 'interview') {
    return interview.value?.expertises;
  }
});

const { titleToDoToday, titleCosmico } = contentCard;
</script>

<template>
  <div class="container">
    <div class="col-4 col-xl-5" v-if="userFullname">
      <div class="WelcomeBlock">
        <Avatar :full-name="fullname" size="medium" />
        <h1 class="WelcomeBlock-title heading-h1">
          {{ welcome.welcome }} <br />{{ firstName(userFullname) }}
        </h1>
        <div
          class="WelcomeBlock-subtitle heading-h4"
          v-if="userRole !== 'EVENT_MANAGER'"
          v-html="talentsValued"
        ></div>
        <Tab
          v-if="selectList && userRole !== 'EVENT_MANAGER'"
          class="WelcomeBlock-tab"
          :items="tabSelection"
          @clicked="select"
          :initial-active="tabSelection[0].name"
        >
          <template #content>
            <RecapListItem
              class="WelcomeBlock-recapList"
              v-for="(list, index) in selectList"
              :key="index"
              :count="list.count"
              :title="list.expertise"
            ></RecapListItem>
          </template>
        </Tab>
      </div>
    </div>
    <o-skeleton class="col-4 col-xl-5" v-else :animated="true" height="100%" />
    <div class="col-8 col-xl-7 column-cardBlock">
      <o-skeleton
        v-if="todayTalentsLoading || todayAgenciesLoading"
        class="mb-4"
        :animated="true"
        height="30rem"
      />
      <CardsBlock
        v-else
        :title="titleToDoToday"
        :label="moment().locale('en').format('dddd D MMMM YYYY')"
        :items="dataToday"
      ></CardsBlock>
      <o-skeleton v-if="weekLoading || monthLoading" :animated="true" height="30rem" />
      <CardsBlock v-else :title="titleCosmico" :items="dataPeriod" :splitted="true"></CardsBlock>
    </div>
  </div>
</template>

<style scoped lang="scss">
.WelcomeBlock {
  border: 0.1rem solid $black-20;
  border-radius: 0.4rem;
  padding: 2.4rem;
  height: 100%;

  &-title {
    margin: 0 0 1.6rem 0;
  }

  &-subtitle {
    margin: 0 0 6.9rem 0;
    color: $black-50;

    :deep {
      strong {
        color: $primary-color;
      }
    }
  }

  &-tab {
    :deep {
      .o-tabs__nav {
        margin-bottom: 2.4rem;
      }
    }
  }

  &-recapList {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }

    &:last-child {
      border: none;
      padding: 0;
    }
  }
}

.column-cardBlock {
  @include flexing(column, space-around, normal);
}
</style>
