import { gql } from 'graphql-request';
import type { MigrateToAgencyMutationMutation, MutationMigrateToAgencyArgs } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const migrateToAgencyMutation = gql`
  mutation MigrateToAgencyMutation($talentId: String!) {
    migrateToAgency(talentId: $talentId)
  }
`;

export const migrateToAgency = (
  props?: MutationMigrateToAgencyArgs,
  options?: UseQueryOptions<
    MigrateToAgencyMutationMutation,
    MutationMigrateToAgencyArgs,
    MigrateToAgencyMutationMutation,
    any[]
  >
): UseQueryReturnType<MigrateToAgencyMutationMutation, MutationMigrateToAgencyArgs> =>
  useGraphql({
    query: migrateToAgencyMutation,
    variables: props,
    options,
  });
