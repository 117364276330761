import { gql } from '@/api/graphql-client';

export const usersFragment = gql`
  fragment node on User {
    id
    calendlyUrl
    createdAt
    deletedAt
    email
    firstname
    lastname
    role
    sign
    updatedAt
    username
  }
`;
