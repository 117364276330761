import { gql } from 'graphql-request';
import type { MutationSoftDeleteTalentsArgs, SoftDeleteTalentsMutation } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const softDeleteTalentsMutation = gql`
  mutation SoftDeleteTalents($talentIds: [String!]!) {
    softDeleteTalents(talentIds: $talentIds)
  }
`;

export const softDeleteTalents = (
  props?: MutationSoftDeleteTalentsArgs,
  options?: UseQueryOptions<
    SoftDeleteTalentsMutation,
    MutationSoftDeleteTalentsArgs,
    SoftDeleteTalentsMutation,
    any[]
  >
): UseQueryReturnType<SoftDeleteTalentsMutation, MutationSoftDeleteTalentsArgs> =>
  useGraphql({
    query: softDeleteTalentsMutation,
    variables: props,
    options,
  });
