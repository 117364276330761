<script setup lang="ts">
import { toRefs } from 'vue';
import strings from '@/data/GenericStrings.json';
import Highlight from '@/components/atoms/Highlight.vue';

type ITableNameView = {
  name?: string;
  email: string;
  lastEdit: string;
  createdAt: string;
  companyName?: string;
  highlightText?: string;
};

const props = withDefaults(defineProps<ITableNameView>(), {
  createdAt: '',
  email: '',
  name: '',
  lastEdit: '',
});
const { email, name, lastEdit, createdAt } = toRefs(props);
</script>

<template>
  <div class="TableNameView">
    <div class="TableNameView__wrap">
      <span class="text-body-2">
        <Highlight :text="name || (companyName ?? name)" :highlight-text="highlightText" />
      </span>
      <span class="text-body-2 --black-50">
        <Highlight :text="email" :highlight-text="highlightText" />
      </span>
    </div>
    <div class="TableNameView__wrap">
      <span class="text-body-2">
        <Highlight :text="strings.createdAt" :highlight-text="highlightText" />
      </span>
      <span class="text-body-2 --black-50">
        <Highlight :text="createdAt" :highlight-text="highlightText" />
      </span>
    </div>
    <div class="TableNameView__wrap">
      <span class="text-body-2">
        <Highlight :text="strings.lastEdit" :highlight-text="highlightText" />
      </span>
      <span class="text-body-2 --black-50">
        <Highlight :text="lastEdit" :highlight-text="highlightText" />
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.TableNameView {
  @include flexing(column);

  @media screen and (max-width: 1440px) {
    width: 8rem;
  }

  &__wrap {
    @include flexing(column);
    margin-bottom: 0.8rem;

    span {
      word-break: break-word;
    }
  }
}
</style>
