import { gql } from '@/api/graphql-client';
import type { QueryUserArgs, User } from '@/gql/Gql.types';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useGraphql } from '@/api/use-graphql';

export const userQuery = gql`
  query getUser($userId: String!) {
    user(userId: $userId) {
      id
      calendlyUrl
      createdAt
      deletedAt
      email
      firstname
      lastname
      role
      sign
      updatedAt
      username
    }
  }
`;

export const getUser = (
  props?: QueryUserArgs,
  options?: UseQueryOptions<{ user: User }, QueryUserArgs, { user: User }, any[]>
): UseQueryReturnType<{ user: User }, QueryUserArgs> =>
  useGraphql({
    query: userQuery,
    variables: props,
    options,
  });
