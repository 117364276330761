<script setup lang="ts">
import Button from '../atoms/Button.vue';
import CreateUser from '@/data/CreateUser.json';
import FormGroup from './FormGroup.vue';
import generatePassword from '@/composables/generatePassword';
import InputText from '../atoms/InputText.vue';
import PasswordGenerator from './PasswordGenerator.vue';
import Radio from '../atoms/Radio.vue';
import { PASSWORD_LENGTH } from '@/data/constants';
import { ref, reactive, toRefs, computed } from 'vue';
import { useForm } from 'vee-validate';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const { fullPath } = useRoute();
const userStore = useUserStore();
const { userRole } = storeToRefs(userStore);

const isEdit = computed(() => fullPath.includes('edit'));

type UserData = {
  email?: string;
  firstname?: string;
  lastname?: string;
  password?: string;
  role?: string;
  username?: string;
  calendlyUrl?: string;
  sign?: string;
};

const props = withDefaults(defineProps<UserData>(), {
  email: '',
  firstname: '',
  lastname: '',
  role: '',
  username: '',
  password: '',
  calendlyUrl: '',
  sign: '',
});

const { email, calendlyUrl, lastname, role, username, password, firstname, sign } = toRefs(props);

const user = reactive({
  email: email.value || '',
  firstname: firstname.value || '',
  lastname: lastname.value || '',
  password: password.value || '',
  role: role.value || '',
  sign: sign.value || '',
  username: username.value || '',
  calendlyUrl: calendlyUrl.value || '',
});

const { role: roleString, registry, passwordForm, calendly, submit, updateUserSubmit } = CreateUser;
const { handleSubmit } = useForm({ initialValues: { ...user } });

const passwordValue = ref(password.value ?? generatePassword(PASSWORD_LENGTH));

const emits = defineEmits(['userData', 'resetForm']);

const onSubmit = handleSubmit((values, actions) => {
  emits('userData', Object.assign(user, values));
  emits('resetForm', actions.resetForm);
});

const getInitialValue = (name: string) => user[name as keyof typeof user] ?? '';
</script>

<template>
  <form class="AddUser Form-group" @submit="onSubmit">
    <FormGroup :title="roleString.title">
      <template #formContent>
        <div class="AddUser__role-subtitle text-body-1">{{ roleString.subtitle }}</div>
        <div v-for="(radio, index) in roleString.roles" :key="index" class="AddUser__role-radio">
          <Radio
            as="radio"
            :description="radio.description"
            :label="radio.label"
            :name="radio.name"
            :rules="{ required: true }"
            :value="radio.value"
            :initial-value="getInitialValue(radio.name)"
            :disabled="userRole === 'JUNIOR'"
          />
        </div>
      </template>
    </FormGroup>

    <FormGroup :title="registry.title">
      <template #formContent>
        <div class="AddUser__registry-input">
          <InputText
            v-for="(input, index) in registry.input"
            :key="index"
            :label="input.label"
            :placeholder="input.placeholder"
            :name="input.name"
            :initial-value="getInitialValue(input.name)"
            type="text"
            :rules="
              input.label.toLowerCase() !== 'email'
                ? { required: true }
                : { email: true, required: true }
            "
            :disabled="userRole === 'JUNIOR'"
          />
        </div>
      </template>
    </FormGroup>

    <FormGroup class="AddUser__password" :title="passwordForm.title">
      <template #formContent>
        <PasswordGenerator
          :cta="passwordForm.cta"
          :input="passwordForm.input"
          :password="passwordValue"
          :required="isEdit ? false : true"
          :lenght-pass="PASSWORD_LENGTH"
          :initial-value="getInitialValue(passwordForm.input.name)"
          :disabled="userRole === 'JUNIOR'"
        />
      </template>
    </FormGroup>

    <FormGroup class="AddUser__calendly" :title="calendly.title">
      <template #formContent>
        <InputText
          :label="calendly.input.label"
          :placeholder="calendly.input.placeholder"
          :name="calendly.input.name"
          :initial-value="user.calendlyUrl ?? ''"
          type="text"
          :rules="{ url: true }"
          :disabled="userRole === 'JUNIOR'"
        />
      </template>
    </FormGroup>

    <div class="AddUser__submit">
      <Button
        :label="!user.email ? submit : updateUserSubmit"
        native-type="submit"
        :disabled="userRole === 'JUNIOR'"
      />
    </div>
  </form>
</template>

<style scoped lang="scss">
.AddUser {
  width: 100%;

  &__role {
    &-subtitle {
      margin-bottom: 1.5rem;
    }

    &-radio {
      margin-bottom: 1.6rem;

      :deep {
        &:not(:last-child) .error-message {
          display: none;
        }
      }
    }
  }

  &__registry-input {
    :deep .InputText {
      margin-bottom: 1rem;
    }
  }

  &__password {
    :deep {
      .FormGroup-title {
        margin-bottom: 2.4rem;
      }

      .Button,
      .InputText {
        margin-bottom: 2.4rem;
      }
    }
  }

  &__calendly {
    :deep .FormGroup-title {
      margin-bottom: 2.4rem;
    }
  }

  &__submit {
    margin-top: 3.2rem;
    text-align: center;
  }
}
</style>
