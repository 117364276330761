import { gql } from '@/api/graphql-client';
import { pageInfo } from '@/api/fragments/pageInfo';
import { useGraphql } from '@/api/use-graphql';
import type { UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import type { GetSkillsQuery, QuerySkillsArgs } from '@/gql/Gql.types';
import { skillFragment } from '@/api/fragments/skill';

const skillsQuery = gql`
  query getSkills($after: String, $before: String, $first: Int, $last: Int) {
    skills(after: $after, before: $before, first: $first, last: $last) {
      edges {
        cursor
        node {
          ...skill
        }
      }
      pageInfo {
        ...pageInfo
      }
      totalCount
    }
  }
  ${skillFragment}
  ${pageInfo}
`;

export const getSkills = (
  props?: QuerySkillsArgs,
  options?: UseQueryOptions<GetSkillsQuery, QuerySkillsArgs, GetSkillsQuery, any[]>
): UseQueryReturnType<GetSkillsQuery, QuerySkillsArgs> =>
  useGraphql({
    query: skillsQuery,
    variables: props,
    options,
  });
