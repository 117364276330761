<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';

type ITabProps = {
  items: { label: string; name: string }[];
  initialActive?: string;
  isDisabled?: boolean[];
};

const props = defineProps<ITabProps>();
const { items, initialActive, isDisabled } = toRefs(props);

const activeTab = ref(initialActive?.value ?? '');

watch(
  () => initialActive?.value,
  (newValue) => {
    activeTab.value = newValue ?? '';
  }
);

const emit = defineEmits(['clicked']);

watch(activeTab, () => {
  emit('clicked', activeTab.value);
});
</script>

<template>
  <o-tabs
    :class="['Tab', items.length > 4 ? '--scrollX' : '']"
    v-model="activeTab"
    type="toggle"
    :animated="false"
  >
    <o-tab-item
      v-for="(item, index) in items"
      :key="index"
      :value="item.name"
      :label="item.label"
      :disabled="isDisabled && isDisabled![index]"
    >
      <slot name="content"></slot>
    </o-tab-item>
  </o-tabs>
</template>

<style scoped lang="scss">
.Tab {
  &.--scrollX {
    overflow-x: scroll;
    max-width: max-content;
    width: auto;
    padding-bottom: 0.4rem;
  }

  &::-webkit-scrollbar {
    height: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background: $black-20;
    border-radius: 4rem;
    background-clip: padding-box;
  }

  :deep {
    .o-tabs__nav {
      @include flexing(row, start, center);
      width: max-content;
      background-color: $black-04;
      border-radius: 7.2rem;

      &-item {
        @extend .text-body-2;
        height: 4rem;
        color: $black-50;
        border-radius: 7.2rem;
        padding: 1.1rem 2.4rem;

        &:hover {
          color: $primary-color;
        }

        &-toggle--active {
          background-color: $primary-color;
          color: $secondary-color;

          &:hover {
            color: $secondary-color;
          }
        }

        &-default--disabled {
          color: $black-20;
        }
      }
    }
  }
}
</style>
