import { COMMON_DATA_CACHETIME } from '@/data/constants';
import { computed } from 'vue';
import { defineStore } from 'pinia';
import { getTalentCommonData } from '@/api/queries/talentCommonData';

export const useTalentCommonData = defineStore('talentCommonData', () => {
  const { data } = getTalentCommonData({ cacheTime: COMMON_DATA_CACHETIME });

  const collaborations = computed(() => data.value?.talentCommonData.collaborations);
  const industries = computed(() => data.value?.talentCommonData.industries);
  const languages = computed(() => data.value?.talentCommonData.languages);
  const rates = computed(() => data.value?.talentCommonData.rates);
  const seniorities = computed(() => data.value?.talentCommonData.seniorities);
  const specialities = computed(() => data.value?.talentCommonData.specialities);
  const status = computed(() => data.value?.talentCommonData.status);
  const tools = computed(() => data.value?.talentCommonData.tools);
  const workplaces = computed(() => data.value?.talentCommonData.workplaces);
  const countries = computed(() => data.value?.talentCommonData.countries);

  return {
    collaborations,
    industries,
    languages,
    rates,
    seniorities,
    specialities,
    status,
    tools,
    workplaces,
    countries,
  };
});
