import { configure, defineRule } from 'vee-validate';
// prettier-ignore
// eslint-disable-next-line camelcase
import { required, email, min, max, confirmed, one_of, numeric, min_value, max_value } from "@vee-validate/rules";

import ErrMsg from '@/data/CustomErrorMessage.json';

defineRule('required', required);
defineRule('min', min);
defineRule('max', max);
defineRule('email', email);

defineRule('requiredAutocomplete', required);
defineRule('confirmed', confirmed);
defineRule('one_of', one_of);

defineRule('numeric', numeric);
defineRule('min_value', min_value);
defineRule('max_value', max_value);

defineRule('url', (value: unknown) => {
  if (value) {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );

    if (!urlPattern.test(String(value))) {
      return ErrMsg.url;
    }
    return true;
  }
  return true;
});

defineRule('password', (value: unknown) => {
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-_,.:;'"~<>/(){}[\]|=]).*$/;

  if (!passwordRegex.test(String(value))) {
    return ErrMsg.password;
  }

  return true;
});

type Upload = {
  name: string;
};

defineRule('pdf', (value: unknown | Upload) => {
  if (value) {
    const fileName = String((value as Upload).name);
    const ext = fileName.split('.').pop();

    if (ext !== 'pdf') {
      return ErrMsg.pdf;
    }
    return true;
  }
  return true;
});

defineRule('date_after_today', (value: unknown) => {
  if (value) {
    const currentDate = new Date();
    const inputDate = new Date(value as string);

    if (inputDate.setHours(0, 0, 0, 0) == currentDate.setHours(0, 0, 0, 0)) {
      return true;
    } else if (inputDate < currentDate) {
      return ErrMsg.date_after_today;
    }
    return true;
  }
  return true;
});

defineRule('invalid_char', (value: string) => {
  if (value.includes('<') || value.includes('>')) {
    return ErrMsg.invalid_char;
  }

  return true;
});

export function useCustomMessage() {
  configure({
    generateMessage: ({ rule }) => {
      const messages: { [key: string]: string } = {
        min: ErrMsg.min.replace('{num}', rule?.params?.toString() ?? ''),
        max: ErrMsg.max.replace('{num}', rule?.params?.toString() ?? ''),
        numeric: ErrMsg.numeric,
        min_value: ErrMsg.min_value.replace('{num}', rule?.params?.toString() ?? ''),
        max_value: ErrMsg.max_value.replace('{num}', rule?.params?.toString() ?? ''),
        required: ErrMsg.required,
        email: ErrMsg.email,
        requiredAutocomplete: ErrMsg.requiredAutocomplete,
        one_of: ErrMsg.one_of,
        url: ErrMsg.url,
        confirmed: ErrMsg.confirmed,
      };
      return rule?.name && messages[rule?.name] ? messages[rule?.name] : ErrMsg.default;
    },
  });
}
