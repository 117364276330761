<script setup lang="ts">
import { toRefs } from 'vue';
import Highlight from '@/components/atoms/Highlight.vue';

type ITableSimpleTextProps = {
  items: string[];
  highlightText?: string;
};

const props = withDefaults(defineProps<ITableSimpleTextProps>(), {
  items: () => [],
});

const { items } = toRefs(props);
</script>

<template>
  <div class="TableSimpleText">
    <span
      v-for="(item, index) in items"
      :key="index + item"
      class="TableSimpleText__item text-body-2"
    >
      <Highlight :text="item" :highlight-text="highlightText" />
    </span>
  </div>
</template>

<style scoped lang="scss">
.TableSimpleText {
  @include flexing(column);

  &__item:not(:last-child)::after {
    content: ', ';
  }
}
</style>
