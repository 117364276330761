<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';
import { useAuthenticateExternalDomain } from '@/api/mutations/authenticateExternalDomain';

const { mutate } = useAuthenticateExternalDomain();

onMounted(() => {
  document.body.style.overflow = 'hidden';
  mutate({ externalDomain: 'analytics.wearecosmico.com' });
});

onUnmounted(() => {
  document.body.style.overflow = 'auto';
});
</script>

<template>
  <iframe
    src="https://analytics.wearecosmico.com/?status=ALL&iframe=true"
    width="100%"
    height="100%"
    frameborder="0"
  />
</template>
<style scoped lang="scss">
iframe {
  width: 100%;
  min-height: 100vh;
  border: none;
  overflow: auto;
}
</style>
