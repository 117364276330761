// TODO UPDATE WITH AGENCIES
interface IData {
  counter: { _count: number; status: string }[] | undefined;
  tabItems: { label: string; name: string }[];
}

export const useTabItemsMapper = ({ counter, tabItems }: IData) => {
  if (counter === undefined || counter.length <= 0) {
    return tabItems.map(({ label, name }) => {
      return { label: label + ' 0', name: name };
    });
  }
  return tabItems.map(({ label, name }) => {
    const mappedCounter = counter.find((item) => item.status === name);

    if (mappedCounter) {
      return { label: `${label} ${mappedCounter._count}`, name: name };
    }
  }) as {
    label: string;
    name: string;
  }[];
};
