<script setup lang="ts">
import Label from '@/components/atoms/Label.vue';
import Button from '@/components/atoms/Button.vue';
import { toRefs } from 'vue';
import type { IRecapListItemProps } from '@/models/recapListItemModel';

const props = withDefaults(defineProps<IRecapListItemProps>(), {
  title: '',
  count: 0,
});

const { title, count, items, to } = toRefs(props);
</script>

<template>
  <div class="RecapListItem">
    <div class="RecapListItem__head">
      <div class="RecapListItem__head-title">
        <span class="text-body-1">{{ title }}</span>
        <Button
          v-if="to"
          variant="primary"
          type="indicator"
          icon="chevron-right"
          tag="router-link"
          :to="to"
        />
      </div>
      <span class="RecapListItem__head-count heading-h5">{{ count }}</span>
    </div>
    <div class="RecapListItem__main" v-if="items">
      <Label v-for="(item, index) in items" :key="index" :label="item.label" :total="item.count" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.RecapListItem {
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid $black-20;
  &__head {
    @include flexing(row, space-between, center);

    &-title {
      @include flexing(row, start, center);
      gap: 1.6rem;
    }

    &-count {
      color: $accent;
    }
  }

  &__main {
    @include flexing(row, start, center);
    gap: 0.8rem;
    flex-wrap: wrap;
    margin-top: 1.8rem;
  }
}
</style>
